import * as ActionTypes from './ActionTypes';

export const CreditSimulatorAccounts = (
	state = {
		isLoading: true,
		errMess: null,
		creditSimulatorAccounts: []
	},
	action

) => {

	switch (action.type) {
		case ActionTypes.ADD_CREDIT_SIMULATOR_ACCOUNTS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				creditSimulatorAccounts: action.payload
			};
		case ActionTypes.CREDIT_SIMULATOR_ACCOUNTS_LOADING:
			return { ...state, isLoading: true, errMess: null, creditSimulatorAccounts: [] };
		case ActionTypes.CREDIT_SIMULATOR_ACCOUNTS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				creditSimulatorAccounts: []
			};
		default:
			return state;
	}
};
