import * as ActionTypes from './ActionTypes';

export const ShoppingCart = (
	state = {
		isLoading: true,
		errMess: null,
		shoppingCart: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_SHOPPING_CART_ITEMS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				shoppingCart: action.payload
			};
		case ActionTypes.SHOPPING_CART_ITEMS_LOADING:
			return { ...state, isLoading: true, errMess: null, shoppingCart: [] };
		case ActionTypes.SHOPPING_CART_ITEMS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				shoppingCart: []
			};
		default:
			return state;
	}
};
