import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestPath } from '../../redux/util/util';
import { Loading } from '../Loading/LoadingComponent';
import styles from './KredGuruSection.module.css';

class KredGuruSection extends Component {
	constructor() {
		super();
		this.state = {
			initiatedDovs: false,
			enquiryID: null,
			enquiryResultID: null,
			isLoading: false,
			successfullyVerified: false,
			errorMessage: false,
		};
	}

	componentDidMount() {
		this.setState({
			initiatedDovs: false,
			enquiryID: null,
			enquiryResultID: null,
			isLoading: false,
			successfullyVerified: false,
			errorMessage: false,
		});
	}

	handleDovsVerification = async () => {
		if (this.props && this.props.user) {
			const { idNumber, cellphoneNumber } = this.props.user.user;

			try {
				this.setState({
					isLoading: true,
					errorMessage: false,
				});
				const response = await fetch(requestPath('/api/initiate-dovs'), {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						idNumber: idNumber,
						cellularNo: cellphoneNumber,
						reference: '',
						solutionTransactionId: 'Splendi DOVS',
						entity: 'Splendi',
					}),
				});

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const data = await response.json();

				this.setState({
					enquiryID: data.response.enquiryID,
					enquiryResultID: data.response.enquiryResultID,
					initiatedDovs: true,
					isLoading: false,
				});
			} catch (error) {
				this.setState({
					...this.state,
					isLoading: false,
					successfullyVerified: false,
					errorMessage: true,
				});
				console.error('Error initiating verification:', error);
			}
		}
	};

	getDovsResult = async () => {
		if (this.state.enquiryID && this.state.enquiryResultID) {
			try {
				this.setState({
					...this.state,
					isLoading: true,
					successfullyVerified: false,
					errorMessage: false,
				});
				const response = await fetch(requestPath('/api/get-dovs-result'), {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						enquiryID: this.state.enquiryID,
						enquiryResultID: this.state.enquiryResultID,
					}),
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Couldn't reach the DOVS service: ${response.status}`);
				}

				this.setState({
					...this.state,
					isLoading: false,
					successfullyVerified: true,
				});

				setTimeout(() => {
					window.location.href = '/credit-report';
				}, 3000);
			} catch (error) {
				this.setState({
					...this.state,
					isLoading: false,
					successfullyVerified: false,
					errorMessage: true,
				});
				console.error('Error occurred while getting DOVS result :', error);
			}
		}
	};

	render() {
		return (
			<div className={styles.dovsModalContainer}>
				<div onClick={() => this.props.toggleModal()} className={styles.closeDovsIcon}>
					<img src="assets/img/xmark-solid.svg" alt="close icon" width={20} />
				</div>
				<MDBRow className="d-flex align-items-center">
					<div className="speech-bubble pt-3 pl-3 pr-3 pb-1 w-75">
						<p>Please complete the facial verification to view your full credit report.</p>

						<ul className={`mt-4 ${styles.listStyle} d-flex flex-column gap-2`}>
							<li>Click on the button below to initiate the verification</li>
							<li>A verification SMS will be sent to you</li>
							<li>Do not leave this page</li>
						</ul>
					</div>
					<div className="w-25 text-center">
						<img
							src={`/assets/img/kred_guru_+2.svg`}
							className="img-fluid kredGuruImg"
							alt="kred guru"
							width={104}
						/>
					</div>
				</MDBRow>
				<MDBRow className="d-flex justify-content-center">
					{this.state.initiatedDovs === true ? (
						<button className={styles.resetButton} onClick={this.getDovsResult}>
							Check Verification Status
						</button>
					) : (
						<button className={styles.resetButton} onClick={this.handleDovsVerification}>
							Complete Verification
						</button>
					)}
				</MDBRow>
				{this.state.successfullyVerified ? (
					<p className="mt-3 text-center" style={{ color: '#5151f9' }}>
						You have been successfully verified!
					</p>
				) : null}
				{this.state.errorMessage ? (
					<p className="mt-3 text-center" style={{ color: 'red' }}>
						No result found, please complete the verification SMS. For assistance, please contact
						info@splendi.co.za.
					</p>
				) : null}
				{this.state.isLoading ? (
					<div className="mt-3">
						<Loading />
					</div>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
const mapDispatchToProps = () => ({});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(KredGuruSection)
);
