import * as ActionTypes from './ActionTypes';

export const AirtimeVASProducts = (state={
    isLoading: true,
    errMess: null,
    airtimeVASProducts: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_VAS_AIRTIME_PRODUCTS:
            return {...state, isLoading: false, errMess:null, airtimeVASProducts: action.payload};
        case ActionTypes.VAS_AIRTIME_PRODUCTS_LOADING:
            return {...state, isLoading: true, errMess:null, airtimeVASProducts: []};
        case ActionTypes.VAS_AIRTIME_PRODUCTS_FAILED:
            return {...state, isLoading: false, errMess:action.payload, airtimeVASProducts: []};
        default:
            return state;
    }
}