import React, { Component } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import './PopoverComponent.css';

class PopoverComponent extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			popoverOpen: false,
		};
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen,
		});
	}

	render() {
		return (
			<div>
				<a id={'Popover' + this.props.id} onClick={this.toggle}>
					{this.props.popoverType === 'info' ? (
						<img src="assets/img/circle-info-solid.svg" className="img-fluid" alt="" width="80%" />
					) : (
						<img
							src={
								this.props.popoverImgType === 'dark'
									? 'assets/img/help-button-2.svg'
									: 'assets/img/Help Button.svg'
							}
							className="img-fluid"
							alt=""
							width="80%"
						/>
					)}
				</a>
				<Popover
					placement="right"
					isOpen={this.state.popoverOpen}
					target={'Popover' + this.props.id}
					toggle={this.toggle}
				>
					<PopoverBody>{this.props.content}</PopoverBody>
				</Popover>
			</div>
		);
	}
}

export default PopoverComponent;
