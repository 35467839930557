import React, { Component } from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	Col,
	MDBBtn,
	MDBTooltip,
	Row
} from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';
import './CrimeAndSecurityAssist.css';
class CrimeAndSecurityAssist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offer: false
		};
	}

	navigateToPage = () => {
		this.setState({
			toQuote: true
		});
	};
	subscribeNow = () => {
		this.setState({
			offer: true
		});
	};
	render() {
		const userPermission = this.props.user.user.userPermissions;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter(value => value.OfferID === 1)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isSubscribed =
			offerID1 === 1 && isUserSubscribed ? (
				<Col className="col-12 col-md-4 pt-4">
					<h5 className="pt-4">You have already subscribed</h5>
				</Col>
			) : (
				<>
					<Col className="col-12 col-md-2 pt-5 pr-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/1">
								<MDBBtn size="md" color="success" className="font-weight-bold">
									R24.99 per month
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
					<Col className="col-12 col-md-2 pt-5 pr-0 pl-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/3">
								<MDBBtn color="success" size="md" className="font-weight-bold">
									R299.88 per year
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
				</>
			);
		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="d-md-flex justify-content-between ">
									<div className="p-2 text-left">
										<CardTitle className="h4-responsive title text-left">
											VICTIM-OF-CRIME ASSIST
										</CardTitle>
										<p>
											A 24-hour crisis management benefit to assist you in the
											event of a home invasion and attempted hijacking.
										</p>
									</div>
									{isSubscribed}
									<div className="p-2 col-example text-md-right">
										<img
											src={`${baseUrl}assets/img/splendi_launch_offer_rocket_icon-small.svg`}
											alt="launch icon"
											width="50%"
										/>
										<p className="white-text">LaunchOfferRocketIcon</p>
									</div>
								</div>
								<Row>
									<Col className="col-12 col-md-6">
										<div className="d-flex flex-row">
											<div className="px-2">
												<CardTitle className="h4-responsive font-weight-bolder">
													The call centre will assist with the following:
												</CardTitle>
											</div>
										</div>
										<div className="px-2">
											<ul>
												<li>
													If your cell phone is stolen from your home in a home
													invasion, we will courier you a cell phone pre-loaded
													with airtime to the value of R100. This component of
													service only operates during business hours.
												</li>
												<li>
													If your vehicle is stolen, we will provide you with
													Group B car hire for 48 hours to keep you mobile.
													(Only valid with your Driver’s License & credit card.)
												</li>
												<li>
													If your credit card is stolen, we will courier you a
													pre-loaded debit card to the value of R500 to assist
													you in the interim. This component of service only
													operates during business hours.
												</li>
												<li>
													If your keys are stolen in a home invasion, we will
													send a locksmith to your house to assist you with your
													locks, up to the value of R1 000 per annum. The
													service provider will not cover the cost of the
													replacement of a lock or the cutting of keys.
												</li>
												<li>
													If your home is invaded, upon your request, we will
													place a security guard at your house for 24-hours
													after the event has taken place.
												</li>
												<li>
													If there is an attempted hijacking at your main
													residence, upon your request, we will place a security
													guard at your house for 24-hours after the event has
													taken place.
												</li>
												<li>
													If your home has been invaded, we will facilitate
													hotel accommodation to the value of R1 000 per annum.
												</li>
											</ul>
										</div>
									</Col>
									<Col className="col-12 col-md-6">
										<img
											src={`${baseUrl}assets/img/crime_assist_article_image.svg`}
											alt="crime assist image"
											width="100%"
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					{this.props.location.pathname ===
						'/offers/featured/offer/crime-and-security-assist/req-form' && (
						<Col className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img
									src={`${baseUrl}assets/img/next_arrow_active.svg`}
									alt=""
									width="100%"
								/>
								<p className="white-text">PreviousArrow</p>
							</a>
						</Col>
					)}
				</Row>
			</FadeTransform>
		);
	}
}

export default CrimeAndSecurityAssist;
