import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const updateOfferSelect = (data) => (dispatch) => {
	dispatch(updateOffersSelectLoading(true));

	return fetch(baseUrl + requestPath('api/offerselect'), {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				throw new Error(error.message);
			}
		)
		.then((response) => response.json())
		.then((offer) => dispatch(updatedOfferSelect(offer)))
		.catch((error) => dispatch(updateOffersSelectFailed(error.message)));
};

export const updateOffersSelectLoading = () => ({
	type: ActionTypes.OFFER_SELECT_UPDATE_LOADING,
});

export const updateOffersSelectFailed = (errmess) => ({
	type: ActionTypes.OFFER_SELECT_UPDATE_FAILED,
	payload: errmess,
});

export const updatedOfferSelect = (updatedOfferSelect) => ({
	type: ActionTypes.OFFER_SELECT_UPDATED,
	payload: updatedOfferSelect,
});
