import * as ActionTypes from '../../ActionTypes';

export const AddDispute = (
	state = {
		isLoading: false,
		errMess: null,
		addDispute: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.CREATE_DISPUTE:
			return {
				...state,
				isLoading: false,
				errMess: null,
				addDispute: action.payload
			};
		case ActionTypes.CREATE_DISPUTE_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				addDispute: []
			};
		case ActionTypes.CREATE_DISPUTE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				addDispute: []
			};
		default:
			return state;
	}
};
