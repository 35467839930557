import * as ActionTypes from './ActionTypes';

export const BayPortQuote = (
	state = {
		isLoading: true,
		errMess: null,
		bayPortQuote: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_BAYPORT_QUOTE:
			return {
				...state,
				isLoading: false,
				errMess: null,
				bayPortQuote: action.payload
			};
		case ActionTypes.BAYPORT_QUOTE_LOADING:
			return { ...state, isLoading: true, errMess: null, bayPortQuote: [] };
		case ActionTypes.BAYPORT_QUOTE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				bayPortQuote: []
			};
		default:
			return state;
	}
};
