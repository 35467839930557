import React, { Component } from 'react';
import {
	Row,
	Col,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBBtn,
	Card,
	CardBody,
	CardTitle,
	MDBInput,
	MDBIcon
} from 'mdbreact';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { baseUrl } from '../../../shared/baseUrl';
import { FadeTransform } from 'react-animation-components';
import PaymentGateWay from '../../pages/OffersAndServices/PaymentGateWay';

class ShoppingCartModal extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.shoppingCart.isLoading) {
			return <Loading />;
		} else if (this.props.shoppingCart.errMess) {
			return <ErrorComponent />;
		} else {
			const shoppingCart = this.props.shoppingCart.shoppingCart;
			let totalAmount = 0;
			let quanity = 0;
			const columns = [
				{
					label: 'Offer Description',
					field: 'offerDescription',
					sort: 'asc'
				},
				{
					label: 'Quantity',
					field: 'quantity',
					sort: 'asc'
				},
				{
					label: 'Unit Price',
					field: 'unitPrice',
					sort: 'asc'
				},
				{
					label: 'Total Amount',
					field: 'totalAmount',
					sort: 'asc'
				},
				{
					label: 'Remove Item',
					field: 'removeItem',
					sort: 'asc'
				}
			];
			const rows_products = [];
			shoppingCart.Products.forEach(product => {
				totalAmount += Number(product.TotalAmount);
				quanity += Number(product.Quantity);
				rows_products.push({
					offerDescription: product.ItemDescription,
					quantity: (
						<span>
							<div className="d-flex flex-row">
								<div className="px-2 align-self-center">{product.Quantity}</div>
								<div className="px-2">
									<div className="d-flex flex-column">
										<div className="text-left">
											<a
												onClick={() => {
													product.Quantity += 1;
													product.TotalAmount =
														product.UnitPrice * product.Quantity;
													this.props.updateShoppingCartItem(product);
												}}
											>
												<span>
													<MDBIcon className="text-color-green" icon="plus" />
												</span>
											</a>
										</div>
										<div className="text-left">
											<a
												onClick={() => {
													product.Quantity -= 1;
													product.TotalAmount =
														product.UnitPrice * product.Quantity;
													this.props.updateShoppingCartItem(product);
												}}
											>
												<span>
													<MDBIcon className="text-color-purple" icon="minus" />
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</span>
					),
					unitPrice: `R${product.UnitPrice}`,
					totalAmount: `R${product.TotalAmount}`,
					removeItem: (
						<span>
							<a
								onClick={() => {
									this.props.deleteShoppingCartItem(product);
								}}
							>
								<span>
									<MDBIcon className="text-color-red" icon="times" />
								</span>
							</a>
						</span>
					)
				});
			});

			return (
				<FadeTransform
					in
					transfromProps={{
						exitTransform: 'scale(0.5) translate()-50%'
					}}
				>
					<MDBTable btn className="text-nowrap">
						<MDBTableHead columns={columns} />
						<MDBTableBody rows={rows_products} />
					</MDBTable>
					<Row>
						<Col>
							<Row>
								<Col className="col-12 col-md-6 text-nowrap">
									<button
										className="bg-color-gray-dark text-white"
										onClick={() => {
											this.props.deleteAllShoppingCartItems();
										}}
									>
										REMOVE ALL
									</button>
								</Col>
								<Col className="col-12 col-md-6 text-nowrap">
									<a
										onClick={() => {
											this.props.fetchShoppingCartItems();
										}}
									>
										REFRESH CART{' '}
										<img
											src={`${baseUrl}assets/img/shopping_cart_refresh_icon_click.svg`}
											onMouseEnter={e =>
												(e.currentTarget.src = `${baseUrl}assets/img/shopping_cart_refresh_icon_active.svg`)
											}
											onMouseOver={e =>
												(e.currentTarget.src = `${baseUrl}assets/img/shopping_cart_refresh_icon_active.svg`)
											}
											onMouseOut={e =>
												(e.currentTarget.src = `${baseUrl}assets/img/shopping_cart_refresh_icon_disabled.svg`)
											}
											alt="refresh cart button"
											width="20%"
										/>
									</a>
								</Col>
							</Row>
							<p className="font-weight-bolder pt-5">
								Placing an item in your shopping cart does not reserve that item
								or price. We only reserve items for your order once payment is
								received.
							</p>
							<p className="font-weight-bolder text-nowrap">
								<span>
									<img
										src={`${baseUrl}assets/img/secure_transact_lock.svg`}
										alt=""
										width="10%"
									/>
								</span>{' '}
								Secure checkout using XDS Payment Gateway
							</p>
						</Col>
						<Col className="text-nowrap">
							<Card>
								<CardBody>
									<CardTitle className="text-center">CART SUMMARY</CardTitle>
									<Row>
										<Col className="px-5 py-2 text-left">
											Sub Total ({quanity} items)
										</Col>
										<Col className="px-5 py-2 text-right">R{totalAmount}</Col>
									</Row>
									<Row>
										<Col className="px-5 py-2 text-left font-weight-bolder">
											Total
										</Col>
										<Col className="px-5 py-2 text-right font-weight-bolder">
											R{totalAmount}
										</Col>
									</Row>
									<PaymentGateWay
										buttonStyle={'img-proceed-to-checkout'}
										location={this.props.location}
										amountField={totalAmount}
										transRefField={shoppingCart.ShoppingCartID}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</FadeTransform>
			);
		}
	}
}

export default ShoppingCartModal;
