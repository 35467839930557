import { useEffect, useState } from 'react';

const useCountDownTimer = time => {
	const [countDown, setCountDown] = useState(time);

	useEffect(() => {
		if (!countDown) return;
		const interval = setInterval(() => setCountDown(t => t - 1), 1000);
		return () => clearInterval(interval);
	}, []);

	return getReturnValues(countDown);
};

const getReturnValues = countDown => {
	const minutes = Math.floor((countDown % (60 * 60)) / 60);
	const seconds = Math.floor((countDown % 60) / 1);
	return [minutes, seconds];
};

export default useCountDownTimer;
