import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import InputComponent from '../../../components/Input/InputComponent';
import { AccountDisputeNature } from './nature/account';
import { JudgementDisputeNature } from './nature/judgement';
import { AdminOrderDisputeNature } from './nature/adminOrder';
import { SequestrationDisputeNature } from './nature/sequestration';
import { DebtReview } from './nature/debtReview';

class DescriptionNature extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	handleNatureDescription = () => {
		const { selectedValue, natureOfDispute, handleCheckChange } = this.props;
		switch (selectedValue) {
			case 'account':
				return (
					<AccountDisputeNature
						handleCheckChange={handleCheckChange}
						natureOfDispute={natureOfDispute}
					/>
				);
			case 'judgement':
				return (
					<JudgementDisputeNature
						handleCheckChange={handleCheckChange}
						natureOfDispute={natureOfDispute}
					/>
				);
			case 'adminOrder':
				return (
					<AdminOrderDisputeNature
						handleCheckChange={handleCheckChange}
						natureOfDispute={natureOfDispute}
					/>
				);
			case 'sequestration':
				return (
					<SequestrationDisputeNature
						handleCheckChange={handleCheckChange}
						natureOfDispute={natureOfDispute}
					/>
				);
			case 'debtReview':
				return (
					<DebtReview handleCheckChange={handleCheckChange} natureOfDispute={natureOfDispute} />
				);
			default:
				return;
		}
	};

	render() {
		const { selectedValue, personalInfo, handleChange } = this.props;
		if (selectedValue === null) {
			return null;
		}
		return (
			<MDBRow className="mt-5">
				<MDBCol className="col-12 col-md-12">
					<MDBRow>
						<MDBCol>
							<p className="text-center my-2 font-weight-bold">
								Please indicate the description based on the nature of dispute you have chosen above
							</p>
						</MDBCol>
					</MDBRow>
					{this.handleNatureDescription()}
					<MDBRow>
						<MDBCol>
							<InputComponent
								name="subjectOfDispute"
								id="subjectOfDispute"
								hint="PLEASE PROVIDE DETAILS OF THE ACCOUNT / JUDGMENT / ADMIN ORDER / SEQUESTRATION / OTHER THAT IS THE SUBJECT OF THIS DISPUTE/QUERY"
								disabled={false}
								type="textarea"
								value={personalInfo.subjectOfDispute}
								rows={7}
								minLength="4"
								required={true}
								outline={true}
								handleChange={handleChange}
							/>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	}
}

export default DescriptionNature;
