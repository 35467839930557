import React from 'react';
import { Row, Col } from 'mdbreact';
import { baseUrl } from '../../../shared/baseUrl';
import './KredGuruSection.css';

export default class KredGuruSection extends React.Component {
	render() {
		return (
			<Row>
				<Col className="col-9">
					<hgroup
						className={`speech-bubble ${
							this.props.secondaryColor ? 'speech-bubble-color-2' : null
						} pt-3 pl-3 pr-3 pb-1`}
						style={{ color: this.props.color ? this.props.color : '#fff' }}
					>
						<p>{this.props.header}</p>
						<p>{this.props.content}</p>
						<strong>
							<p>{this.props.signature}</p>
						</strong>
					</hgroup>
				</Col>
				<Col className="col-md-auto align-self-center">
					<img
						src={`${baseUrl}assets/img/${this.props.kredGuruImage}`}
						className="img-fluid kredGuruImg"
						alt=""
						width="100%"
					/>
				</Col>
			</Row>
		);
	}
}
