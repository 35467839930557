import React from 'react';
import { MDBDatePicker } from 'mdbreact';
import { MDBInput } from '../Input/InputComponent';

class DatePickerComponent extends React.Component {
	render() {
		return (
			<div>
				<MDBDatePicker
					name={this.props.name}
					id={this.props.id}
					label={this.props.label}
					animateYearScrolling={true}
					showTodayButton={true}
					todayLabel="It's today"
					invalidDateMessage="Bad format"
					invalidLabel="Invalid label"
					value={new Date(this.props.value)}
					getValue={this.props.getValue}
				/>
			</div>
		);
	}
}

export default DatePickerComponent;
