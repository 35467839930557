import React, { Component } from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	Col,
	MDBBtn,
	MDBTooltip,
	Row
} from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';
import './BailAssist.css';
class BailAssist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offer: false
		};
	}

	navigateToPage = () => {
		this.setState({
			toQuote: true
		});
	};
	subscribeNow = () => {
		this.setState({
			offer: true
		});
	};

	render() {
		const userPermission = this.props.user.user.userPermissions;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter(value => value.OfferID === 1)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isSubscribed =
			offerID1 === 1 && isUserSubscribed ? (
				<Col className="col-12 col-md-4 pt-4">
					<h5 className="pt-4">You have already subscribed</h5>
				</Col>
			) : (
				<>
					<Col className="col-12 col-md-2 pt-5 pr-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/1">
								<MDBBtn size="md" color="success" className="font-weight-bold">
									R24.99 per month
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
					<Col className="col-12 col-md-2 pt-5 pr-0 pl-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/3">
								<MDBBtn color="success" size="md" className="font-weight-bold">
									R299.88 per year
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
				</>
			);
		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="d-md-flex justify-content-between ">
									<div className="p-2 text-left">
										<CardTitle className="h4-responsive title text-left">
											BAIL ASSIST
										</CardTitle>
										<p>Relief - Recoveries – Assistance</p>
									</div>
									{isSubscribed}
									<div className="p-2 col-example text-md-right">
										<img
											src={`${baseUrl}assets/img/splendi_launch_offer_rocket_icon-small.svg`}
											alt="launch icon"
											width="50%"
										/>
										<p className="white-text">LaunchOfferRocketIcon</p>
									</div>
								</div>
								<Row>
									<Col className="col-12 col-md-6">
										<div className="px-2">
											<p>
												Bail Protect provides Splendi Launch Offer subscribers
												with assistance in posting bail following an arrest for
												a minor crime. In addition, Bail Protect undertakes to
												do all administrative management of the bail from start
												to finish. This service assists in exercising your legal
												right, however, the transgressor still faces the extent
												of the law for whatever it is alleged that he/she has
												done.
											</p>
											<label className="h4-responsive font-weight-bolder">
												What we offer
											</label>
											<p>Our solution is simple and very effective:</p>
											<ul>
												<li>
													<strong>Relief</strong> –24/7 availability to post
													bail on your behalf and thereby ensure that you don’t
													have to spend unnecessary time behind bars.
												</li>
												<li>
													<strong>Recoveries</strong> – You don’t have to worry
													about having funds available or being able to access
													them as we’ll post bail of up to R3, 000 on your
													behalf.
												</li>
											</ul>
											<label className="h4-responsive font-weight-bolder">
												For the duration of the claim, you receive:
											</label>
											<ul>
												<li>
													24/7, 365 days a year call centre support if you are
													arrested and require bail.
												</li>
												<li>
													Comprehensive Legal advice on your rights regarding
													your arrest and bail.
												</li>
												<li>
													Assistance by contacting the police officer on duty to
													verify the arrest and bail details.
												</li>
												<li>
													Contact with your family to advise them of bail
													assistance.
												</li>
												<li>
													Assistance on your behalf where we ensure that we
													report to the relevant police station to post your
													bail.
												</li>
												<li>After bail follow up and assistance.</li>
											</ul>
										</div>
									</Col>
									<Col className="col-12 col-md-6">
										<img
											src={`${baseUrl}assets/img/bail_assist_article_image.svg`}
											alt="bail assist"
											width="100%"
										/>
									</Col>
									<Col className="col-12 col-md-12">
										<label className="h4-responsive font-weight-bolder">
											You will be covered in typical cases like:
										</label>
										<ul>
											<li>
												Road traffic offences such as neglecting to pay speeding
												fines
											</li>
											<li>
												Failure to appear in court to defend such traffic
												offences (contempt of court)
											</li>
											<li>Driving while under the influence of alcohol</li>
											<li>Common assault</li>
											<li>
												Shoplifting or theft where the amount is less than R 2
												500
											</li>
											<li>
												Fraud where the value involved does not exceed R 2 500
											</li>
											<li>
												Illicit possession of dagga where the amount does not
												exceed 115 grams
											</li>
											<li>Drinking in public</li>
											<li>Public disturbance</li>
										</ul>
									</Col>
									<Col className="col-12 col-md-12">
										<label className="h4-responsive font-weight-bolder">
											Who is entitled to Claim:
										</label>
										<p>
											Any Bail Protect beneficiary, provided the membership fees
											are paid and up to date.
										</p>
										<label className="h4-responsive font-weight-bolder">
											How do you claim with us?
										</label>
										<p>
											Contact the Call Centre at 0861 467 835 and give the
											particulars of your case. Your membership details will be
											verified, and you will be given a case number.
										</p>
										<ul>
											<li>
												We will contact the officer on duty to verify the arrest
												and bail details;
											</li>
											<li>
												We will SMS you to verify that an agent will be
												dispatched to post bail;
											</li>
											<li>
												We will contact your spouse/partner to advise of bail
												assistance;
											</li>
											<li>
												We will ensure that our agent reports to the relevant
												police station within 4 hours of receiving bail
												assistance request;
											</li>
											<li>
												We will obtain the original bail receipt from the police
												official;
											</li>
											<li>
												We will contact you one day after your 1st court
												appearance to determine the outcome;
											</li>
											<li>
												We will continue to follow up with you until your matter
												is finalised in court;
											</li>
											<li>
												Our lawyers will advise you on any issue regarding your
												arrest and bail.
											</li>
										</ul>
									</Col>
									<Col>
										<label className="h4-responsive font-weight-bolder">
											Terms and Conditions
										</label>
										<ul>
											<li>
												The maximum amount of bail per incident is R3 000. No
												assistance is available for bail in excess of this
												amount.
											</li>
											<li>
												If bail is granted, you will be asked to accept the
												terms and conditions (delivery of the receipt and bail
												and legal costs will be owed to Bail Protect if the
												policyholder does not appear at court at the allotted
												time and date).
											</li>
											<li>
												The bail will only be paid directly to the courts or
												government body via cash to the police departments or
												via attorney trust accounts to the relevant courts.
											</li>
											<li>
												The receipt for the bail payment must be given to the
												service administrator or its appointed agents or posted
												by registered mail within 24 hours of payment of bail,
												failing which a penalty of R500 will apply.
											</li>
											<li>
												If you do not appear at court on your appointed date, or
												if you appear but Bail Protect cannot recover the bail
												loan for whatever reason, you will be liable to pay the
												service provider the amount of the bail plus any legal
												costs incurred.
											</li>
										</ul>
									</Col>
									<Col className="col-12 col-md-12">
										<label className="h4-responsive font-weight-bolder">
											Limits of Service
										</label>
										<br />
										<label className="h4-responsive font-weight-bolder">
											No bail assistance will be provided where:
										</label>
										<ul>
											<li>The amount of bail exceeds R 3,000</li>
											<li>
												The crime of which the beneficiary is accused is
												classified as one of the following:
												<ul>
													<li>Assault with Grievous Bodily Harm</li>
													<li>Murder or Attempted murder</li>
													<li>Rape</li>
													<li>Child abuse</li>
													<li>Child pornography</li>
													<li>Dealing in drugs</li>
													<li>
														Armed robbery or robbery with aggravating
														circumstances
													</li>
													<li>Treason, sabotage or subversion</li>
												</ul>
											</li>
											<li>
												The crime of which the beneficiary is accused is
												classified as one contemplated in Part II or Part III of
												Schedule 2 of the Criminal Procedure Act, 51 of 1977;
											</li>
											<li>The beneficiary’s fees have not been paid</li>
											<li>
												<p>
													A claim has previously been paid under this Agreement
													where the beneficiary did not supply the
													<br />
													Administrator with the receipt for the bail within 24
													hours of the payment being lodged with the Issuing
													<br />
													Authority and/or where the beneficiary did not appear
													in court as per the bail conditions.
												</p>
											</li>
										</ul>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					{this.props.location.pathname ===
						'/offers/featured/offer/bail-assist/req-form' && (
						<Col className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img
									src={`${baseUrl}assets/img/next_arrow_active.svg`}
									alt=""
									width="100%"
								/>
								<p className="white-text">PreviousArrow</p>
							</a>
						</Col>
					)}
				</Row>
			</FadeTransform>
		);
	}
}

export default BailAssist;
