import * as ActionTypes from '../../ActionTypes';
import { persistExpiresAt } from '../../persistExpiresAt';

export const GetKgCredEdCmsArticles = (
	state = {
		isLoading: false,
		errMess: null,
		articles: [],
		persistExpiresAt: persistExpiresAt()
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GET_KG_CRED_ED_ARTICLES_SUCCESS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				articles: action.payload
			};
		case ActionTypes.GET_KG_CRED_ED_ARTICLES_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				articles: []
			};
		case ActionTypes.GET_KG_CRED_ED_ARTICLES_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				articles: []
			};
		default:
			return state;
	}
};
