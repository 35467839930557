import React from 'react';
import { Container, Row, Col } from 'mdbreact';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import PopoverComponent from '../../../../components/Popover/PopoverComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import { CIPCRenderFieldEntries } from './CIPC';
import { DeedsRenderFieldEntries } from './Deeds';
import KredGuruSection from '../../../sections/KredGuru/KredGuruSection';
let quotesData = require('../../../../shared/data');

const CIPCAndDeedsContent = (props) => {
	if (props.consumer.isLoading) {
		return <Loading />;
	} else if (props.consumer.errMess) {
		return <ErrorComponent />;
	} else {
		const consumer = props.consumer.consumer;

		let kredImage = 'kred_guru_+2.svg';
		let kredContent =
			'This section details the companies where you hold directorship at the Companies and Intellectual Property Commission (CIPC) and any properties registered under your name at the Deeds Office in South Africa.';

		return (
			<Container>
				<Row>
					<Col className="col-12 col-md-6 ">
						<div className="creditReportTitle">
							<h4 className="h4-responsive credit-report-header">Directorships and Properties</h4>
							{/* <div>
								<PopoverComponent
									content={quotesData.D13 + ' ' + quotesData.D14}
									id={'D13-14'}
									popoverImgType={'dark'}
									popoverType={'info'}
								/>
							</div> */}
						</div>
					</Col>
					<Col className="col-12 col-md-6">
						<KredGuruSection id="OverviewKG" kredGuruImage={kredImage} content={kredContent} />
					</Col>
				</Row>
				<Row>
					<Col className="col-12">
						<CIPCRenderFieldEntries type={consumer.ConsumerDirectorShipLink} />
					</Col>
					<Col className="col-12">
						<DeedsRenderFieldEntries type={consumer.ConsumerPropertyInformation} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default CIPCAndDeedsContent;
