import React, { Component } from 'react';
import {
	Row,
	Col,
	MDBListGroup,
	MDBListGroupItem,
	CardTitle,
	MDBIcon
} from 'mdbreact';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { baseUrl } from '../../../shared/baseUrl';
import { Popover, PopoverBody } from 'reactstrap';
import ShoppingCartModal from './ShoppingCartModal';
import PaymentGateWay from '../../pages/OffersAndServices/PaymentGateWay';

class ShoppingCartPopover extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.shoppingCart.isLoading) {
			return <Loading />;
		} else if (this.props.shoppingCart.errMess) {
			return <ErrorComponent />;
		} else {
			const shoppingCart = this.props.shoppingCart.shoppingCart;
			let totalAmount = 0;
			const products = shoppingCart.Products
				? shoppingCart.Products.map((product, index) => {
						totalAmount += Number(product.TotalAmount);
						return (
							<MDBListGroupItem
								className="border-left-0 border-right-0 border-top-0 text-nowrap"
								key={index}
							>
								<Row>
									<Col className="text-left">
										<h5 className="font-weight-bolder">
											{product.ItemDescription}
										</h5>
									</Col>
									<Col className="text-right">
										<a
											onClick={() => {
												this.props.deleteShoppingCartItem(product);
											}}
										>
											<span>
												<MDBIcon
													className="text-color-red"
													icon="times-circle"
												/>
											</span>
										</a>
									</Col>
								</Row>

								<Row>
									<Col className="text-left">Quantity:{product.Quantity}</Col>
									<Col className="text-right">R{product.TotalAmount}</Col>
								</Row>
							</MDBListGroupItem>
						);
				  })
				: null;
			return (
				<Popover
					placement="bottom"
					isOpen={this.props.popoverOpen}
					target={'Popover' + 'shoppingCart'}
					toggle={this.props.togglePopOver}
				>
					<PopoverBody className="bg-white text-color-gray-dark">
						<MDBListGroup>{products}</MDBListGroup>
						<Row>
							<Col className="px-5 py-2 text-left font-weight-bolder">
								Total
							</Col>
							<Col className="px-5 py-2 text-right font-weight-bolder">
								R{totalAmount}
							</Col>
						</Row>
						{(!shoppingCart.Products || shoppingCart.Products.length == 0) && (
							<Row>
								<Col>
									<a>
										<img
											src={`${baseUrl}assets/img/view_shopping_cart_button_disabled.svg`}
											alt=""
											width="100%"
										/>
									</a>
								</Col>
								<Col>
									<a>
										<img
											src={`${baseUrl}assets/img/checkout_button_disabled.svg`}
											alt=""
											width="100%"
										/>
									</a>
								</Col>
							</Row>
						)}
						{shoppingCart.Products && shoppingCart.Products.length > 0 && (
							<Row>
								<Col>
									<a
										onClick={() => {
											this.props.toggle(
												'YOUR SHOPPING CART',
												<ShoppingCartModal
													shoppingCart={this.props.shoppingCart}
													deleteShoppingCartItem={
														this.props.deleteShoppingCartItem
													}
													deleteAllShoppingCartItems={
														this.props.deleteAllShoppingCartItems
													}
													fetchShoppingCartItems={
														this.props.fetchShoppingCartItems
													}
													updateShoppingCartItem={
														this.props.updateShoppingCartItem
													}
													location={this.props.location}
												/>,
												'text-color-purple'
											);
											this.props.togglePopOver();
										}}
									>
										<img
											src={`${baseUrl}assets/img/view_shopping_cart_button_active.svg`}
											alt=""
											width="100%"
										/>
									</a>
								</Col>
								<Col>
									<PaymentGateWay
										buttonStyle={'img-checkout'}
										location={this.props.location}
										amountField={totalAmount}
										transRefField={shoppingCart.ShoppingCartID}
									/>
								</Col>
							</Row>
						)}
					</PopoverBody>
				</Popover>
			);
		}
	}
}

export default ShoppingCartPopover;
