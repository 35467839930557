import React from 'react';
import { Animation, Col, Mask, Row, View, MDBCard } from 'mdbreact';
import ReactSpeedometer from 'react-d3-speedometer';
import './RadialCreditGuageComponent.css';
let quotesData = require('../../../../shared/data');

export const CreditScoreComponent = (props) => {
	const { data, title } = props;
	if (data && !(Object.entries(data).length === 0 && data.constructor === Object)) {
		const creditScore = data.FinalScore.value;
		sessionStorage.setItem('creditScore', creditScore);
		let scoreBGColor = 'bg-warning';
		let scoreTextColor = 'text-warning';
		if (data.RiskCategory.value === 'Medium Risk') {
			scoreBGColor = 'bg-color-orange';
			scoreTextColor = 'text-medium-risk';
		} else if (data.RiskCategory.value === 'Potential High Risk') {
			scoreBGColor = 'bg-color-red';
			scoreTextColor = 'text-high-risk';
		} else if (data.RiskCategory.value === 'Low Risk') {
			scoreBGColor = Number(data.FinalScore.value) < 840 ? 'bg-color-green' : 'bg-color-green';
			scoreTextColor =
				Number(data.FinalScore.value) < 840 ? 'text-okay-low-risk' : 'text-good-low-risk';
		}
		return (
			<Row className="text-nowrap text-center">
				<Col className="col-12 ">
					<h4 className="h4-responsive sub-title font-medium">{title}</h4>
				</Col>
				<Col className="col-12">
					<MDBCard className="bg-color-purple-dark-2">
						<Animation type="bounce" className="animatedBounce">
							<h1 className={'display-larger font-weight-bolder ' + scoreTextColor}>
								{creditScore}
							</h1>
						</Animation>
						<h4 className={scoreTextColor}>
							OUT OF <strong>{1000}</strong>
						</h4>
					</MDBCard>
				</Col>
			</Row>
		);
	} else {
		return <div />;
	}
};

export const RadialCreditGuageComponent = (props) => {
	const { data, guageWidth, guageHeight } = props;
	if (data && !(Object.entries(data).length === 0 && data.constructor === Object)) {
		const creditScore = data.FinalScore.value;
		return (
			<Row>
				<Col className="col-12">
					<View>
						<div className="d-flex justify-content-center">
							<ReactSpeedometer
								value={Number(creditScore)}
								width={guageWidth}
								height={guageHeight}
								minValue={0}
								maxValue={1000}
								needleColor="red"
								startColor="red"
								endColor="green"
								textColor="#fff"
								segments={1000}
							/>
						</div>
						<Mask overlay="white-slight" className="flex-center">
							<img
								src="assets/img/Dashboard dial disk only.svg"
								className="img-fluid align-self-end"
								alt=""
								width="7%"
								height="7%"
							/>
						</Mask>
					</View>
				</Col>
			</Row>
		);
	} else {
		return <div />;
	}
};
