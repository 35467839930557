import React, { Component } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import { fetchDisputeId } from '../../../redux/dispute/actionCreators/getDisputeId';
import { Loading } from '../../../components/Loading/LoadingComponent';
import DisputeInfo from './disputeInfo';
import xds_dispute_form from '../../../../src/assets/files/XDS Dispute Form.pdf';
import styles from './dispute.module.css';

class Dispute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subscriptionKGCount: 0,
		};
	}

	handleKredGuruSection = () => {
		let header = null;
		let signature = this.state.subscriptionKGCount === 0 ? 'KG – Splendi’s Kred Guru' : null;
		let kredImage = 'kred_guru_+2.svg';
		const kgMessage = [
			'You have two options, dispute online or download the form and email later. Please choose your option',
		];
		return (
			<KredGuruSection
				id="dispute"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
				secondaryColor={true}
				color={'#000'}
			/>
		);
	};
	handleOnlineDisputeId = async () => {
		this.props.fetchDisputeId();
	};
	handleFormDownload = () => {
		const urls = [{ download: xds_dispute_form, fileName: 'XDS Dispute Form.pdf' }];
		const link = document.createElement('a');
		for (let i = 0; i < urls.length; i++) {
			link.setAttribute('download', urls[i].fileName);
			link.style.display = 'none';
			document.body.append(link);
			link.setAttribute('href', urls[i].download);
			link.click();
		}
		document.body.removeChild(link);
	};
	render() {
		const { disputeId } = this.props.disputeId;
		if (disputeId.isLoading) {
			return <Loading />;
		} else {
			return (
				<MDBContainer>
					<MDBCard md={11} className="pl-3 pr-3 my-5">
						<MDBCardBody className="mt-4 pb-5">
							<MDBRow>
								<MDBCol className="d-flex gap-3">
									<MDBRow>
										<MDBCol>
											<h3 className={styles.disputHeader}>Dispute</h3>
											<div className="mt-4 d-flex flex-column flex-md-row">
												<Link to="/online-dispute">
													<MDBBtn
														size="md"
														color="success"
														className={`font-weight-bold ${styles.disputeAtionButtons}`}
														onClick={() => this.handleOnlineDisputeId()}
													>
														Create online dispute
														<img src="assets/img/arrow.svg" alt="white arrow" width={20} />
													</MDBBtn>
												</Link>
												<MDBBtn
													onClick={this.handleFormDownload}
													color="success"
													size="md"
													className={`font-weight-bold ${styles.disputeAtionButtons}`}
												>
													Download forms
													<img src="assets/img/arrow.svg" alt="white arrow" width={20} />
												</MDBBtn>
											</div>
										</MDBCol>
									</MDBRow>
								</MDBCol>
								<MDBCol className="d-flex justify-content-end mt-5 mt-md-0">
									{this.handleKredGuruSection()}
								</MDBCol>
							</MDBRow>
							<DisputeInfo />
						</MDBCardBody>
					</MDBCard>
				</MDBContainer>
			);
		}
	}
}
const mapDispatchToProps = (dispatch) => ({
	fetchDisputeId: () => dispatch(fetchDisputeId()),
});
const mapStateToProps = (state) => {
	return {
		disputeId: state.disputeId,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dispute);
