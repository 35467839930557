import React, { Component } from 'react';
import {
	MDBCard,
	MDBCardBody,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBCardTitle
} from 'mdbreact';
import { Link } from 'react-router-dom';
import SocialMedia from '../SocialMedia/social-medial';
import './SettingsPage.css';

class SettingsPage extends Component {
	render() {
		return (
			<MDBContainer className="mt-2 support-page">
				<p className="text-center">
					<a href="https://sacoronavirus.co.za/" target="_blank">
						<img
							src="assets/img/covid-sa-logo.png"
							alt="COVID19"
							width="auto"
							height="auto"
							className="covid-image"
						/>
					</a>
				</p>
				<MDBCard>
					<MDBCardBody className="pb-3  pb-md-0">
						<MDBRow className="d-md-flex justify-content-between px-3 ">
							<MDBCol className="col-12 col-md-3 p-2">
								<MDBCardTitle
									style={{ color: '#3f51b5' }}
									className="h4-responsive title"
								>
									Find Out More
								</MDBCardTitle>
								<p>
									<Link to="/about">About Splendi</Link> |{' '}
									<Link to="/faqs">FAQs</Link> |{' '}
									<Link to="/terms-and-conditions">Terms and Conditions</Link>
								</p>
								<p>
									<Link to="/privacy_policy">Privacy & Cookies Policy</Link>
								</p>
								<br className="support-break" />
								<br className="support-break" />
								<p>&#9400; {new Date().getFullYear()} zenAptix</p>
							</MDBCol>
							<MDBCol className="col-12 col-md-3 p-2">
								<h4 className="title">Contact Us</h4>
								<p>Atrium on 5th, Sandton City</p>
								<p>Sandton, Johannesburg</p>
								<p>2196</p>
								<br className="support-break" />
								<p>info@splendi.co.za</p>
							</MDBCol>
							<MDBCol className="col-12 col-md-3 p-2">
								<h4 className="title mb-2">Follow US</h4>
								<SocialMedia />
							</MDBCol>
						</MDBRow>
					</MDBCardBody>
				</MDBCard>
			</MDBContainer>
		);
	}
}

export default SettingsPage;
