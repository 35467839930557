import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './DetailedReportPDFPaymentFailure.module.css';
import { baseUrl } from '../../../shared/baseUrl';
import axios from 'axios';

const DetailedReportPDFPaymentFailure = (props) => {
	const [rendered, setRendered] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	// Defaults
	const transactionId = localStorage.getItem('transactionId');
	const urlParams = new URLSearchParams(window.location.search);
	const gatewayId = urlParams.get('gatewayId');
	const { userId } = props.user.user;

	useEffect(() => {
		// Double set rendered to false to prevent the code executing twice, not proper code. Refactor at own risk!.
		setRendered(false);
		if (props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined') {
			setRendered(true);
		} else {
			setRendered(false);
		}
	}, []);

	// Double useEffect to prevent the code executing twice, not proper code. Refactor at own risk!.
	useEffect(() => {
		if (
			(props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined') &&
			rendered === true
		) {
			cancelPayment();
		} else {
			console.log('I am NOT rendered');
		}
	}, [rendered]);

	const cancelPayment = async () => {
		setIsLoading(true);
		try {
			const response = await axios.post(`${baseUrl}api/report-cancel-payment`, {
				userId: userId,
				transactionId: transactionId,
				gatewayId: gatewayId,
			});

			console.log(response);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setErrorMessage('Error cancelling payment');
		}
	};

	return (
		<>
			{props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined' ? (
				<>
					{isLoading ? (
						<div className="d-flex justify-content-center align-items-center">
							<img
								width={150}
								src={`${baseUrl}assets/img/splendi-loading-spinner.gif`}
								alt="loader"
							/>
							Loading...
						</div>
					) : (
						<div className={`card-big ${styles.cardSizeSmall}`}>
							<div className={styles.errorMessageContainer}>
								<div className={styles.errorGreyBox}>
									<div className={styles.errorText}>Your payment was cancelled.</div>
									<div className={styles.kgText}>
										<i>
											<strong>KG – Splendi’s Kred Guru</strong>
										</i>
									</div>
								</div>
								<div className={styles.kgError} />
							</div>
							<div className={styles.kgErrorButtons}>
								<Link to="/dashboard">
									<button className={styles.kgErrorCancel}>Cancel</button>
								</Link>
								<Link to="/credit-report?retry=yes">
									<div className={styles.kgErrorTryAgain}>Try again</div>
								</Link>
							</div>
							{errorMessage ? (
								<div>
									<p className={styles.successError}>{errorMessage}</p>
								</div>
							) : null}
						</div>
					)}
				</>
			) : (
				<div className="d-flex justify-content-center align-items-center">
					<img width={150} src={`${baseUrl}assets/img/splendi-loading-spinner.gif`} alt="loader" />
					Loading...
				</div>
			)}
		</>
	);
};

export default DetailedReportPDFPaymentFailure;
