import React, { Component } from 'react';
import { MDBBtn, MDBStep, MDBStepper } from 'mdbreact';

class Stepper extends Component {
	render() {
		const { formActivePanel1, swapFormActive } = this.props;
		return (
			<MDBStepper form className="px-3 mt-5 mb-5">
				<MDBStep form>
					<a onClick={swapFormActive(1)(1)}>
						<MDBBtn color={formActivePanel1 === 1 ? 'indigo' : 'default'} circle>
							1
						</MDBBtn>
					</a>
					<p>Step 1</p>
				</MDBStep>
				<MDBStep form>
					<a onClick={swapFormActive(1)(2)}>
						<MDBBtn color={formActivePanel1 === 2 ? 'indigo' : 'default'} circle>
							2
						</MDBBtn>
					</a>
					<p>Step 2</p>
				</MDBStep>
				<MDBStep form>
					<a onClick={swapFormActive(1)(3)}>
						<MDBBtn color={formActivePanel1 === 3 ? 'indigo' : 'default'} circle>
							3
						</MDBBtn>
					</a>
					<p>Step 3</p>
				</MDBStep>
				<MDBStep form>
					<a onClick={swapFormActive(1)(4)}>
						<MDBBtn color={formActivePanel1 === 4 ? 'indigo' : 'default'} circle>
							4
						</MDBBtn>
					</a>
					<p>Step 4</p>
				</MDBStep>
				{/* <MDBStep form>
					<a onClick={swapFormActive(1)(5)}>
						<MDBBtn color={formActivePanel1 === 5 ? 'indigo' : 'default'} circle>
							5
						</MDBBtn>
					</a>
					<p>Step 5</p>
				</MDBStep> */}
			</MDBStepper>
		);
	}
}

export default Stepper;
