import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';
export const disputeFileUpload = (files) => async (dispatch) => {
	dispatch(fileUploadLoading(true));
	return fetch(baseUrl + requestPath('api/upload-file'), {
		method: 'POST',
		body: files,
		headers: {
			Accept: 'application/pdf',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((fileUpload) => dispatch(uploadFile(fileUpload)))
		.catch((error) => dispatch(fileUploadFailed(error.message)));
};

export const fileUploadLoading = () => ({
	type: ActionTypes.UPLOAD_DISPUTE_FILE_LOADING,
});

export const fileUploadFailed = (errmess) => ({
	type: ActionTypes.UPLOAD_DISPUTE_FILE_FAILED,
	payload: errmess,
});

export const uploadFile = (fileUpload) => ({
	type: ActionTypes.UPLOAD_DISPUTE_FILE,
	payload: fileUpload,
});
