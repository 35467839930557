import * as ActionTypes from '../../ActionTypes';

export const GeneratePDF = (
	state = {
		isLoading: false,
		errMess: null,
		pdfGenerator: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GENERATE_PDF:
			return {
				...state,
				isLoading: false,
				errMess: null,
				pdfGenerator: action.payload
			};
		case ActionTypes.GENERATE_PDF_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				pdfGenerator: []
			};
		case ActionTypes.GENERATE_PDF_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				pdfGenerator: []
			};
		default:
			return state;
	}
};
