import React, { Component } from 'react';
import './home.css';
import LandingFooter from '../Footer/footer';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView } from 'mdbreact';
import { TransitionGroup } from 'react-transition-group';
import CookieConsent from 'react-cookie-consent';
import Banner1 from '../../../assets/img/splendi_banner_1.jpg';
import Banner1Mobi from '../../../assets/img/splendi_banner_1_mobile.jpg';
import Banner2 from '../../../assets/img/splendi_banner_2.jpg';
import Banner2Mobi from '../../../assets/img/splendi_banner_2_mobile.jpg';
import Banner3 from '../../../assets/img/splendi_banner_3.jpg';
import Banner3Mobi from '../../../assets/img/splendi_banner_3_mobile.jpg';

class Home extends Component {
	componentDidMount() {
		const currentURL = window.location.href;
		const ref = this.getClientRef(currentURL);
		if (ref) {
			localStorage.setItem('ref', ref);
		}
	}

	getClientRef(url) {
		const params = {};
		const queryString = url.split('?')[1];
		if (queryString) {
			const paramPairs = queryString.split('&');
			for (const pair of paramPairs) {
				const [key, value] = pair.split('=');
				params[key] = decodeURIComponent(value);
				if (key === 'ref') {
					return value;
				}
			}
		}
	}
	render() {
		return (
			<div className="container-fluid landingHomePage">
				<p
					className="text-center"
					style={{
						marginTop: '7rem',
						marginBottom: '1rem',
					}}
				>
					<a href="https://sacoronavirus.co.za/" target="_blank">
						<img
							src="assets/img/covid-sa-logo.png"
							alt="COVID19"
							width="auto"
							height="auto"
							className="covid-image"
						/>
					</a>
				</p>
				<div className="">
					<MDBCarousel
						activeItem={1}
						length={3}
						showControls={true}
						showIndicators={true}
						className="z-depth-1"
					>
						<MDBCarouselInner className="inner2">
							<MDBCarouselItem itemId="1">
								<MDBView>
									<img
										className="d-block w-100"
										src={Banner1}
										srcSet={`${Banner1} 1920w, ${Banner1Mobi} 840w`}
										alt="First slide"
									/>
								</MDBView>
							</MDBCarouselItem>
							<MDBCarouselItem itemId="2">
								<MDBView>
									<img
										className="d-block w-100"
										src={Banner2}
										srcSet={`${Banner2} 1920w, ${Banner2Mobi} 840w`}
										alt="Second slide"
									/>
								</MDBView>
							</MDBCarouselItem>
							<MDBCarouselItem itemId="3">
								<MDBView>
									<img
										className="d-block w-100"
										src={Banner3}
										srcSet={`${Banner3} 1920w, ${Banner3Mobi} 840w`}
										alt="3rd slide"
									/>
								</MDBView>
							</MDBCarouselItem>
						</MDBCarouselInner>
					</MDBCarousel>
				</div>
				<div className="conta">
					<div className="mainHeading pt-1">
						<h1
							className="get-free-access pt-4 pb-4"
							style={{
								textAlign: 'center',
								backgroundColor: '#5A5BA4',
								color: '#fff',
							}}
						>
							Free access to your credit report... with more on the way!
						</h1>
					</div>
					<div className="row" style={{ color: '#5A5BA4' }}>
						<div className="col-md-6 text-center ">
							<h4 className="pt-2 px-3 px-md-0">
								Custom-designed with
								<br />
								your credit health at the centre.
							</h4>
						</div>
						<div className="col-md-6 text-center ">
							<h4 className="pt-2 px-3 px-md-0">
								Powered by XDS
								<br />
								South Africa’s largest locally-owned credit bureau.
							</h4>
						</div>
					</div>
					<div className="row text-center pl-4 pr-4">
						<div className="col-md-6">
							<div className="custom-container">
								<img
									src="assets/img/custom-designed-banner-1-adobe-stock.jpg"
									alt="Avatar"
									className="home-image"
								/>
								<div className="overlay home-overlay">
									<div className="text">
										<ul>
											<li>Free up-to-date Credit Score on each log in</li>
											<li>Detailed credit report and overview</li>
											<li>
												Get alerts when New Accounts are opened in your name or Open Accounts go
												into arrears
											</li>
											<li>
												All your credit account details conveniently in one place with 24 month
												payment history
											</li>
										</ul>
									</div>
								</div>
							</div>

							<h4 className="pt-3" style={{ color: '#666' }}>
								Build a credit profile that supports your financial future.
							</h4>
						</div>
						<div className="col-md-6">
							<div className="custom-container">
								<img src="assets/img/xds-small-banner.jpg" alt="Avatar" className="home-image" />
								<div className="overlay home-overlay">
									<div className="text">
										<ul>
											<li>Highest standards in data privacy, security and confidentiality</li>
											<li>Registered with the National Credit Regulator and NCA compliant</li>
											<li>
												Tools built to decrease consumer indebtedness and reduce reckless lending
											</li>
											<li>Supporters of anti-money laundering activities</li>
										</ul>
									</div>
								</div>
							</div>
							<h4 className="pt-3 " style={{ color: '#666' }}>
								Providing South Africa with reliable credit information since 2002.
							</h4>
						</div>
					</div>
					<div className="row mt-5 mb-2 pl-4 pr-4 custom-card ">
						<div className="col-lg-3 col-md-6 col-sm-6 card-test">
							<div className="card home-card">
								<img
									className="card-img-top mt-5"
									src="assets/img/identity-protection-icon.svg"
									style={{ maxWidth: '28em', height: '15em' }}
									alt=""
								/>
								<div className="card-body ">
									<h3>Identity Protection</h3>
									<p>
										Know when new accounts open in your name, if legal actions occur against you or
										when other suspicious activities get flagged.
									</p>
								</div>
							</div>
						</div>
						<div className=" col-lg-3 col-md-6 col-sm-6 card-test">
							<div className="card home-card">
								<img
									className="card-img-top mt-5"
									src="assets/img/xds-payment-gateway-icon.svg"
									style={{ maxWidth: '28em', height: '15em' }}
									alt=""
								/>
								<div className="card-body">
									<h3>Follow your money</h3>
									<p>
										Know where your money is going every month with access to 24 months of payment
										history on all short, medium and long term debt.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-6 card-test">
							<div className="card home-card">
								<img
									className="card-img-top mt-5"
									src="assets/img/alerts-and-notifications-icon.svg"
									style={{ maxWidth: '28em', height: '15em' }}
									alt=""
								/>
								<div className="card-body">
									<h3>Alerts and Notifications</h3>
									<p>
										We monitor activity linked to your profile, such as when payments are in arrears
										or once your accounts are 'Paid-Up'.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-6 card-test">
							<div className="card home-card">
								<img
									className="card-img-top mt-5"
									src="assets/img/credit-education-icon.svg"
									style={{ maxWidth: '28em', height: '15em' }}
									alt=""
								/>
								<div className="card-body">
									<h3>Credit Education</h3>
									<p>
										Our Cred Ed resources offer insights, perspectives and sound advice to help you
										build and maintain a solid credit reputation.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="splendi-fy-section  pt-3 pb-3">
						<div className="row ">
							<div className="col-md-12">
								<span className="d-flex justify-content-center">
									<img
										className="h-100 pl-3"
										src="assets/img/splendi-fy_icon_white.svg"
										width="50px"
										alt="splendi-fy"
									/>
									<h4 className=" pl-2 pt-3 mb-0 h4-splendi-fy">Splendi-fy your life</h4>
								</span>
							</div>
						</div>
					</div>
					<div className="dashboard-credit-summary">
						<div className="row pt-5 mb-5">
							<div className="col-lg-6 col-md-12 pl-5 pr-5">
								<div className="card home-card">
									<div className="card-body">
										<h4 className="card-title font-weight-bold mb-2">
											DASHBOARD WITH AVAILABLE CREDIT SUMMARY
										</h4>
									</div>
									<div className="px-3 px-md-0 credit-summary">
										<img
											className="credit-summary-image"
											src="assets/img/dashboard_preview.svg"
											alt="Credit-report"
										/>
										<a>
											<div className="mask rgba-white-slight" />
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 pl-5 pr-5 pt-5">
								<div className="real-time-data splendi-fy-items mb-4">
									<img
										className="mb-3"
										src="assets/img/real-time-data-icon.svg"
										style={{ width: '7em', height: '7em' }}
										alt=""
									/>
									<span className="feature-list">
										<h3>REAL-TIME DATA</h3>
										<h5>Your data is processed each time you log in.</h5>
									</span>
								</div>
								<div className="data-filtering splendi-fy-items  mb-4">
									<img
										className="mb-3"
										src="assets/img/data-filtering-icon.svg"
										style={{ width: '7em', height: '7em' }}
										alt=""
									/>
									<span className="feature-list">
										<h3>DATA FILTERING</h3>
										<h5>Verify or dispute the data linked to your personal profile.</h5>
									</span>
								</div>
								<div className="lastest-credit-repo splendi-fy-items  mb-4">
									<img
										className="mb-3"
										src="assets/img/latest-credit-report-icon.svg"
										style={{ width: '7em', height: '7em' }}
										alt=""
									/>
									<span className="feature-list">
										<h3>LATEST CREDIT REPORT</h3>
										<h5>An easy to understand summary of your current credit status.</h5>
									</span>
								</div>
								<div className="cred-ed splendi-fy-items ">
									<img
										className="mb-3"
										src="assets/img/cred-ed-resources-icon.svg"
										style={{ width: '7em', height: '7em' }}
										alt=""
									/>
									<span className="feature-list">
										<h3>UP-TO-DATE DATASETS</h3>
										<ul>
											<li>National Loans Register (NLR)</li>
											<li>South African Credit & Risk Reporting Association (SACRRA)</li>
											<li>Companies and Intellectual Property Commission (CIPC)</li>
										</ul>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CookieConsent
					className="cookie"
					location="bottom"
					buttonText="Ok"
					cookieName="splendiCookie"
					style={{ background: 'white', color: 'black' }}
					buttonStyle={{
						background: 'black',
						color: 'white',
						borderRadius: '.8em',
					}}
					expires={150}
				>
					The Splendi website uses necessary, performance and advertisement cookies to help us know
					a little bit about you and how you use our website, which improves the browsing experience
					and marketing - both for you and others. If you continue to use this site you consent to
					the use of cookies on the site in accordance with our privacy and cookie policy. &nbsp;{' '}
					<a href="https://www.splendi.co.za/privacy_policy" target="_blank">
						Learn more
					</a>
				</CookieConsent>
				<LandingFooter />
			</div>
		);
	}
}

export default Home;
