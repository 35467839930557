import React, { Component } from 'react';
import { Row, Col, MDBCard, MDBCardBody, Table, TableBody, TableHead } from 'mdbreact';
import './AccountsComponent.css';
import { AccountCollapseComponent } from '../../../components/Collapse/AccountCollapseComponent';
class AccountsComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { accounts, paymentHeaders, monthlyPayment, cpaDescription, nlrDescription } = this.props;
		let accountContent = [];
		let headerContent = <div />;
		let paid,
			paidUp,
			missedPayment,
			badAccount,
			CancelledByClient,
			loanSettledEarlyByConsumer,
			accountClosed,
			finalClosure,
			noDataFound = '';

		if (accounts instanceof Array) {
			accounts.forEach((t) => {
				let showAvailableCredit =
					t.CreditLimitAmt.value === null ? null : (
						<Col className="col-12 col-md-6 ">
							<strong>{t.CreditLimitAmt.label}</strong> : R {t.CreditLimitAmt.value}
						</Col>
					);

				headerContent = (
					<Row>
						<Col className="col-12 col-md-3 col-sm-6 text-black">
							<strong>{t.SubscriberName.value}</strong>
						</Col>
						<Col className="col-12 col-md-3 col-sm-6 text-black">
							<strong>{t.AccountNo.label}</strong> : {t.AccountNo.value}
						</Col>
						<Col className="col-12 col-md-3 col-sm-6 text-black">
							<strong>{t.CurrentBalanceAmt.label}</strong> : R {t.CurrentBalanceAmt.value}
						</Col>
						<Col className="col-12 col-md-3 col-sm-6 text-black">
							<strong>{t.StatusCodeDesc.label}</strong> : {t.StatusCodeDesc.value}
						</Col>
					</Row>
				);

				const data_collspan = {
					columns: [],
					rows: [],
					tempColumns: [],
					dataList: [],
				};

				for (let [key, value] of Object.entries(paymentHeaders)) {
					if (key !== 'DisplayText' && key !== 'Company') {
						data_collspan.tempColumns.push({
							label: value.value,
							field: key,
							sort: 'asc',
						});
					}
				}

				let payment = {
					...monthlyPayment.filter((payment) =>
						t.AccountNo.value.includes(payment.AccountNo.value)
					)[0],
				};
				let temp = [];

				data_collspan.tempColumns.forEach((column, index) => {
					if (nlrDescription instanceof Array) {
						nlrDescription.forEach((des) => {
							if (des.DefinitionCode.value === '#' && payment[column.field].value === '#') {
								noDataFound = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">
											<img src="assets/img/future_payments.svg" alt="No data found" />{' '}
											{des.DefinitionDesc.value}
										</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'C' && payment[column.field].value === 'C') {
								accountClosed = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">{des.DefinitionDesc.value} C</span>
									</Col>
								);
							} else if (
								['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
									payment[column.field].value
								) &&
								des.DefinitionCode.value === '1-9'
							) {
								if (des.DefinitionDesc.value === 'No of months in arrears') {
									des.DefinitionDesc.value = 'Missed payment';
								}
								missedPayment = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">
											<img src="assets/img/missed_payments.svg" alt="missed payment" />{' '}
											{des.DefinitionDesc.value}
										</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'P' && payment[column.field].value === 'P') {
								paidUp = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">{des.DefinitionDesc.value} P</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'W' && payment[column.field].value === 'W') {
								badAccount = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">{des.DefinitionDesc.value} W</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === '0' && payment[column.field].value === '0') {
								paid = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">
											<img src="assets/img/made_payments.svg" alt="Not in arrears" /> Paid
										</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'T' && payment[column.field].value === 'T') {
								loanSettledEarlyByConsumer = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap"> {des.DefinitionDesc.value} T</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'G' && payment[column.field].value === 'G') {
								CancelledByClient = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">{des.DefinitionDesc.value} G</span>
									</Col>
								);
							} else if (des.DefinitionCode.value === 'B' && payment[column.field].value === 'B') {
								finalClosure = (
									<Col className="col-2 mw-100 text-nowrap">
										<br />
										<span className="mw-100 text-nowrap">{des.DefinitionDesc.value} B</span>
									</Col>
								);
							}
						});
					}
					if (payment[column.field].value === '#') {
						temp.push(<img src="assets/img/future_payments.svg" alt="No data found" />);
					} else if (payment[column.field].value === '0') {
						temp.push(<img src="assets/img/made_payments_purple.svg" alt="Not in arrears" />);
					} else if (
						['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(payment[column.field].value)
					) {
						temp.push(
							<img src="assets/img/missed_payments.svg" alt="No of months in arrears 1-9" />
						);
					} else if (payment[column.field].value === '*') {
						temp.push(index > 0 ? temp[index - 1] : payment[column.field].value);
					} else {
						temp.push(payment[column.field].value);
					}
				});

				for (let index = data_collspan.tempColumns.length - 1; index >= 0; index--) {
					data_collspan.columns.push(data_collspan.tempColumns[index]);
					data_collspan.dataList.push(temp[index]);
				}

				let data = data_collspan.dataList.map((data) => {
					return data;
				});

				data_collspan.rows.push(data);

				let bodyContent = (
					<div>
						<Row>
							<Col className="col-12 col-md-6 text-nowrap">
								<strong>{t.MonthlyInstalmentAmt.label}</strong> : R {t.MonthlyInstalmentAmt.value}
							</Col>
							{showAvailableCredit}
							<Col className="col-12 col-md-6 text-nowrap">
								<strong>{t.ArrearsAmt.label}</strong> : R {t.ArrearsAmt.value}
							</Col>
							<Col className="col-12 col-md-6 text-nowrap" md="6">
								<strong>{t.AccountOpenedDate.label}</strong> : {t.AccountOpenedDate.value}
							</Col>
						</Row>
						<Row>
							<Col className="col-md-12">
								<br />
								<h6>{payment.DisplayText.value}</h6>
								<MDBCard narrow>
									<MDBCardBody cascade>
										<Row>
											<Col className="col-12">
												<Table className="text-nowrap" borderless responsive>
													<TableHead columns={data_collspan.columns} />
													<TableBody rows={data_collspan.rows} />
												</Table>
											</Col>
										</Row>
										<Row>
											{paid}

											{missedPayment}

											{noDataFound}

											{finalClosure}

											{accountClosed}

											{paidUp}

											{badAccount}

											{CancelledByClient}

											{loanSettledEarlyByConsumer}
										</Row>
									</MDBCardBody>
								</MDBCard>
							</Col>
						</Row>
					</div>
				);

				accountContent.push({
					id: t.id,
					headerContent: headerContent,
					bodyContent: bodyContent,
				});
			});
		} else {
			headerContent = (
				<div>
					<strong>{accounts}</strong>
				</div>
			);
			accountContent.push({
				id: 1,
				headerContent: headerContent,
				bodyContent: <div />,
			});
		}

		return <AccountCollapseComponent accordionContent={accountContent} />;
	}
}

export default AccountsComponent;
