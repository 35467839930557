import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const updateNatureDetails = (data) => (dispatch) => {
	dispatch(updateDisputeLoading(true));
	return fetch(baseUrl + requestPath('api/update-dispute'), {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((data) => dispatch(updateDispute(data)))
		.catch((error) => dispatch(updateDisputeFailed(error.message)));
};

export const updateDisputeLoading = () => ({
	type: ActionTypes.UPDATE_NATURE_DETAILS_LOADING,
});

export const updateDisputeFailed = (errmess) => ({
	type: ActionTypes.UPDATE_NATURE_DETAILS_FAILED,
	payload: errmess,
});

export const updateDispute = (data) => ({
	type: ActionTypes.UPDATE_NATURE_DETAILS,
	payload: data,
});
