import React, { Component } from 'react';
import Main from './views/components/Main/MainComponent';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import withTracker from './withTracker';
import { PersistGate } from 'redux-persist/integration/react';

/*To setup a test json server: json-server --watch db.json -d 2000 -p 3001*/
/*json-server db.json --routes routes.json*/
/*json-server --watch db.json  --routes routes.json -d 2000 -p 3001*/

const store = ConfigureStore();

class App extends Component {
	render() {
		return (
			<Provider store={store.store}>
				<PersistGate loading={null} persistor={store.persistor}>
					<BrowserRouter>
						<div className="App">
							<Route component={withTracker(Main, {})} />
						</div>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
