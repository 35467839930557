import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { connect } from 'react-redux';
import './creditReportDownloadContainer.css';
import { baseUrl } from '../../../../shared/baseUrl';
import axios from 'axios';

const CreditReportDownloadContainer = (props) => {
	// Defaults
	const sessionState = localStorage.getItem('session_state');

	// State
	const [isLoading, setIsLoading] = useState(false);
	const [initializeSuccess, setInitializeSuccess] = useState(false);
	const [initializeError, setInitializeError] = useState(false);

	const initiatePayment = async (paymentGateway) => {
		setIsLoading(true);
		setInitializeError(false);
		setInitializeSuccess(false);
		try {
			const response = await axios.post(`${baseUrl}api/report-initiate-payment`, {
				sessionId: sessionState,
				userId: props.user.user.userId,
			});

			setIsLoading(false);
			setInitializeSuccess(true);

			// Save the transactionId to local storage
			localStorage.setItem('transactionId', response.data.id);

			// Re-direct to the payment gateway screen.
			setTimeout(() => {
				window.location.href = response.data.mdpData.goto;
			}, 1500);
		} catch (error) {
			setIsLoading(false);
			setInitializeError(true);
			console.log(error);
		}
	};

	return (
		<div className="p-md-5">
			<h3 className="pdf-report-title">PDF Report</h3>
			<>
				{initializeSuccess ? (
					<div className="pdf-report-success mt-3">
						<p>Payment initialized successfully, redirecting to the payment gateway.</p>
					</div>
				) : (
					<>
						<section className="pdf-report-download">
							<div>
								<p className="pdf-report-text">
									You will automatically be redirected to the payment gateway
								</p>
							</div>
							<div>
								<button
									className="pdf-download-button"
									onClick={() => initiatePayment('payfastintegration')}
								>
									Buy Credit Report
								</button>
							</div>
						</section>
						<section className="pdf-report-instructions-wrapper">
							<div className="pdf-instructions">
								<ul>
									<li>It will automatically download after payment.</li>
									<li>It is password protected with your ID number or passport</li>
								</ul>
							</div>
							<div className="pdf-kred-guru">
								<div>
									You can view your XDS credit report on Splendi for free. If you are looking for a
									PDF verison, you can buy and download it for only R20.00.
								</div>
								<img width={100} src="assets/img/kred_guru_+2.svg" alt="kred guru" />
							</div>
						</section>
					</>
				)}
				{initializeError ? (
					<div className="pdf-report-error mt-3">
						<p>
							Something went wrong with initializing your payment, please try again after 5 minutes.
						</p>
						<p>For assistance, please contact info@splendi.co.za</p>
					</div>
				) : null}
				{isLoading ? <div className="mt-3">{Loading()}</div> : null}
			</>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditReportDownloadContainer);
