import React from 'react';
import {
	MDBRow,
	MDBCol,
	MDBMask,
	MDBView,
	MDBCardBody,
	MDBCard
} from 'mdbreact';
import { baseUrl } from '../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';

class JustForYouSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: ''
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = url => {
		this.setState({
			url: url
		});
	};

	render() {
		if (this.state.url.length > 0) {
			return <Redirect to={`/offers/featured/offer/${this.state.url}`} />;
		}
		const partners = [
			{
				name: 'BayPort',
				logo: 'assets/img/Bayport-logo-big.png',
				image: 'assets/img/Bayport-banner.png',
				url: 'bayport/req-form'
			},
			{
				name: 'CarZAR',
				logo: 'assets/img/car_zar_logo.png',
				image: 'assets/img/car_zar _offer_banners.png',
				url: 'carzar/req-form'
			},
			{
				name: 'Dealfin',
				logo: 'assets/img/dealfin_log.png',
				image: 'assets/img/dealfin.gif',
				url: 'dealfin/req-form'
			}
		];

		const carouselItems = [];

		partners.forEach(partner => {
			carouselItems.push({
				header: null,
				captionParagraph: null,
				body: (
					<a
						onClick={() => {
							this.navigateToPage(partner.url);
						}}
					>
						<MDBView>
							<MDBCard>
								<MDBCardBody>
									<MDBRow>
										<MDBCol className="col-4  align-self-center">
											<img src={baseUrl + partner.logo} alt="" width="100%" />
										</MDBCol>
										<MDBCol className="col-8  align-self-center">
											<img src={baseUrl + partner.image} alt="" width="100%" />
										</MDBCol>
									</MDBRow>
								</MDBCardBody>
							</MDBCard>
							<MDBMask overlay="black-slight" />
						</MDBView>
					</a>
				)
			});
		});

		return (
			<MDBRow>
				<MDBCol className="col-12 mt-4" />
				<MDBCol>{/*<CarouselComponent items={carouselItems}/>*/}</MDBCol>
			</MDBRow>
		);
	}
}

export default JustForYouSection;
