import React, { Component } from 'react';
import './Vehicle.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOfferSelect } from '../../../../redux/launchOffers/actionCreators/selectedOffer';
import { Loading } from '../../../../components/Loading/LoadingComponent';

const PRODUCT = {
	CAMPAIGN_ID: 2,
	// CAMPAIGN_ID: 116,
	CAMPAIGN_NAME: 'Splendi Household Insure',
};

class HouseholdOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offerCreated: false,
		};

		this.createOfferSelect = this.createOfferSelect.bind(this);
	}

	async createOfferSelect() {
		const offerData = {
			campaignReference: PRODUCT.CAMPAIGN_ID,
			sourceCampaign: PRODUCT.CAMPAIGN_NAME,
		};

		try {
			await this.props.apiOfferCreateSelect(offerData);
			this.setState({ offerCreated: true });
		} catch (err) {
			console.log('Error selecting the offer', err);
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.selectedOffer && this.state.offerCreated) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}

			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			return (
				<div>
					<div>
						<div className="card-big">
							<div className="card-big-header">
								<div className="card-big-header-title-container">
									<div className="rocket-img" />
									<div className="card-big-header-title">
										<div className="heading">HOUSEHOLD INSURANCE</div>
										<div className="text10">
											Household insurance that protects your valuables and your precious memories
											too. Household insurance compensates the breakage or loss of your personal
											treasures and essentials to ensure your peace of mind. Items are covered when
											you take them out of your home.
										</div>
									</div>
								</div>
								<div className="card-big-header-button">
									<div className="btn-green" onClick={this.createOfferSelect}>
										I'm Interested
									</div>
								</div>
							</div>

							<div className="card-small">
								<div className="household" />
								<div className="card-small-body">
									<div className="heading2">HOUSEHOLD INSURANCE</div>
									<div className="heading3"> Get this cover:</div>
									<div className="heading4">
										{' '}
										Theft | Fire | Floods | Storms | Explosions | Hail | Lightning | Wind | Snow |
										Earthquake | Water leaking
									</div>
									<div className="icon-box">
										<div className="bombIcon" />
										<div className="cloudIcon" />
										<div className="electricIcon" />
										<div className="fireIcon" />
										<div className="floodIcon" />
										<div className="lockIcon" />
										<div className="pipeIcon" />
										<div className="snowIcon" />
										<div className="windIcon" />
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferCreateSelect: (data) => dispatch(createOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HouseholdOffer);
