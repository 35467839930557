import React from 'react';
import { Animation, Col, Mask, MDBCard, MDBCardBody, Row, View } from 'mdbreact';
import { Circle } from 'rc-progress';
import PopoverComponent from '../../../../components/Popover/PopoverComponent';

export function RenderDeptComponent({ data }) {
	return (
		<MDBCard className="bg-color-purple-dark-2 text-white text-nowrap text-center mt-3 mb-3">
			<MDBCardBody>
				<Row>
					<Col className="col-12 justify-content-center">
						<h2 className="h2-responsive">R {parseInt(data.totalOutstandingDebt)}</h2>
					</Col>
					<Col className="col-12 justify-content-center">
						<p>
							{data.creditLimitLabel} R {parseInt(data.totalCreditLimit)}
						</p>
					</Col>
				</Row>
			</MDBCardBody>
		</MDBCard>
	);
}

export function RenderDebtGuage({ data }) {
	const percentageStyle = {
		color: '#0A0D29',
		textAlign: 'center',
		fontFamily: 'Roboto',
		fontSize: '60px',
		fontStyle: 'normal',
		fontWeight: 800,
		lineHeight: '151.188%' /* 75.594px */,
		letterSpacing: '-1px',
	};

	if (data.totalDebtPercentage > 100) {
		data.totalDebtPercentage = '>' + 101;
	}
	return (
		<View>
			<Row>
				<Col className="col-12 flex-center">
					<Circle
						percent={data.totalDebtPercentage}
						gapPosition="top"
						width="100%"
						trailWidth="7"
						trailColor={'#f5f5f5'}
						strokeWidth="7"
						strokeColor={'#bb90fe'}
						strokeLinecap="square"
					/>
					<Mask overlay="white-slight" className="flex-center text-wrap">
						<Row>
							<Col className="col-12 d-flex justify-content-center">
								<h3 style={percentageStyle} className={' display-3 font-weight-bolder'}>
									{data.totalDebtPercentage}%
								</h3>
							</Col>
							{/* <Col className="col-12 d-flex justify-content-center">
								<h3 className="h3-responsive sub-title">{data.status}</h3>
							</Col>
							<Col className="col-12 d-flex justify-content-center">
								<PopoverComponent content={data.description} id={data.id} />
							</Col> */}
						</Row>
					</Mask>
				</Col>
			</Row>
		</View>
	);
}

export const ProgressDebtGuageComponent = (props) => {
	const { data } = props;
	if (data && !(Object.entries(data).length === 0 && data.constructor === Object)) {
		return (
			<Row>
				<Col className="col-12 justify-content-center">
					<Row>
						<Col className="col-12">
							<RenderDebtGuage
								data={{
									totalDebtPercentage: data.totalDebtPercentage,
									trailColor: data.trailColor,
									strokeColor: data.strokeColor,
									status: data.status,
									percentageColor: data.percentageColor,
									description: data.description,
									id: data.id,
								}}
							/>
						</Col>
						<Col className="col-12 pt-4 text-center">
							<Row>
								<Col className="col-12">
									<h5 className="h5-responsive sub-title">{data.title}</h5>
									<h5 className="h5-responsive sub-title">TERM DEBT</h5>
								</Col>
							</Row>
						</Col>
						<Col className="col-12 pt-2">
							<RenderDeptComponent data={data.creditData} />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	} else {
		return <div />;
	}
};
