import React from 'react';
import { Card, CardBody, Table, TableBody, TableHead } from 'mdbreact';
import './deedsTable.css';
import { CollapseComponent } from '../../../../components/Collapse/CollapseComponent';

function DeedsRenderFieldEntries({ type }) {
	if (type instanceof Array) {
		const data_collspan = {
			columns: [],
			rows: []
		};

		if (type[0].CommercialName) {
			data_collspan.columns.push({
				label: type[0].CommercialName.label,
				field: 'CommercialName',
				sort: 'asc'
			});
		}
		for (let [key, value] of Object.entries(type[0])) {
			if (key !== 'DisplayText' && key !== 'CommercialName') {
				data_collspan.columns.push({
					label: value.label,
					field: key,
					sort: 'asc'
				});
			}
		}

		type.forEach(key => {
			let cols = data_collspan.columns.map((column, index) => {
				if (!key[column.field]) {
					return;
				} else {
					return column.field === 'Amount'
						? `R ${key[column.field].value}`
						: `${key[column.field].value}`;
				}
			});
			data_collspan.rows.push(cols);
		});
		let bodyContent = (
			<Table
				className="mw-100 mh-100 text-nowrap"
				borderless
				responsive
				striped
				id="deedsHead"
			>
				<TableHead
					columns={data_collspan.columns}
					data-toggle="collapse"
					data-target="#accordion"
					className="clickable"
				/>
				<TableBody
					rows={data_collspan.rows}
					id="accordion"
					className="collapse"
				/>
			</Table>
		);
		let accordionContent = [
			{
				id: 'Legal',
				headerContent: type[0].DisplayText.value,
				bodyContent: bodyContent
			}
		];
		return (
			<Card className="mt-3">
				<CardBody>
					<CollapseComponent accordionContent={accordionContent} />
				</CardBody>
			</Card>
		);
	} else {
		return null;
	}
}

export { DeedsRenderFieldEntries };
