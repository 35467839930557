import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../OffersAndServices/Offer/Vehicle.css';
import { connect } from 'react-redux';
import { updateOfferSelect } from '../../../redux/launchOffers/actionCreators/selectOfferUpdate';
import { Loading } from '../../../components/Loading/LoadingComponent';
import styles from './Acknowledge.module.css';

class Acknowledge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
			complete: false,
			failed: false,
			acknowledged: null,
			isSubmitting: false,
		};
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.acknowledge = this.acknowledge.bind(this);
	}

	handleCheckboxChange() {
		this.setState((prevState) => ({
			isChecked: !prevState.isChecked,
		}));
	}

	async acknowledge(acknowledged) {
		this.setState({
			isSubmitting: true,
		});
		try {
			await this.props.apiOfferUpdateSelect({
				...this.props.selectedOffer.selectedOffer,
				acknowledgedOffer: acknowledged,
			});
			this.setState({
				complete: true,
				acknowledged: acknowledged,
			});
		} catch (err) {
			console.log('Error updating the offer', err);
			this.setState({
				failed: true,
			});
		} finally {
			this.setState({
				isSubmitting: false,
			});
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			if (this.state.complete) {
				return this.state.acknowledged ? (
					<Redirect to="/offers_and_services/success" />
				) : (
					<Redirect to="/offers_and_services" />
				);
			}

			const { isChecked } = this.state;
			const { isSubmitting } = this.state;
			return (
				<div>
					<div className="card-big">
						<div className={styles.acknowledgeTitle}>By clicking on yes:</div>
						<div className={styles.acknowledgeSubTitle}>You acknowledge that:</div>
						<div className={styles.acknowledgeText}>
							<p>
								1. Your personal information & contact details will be shared and processed by our
								partner for the product you showed interest in.
							</p>
							<p>
								2. You will receive a phone call from the partner within 2 hours or at the selected
								time to provide more information on the product.
							</p>
							<p>
								3. Splendi will receive a referral fee for products and services purchased. This fee
								will not affect the price of the product.
							</p>
						</div>
						<div className="form-check mt-3">
							<input
								className="form-check-input"
								type="checkbox"
								checked={isChecked}
								onChange={this.handleCheckboxChange}
								id="flexCheckDefault"
							/>
							<label
								className="form-check-label mt-4"
								htmlFor="flexCheckDefault"
								style={{ fontWeight: 500, marginTop: '40px' }}
							>
								I hereby accept Splendi's Terms of Use and Privacy Policy and give consent for
								Splendi to share my personal information & contact details with its partner for this
								offer only*
							</label>
							{/* <p>app date: {this.props.appointmentDate}</p> */}
						</div>

						<div className={styles.acknowledgeButtons}>
							<button
								className={`${styles.yesButton} ${!isChecked ? 'disabled' : ''}`}
								disabled={!isChecked || isSubmitting ? true : false}
								onClick={() => this.acknowledge(true)}
							>
								Yes
							</button>
							<button
								className={`${styles.noButton} ${!isChecked ? 'disabled' : ''}`}
								disabled={!isChecked}
								onClick={() => this.acknowledge(false)}
							>
								No
							</button>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferUpdateSelect: (data) => dispatch(updateOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Acknowledge);
