import React from 'react';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';

export default props => {
	return (
		<div className="px-3">
			<MDBContainer className="mb-2 py-1">
				<MDBRow className="border border-light rounded ">
					<MDBCol sm="3" pl="0" className="fill px-3">
						<div style={{ backgroundImage: `url(${props.thumbnail})` }} />
					</MDBCol>
					<MDBCol sm="9">
						<MDBRow>
							<MDBCol sm="10" className="summary-content-alignment align-top">
								<h3 className="pb-2">
									<Link
										className="title font-weight-bolder"
										to={'/cred-ed/' + props.slug}
									>
										{props.title}
									</Link>
								</h3>
								<p className="summary-content-layout">{props.summaryContent}</p>
							</MDBCol>
							<MDBCol sm="2">
								<Link
									to={'/cred-ed/' + props.slug}
									className="d-none d-sm-inline"
								>
									<img
										src={`${baseUrl}assets/img/next_arrow_active.svg`}
										className="flex-center"
										alt=""
										width="100%"
									/>
								</Link>
							</MDBCol>
						</MDBRow>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	);
};
