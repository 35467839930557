import React, { Component } from 'react';
import { Row, Col } from 'mdbreact';
import { CreditScoreComponent } from '../../components/Charts/RadialCreditGuage/RadialCreditGuageComponent';
import { RiskScaleComponent } from '../../components/Charts/RiskScale/RiskScaleComponent';
import { ProgressDebtGuageComponent } from '../../components/Charts/ProgressDebtGuage/ProgressDebtGuageComponent';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
let quotesData = require('../../../shared/data');

class CreditScoreSection extends Component {
	render() {
		if (this.props.consumer.isLoading) {
			return <Loading />;
		} else if (this.props.consumer.errMess) {
			const msg =
				'We have not found a credit history for this passport number. This might be because you have not applied for a credit account in South Africa using this passport number. ';
			return <ErrorComponent msg={msg} />;
		} else {
			const consumer = this.props.consumer.consumer;
			const cpaConsumerAccounts =
				consumer.ConsumerAccountStatus instanceof Array ? consumer.ConsumerAccountStatus : [];
			const nlrConsumerAccounts =
				consumer.ConsumerNLRAccountStatus instanceof Array ? consumer.ConsumerNLRAccountStatus : [];
			const cpaConsumer24MonthlyPayment = consumer.Consumer24MonthlyPayment;
			const nrlConsumer24MonthlyPayment = consumer.ConsumerNLR24MonthlyPayment;
			const cpaConsumer24MonthlyPaymentHeader = consumer.Consumer24MonthlyPaymentHeader;
			const paymentHeaders = [];
			let count = 0;
			for (let [key, value] of Object.entries(cpaConsumer24MonthlyPaymentHeader)) {
				if (key !== 'DisplayText' && key !== 'Company') {
					count++;
					if (count > 12) {
						paymentHeaders.push(value.value);
					}
				}
			}

			let shortTermOutstandingDebt = 0;
			let shortTermCreditLimit = 0;
			cpaConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(cpaConsumer24MonthlyPayment[index]).map((obj) => obj.value);

				if (
					['Z', 'S', 'R', 'F', 'G', 'I', 'M', 'N', 'O', 'P', 'V', 'K', '1', '2', '3', '4'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					shortTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					shortTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			nlrConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(nrlConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['Z', 'S', 'R', 'F', 'G', 'I', 'M', 'N', 'O', 'P', 'V', 'K', '1', '2', '3', '4'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					shortTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					shortTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			const shortTermUsedPercentage =
				shortTermCreditLimit === 0
					? 0
					: Math.round((shortTermOutstandingDebt / shortTermCreditLimit) * 100);

			let longTermCreditLimit = 0;
			let longTermOutstandingDebt = 0;
			cpaConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(cpaConsumer24MonthlyPayment[index]).map((obj) => obj.value);

				if (
					['B', 'C', 'D', 'E', 'H', 'J', 'L', 'T', 'U', 'W', 'X', 'Y'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					longTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					longTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			nlrConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(nrlConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['B', 'C', 'D', 'E', 'H', 'J', 'L', 'T', 'U', 'W', 'X', 'Y'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					longTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					longTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			const longTermUsedPercentage =
				longTermCreditLimit === 0
					? 0
					: Math.round((longTermOutstandingDebt / longTermCreditLimit) * 100);
			const paymentData = [
				20000,
				21000,
				25000,
				22000,
				30000,
				19000,
				25000,
				27000,
				23000,
				21000,
				14000,
				27000,
			];
			// LineChart
			const dataLine = {
				labels: paymentHeaders,
				datasets: [
					{
						label: 'OUTSTANDING BALANCE – PAST 12 MONTHS',
						fill: false,
						lineTension: 0.1,
						backgroundColor: 'rgba(75,192,192,0.4)',
						borderColor: 'rgba(75,192,192,1)',
						borderCapStyle: 'butt',
						borderDash: [],
						borderDashOffset: 0.0,
						borderJoinStyle: 'miter',
						pointBorderColor: 'rgba(75,192,192,1)',
						pointBackgroundColor: '#fff',
						pointBorderWidth: 1,
						pointHoverRadius: 5,
						pointHoverBackgroundColor: 'rgba(75,192,192,1)',
						pointHoverBorderColor: 'rgba(220,220,220,1)',
						pointHoverBorderWidth: 2,
						pointRadius: 5,
						pointHitRadius: 10,
						data: paymentData,
					},
				],
			};

			const shortTerm = {
				title: 'REVOLVING & SHORT',
				creditData: {
					totalOutstandingDebt: shortTermOutstandingDebt,
					creditLimitLabel: 'Credit Limit',
					totalCreditLimit: shortTermCreditLimit,
					creditType: 'short',
				},
				totalDebtPercentage: shortTermUsedPercentage,
				trailColor: 'rgb(72,72,72)',
				strokeColor: 'rgb(255, 165, 0)',
				status: 'USED',
				percentageColor: 'text-color-orange',
				description: quotesData.D1,
				id: 'D1',
			};
			const longTerm = {
				title: 'MEDIUM & LONG',
				creditData: {
					totalOutstandingDebt: longTermOutstandingDebt,
					creditLimitLabel: 'Credit Limit',
					totalCreditLimit: longTermCreditLimit,
					creditType: 'long',
				},
				totalDebtPercentage: longTermUsedPercentage,
				trailColor: 'rgb(72,72,72)',
				strokeColor: 'rgb(148,148,196)',
				status: 'USED',
				percentageColor: 'text-color-light-purple',
				description: quotesData.D2,
				id: 'D2',
			};

			return (
				<Col className="col-12 col-md-6 pr-5 pl-5">
					<Row className="mt-4">
						<Col className="mb-4 mb-md-0">
							<CreditScoreComponent data={consumer.ConsumerScoring} title="YOUR CREDIT SCORE" />
						</Col>
						<Col>
							<RiskScaleComponent data={consumer.ConsumerScoring} />
						</Col>
					</Row>
					<Row className="d-flex justify-content-center mt-5">
						<Col className="col-12 col-md-6 pr-md-5 pt-2">
							<ProgressDebtGuageComponent data={shortTerm} />
						</Col>
						<Col className="col-12 col-md-6 pr-md-5 pt-2">
							<ProgressDebtGuageComponent data={longTerm} />
						</Col>
					</Row>
				</Col>
			);
		}
	}
}

export default CreditScoreSection;
