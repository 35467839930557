import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading } from '../../../components/Loading/LoadingComponent';
const PrivateRoute = ({ component: Component, user, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			const urlParams = new URLSearchParams(window.location.search);
			const session = urlParams.get('session_state');

			if (user.isLoading) {
				return <Loading />;
			} else if (!user.isAuthenticated && !session) {
				localStorage.removeItem('session_state');
				return <Redirect to="/login" />;
			} else {
				return <Component {...props} />;
			}
		}}
	/>
);
const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
