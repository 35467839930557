import React, { Component } from 'react';
import {
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	MDBIcon
} from 'mdbreact';
import CustomAvatarComponent from '../Avatar/AvatarComponent';
import { Link } from 'react-router-dom';

class CustomDropdown extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const dropdownItems = this.props.dropdownItems.map((t, index) => {
			if (t.divider) {
				return <DropdownItem key={index} divider />;
			} else {
				if (t.url === '#') {
					return (
						<DropdownItem
							key={index}
							tag={Link}
							to={t.url}
							className="align-items-center nav-link text-nowrap"
							onClick={() => this.props.toggle(t.label, t.content, t.className)}
						>
							<span>{t.label}</span>{' '}
							{t.icon && (
								<span className="p-2">
									<MDBIcon icon={t.icon} size="2x" />
								</span>
							)}
						</DropdownItem>
					);
				} else {
					if (t.externalRoute) {
						return (
							<DropdownItem
								key={index}
								href={t.url}
								className="align-items-center nav-link text-nowrap"
								activeClassName="nav-link-active"
							>
								<span>{t.label}</span>{' '}
								{t.icon && (
									<span className="p-2">
										<MDBIcon icon={t.icon} size="2x" />
									</span>
								)}
							</DropdownItem>
						);
					} else {
						return (
							<DropdownItem
								onClick={() => this.props.collapseNavBar()}
								key={index}
								tag={Link}
								to={t.url}
								className="align-items-center nav-link text-nowrap"
								activeClassName="nav-link-active"
							>
								<span>{t.label}</span>{' '}
								{t.icon && (
									<span className="p-2">
										<MDBIcon icon={t.icon} size="2x" />
									</span>
								)}
							</DropdownItem>
						);
					}
				}
			}
		});

		const headerContent = [this.props.headerContent].map(t => {
			if (t.avatar) {
				return (
					<DropdownToggle key={t.id} nav>
						<CustomAvatarComponent headerContent={this.props.headerContent} />
					</DropdownToggle>
				);
			} else {
				return (
					<DropdownToggle key={t.id} nav caret>
						<span> t.title</span>
					</DropdownToggle>
				);
			}
		});
		return (
			<Dropdown>
				{headerContent}
				<DropdownMenu basic>{dropdownItems}</DropdownMenu>
			</Dropdown>
		);
	}
}

export default CustomDropdown;
