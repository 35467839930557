import React from 'react';

import useCountDownTimer from './CountDown';

export const CountDownTimer = ({ time }) => {
	const [minutes, seconds] = useCountDownTimer(time);

	return (
		<>
			{minutes}m {seconds}s
		</>
	);
};
