import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import KredGuruSection from '../../../sections/KredGuru/KredGuruSection';
import styles from './SuscriptionPaymentFailed.module.css';

class SubscriptionPaymentFailure extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toDashboard: false,
			toOffers: false,
		};
	}

	handleRetry = (event) => {
		event.preventDefault();
		event.preventDefault();
		this.setState({
			toOffers: !this.state.toOffers,
		});
	};
	handleCancel = (event) => {
		event.preventDefault();
		event.preventDefault();
		this.setState({
			toDashboard: !this.state.toDashboard,
		});
	};

	render() {
		const { toOffers, toDashboard } = this.state;
		let kredGuru;
		let kredImage = 'kred_guru_-2.svg';
		let kgMessage = `Oh no! Something went wrong, please try again`;
		let header = null;
		let signature = 'KG – Splendi’s Kred Guru';
		kredGuru = (
			<KredGuruSection
				id="registeredSuccessKG"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
			/>
		);
		if (toDashboard) {
			return <Redirect to="/dashboard" />;
		} else if (toOffers) {
			return <Redirect to="/offers_and_services/featured" />;
		}
		return (
			<MDBContainer className="d-flex justify-content-center pt-5" size="sm">
				<MDBRow className="w-100 d-flex justify-content-center">
					<MDBCol className="col-12 col-md-7">
						<MDBCard>
							<MDBCardBody>
								<MDBRow>
									<MDBCol className="col-12 col-md-9 mx-auto mt-3">{kredGuru}</MDBCol>
								</MDBRow>
								<div className="text-center py-0 mb-3 mt-4">
									<MDBBtn
										color="white-text"
										className="btn-custom-secondary"
										size="sm"
										type="submit"
										onClick={this.handleCancel}
									>
										Cancel
									</MDBBtn>
									<MDBBtn
										color="white-text"
										className="btn-custom-primary"
										size="sm"
										type="submit"
										onClick={this.handleRetry}
									>
										Try again
									</MDBBtn>
								</div>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}

export default SubscriptionPaymentFailure;
