import React, { Component } from 'react';
import { MDBCardTitle } from 'mdbreact';
import '../CalendarInput/calendar.css';
import { Link } from 'react-router-dom';

class CalendarInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appointmentDate: this.toDateString(new Date()),
		};
	}

	toDateString(date) {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date
			.getDate()
			.toString()
			.padStart(2, '0');
		const hours = date
			.getHours()
			.toString()
			.padStart(2, '0');
		const minutes = date
			.getMinutes()
			.toString()
			.padStart(2, '0');
		return `${year}-${month}-${day}T${hours}:${minutes}`;
	}

	timeValid(time) {
		const hour = parseInt(time.split(':')[0]);
		return hour <= 17 && hour >= 8;
	}
	dayValid(date) {
		if (date.getDay() === 6 || date.getDay() === 0) {
			return false;
		}
		const day = new Date().getDay();
		const differenceInMs = date - new Date();
		const dayDelta = this.daysDelta(day);
		const twoDaysInMs = dayDelta * 24 * 60 * 60 * 1000;
		return differenceInMs <= twoDaysInMs && (day !== 0 && day !== 6);
	}

	validAppointmentDate() {
		if (!this.state.appointmentDate) {
			return false;
		}
		const date = this.state.appointmentDate
			? new Date(this.state.appointmentDate.split('T')[0])
			: null;
		const time = this.state.appointmentDate ? this.state.appointmentDate.split('T')[1] : null;
		const valid = this.timeValid(time) && this.dayValid(date);
		return valid;
	}

	handleSelect(e) {
		const newDate = e.target.value;
		const date = new Date(newDate);
		this.setState((date) => ({
			appointmentDate: newDate,
		}));
	}

	daysDelta(day) {
		let dayDelta = 1;
		if (day === 6) {
			dayDelta = 2;
		}
		if (day === 5) {
			dayDelta = 3;
		}
		if (day === 4) {
			dayDelta = 4;
		}
		return dayDelta;
	}

	message() {
		return this.validAppointmentDate()
			? `We will call you back on: ${this.state.appointmentDate}`
			: `Please select a valid date to call you back in the next few days within business hours`;
	}

	maxDate() {
		const date = new Date();
		const day = date.getDay();
		const dayDelta = this.daysDelta(day);
		const maxDate = date.setDate(date.getDate() + dayDelta);
		return this.toDateString(new Date(maxDate));
	}

	createICSFile(startDate, endDate, appointMentName) {
		const formattedStartDate =
			startDate
				.toISOString()
				.replace(/-/g, '')
				.replace(/:/g, '')
				.split('.')[0] + 'Z';
		const formattedEndDate =
			endDate
				.toISOString()
				.replace(/-/g, '')
				.replace(/:/g, '')
				.split('.')[0] + 'Z';
		const icsContent = `BEGIN:VCALENDAR
			VERSION:2.0
			BEGIN:VEVENT
			DTSTART:${formattedStartDate}
			DTEND:${formattedEndDate}
			SUMMARY:${appointMentName}
			LOCATION:${appointMentName}
			DESCRIPTION:You requested a call back.
			END:VEVENT
			END:VCALENDAR`;
		return icsContent;
	}
	downloadICSFile() {
		const startDate = new Date(this.state.appointmentDate);
		const endDate = new Date(startDate.getTime() + 1 * 60 * 60 * 1000);
		const icsContent = this.createICSFile(
			startDate,
			endDate,
			'iBureau callback request for product X'
		);
		const linkElement = document.createElement('a');
		linkElement.href = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsContent);
		linkElement.download = 'ibureau-call.ics';
		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	}

	render() {
		const appointmentData = {
			appointmentDate: this.state.appointmentDate,
			appointmentName: this.state.appointmentName,
		};
		return (
			<div>
				<div className="card-big">
					<MDBCardTitle className="h3-responsive card-heading">
						What is the best time for our team to contact you about the product?
					</MDBCardTitle>
					<input
						className="datepicker"
						type="datetime-local"
						value={this.state.appointmentDate}
						min={this.toDateString(new Date())}
						max={this.maxDate()}
						onChange={(e) => this.handleSelect(e)}
					/>
					<p>{this.message()}</p>
					<button disabled={!this.validAppointmentDate()} onClick={(e) => this.downloadICSFile()}>
						Confirm
					</button>
					{this.validAppointmentDate() ? (
						<Link to={{ pathname: '/offers_and_services/acknowledge', state: appointmentData }}>
							<div className="next-btn">PROCEED</div>
						</Link>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	}
}

export default CalendarInput;
