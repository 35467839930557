import React, { Component } from 'react';
import './landingPageFAQs.css';
import {
	MDBContainer,
	MDBModalHeader,
	MDBModalBody,
	Container,
	MDBCollapse,
	MDBCard,
	MDBCardBody,
	MDBCollapseHeader,
	MDBIcon
} from 'mdbreact';

class LandingPageFAQs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			collapseID: '',
			faqs: false
		};
	}
	toggleCollapse = collapseID => e => {
		e.preventDefault();
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));
	};

	render() {
		return (
			<Container className="mt-2 faqs-page">
				<MDBModalHeader toggle={this.faqToggle}>
					<h5 style={{ color: '#8F8FBF' }}>
						<strong>Welcome to Splendi Support</strong>
					</h5>
					<h6>
						Splendi's FAQs offer insights and helpful pointers to streamline
						your journey to credit wellness and help you get the most out of
						your Splendi experience.
					</h6>
				</MDBModalHeader>
				<MDBModalBody className="text-justify">
					<MDBContainer>
						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('faqs1')}>
								<span style={{ color: 'black' }}>
									Can I access Splendi on my cell phone?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'faqs1'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="faqs1" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										Splendi is currently optimised for desktop computer and
										laptop viewing only. Splendi’s mobile app is currently in
										development and will include new features and special
										offers. We will notify all our users once Splendi's mobile
										app is live.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />
						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('faqs2')}>
								<span style={{ color: 'black' }}>
									What if I enter the wrong cell phone number or ID number to
									sign up?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'faqs2'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="faqs2" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										If you proceed after entering the wrong cell phone number,
										that number will receive your OTP. If you entered the wrong
										ID number, or passport number, please send an email to
										&nbsp;
										<a
											href="mailto:info@splendi.co.za"
											style={{ color: '#0000FF' }}
										>
											info@splendi.co.za
										</a>
										so that this profile can be deleted.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />
						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('faqs3')}>
								<span style={{ color: 'black' }}>
									Can I use Selfie Registration to sign in to Splendi?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'faqs3'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="faqs3" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										Yes, during the sign-up process you have the option to
										activate Selfie Sign in. Alternatively, you can skip this
										step and log in with your username and password.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />
						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('faqs4')}>
								<span style={{ color: 'black' }}>
									Can I dispute incorrect information on my Credit Report?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'faqs4'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="faqs4" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										Yes. For such inaccuracies to be investigated, you need to
										lodge a dispute with Splendi. Your dispute will be
										transmitted to XDS for resolution using the following
										process: &nbsp;
										<a
											href="mailto:Dispute@xds.co.za"
											style={{ color: '#0000FF' }}
										>
											Dispute@xds.co.za
										</a>
										. XDS has 20 business days to investigate and provide a
										resolution on your dispute.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />
						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('faqs5')}>
								<span style={{ color: 'black' }}>
									What if I can’t sign up to Splendi because my email, cell
									phone or ID/Passport are already linked to an account.
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'faqs5'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="faqs5" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										We take protecting your personal information seriously. If
										you try to sign up and it says your email, cell phone and
										ID/Passport are already linked to an open Splendi account,
										please contact &nbsp;
										<a
											href="mailto:info@splendi.co.za"
											style={{ color: '#0000FF' }}
										>
											info@splendi.co.za
										</a>{' '}
										&nbsp; to resolve this matter.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />
						<MDBCard class="mb-r">
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse1')}>
								<span style={{ color: 'black' }}>What is a credit bureau?</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse1'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ul>
										<li>
											A credit bureau is a company that collects, processes and
											reports on a person’s credit information.
										</li>
										<li>
											It captures insights into the credit wellness and spending
											habits of any individual who has opened a store account,
											mobile contract, or has taken a personal loan.
										</li>
										<li>
											Splendi is powered by XDS, one of SA’s four trusted credit
											bureaus.
										</li>
										<li>
											XDS is registered with the National Credit Regulator and
											strictly adheres to the National Credit Act.
										</li>
										<li>
											The NCA legally protects South African consumers and
											simplifies and supports their journey to credit wellness.
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse2')}>
								<span style={{ color: 'black' }}>
									What’s on my Credit Report?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse2'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ol>
										<li>
											<strong>Personal details:</strong>
											<p>
												Your identity is your property. It unlocks access to
												your credit profile. Your details are requested by
												Credit Providers when you apply for a new account or
												take out a loan. Your consent to share these details
												with Credit Bureaus wraps the credit verification
												process.
											</p>
										</li>
										<li>
											<strong>Potential Fraud Indicators:</strong>
											<p>
												This section contains information referenced against
												fraud databases. If your details appear on any of these
												sources, you will be displayed as ‘listed’.
											</p>
										</li>
										<li>
											<strong>Presage Credit Score:</strong>
											<p>
												You credit score is calculated by assessing how
												diligently you pay your accounts and honour your
												financial commitments. It is a measure of the credit
												risk that potential credit providers face when granting
												you credit. The higher your score, the lower the risk to
												credit providers. Your credit score is only one part of
												the Credit Providers Assessment.
											</p>
										</li>
									</ol>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse3')}>
								<span style={{ color: 'black' }}>How to improve my score?</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse3'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										<strong>Facts to consider:</strong>
									</p>
									<ol>
										<li>
											<strong>Payment history:</strong>
											<p>
												Always ensure timely payment of all instalments due. If
												you are already in arrears, it is recommended you make
												additional payments to decrease the arrears until all
												accounts are up to date.
											</p>
											<p>
												The impact of arrears on a score depends on the
												seriousness and the recency of the arrears.
											</p>
										</li>
										<li>
											<strong>Utilisation:</strong>
											<p>
												The ratio between the current outstanding balance and
												the credit limit on revolving credit such as credit
												cards and store cards. This ratio can impact your credit
												score. Lowering your utilisation by paying off some of
												the debt can have a positive impact on your credit
												score.
											</p>
										</li>
										<li>
											<strong>Length of credit history:</strong>
											<p>
												Your credit score can improve as your credit history
												ages, assuming that you make regular payments on your
												accounts. Before opening new accounts, ensure that you
												will be able to cover the potential additional
												instalments.
											</p>
										</li>
										<li>
											<strong>Types of credit used:</strong>
											It can have a positive impact on a score if you can manage
											a variety of debt successfully. Broadly speaking debt can
											be classified into:
											<ul>
												<li>Instalment (personal loan, bond)</li>
												<li>Revolving (credit cards, store cards)</li>
												<li>Open accounts (cell phone account, security)</li>
											</ul>
											<p>
												It is not recommended to open new accounts - to improve
												your score. A high number of new accounts can negatively
												impact your score.
											</p>
										</li>
										<li>
											<strong>Adverse records:</strong>
											<ul>
												<li>
													Negative listings such as judgements and defaults can
													harm your score. It is recommended to pay off any
													judgements and follow the recommended steps to have
													the judgements rescinded.
												</li>
												<li>
													Paying off all debt related to defaults is essential.
												</li>
												<li>
													Once you have paid up a default, you can instruct the
													credit provider to request that the default is marked
													as ‘Paid Up’.
												</li>
											</ul>
										</li>
									</ol>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse4')}>
								<span style={{ color: 'black' }}>
									How do I correct an error in my credit information?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse4'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ul>
										<li>Accuracy is crucial to all Credit Bureaus.</li>
										<li>
											We encourage all consumers to request an investigation
											into their disputed account information if they find an
											error on their credit report.
										</li>
										<li>
											Alternatively, you should send the credit bureau proof of
											any changes to your contact details.
										</li>
										<li>
											The Credit Bureau will update your information and also
											investigate where required.
										</li>
										<li>
											If the information is found to be inaccurate, or can no
											longer be verified, it will be deleted.
										</li>
										<li>
											If an investigation does not resolve the question within
											the 20 business days that the process allows for, then the
											matter can be referred to the Credit Information
											Ombudsman.
										</li>
									</ul>
									<p>
										<strong>
											I have an account, which is being paid every month, but my
											balance does not seem to decrease?
										</strong>
									</p>
									<p>
										When you apply for credit, you need to find out how much
										interest you will be charged on the item. Request that this
										is explained to you in simple language. Always request that
										you receive slips, each time you pay towards an account.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse5')}>
								<span style={{ color: 'black' }}>
									I have obtained my credit report from a Credit Bureau, but the
									balance
									<br /> on account history is incorrect. How do I get that
									rectified?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse5'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse5" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ul>
										<li>
											Your credit grantors may not have sent an update to
											indicate that you have been paying your account(s).
										</li>
										<li>
											The quickest way to amend this is to request that they
											send the Credit Bureau an update.
										</li>
										<li>
											You can also request that the Credit Bureau investigates
											this for you (this may take up to 20 business days).
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse6')}>
								<span style={{ color: 'black' }}>
									I have seen notices of companies that claim to be able to
									remove my
									<br /> name. What can these companies do for me?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse6'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse6" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ul>
										<li>
											These companies are known as credit repair companies.
										</li>
										<li>The majority of them operate illegally.</li>
										<li>
											The Credit Bureau Association (CBA) does not support any
											of these organisations and advise consumers not to use
											their services.
										</li>
										<li>
											It is important to remember that there is nothing that
											these credit repair companies can do that you could not do
											for yourself.
										</li>
										<li>
											They often take large repair up-front fees and do not
											deliver the promised results.
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse7')}>
								<span style={{ color: 'black' }}>
									If I am married in Community of Property and my spouse has a
									negative <br /> listing, will I have difficulty obtaining
									credit?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse7'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse7" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										You might have difficulty obtaining credit if you are
										jointly applying for credit. For example, when applying for
										a joint bond, credit checks will be conducted on both
										parties and if either of you has negative information on
										your credit report, you might experience difficulty
										accessing credit.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader
								onClick={this.toggleCollapse('spouseCollapse')}
							>
								<span style={{ color: 'black' }}>
									Are my report and my spouse’s combined because we are married
									<br /> in community of property?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'spouseCollapse'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="spouseCollapse" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										No, every individual has his or her credit report even
										though you may be married in community of property.
									</p>
									<p>
										<strong>What if people have bought on my ID?</strong>
									</p>
									<ul>
										<li>
											The Credit Bureau Association encourages consumers to
											manage their credit reports by accessing their reports at
											least once a year.
										</li>
										<li>
											If your credit report reflects accounts that you did not
											open, you should contact the companies that had inquired
											on you. Next request that they provide you with the
											application form, which you had allegedly signed when you
											opened the account.
										</li>
										<li>
											Should you receive no feedback, we encourage you to
											contact the Credit Bureau to lodge a dispute.
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse8')}>
								<span style={{ color: 'black' }}>
									When can a store or bank repossess my goods?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse8'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse8" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<ul>
										<li>
											Different banks or stores have different credit-granting
											policies.
										</li>
										<li>
											Each store or bank would detail this policy in the
											agreement that is signed with you when you enter into a
											credit agreement with them.
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse9')}>
								<span style={{ color: 'black' }}>
									Who can view my credit report information?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse9'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse9" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										When you apply for credit, you give the store or bank
										consent to view your credit report.
									</p>
									<p>
										This information will help them decide whether or not to
										grant credit to you. In order to get credit, you have to
										give the store information to help them make the right
										business decision.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse10')}>
								<span style={{ color: 'black' }}>
									Why do stores or banks not give me a copy of my credit report?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse10'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse10" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										Stores & banks do not have employees that are adequately
										trained to counsel you on your credit report. They cannot
										advise you on steps that you need to take to understand and
										manage your credit report.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader
								onClick={this.toggleCollapse('rightsCollapse')}
							>
								<span style={{ color: 'black' }}>
									What are my Credit Rights?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'rightsCollapse'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="rightsCollapse" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>The NCA bestows on you the following rights: </p>
									<ul>
										<li>To apply for credit. </li>
										<li>To protection against discrimination.</li>
										<li>To be given reasons for credit being refused.</li>
										<li>To access and challenge your credit information.</li>
										<li>
											To receive your credit information in an official
											language.
										</li>
										<li>
											To receive all credit-related documents in plain and
											understandable language.
										</li>
										<li>To have your confidential information protected.</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse11')}>
								<span style={{ color: 'black' }}>
									When do stores or banks note default data on a credit report?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse11'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse11" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>Each store or bank has its policies. </p>
									<p>Generally, the following points apply: </p>
									<ul>
										<li>
											For a credit grantor to submit a default, they must be a
											registered subscriber.
										</li>
										<li>The credit grantor must be a registered subscriber.</li>
										<li>The default must be accurate.</li>
										<li>The account may not be in dispute.</li>
										<li>The consumer must have received prior notice. </li>
										<li>
											The amount is a result of a credit-related transaction.
										</li>
									</ul>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
						<br />

						<MDBCard>
							<MDBCollapseHeader onClick={this.toggleCollapse('collapse12')}>
								<span style={{ color: 'black' }}>
									I have no open account or credit history. Why do I have a
									Splendi score?
								</span>
								<MDBIcon
									icon={
										this.state.collapseID === 'collapse12'
											? 'angle-up'
											: 'angle-down'
									}
									style={{ float: 'right', color: 'black' }}
								/>
							</MDBCollapseHeader>
							<MDBCollapse id="collapse12" isOpen={this.state.collapseID}>
								<MDBCardBody>
									<p>
										With the new Presage V3 score, Splendi will provide you with
										a credit score - even if you have no open accounts. You will
										also be able to view all your accounts - even if they were
										open for less than three months.
									</p>
								</MDBCardBody>
							</MDBCollapse>
						</MDBCard>
					</MDBContainer>
				</MDBModalBody>
			</Container>
		);
	}
}

export default LandingPageFAQs;
