import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, CardBody } from 'mdbreact';

export default (props) => {

    let banner = null 

    if (props.banner) {
        banner = <div className="px-5 pb-4">
            <Card className="bg-color-gray-dark text-white py-3">
                <CardBody>
                    <ReactMarkdown source={props.banner} />
                </CardBody> 
            </Card>
        </div>
    }

    return(
        <>
            <ReactMarkdown source={props.content} className="px-5"/>
            {banner}
        </>
    )
};