import React, { Component } from 'react';
import { CardTitle } from 'mdbreact';
import xds_dispute_form from '../../../assets/files/XDS Dispute Form.pdf';

class TermsAndConditions extends Component {
	render() {
		return (
			<div>
				<p className="text-center">
					We thank you for considering using Splendi and extend our warmest
					welcome.
				</p>
				<p>
					By using this Website or communicating with Splendi by electronic
					means, you provide your consent and acknowledge that any and all
					agreements, notices and disclosures satisfy the legal requirements,
					that such communications should be in writing, and that you agree to
					be bound by these Terms and Conditions (“ToU”). By proceeding with the
					use of the Splendi website and services, you expressly acknowledge and
					agree to these ToU, especially important disclaimers, warranties and
					limitations on liability. These ToU constitute the entire agreement
					(“the Agreement”) between Splendi and yourself with regard to the use
					of the content and this website (“Website”).
				</p>
				<CardTitle>
					<strong>Our Services</strong>
				</CardTitle>
				<p>
					Splendi is a consumer services portal owned and operated by zenAptix
					(Pty) Ltd hereinafter referred as zenAptix, providing free access to
					your credit information. Splendi thereby assists you in getting a
					better understanding of your credit information for you to manage your
					financial wellbeing. When we refer to “Services,” we mean all products
					and services owned and operated by Splendi or any of its affiliated
					companies (collectively, “Splendi,” “we,” or “us”), including the
					content, features, tools, data, software and functions made available
					by Splendi through the Website, and our mobile applications, and other
					websites or applications we operate, unless those websites or
					applications have posted separate or additional terms of use. As of
					the effective date of these terms, our Services include the following:
				</p>
				<CardTitle>
					<strong>Your Splendi Account</strong>
				</CardTitle>
				<p>
					You will need to register for a free member account to access many
					aspects of our Services, which includes but is not limited to credit
					scores and reports and credit monitoring services. Registering your
					Splendi member account is optional, but if you do not register for an
					account, you will not be able to access certain aspects of our
					Services. By registering for a Splendi member account, you warrant and
					represent that (i) any information you provide to us, both when you
					register and in the future, is and will be true, accurate, current and
					complete (ii) you are only registering an account for yourself and
					(iii) you will keep all information up-to-date, (iv) the updated
					information you provide may be used to update your credit information
					as held by XDS (Pty) Ltd and or zenAptix which are registered Credit
					Bureaus and (v) your information may be shared with partners of
					Splendi with whom you have agreed to engage with.
				</p>
				<p>
					You are prohibited from selling, transferring, or assigning your
					account to anyone else. Your password is at all times to be kept
					confidential, and you may not share it or allow anyone else to log
					into our Services with your account. You accept responsibility for all
					activities that occur under your member account. It is your
					responsibility to notify us immediately if you believe that your
					account is no longer secure.
				</p>
				<p>
					When you register as a member, you authorise us to create and maintain
					an account in your name using your account registration information
					and other information that may be collected about you in accordance
					with our Privacy Policy (link to Privacy Policy). You acknowledge and
					agree, when registering a Splendi member account that you are asking
					us to create your member profile and access your free credit scores
					and reports, and other information so we can provide our tools and
					services.
				</p>
				<CardTitle>
					<strong>Limitations and Restrictions on Use of Our Services</strong>
				</CardTitle>
				<p>
					In order to use Splendi’s Services and access any content on our
					Website you must be 18 or older. If you access this Website from
					locations outside of South Africa, you are responsible for compliance
					with all local laws. You warrant hereby that your use of the services
					and Content is for a legitimate and lawful purpose. We reserve the
					right to limit the availability of our Services or the provision of
					any of our content to any person, geographic area, or jurisdiction, at
					any time and in our sole and absolute discretion. Splendi may need to
					communicate with you via SMS, in which case you agree to (i) having
					access to receive SMS from Splendi and (ii) be liable to your mobile
					operator for such charges. Notwithstanding the above Splendi cannot
					guarantee that you will receive any SMS, OTP or communication as this
					is entirely dependent on your cellular service provider. You may not
					assign the agreements, in whole or in part, nor transfer or
					sub-license your rights under the agreements, to any third party.
				</p>
				<CardTitle>
					<strong>Ownership and Infringement</strong>
				</CardTitle>
				<p>
					All content and materials, including but not limited to images, text,
					visual interfaces, information, data, and computer code, provided by
					us through our Services (our “Content”) and all related intellectual
					property rights are the property of Splendi and/or its third-party
					licensors. Our Content is protected by South African and international
					intellectual property laws and treaties. You may not copy, modify,
					sell, reproduce, distribute, republish, display, post, create
					derivative or collective works from, or transmit in any form
					(including in-line linking or mirroring) our Content, in whole or in
					part, without our express prior written consent. You may not reverse
					engineer or reverse compile any of our Services or the technology used
					to operate our Services. Nothing in the Agreement or our Services
					grants you, by implication, estoppel, or otherwise, any license or
					right to use our Content except as expressly stated in the Agreement.
				</p>
				<p>
					Our Services may also display certain intellectual property, such as
					company, product, and service name and logos, that is owned by our
					third-party partners (our “Partners' Intellectual Property"). Nothing
					in the Agreement or our Services grants you, by implication, estoppel,
					or otherwise, any license or right to copy, modify, sell, reproduce,
					distribute, republish, display, post, create derivative works from or
					transmit in any form (including in-line linking or mirroring) any of
					our Partners' Intellectual Property.
				</p>
				<CardTitle>
					<strong>Disputes</strong>
				</CardTitle>
				<p>
					Your credit report contains credit information from XDS, a registered
					Credit Bureau. Your credit or service account information is provided
					to XDS by credit or service providers with whom you have an account or
					accounts. These credit or service providers may have inaccuracies in
					the data that they have provided to XDS. For such inaccuracies to be
					investigated, you need to lodge a dispute using a dispute form which
					must be completed and emailed to{' '}
					<a href="mailto:info@splendi.co.za">info@splendi.co.za</a> or{' '}
					<a href="mailto:informationofficer@zenaptix.com">
						informationofficer@zenaptix.com.
					</a>{' '}
					zenAptix has 20 business days to investigate and provide a resolution
					on your dispute. Please download the dispute form{' '}
					<a href={xds_dispute_form} download="XDS Dispute Form.pdf">
						<strong>here</strong>
					</a>
				</p>
				<CardTitle>
					<strong>Disclosure & Report Abuse or Complaints</strong>
				</CardTitle>
				<p>
					Splendi has the right, but not the obligation, to monitor our Services
					electronically. We may access, use, preserve, transfer or disclose, at
					any time and without notice to you, any information (including
					Personal Information as defined in our Privacy Policy) as reasonably
					necessary to: (i) comply with any applicable law, regulation,
					subpoena, or legal process, or respond to any governmental request, if
					we believe such action is required or permitted by law; (ii) enforce
					this Agreement, including to investigate potential violations; (iii)
					protect the safety, integrity, rights, or security of our members, our
					Services or equipment, or any third party; or (iv) detect, prevent, or
					otherwise address fraud, security, or technical issues related to our
					Services or the services of our partners. Any complaints regarding the
					standard and quality of the product or services bought by you through
					our facility should be directed to zenAptix Information Officer:{' '}
					<a href="mailto: informationofficer@zenaptix.com ">
						informationofficer@zenaptix.com
					</a>
				</p>
				<CardTitle>
					<strong>Disclaimers</strong>
				</CardTitle>
				<p>
					We provide our services, our content, and all content and materials
					available through our services “as is” and “as available” without any
					warranty or condition of any kind, express or implied. Splendi, on
					behalf of itself, its affiliates and its licensors, disclaims all
					warranties of any kind, whether express or implied, relating to our
					services, our content, including the implied warranties of
					merchantability, fitness for a particular purpose, quality, accuracy,
					title and non-infringement, and any warranty arising out of course of
					dealing, usage or trade.
				</p>
				<p>
					No advice or information, whether oral or written, obtained by you
					from our services, our content, or otherwise available through our
					services will create any warranty regarding Splendi or any of our
					services that is not expressly stated in these terms. You use our
					services, and use, access, download, or otherwise obtain our content,
					or other content available through our services, at your own
					discretion and risk. You assume all risk for any damage that may
					result from your use of or access to our services, your dealings with
					any other member or third party, and your use of our content, or other
					content available through our services. You are solely responsible for
					any damage to your property (including any computer system or mobile
					device used in connection with our services), or the loss of data that
					may result from the use of our services or the use of any of our
					content, the community content or other content available through our
					services.
				</p>
				<p>
					You acknowledge that Splendi is solely an intermediary (i) between you
					and any third party or other information providers actually supplying
					your credit reports, credit scores and other information.
				</p>
				<p>
					Splendi expressly disclaims any liability for any errors or omissions
					in our content or our services, including the inaccuracy or
					incompleteness of any such credit reports, credit scores, or other
					information, tools or analyses available through our services or our
					content. Notwithstanding the foregoing, nothing in this section shall
					affect warranties which are incapable of exclusion or restriction
					under applicable law.
				</p>
				<CardTitle>
					<strong>Limitation of Liability</strong>
				</CardTitle>
				<p>
					Splendi and its representatives, agents, and any of their respective
					officers, directors, equity holders, employees, parent entities,
					subsidiaries, affiliated entities, representatives, agents and
					licensors (collectively, the “Splendi parties”) will not be liable to
					you or any third party for any special, indirect, incidental,
					consequential or punitive damages (such as loss of profits, loss of
					goodwill, loss of use, loss of data, business interruption, or other
					intangible losses) arising out of or relating in any way to our
					services or content. if any of the foregoing limitations are found to
					be invalid, the Splendi parties' total liability for all damages,
					losses, or causes of action of any kind or nature whatsoever shall be
					limited to the greatest extent permitted by applicable law.
				</p>
				<CardTitle>
					<strong>Indemnification</strong>
				</CardTitle>
				<p>
					You will defend (on demand by any Splendi Party), indemnify, and hold
					harmless the Splendi Parties from and against any and all claims,
					losses, expenses, demands, or liabilities, including attorneys' fees
					and costs, incurred by the Splendi Parties in connection with any
					claim by a third party arising out of or in any way related to: (i)
					your use of our Services or our Content; (ii) your violation or
					alleged violation of the Agreement or your violation or alleged
					violation of any applicable law; (iii) your infringement or alleged
					infringement of any intellectual property or other right of any other
					person or entity; or (iv) any dispute between you and a third party,
					including any provider of third-party content. You must not settle any
					such claim or matter without the prior written consent of Splendi.
				</p>
				<CardTitle>
					<strong>Consent to Jurisdiction</strong>
				</CardTitle>
				<p>
					This Website is controlled, operated and administered by Splendi from
					its offices within the Republic of South Africa. These Terms and
					Conditions shall be governed by the laws of the Republic of South
					Africa, and the User consents to the jurisdiction of the Witwatersrand
					courts in the event of any dispute (including alternative dispute
					resolution in the sole discretion of Splendi).
				</p>
				<CardTitle>
					<strong>Duration and Termination</strong>
				</CardTitle>
				<p>
					Unless terminated by yourself or Us, your access to Splendi shall
					remain in force. Your access to Splendi may be terminated by yourself
					by informing Splendi on{' '}
					<a href="mailto: info@splendi.co.za">info@splendi.co.za</a> that you
					wish for your membership and access to Splendi to be terminated. These
					Terms of Use shall remain in full force and effect while you use the
					Splendi’s Website.
				</p>
				<p>
					Without limiting any other provision of these terms of use, we reserve
					the right to, in our sole discretion and without notice or liability,
					deny access to and use of the website (including blocking certain ip
					addresses), to any person for any reason or for no reason, including
					without limitation for breach of any representation, warranty, or
					covenant contained in these terms of use or of any applicable law or
					regulation. We may terminate your use or participation in the site or
					delete your Splendi member account and any content or information that
					you posted at any time, without warning, in our sole discretion.
				</p>
				<p>
					If we terminate or suspend your account for any reason, you are
					prohibited from registering and creating a new account under your
					name, a fake or borrowed name, or the name of any third party, even if
					you may be acting on behalf of the third party. In addition to
					terminating or suspending your account, we reserve the right to take
					appropriate legal action, including without limitation pursuing civil,
					criminal, and injunctive redress.
				</p>
				<CardTitle>
					<strong>General</strong>
				</CardTitle>
				<p>
					<strong>Severability and entire agreement</strong>. If any of the
					provisions of these Terms and Conditions are found by a court of
					competent jurisdiction to be invalid or unenforceable, that provision
					shall be enforced to the maximum extent permissible so as to give
					effect to the intent of these Terms and Conditions, and the remainder
					of these Terms and Conditions shall continue in full force and effect.
				</p>
				<p>
					<strong>Variation of Terms and Conditions</strong>. Splendi reserves
					the rights to change, modify, add or remove portions or the whole of
					these Terms and Conditions from time to time. Occasionally we may make
					these changes for valid reasons, such as improving the
				</p>
				<p>
					existing functions or features or adding new functions or features to
					the service, implementing advancements in science and technology, and
					reasonable technical adjustments to the service, ensuring the
					operability or the security of the service, and for legal or
					regulatory reasons. Changes to these Terms and Conditions will become
					effective upon such changes being posted to this Website. It is your
					obligation to check changes, upon notice which will be provided once
					you access this Website, to these Terms and Conditions. Your continued
					use of this Website following the posting of changes or updates will
					be considered notice of your acceptance to abide by and be bound by
					these Terms and Conditions, including such changes or updates.
				</p>
			</div>
		);
	}
}

export default TermsAndConditions;
