import * as ActionTypes from '../../ActionTypes';
import { persistExpiresAt } from '../../persistExpiresAt';

export const IDHeroNotification = (
	state = {
		isLoading: true,
		errMess: null,
		idHeroNotification: [],
		persistExpiresAt: persistExpiresAt()
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_ID_HERO_NOTIFICATION:
			return {
				...state,
				isLoading: false,
				errMess: null,
				idHeroNotification: action.payload
			};
		case ActionTypes.ID_HERO_NOTIFICATION_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				idHeroNotification: []
			};
		case ActionTypes.ID_HERO_NOTIFICATION_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				idHeroNotification: []
			};
		default:
			return state;
	}
};
