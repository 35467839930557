import React, { Component } from 'react';
import { Container, Card, Row, Col, MDBBtn } from 'mdbreact';
import './disputes.css';
import xds_dispute_form from '../../../../src/assets/files/XDS Dispute Form.pdf';
import xds_power_of_attorney from '../../../../src/assets/files/XDS Power of Attorney.pdf';

class DisputesPage extends Component {
	render() {
		return (
			<Container className="mt-2 disputes-page">
				<Row>
					<Card>
						<Row>
							<Col className="col-12 col-lg-6 disputeBtn box">
								<a href={xds_dispute_form} download="XDS Dispute Form.pdf">
									<MDBBtn size="lg" color="blue" className="font-weight-bold">
										XDS Dispute Form
									</MDBBtn>
								</a>
							</Col>
							<Col className="col-12 col-lg-6 disputeBtn box">
								<a
									href={xds_power_of_attorney}
									download="XDS Power of Attorney.pdf"
								>
									<MDBBtn size="lg" color="blue" className="font-weight-bold">
										XDS Power of Attorney
									</MDBBtn>
								</a>
							</Col>
						</Row>
						<div>
							<div className="col-12 requiredDoc box">
								the following documentation is required:
							</div>
							<div className="col-lg-12 box">
								<ul>
									<li>Dispute forms signed and completed</li>
									<li>Proof of residence</li>
									<li>ID copy(clear)</li>
									<li>Power of attorney (if requested by an attorney)</li>
									<li>Paid up letters</li>
								</ul>
							</div>
							<div className="sendFormTxt box">
								After completing the forms please send them to&nbsp;
								<a href="mailto:Dispute@xds.co.za">Dispute@xds.co.za</a>
							</div>
							<div className="col-lg-12 complianceTxt">
								In compliance with the National Credit Act 34 of 2005 [NCA], XDS
								commits to resolve your Dispute/Query within 20 business days
								and for paid up Judgements in compliance with Regulation No. R.
								144 of the NCA, within 7 business days. If you are dissatisfied
								with the outcome of this investigation, please contact the
								Credit Ombud for further assistance quoting the above reference
								number on 0861 66 28 67 or visit their website{' '}
								<a href="mailto:www.creditombud.org.za">
									www.creditombud.org.za
								</a>{' '}
								to log a complaint. The information provided above will be used
								to update your XDS credit profile.
							</div>
						</div>
					</Card>
				</Row>
			</Container>
		);
	}
}

export default DisputesPage;
