import React from 'react';
import { Col, Row } from 'mdbreact';

export default (props) => {

    return(
        <Row>
				<Col className="col-9">
					<hgroup className="speech-bubble pt-3 pl-3 pr-3 pb-1 ">
						<p>{props.header}</p>
						<p>{props.content}</p>
						<strong>
							<p>{props.signature}</p>
						</strong>
					</hgroup>
				</Col>
				<Col className="col-md-auto align-self-center">
					<img
						src={props.kredGuruImage}
						className="img-fluid"
						alt=""
						width="100%"
					/>
				</Col>
			</Row>
    )
};