import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';

export const DebtReview = (props) => {
	const { handleCheckChange, natureOfDispute } = props;
	return (
		<MDBRow className="mt-4">
			<MDBCol md="6" className="text-md-center">
				<CheckBoxComponent
					id="withdrewDebtReview"
					name="withdrewDebtReview"
					label="I withdrew from my debt review application"
					disabled={false}
					value={natureOfDispute.withdrewDebtReview}
					required={true}
					filled={true}
					handleChange={handleCheckChange}
					containerClass="mr-5"
				/>
			</MDBCol>
			<MDBCol md="6" className="text-md-center">
				<CheckBoxComponent
					id="debtReviewIsOver"
					name="debtReviewIsOver"
					label="My debt review is over"
					disabled={false}
					value={natureOfDispute.debtReviewIsOver}
					required={true}
					filled={true}
					handleChange={handleCheckChange}
					containerClass="mr-5"
				/>
			</MDBCol>
		</MDBRow>
	);
};
