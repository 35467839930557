import * as ActionTypes from '../../ActionTypes';

export const UpdateContactWayDetails = (
	state = {
		isLoading: false,
		errMess: null,
		updateContact: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.UPDATE_CONTACT_DETAILS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				updateContact: action.payload
			};
		case ActionTypes.UPDATE_CONTACT_DETAILS_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				updateContact: []
			};
		case ActionTypes.UPDATE_CONTACT_DETAILS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				updateContact: []
			};
		default:
			return state;
	}
};
