import React, { Component } from 'react';
import { Loading } from '../../../../../components/Loading/LoadingComponent';
import SelectComponent from '../../../../../components/Select/SelectComponent';
import { ErrorComponent } from '../../../../../components/Error/ErrorComponent';

function getNetworks(networks, location) {
	console.log('location', location.pathname);
	const options = [];
	networks.forEach((network, index) => {
		if (
			location.pathname.includes(
				'/services/buy/blue-label/airtime/vas-network-list-form'
			) &&
			network.NetworkType === 'Airtime'
		) {
			options.push({ text: network.NetworkName, value: index });
		} else if (
			location.pathname.includes(
				'/services/buy/blue-label/bundles/vas-network-list-form'
			) &&
			network.NetworkType === 'Bundle'
		) {
			options.push({ text: network.NetworkName, value: index });
		} else if (
			location.pathname.includes(
				'/services/buy/blue-label/electricity/vas-network-list-form'
			) &&
			network.NetworkType === 'Electricity'
		) {
			options.push({ text: network.NetworkName, value: index });
		}
	});

	return options;
}

class AirtimeNetworkInputField extends Component {
	constructor(props) {
		super(props);
		this.getValueOfNetwork = this.getValueOfNetwork.bind(this);
	}

	getValueOfNetwork = value => {
		console.log(value);
		if (value) {
			this.props.setNetwork(value);
		}
	};

	render() {
		if (this.props.airtimeVASNetworks.isLoading) {
			return <Loading />;
		} else if (this.props.airtimeVASNetworks.errMess) {
			return <ErrorComponent />;
		} else {
			const airtimeVASNetworks = this.props.airtimeVASNetworks
				.airtimeVASNetworks;

			if (airtimeVASNetworks instanceof Array) {
				const networks = getNetworks(airtimeVASNetworks, this.props.location);
				return (
					<SelectComponent
						id="Networks"
						name="Networks"
						label="Networks"
						value={
							this.props.network !== null
								? this.props.network
								: 'Choose a network'
						}
						options={networks}
						getValue={this.getValueOfNetwork}
						handleChange={this.props.handleChange}
						required={true}
					/>
				);
			}
		}
	}
}

export default AirtimeNetworkInputField;
