import * as ActionTypes from './ActionTypes';

export const BayPortQuoteAcknowledgement = (
	state = {
		isLoading: true,
		errMess: null,
		bayPortAcknowledgment: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_BAYPORT_QUOTE_ACCEPT:
			return {
				...state,
				isLoading: false,
				errMess: null,
				bayPortAcknowledgment: action.payload
			};
		case ActionTypes.BAYPORT_QUOTE_ACCEPT_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				bayPortAcknowledgment: []
			};
		case ActionTypes.BAYPORT_QUOTE_ACCEPT_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				bayPortAcknowledgment: []
			};
		default:
			return state;
	}
};
