import React from 'react';
import { MDBRow, MDBCol, MDBView } from 'mdbreact';
import CarouselComponent from '../../../components/Carousel/CarouselComponent';
import { baseUrl } from '../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';
import './FeaturedSection.css';

const cursorStyle = {
	cursor: 'pointer',
};

class FeaturedSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = (url) => {
		this.setState({
			url: url,
		});
	};

	render() {
		if (this.state.url.length > 0) {
			return <Redirect to={`${this.state.url}`} />;
		}
		const partners = [
			{
				name: 'OfferImage1',
				image: 'assets/img/splendi_launch_offer_ad_01.jpg',
				url: '/offers_and_services/featured',
			},
			{
				name: 'OfferImage2',
				image: 'assets/img/vehicle.png',
				url: '/offers_and_services/featured',
			},
			{
				name: 'OfferImage3',
				image: 'assets/img/splendi_launch_offer_ad_03.jpg',
				url: '/offers_and_services/featured',
			},
		];

		const carouselItems = [];

		partners.forEach((partner) => {
			carouselItems.push({
				header: null,
				captionParagraph: null,
				body: (
					<a
						onClick={() => {
							this.navigateToPage(partner.url);
						}}
					>
						<MDBView>
							<img style={cursorStyle} src={baseUrl + partner.image} alt="" width="100%" />
						</MDBView>
					</a>
				),
			});
		});

		/*return (
			<MDBRow>
				<MDBCol className="col-12 mt-4">
					<h4 className="h4-responsive sub-title text-nowrap offers">OFFERS</h4>
				</MDBCol>
				<MDBCol>
					<CarouselComponent items={carouselItems} />
				</MDBCol>
			</MDBRow>
		);*/
		return <MDBRow />;
	}
}

export default FeaturedSection;
