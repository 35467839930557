import React, { Component } from 'react';
import { MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from 'mdbreact';
import { FieldFeedback, FieldFeedbacks } from 'react-form-with-constraints';
import './SelectComponent.css';
import styles from './SelectComponent.module.css';

class SelectComponent extends Component {
	render() {
		const asterisk = <span style={{ color: '#5151F9', marginLeft: '5px' }}>*</span>;
		let label = this.props.label;
		if (this.props.required === true) {
			label = (
				<p className={styles.inputLabel}>
					{label}
					{asterisk}
				</p>
			);
		} else {
			label = <p className={styles.inputLabel}>{label}</p>;
		}
		const options = this.props.options.map((option, index) => {
			return (
				<MDBSelectOption key={index} value={option.value} selected={option.checked}>
					{option.text}
				</MDBSelectOption>
			);
		});
		return (
			<div className="select-border">
				{this.props.labelDisplay ? label : null}
				<MDBSelect getTextContent={this.props.getValue} label={label}>
					<MDBSelectInput
						selected={this.props.value}
						required={this.props.required}
						attributes={{
							id: this.props.id,
							name: this.props.name,
							readOnly: false,
							onChange: this.props.handleChange,
						}}
					>
						<FieldFeedbacks for={this.props.name}>
							<FieldFeedback className="invalid-feedback" when="*" />
						</FieldFeedbacks>
					</MDBSelectInput>
					<MDBSelectOptions>{options}</MDBSelectOptions>
				</MDBSelect>
			</div>
		);
	}
}

export default SelectComponent;
