const GetExceptionCode = exceptionCode => {
	if (exceptionCode) {
		switch (exceptionCode.trim()) {
			case 'DEBT REVIEW LISTED AGAINST CONSUMER':
				return 'you are under debt review.';
			case 'CONSUMER DISPUTING INFORMATION':
				return 'you are currently disputing items on your credit profile. Your score will return once this is complete.';
			case 'SEQUESTRATION ORDER LISTED AGAINST CONSUMER':
				return 'you are under sequestration.';
			case 'CONSUMER LISTED AS DECEASED ON PAYMENT PROFILE':
				return 'a credit provider marked you as deceased.';
			case 'CONSUMER LISTED AS DECEASED AT HOME AFFAIRS':
				return 'Home affairs has marked you as deceased.';
			case 'ADMINISTRATION ORDER LISTED AGAINST CONSUMER':
				return 'you are under administration.';
			default:
				return '';
		}
	}
	return exceptionCode;
};
export default GetExceptionCode;
