import React, { Component } from 'react';
import { Loading } from '../Loading/LoadingComponent';
import { Avatar } from 'mdbreact';
import { baseUrl } from '../../shared/baseUrl';
import './AvatarComponent.css';

class CustomAvatarComponent extends Component {
	render() {
		if (this.props.headerContent.user.isLoading) {
			return <Loading />;
		} else if (this.props.headerContent.user.errMess) {
			return <h5 className="red-text">{this.props.headerContent.user.errMess}</h5>;
		} else {
			const avatarURL =
				String(this.props.headerContent.user.user.gender).toLowerCase() === 'm' ||
				String(this.props.headerContent.user.user.gender).toLowerCase() === 'male'
					? {
							imageActive: `${baseUrl}assets/img/faces/Male User icon active state.svg`,
							imageRollover: `${baseUrl}assets/img/faces/Male User icon rollover state.svg`,
					  }
					: {
							imageActive: `${baseUrl}assets/img/faces/Female User icon active state.svg`,
							imageRollover: `${baseUrl}assets/img/faces/Female User icon rollover state.svg`,
					  };

			return (
				<div className="d-flex align-items-center flex-nowrap">
					<div className="px-2 py-0 py-md-2">
						<p className="text-color-purple font-sm-small">
							Hi {this.props.headerContent.user.user.givenName}
						</p>
					</div>
					<Avatar className="mx-auto white p-2" circle>
						<img
							src={avatarURL.imageActive}
							onMouseOver={(e) => (e.currentTarget.src = avatarURL.imageRollover)}
							onMouseOut={(e) => (e.currentTarget.src = avatarURL.imageActive)}
							className="rounded-circle navbar-avatar"
							size="5x"
							alt={this.props.headerContent.avatarBrand}
						/>
					</Avatar>
				</div>
			);
		}
	}
}

export default CustomAvatarComponent;
