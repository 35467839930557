import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import DashboardPage from '../../pages/Dashboard/DashboardPage';
import DetailedReportPage from '../../pages/DetailedReport/DetailedReportPage';
import OffersAndServices from '../../pages/OffersAndServices/OffersAndServices';
import Carousels from '../../pages/Carousels/Carousels';
import MobileNumber from '../../../views/pages/MobileNumber/MobileNumber';
import CalendarInput from '../../pages/CalendarInput/calendar';
import AARoadsideAssistOffer from '../../../../src/views/contents/partner-offers/AARoadsideAssist/AARoadsideAssistOffer';
import OfferPage from '../../pages/OffersAndServices/Offer/OfferPage';
import ServicePage from '../../pages/OffersAndServices/Service/ServicePage';
import PaymentGateWay from '../../pages/OffersAndServices/PaymentGateWay';
import ProfilePage from '../../pages/Profile/ProfilePage';
import CredEdPage from '../../pages/CredEd/CredEdPage';
import SettingsPage from '../../pages/Settings/SettingsPage';
import SignUp from '../../pages/Auth/SignUp';
import Header from '../../sections/Header/HeaderSection';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import {
	downloadReportPDF,
	fetchUser,
	logout,
	fetchAirtimeVASNetworks,
	fetchAirtimeVASProducts,
	buyVASProduct,
	fetchShoppingCartItems,
	addShoppingCartItem,
	updateShoppingCartItem,
	deleteShoppingCartItem,
	deleteAllShoppingCartItems,
} from '../../../redux/ActionCreators';
import { Carousel, Container } from 'mdbreact';
import { ModalComponent } from '../../../components/Modal/ModalComponent';
import { CountDownTimer } from '../../../components/CountDownTimer/CountDownTimer';
import ShoppingCartPopover from '../../contents/ShoppingCart/ShoppingCartPopover';
import { FeatureToggleProvider, FeatureToggle } from 'react-feature-toggles';
import { FEATURE_TOGGLE_LIST, FEATURE_TOGGLE_VALUES } from '../../../shared/featureToggles';
import TermsAndConditionsPage from '../../pages/TermsAndConditions/TermsAndConditionsPage';
import { fetchSms } from '../../../redux/launchOffers/actionCreators/sendSms';
import { fetchVerifySms } from '../../../redux/launchOffers/actionCreators/verifySms';
import SmsValidation from '../../pages/SmsValidation/SmsValidation';
import SubscriptionPaymentFailure from '../../contents/Services/Subscription/SubscriptionPaymentFailure';
import Subscription from '../../contents/Services/Subscription/Subscription';
import SubscriptionPaymentSuccess from '../../pages/SmsValidation/SubscriptionPaymentSuccess';
import { fetchIdHeroUserNotification } from '../../../redux/idHeroNotifications/actionCreators/id-hero-user-notification';
import { fetchEnvironmentVariables } from '../../../redux/envVariables/actionCreator/envVariables';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { baseUrl } from '../../../shared/baseUrl';
import About from '../../pages/About/about';
import Home from '../../pages/Home/home';
import PrivacyAndPolicy from '../../pages/Settings/PrivacyAndPolicy';
import LandingPageFAQs from '../../pages/FAQs/LandingPageFAQs';
import NoMatchPage from '../../pages/NotFound/NotFound';
import Dispute from '../../pages/Dispute/dispute';
import OnlineFormDispute from '../../pages/Dispute/onlineFormDispute';
import DisputesPage from '../../pages/Disputes/DisputesPage';
import ElectronicDisputeSuccess from '../../pages/Dispute/electronicDisputeSuccess';
import { fetchKgCredEdCmsArticles } from '../../../redux/kg-cred-ed-cms-articles/actionCreators/getKgCredEdCmsArticles';
import SendEmailSuccess from '../../contents/detailed-report/CreditReportDownloadRequest/sendEmailSuccess';
import '../../../components/Navbar/NavbarComponent.css';
import VehicleOffer from '../../pages/OffersAndServices/Offer/VehicleOffer';
import InputComponent from '../../../components/Input/InputComponent';
import HomeOffer from '../../pages/OffersAndServices/Offer/HomeOffer';
import HouseholdOffer from '../../pages/OffersAndServices/Offer/HouseholdOffer';
import BoostOffer from '../../pages/OffersAndServices/Offer/BoostOffer';
import Acknowledge from '../../pages/Acknowledge/Acknowledge';
import Success from '../../pages/Success/Success';
import Failure from '../../pages/Error/Error';
import NoCreditPage from '../../pages/NoCreditPage/NoCreditPage';
import Consolidation from '../../pages/OffersAndServices/Offer/Consolidation';
import { DebtReview } from '../../pages/Dispute/nature/debtReview';
import DebtOffer from '../../pages/OffersAndServices/Offer/DebtOffer';
import PersonalLoan from '../../pages/OffersAndServices/Offer/PersonalLoad';
import Login from '../../pages/Auth/Login';
import DetailedReportPDFPaymenSuccess from '../../pages/DetailedReport/DetailedReportPDFPaymenSuccess';
import DetailedReportPDFPaymentFailure from '../../pages/DetailedReport/DetailedReportPDFPaymentFailure';
import DetailedReportPDFPaymentError from '../../pages/DetailedReport/DetailedReportPDFPaymentError';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: { isOpen: false, header: '', content: '' },
			inActivityModal: {
				isOpen: false,
				header: '',
				content: <></>,
			},
			kcClientID: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID,
			network: null,
			isScoreUpdated: false,
			dashboardKGCount: 0,
			popoverOpen: false,
			user: [],
			isUserAuthenticated: false,
		};
		this.toggle = this.toggle.bind(this);
		this.toggleInActivity = this.toggleInActivity.bind(this);
		this.setNetwork = this.setNetwork.bind(this);
		this.updateScore = this.updateScore.bind(this);
		this.updateParentState = this.updateParentState.bind(this);
		this.resetDashboardKGCount = this.resetDashboardKGCount.bind(this);
		this.togglePopOver = this.togglePopOver.bind(this);
	}

	toggle(header, content, className) {
		this.setState({
			modal: {
				isOpen: !this.state.modal.isOpen,
				header: header,
				content: content,
				className: className,
			},
		});
	}

	toggleInActivity() {
		clearTimeout(this.LogoutTimer);
		this.setState({
			inActivityModal: {
				isOpen: !this.state.inActivityModal.isOpen,
			},
		});
	}

	setNetwork = (network) => {
		if (this.props.location.pathname === '/services/buy/blue-label/bundles/vas-network-list-form') {
			this.setState({
				network: network,
			});

			this.props.fetchAirtimeVASProducts(network.replace(/\s/g, ''));
		}
	};

	updateScore() {
		this.setState({
			isScoreUpdated: true,
		});
	}

	resetDashboardKGCount() {
		this.setState({
			dashboardKGCount: 1,
		});
	}

	updateParentState(url) {
		if (url.includes('/dashboard')) {
			this.setState({
				dashboardKGCount: this.state.dashboardKGCount + 1,
			});
		}
	}

	togglePopOver() {
		this.setState({
			popoverOpen: !this.state.popoverOpen,
		});
	}

	componentDidMount() {
		// avoid calling fetch user on root page.
		if (this.props.location.pathname !== '/') {
			const urlParams = new URLSearchParams(window.location.search);
			const session_state = urlParams.get('session_state');
			if (session_state) localStorage.setItem('session_state', session_state);

			this.props.fetchUser();
		}

		this.props.fetchCmsCredEdArticles();
	}
	componentDidUpdate() {
		if (this.props.user && this.props.user.isAuthenticated && !this.state.isUserAuthenticated) {
			const data = localStorage.getItem('persist:persistedStore');

			if (data === null) return;

			const savedData = JSON.parse(data);
			const decrypted = CryptoJS.AES.decrypt(
				`${savedData.user}`.replace('"', ''),
				'Splendi-ui'
			).toString(CryptoJS.enc.Utf8);
			const decryptedDataJSONFormat = JSON.parse(decrypted);

			if (decryptedDataJSONFormat.user.length === undefined) {
				localStorage.setItem('continue', 'false');
				this.setState({ isUserAuthenticated: true });
				this.setState({ savedData });
			}
		}
	}

	modalShowTime = 5 * 60 * 1000; // 5 minutes * 60 seconds * 1000 milliseconds
	logoutTime = 2 * 60 * 1000;

	inactivityTimer = setTimeout(() => {
		const loginRedirect = window._env_.REACT_APP_LOGIN_URL;
		this.props.fetchLogout();
		window.location.href = `${loginRedirect}/login`;
	}, this.modalShowTime);

	// logoutTimer2 = setTimeout(() => {}, this.logout * this.modalShowTime);
	logoutTimer = setTimeout(() => {}, this.logout * this.modalShowTime);

	handleInactivityModal = () => {
		if (this.state.inActivityModal.isOpen === false) {
			clearTimeout(this.logoutTimer);
			clearTimeout(this.inactivityTimer);
		}

		this.inactivityTimer = setTimeout(() => {
			this.logoutTimer = setTimeout(() => {
				const loginRedirect = window._env_.REACT_APP_LOGIN_URL;
				this.props.fetchLogout();
				window.location.href = `${loginRedirect}/login`;
			}, this.logoutTime);

			this.setState({
				inActivityModal: {
					isOpen: true,
					header: 'Are you still there?',
					className: 'stay-logged-in-modal-header',
					content: (
						<>
							<p>
								You have been inactive for {this.modalShowTime / (60 * 1000)} minutes, you will
								automatically be logged out in <CountDownTimer time={this.logoutTime / 1000} />
							</p>
						</>
					),
					bodyClass: 'stay-logged-in-modal-body',
					footer: this.ContinueButton(),
					footerClass: 'stay-logged-in-modal-footer',
				},
			});
		}, this.modalShowTime);
	};

	ContinueButton = () => {
		return (
			<button
				className="h5-responsive stay-logged-in navbar-button"
				onClick={() => this.toggleInActivity()}
			>
				Stay logged in
			</button>
		);
	};

	render() {
		this.props.user.isAuthenticated && this.handleInactivityModal();

		const kcBaseUrl = window._env_.REACT_APP_KEYCLOAK_BASE_URL;
		const kcRealm = window._env_.REACT_APP_KEYCLOAK_REALM;
		const kcClientID = window._env_.REACT_APP_KEYCLOAK_CLIENT_ID;

		const currentHost = window.location.host;
		const currentPath = window.location.href;

		const loginRedirect = window._env_.REACT_APP_LOGIN_URL;

		const session_state = localStorage.getItem('session_state');

		return (
			<>
				<ModalComponent
					modal={this.state.inActivityModal}
					toggle={this.toggleInActivity}
					footer={this.ContinueButton()}
					size="md"
				/>
				<FeatureToggleProvider featureToggleList={FEATURE_TOGGLE_VALUES}>
					<div className="flyout">
						<Header
							user={this.props.user}
							shoppingCart={this.props.shoppingCart}
							togglePopOver={this.togglePopOver}
							toggle={this.toggle}
							updateParentState={this.updateParentState}
						/>
						<main
							style={{
								marginTop: '8rem',
								marginBottom: '7rem',
								boxSizing: 'content-box!important',
							}}
						>
							<TransitionGroup>
								<CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
									<Switch>
										<Route path="/" exact component={Home} />
										<Route path="/about" component={About} />
										<Route
											path="/login"
											component={() => {
												window.location.href = `${loginRedirect}/login`;
												return true;
											}}
										/>
										<Route
											path="/sign-out"
											component={() => {
												this.props.fetchLogout();
												return <Redirect to="/" />;
											}}
										/>
										<PrivateRoute exact path="/offers_and_services" component={Carousels} />
										<PrivateRoute
											path="/offers_and_services/vehicleinsurance"
											component={VehicleOffer}
										/>
										<PrivateRoute path="/offers_and_services/homeinsurance" component={HomeOffer} />
										<PrivateRoute
											path="/offers_and_services/householdinsurance"
											component={HouseholdOffer}
										/>
										<PrivateRoute path="/offers_and_services/splendiboost" component={BoostOffer} />
										<PrivateRoute
											path="/offers_and_services/debtconsolidation"
											component={Consolidation}
										/>
										<PrivateRoute path="/offers_and_services/debtreview" component={DebtOffer} />
										<PrivateRoute
											path="/offers_and_services/personalloan"
											component={PersonalLoan}
										/>
										<PrivateRoute
											path="/offers_and_services/roadsideassist"
											component={OffersAndServices}
										/>
										<PrivateRoute path="/offers_and_services/acknowledge" component={Acknowledge} />
										<PrivateRoute path="/offers_and_services/success" component={Success} />
										<PrivateRoute path="/offers_and_services/failure" component={Failure} />
										<PrivateRoute
											path="/offers_and_services/mobilenumber"
											component={MobileNumber}
										/>
										<PrivateRoute path="/offers_and_services/calendar" component={CalendarInput} />
										<PrivateRoute path="/credit" component={NoCreditPage} />

										{/* <Route
											path="/sign-up"
											component={() => {
												window.location.href = `${kcBaseUrl}/auth/realms/${kcRealm}/protocol/openid-connect/registrations?client_id=${kcClientID}&response_type=none&scope=openid&redirect_uri=${baseUrl}dashboard`;
												return null;
											}}
										/> */}

										<Route
											path="/sign-up"
											component={() => {
												window.location.href = `${loginRedirect}/register?redirect_uri=${currentPath}`;
												return true;
											}}
										/>

										<PrivateRoute
											path="/dashboard"
											component={() => (
												<DashboardPage
													user={this.props.user}
													dashboardKGCount={this.state.dashboardKGCount}
													resetDashboardKGCount={this.resetDashboardKGCount}
													timeOutStatus={this.state.inActivityModal.isOpen}
												/>
											)}
										/>
										<PrivateRoute
											path="/credit-report"
											component={() => (
												<DetailedReportPage user={this.props.user} consumer={this.props.consumer} />
											)}
										/>
										<Route
											path="/pdf-report-payment-success"
											component={() => <DetailedReportPDFPaymenSuccess user={this.props.user} />}
										/>
										<Route
											path="/pdf-report-payment-cancelled"
											component={() => <DetailedReportPDFPaymentFailure user={this.props.user} />}
										/>
										<Route
											path="/pdf-report-payment-error"
											component={() => <DetailedReportPDFPaymentError user={this.props.user} />}
										/>
										<Route
											path="/cred-ed/:id"
											component={() => (
												<CredEdPage
													location={this.props.location}
													user={this.props.user}
													articles={this.props.articles}
													articlesIsLoading={this.props.articles.isLoading}
													updateScore={this.updateScore}
													isScoreUpdated={this.state.isScoreUpdated}
													creditSimulatorAccounts={this.props.creditSimulatorAccounts}
													creditSimulatorScore={this.props.creditSimulatorScore}
													updateCreditSimulatorScore={this.props.updateCreditSimulatorScore}
												/>
											)}
										/>
										<Route path="/support" component={SettingsPage} />
										<Route
											path="/profile"
											component={() => {
												window.location.href = `${loginRedirect}/edit-profile?session_state=${session_state}`;
												return true;
											}}
										/>
										<PrivateRoute path="/inputcomponent" component={InputComponent} />
										<PrivateRoute
											path="/offers_and_services/verify-otp"
											component={() => (
												<SmsValidation
													verifySms={this.props.verifySms}
													user={this.props.user}
													fetchSms={this.props.fetchSms}
													fetchVerifySms={this.props.fetchVerifySms}
												/>
											)}
										/>
										<PrivateRoute
											path="/payment-success"
											component={() => <SubscriptionPaymentSuccess user={this.props.user} />}
										/>
										<Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
										<Route path="/privacy_policy" component={PrivacyAndPolicy} />
										<Route path="/faqs" component={LandingPageFAQs} />
										<PrivateRoute path="/payment-failure" component={SubscriptionPaymentFailure} />
										<PrivateRoute path="/new-subscription/:modeID" component={Subscription} />
										<PrivateRoute path="/dispute" component={Dispute} />
										<PrivateRoute
											path="/online-dispute"
											component={() => <OnlineFormDispute user={this.props.user} />}
										/>
										<PrivateRoute path="/download-form" component={DisputesPage} />
										<PrivateRoute path="/dispute-success" component={ElectronicDisputeSuccess} />
										<PrivateRoute
											path="/credit-report-request-success"
											component={SendEmailSuccess}
										/>
										<PrivateRoute
											exact
											path="/offers_and_services/:id"
											component={() => <OffersAndServices user={this.props.user} />}
										/>

										<PrivateRoute
											exact
											path="/carousel:id"
											component={() => <Carousel user={this.props.user} />}
										/>
										<PrivateRoute
											path="/offers/:id/:id/:id/:id"
											component={() => (
												<OfferPage
													location={this.props.location}
													fetchBayPortQuote={this.props.fetchBayPortQuote}
													bayPortQuote={this.props.bayPortQuote}
													bayPortAcknowledgment={this.props.bayPortAcknowledgment}
													acceptBayPortQuote={this.props.acceptBayPortQuote}
													shoppingCart={this.props.shoppingCart}
													addShoppingCartItem={this.props.addShoppingCartItem}
													user={this.props.user}
												/>
											)}
										/>
										<PrivateRoute path="/shopping-cart" component={PaymentGateWay} />
										<PrivateRoute
											path="/services/:id/:id/:id/:id"
											component={() => (
												<ServicePage
													location={this.props.location}
													network={this.state.network}
													setNetwork={this.setNetwork}
													airtimeVASNetworks={this.props.airtimeVASNetworks}
													airtimeVASProducts={this.props.airtimeVASProducts}
													buyVASProductResponse={this.props.buyVASProductResponse}
													fetchAirtimeVASProducts={this.props.fetchAirtimeVASProducts}
													buyVASProduct={this.props.buyVASProduct}
													addShoppingCartItem={this.props.addShoppingCartItem}
												/>
											)}
										/>
										<Route component={NoMatchPage} />
									</Switch>
								</CSSTransition>
							</TransitionGroup>
							<Container>
								<FeatureToggle eatureName={FEATURE_TOGGLE_LIST.SHOPPING_CARD} showOnlyWhenDisabled>
									<ModalComponent modal={this.state.modal} toggle={this.toggle} size="lg" />
									<ShoppingCartPopover
										shoppingCart={this.props.shoppingCart}
										togglePopOver={this.togglePopOver}
										toggle={this.toggle}
										popoverOpen={this.state.popoverOpen}
										deleteShoppingCartItem={this.props.deleteShoppingCartItem}
										deleteAllShoppingCartItems={this.props.deleteAllShoppingCartItems}
										updateShoppingCartItem={this.props.updateShoppingCartItem}
										fetchShoppingCartItems={this.props.fetchShoppingCartItems}
										location={this.props.location}
									/>
								</FeatureToggle>
							</Container>
						</main>
					</div>
				</FeatureToggleProvider>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		airtimeVASNetworks: state.airtimeVASNetworks,
		airtimeVASProducts: state.airtimeVASProducts,
		buyVASProductResponse: state.buyVASProductResponse,
		user: state.user,
		debitOrderResult: state.debitOrderResult,
		sendSms: state.sendSms,
		verifySms: state.verifySms,
		availableOffers: state.availableOffers,
		subscription: state.subscription,
		idHeroNotification: state.idHeroNotification,
		consumer: state.consumer,
		environmentV: state.environmentV,
		articles: state.articles,
	};
};
const mapDispatchToProps = (dispatch) => ({
	fetchShoppingCartItems: () => {
		dispatch(fetchShoppingCartItems());
	},
	addShoppingCartItem: (formData) => {
		dispatch(addShoppingCartItem(formData));
	},
	updateShoppingCartItem: (formData) => {
		dispatch(updateShoppingCartItem(formData));
	},
	deleteShoppingCartItem: (formData) => {
		dispatch(deleteShoppingCartItem(formData));
	},
	deleteAllShoppingCartItems: () => {
		dispatch(deleteAllShoppingCartItems());
	},
	fetchAirtimeVASNetworks: () => {
		dispatch(fetchAirtimeVASNetworks());
	},
	fetchAirtimeVASProducts: (network) => {
		dispatch(fetchAirtimeVASProducts(network));
	},
	buyVASProduct: (url, formData) => {
		dispatch(buyVASProduct(url, formData));
	},
	downloadReportPDF: (consumer) => {
		dispatch(downloadReportPDF(consumer));
	},
	environmentVariables: () => {
		dispatch(fetchEnvironmentVariables());
	},
	fetchLogout: () => {
		dispatch(logout());
	},
	fetchUser: () => {
		dispatch(fetchUser());
	},
	fetchSms: (sendSms) => {
		dispatch(fetchSms(sendSms));
	},
	fetchVerifySms: (verifySms) => {
		dispatch(fetchVerifySms(verifySms));
	},
	fetchIdHeroUserNotification: () => {
		dispatch(fetchIdHeroUserNotification());
	},
	fetchCmsCredEdArticles: () => {
		//dispatch(fetchKgCredEdCmsArticles());
	},
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Main)
);
