import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardText,
	CardTitle,
	MDBBtn,
	MDBCol,
	MDBContainer,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
} from 'mdbreact';
import SelectComponent from '../../../../components/Select/SelectComponent';
import InputComponent from '../../../../components/Input/InputComponent';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';
import './bankVerification.css';
import KredGuruSection from '../../../sections/KredGuru/KredGuruSection';
import { fetchNewSubscription } from '../../../../redux/launchOffers/actionCreators/newSubscription';
import { FormWithConstraints } from 'react-form-with-constraints';
import { FadeTransform } from 'react-animation-components';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import TermsAndConditions from '../../../sections/TermsAndConditions/TermsAndConditions';
import SubscriptionTable from './SubscriptionTable';
import styles from './Subscription.module.css';

const selectOptions = require('./subscriptionData');
const formData = require('./subscriptionFormData');

class Subscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDisabled: true,
			modal: false,
			showTable: false,
			formData: formData,
			isFormValid: false,
			invalidFormMessage: '',
			alreadyExist: '',
			selectedValue: '',
			monthlySubscriptionAmount: '24.99',
			yearlySubscriptionAmount: '299.88',
			monthlyAddedAmount: '20',
			yearlyAddedAmount: '240',
			subscriptionKGCount: 0,
			vehicleDetails: [],
		};
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	handleChange = async (event) => {
		if (event.target) {
			event.preventDefault();
			const {
				target: { name, value },
			} = event;
			this.setState({
				formData: {
					...this.state.formData,
					[name]: value,
				},
			});
			const fields = await this.form.validateFields(event.target);
			const fieldIsValid = fields.every((field) => field.isValid);
			const validateForm = await this.form.validateForm();
			const formIsValid = validateForm.every((field) => field.isValid());

			if (fieldIsValid) {
				this.setState({
					isFormValid: formIsValid,
				});
				console.log(`Field '${name}' is valid and value is ${value}`);
			} else {
				console.log(`Field '${name}' is invalid`);
			}
			if (this.form.isValid()) {
				console.log('The form is valid');
			} else {
				console.log('The form is invalid');
			}
		}
	};
	getSelectedValue = async (value) => {
		this.setState({
			selectedValue: value,
		});
	};
	handleAddedAmount = () => {
		const { modeID } = this.props.match.params;
		if (modeID === '1') {
			this.setState((prevState) => ({
				monthlySubscriptionAmount:
					parseFloat(prevState.monthlySubscriptionAmount) +
					parseFloat(prevState.monthlyAddedAmount),
			}));
		} else if (modeID === '3') {
			this.setState((prevState) => ({
				yearlySubscriptionAmount:
					parseFloat(prevState.yearlySubscriptionAmount) + parseFloat(prevState.yearlyAddedAmount),
			}));
		}
	};
	handleReset = async () => {
		this.setState({
			formData: {
				VehicleRegistration: '',
				Model: '',
				Colour: '',
				Year: '',
				Make: '',
			},
			isFormValid: false,
		});
	};
	handleCheck = (data) => {
		return this.state.vehicleDetails.some(
			(item) =>
				data.VehicleRegistration.trim().toUpperCase() ===
				item.VehicleRegistration.trim().toUpperCase()
		);
	};
	formFields = async () => {
		let { modeID } = this.props.match.params;
		const formData = new FormData(document.getElementById('subscription-form'));
		let data = this.state.formData;
		data.Make = formData.get('Make');
		data.Colour = formData.get('Colour');
		data.VehicleRegistration = formData.get('VehicleRegistration');
		data.Year = formData.get('Year');
		data.Model = formData.get('Model');
		data.ModelID = modeID;
		return data;
	};
	handleSubmit = async (event) => {
		event.preventDefault();
		if (event.keyCode === 13) {
			return false;
		}
		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every((field) => field.isValid());

		this.setState({
			isFormValid: formIsValid,
		});
		if (formIsValid) {
			let data = await this.formFields();
			let vehicleDetails = [...this.state.vehicleDetails];
			let carExist = this.handleCheck(data);
			if (carExist) {
				this.setState({
					alreadyExist: `Vehicle details with registration number: ${
						data.VehicleRegistration
					} already exist `,
				});
			} else {
				vehicleDetails.push(data);
				this.setState({
					vehicleDetails,
					showTable: true,
				});
				/**Increase the vehicle fees if the consumer has more than one vehicle **/
				if (vehicleDetails.length > 1) {
					this.handleAddedAmount();
				}
				this.handleReset();
			}
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid',
			});
			console.log('The form is invalid');
		}
	};
	handleDelete = (index) => {
		let { modeID } = this.props.match.params;
		let vehicleDetails = [...this.state.vehicleDetails];
		vehicleDetails.splice(index, 1);
		if (vehicleDetails.length >= 1) {
			this.setState({
				vehicleDetails,
				alreadyExist: '',
			});
			if (modeID === '1') {
				this.setState((prevState) => ({
					monthlySubscriptionAmount:
						parseFloat(prevState.monthlySubscriptionAmount) -
						parseFloat(prevState.monthlyAddedAmount),
				}));
			} else if (modeID === '3') {
				this.setState((prevState) => ({
					yearlySubscriptionAmount:
						parseFloat(prevState.yearlySubscriptionAmount) -
						parseFloat(prevState.yearlyAddedAmount),
				}));
			}
		} else {
			this.setState({
				vehicleDetails,
				alreadyExist: '',
				showTable: false,
			});
		}
	};
	handleVehicleSubmit = async (event) => {
		let { vehicleDetails } = this.state;
		event.preventDefault();
		if (vehicleDetails.length === 0) {
			let data = await this.formFields();
			vehicleDetails.push(data);
			this.setState({
				vehicleDetails,
				alreadyExist: '',
				isDisabled: true,
			});
			this.props.fetchNewSubscription(vehicleDetails);
			this.handleReset();
		} else {
			this.setState({
				alreadyExist: '',
				isDisabled: true,
			});
			this.props.fetchNewSubscription(vehicleDetails);
			this.handleReset();
		}
	};
	handleCheckboxChange = async (event) => {
		let inputName = event.target.name;
		if (document.getElementById(inputName).checked) {
			this.setState({
				isDisabled: false,
			});
		} else {
			this.setState({
				isDisabled: true,
			});
		}
	};

	render() {
		let {
			selectedValue,
			monthlySubscriptionAmount,
			yearlySubscriptionAmount,
			yearlyAddedAmount,
			monthlyAddedAmount,
			isDisabled,
			vehicleDetails,
			isFormValid,
			alreadyExist,
			showTable,
		} = this.state;

		let { modeID } = this.props.match.params;
		let subscriptionBtn,
			content,
			amountBasedOnUserOption,
			amountAddedForVehicleSub,
			kredGuru,
			monthlyOrYearlyTitle,
			noOptionKredGuru;
		let selectedOption = '';
		const showCarTable = showTable ? (
			<SubscriptionTable vehicleDetails={vehicleDetails} handleDelete={this.handleDelete} />
		) : (
			''
		);
		if (modeID === '3') {
			amountBasedOnUserOption = yearlySubscriptionAmount;
			amountAddedForVehicleSub = yearlyAddedAmount;
			monthlyOrYearlyTitle = 'Yearly Subscription';
			subscriptionBtn = (
				<MDBBtn
					color="success"
					className={`rounded font-weight-bold ${styles.subscribeButton}`}
					disabled={isDisabled}
					onClick={this.handleVehicleSubmit}
				>
					Subscribe for R{parseFloat(amountBasedOnUserOption).toFixed(2)} per year
				</MDBBtn>
			);
		} else if (modeID === '1') {
			amountBasedOnUserOption = monthlySubscriptionAmount;
			amountAddedForVehicleSub = monthlyAddedAmount;
			monthlyOrYearlyTitle = 'Monthly Subscription';
			subscriptionBtn = (
				<MDBBtn
					color="success"
					className={`rounded font-weight-bold ${styles.subscribeButton}`}
					disabled={isDisabled}
					onClick={this.handleVehicleSubmit}
				>
					Subscribe for R{parseFloat(amountBasedOnUserOption).toFixed(2)} per month
				</MDBBtn>
			);
		}
		let { newSubscription } = this.props;
		let kredImage = 'kred_guru_+2.svg';
		let header = null;
		let signature = this.state.subscriptionKGCount === 0 ? 'KG – Splendi’s Kred Guru' : null;
		const kgMessage = ['Last step! enter your vehicle details and get covered'];
		kredGuru = (
			<KredGuruSection
				id="subscriptionId"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
			/>
		);
		let noOptionkredImage = 'kred_guru_+4.svg';
		const noOptionkgMessage = [
			'No problem! Should you ever wish to add a vehicle, please contact us on info@splendi.co.za',
		];
		noOptionKredGuru = (
			<KredGuruSection
				id="vehicleOption"
				kredGuruImage={noOptionkredImage}
				header={header}
				content={noOptionkgMessage}
				signature={signature}
			/>
		);
		content = (
			<div className="mt-4">
				<FormWithConstraints
					className="subscription"
					id="subscription-form"
					ref={(formWithConstraints) => (this.form = formWithConstraints)}
					onSubmit={this.handleSubmit}
					noValidate
				>
					<MDBRow>
						<MDBCol md="6">
							<InputComponent
								name="VehicleRegistration"
								id="VehicleRegistration"
								label="Vehicle Registration Number"
								disabled={false}
								value={this.state.formData.VehicleRegistration}
								type="text"
								step="100"
								minLength="4"
								maxLength="30"
								required={true}
								outline={true}
								handleChange={this.handleChange}
								hint="43517"
							/>
						</MDBCol>
						<MDBCol md="6">
							<InputComponent
								name="Colour"
								id="Colour"
								label="Colour"
								disabled={false}
								value={this.state.formData.Colour}
								type="text"
								step="100"
								required={true}
								minLength="3"
								maxLength="10"
								outline={true}
								handleChange={this.handleChange}
								hint="Red"
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="6">
							<InputComponent
								name="Model"
								id="Model"
								label="Model"
								disabled={false}
								value={this.state.formData.Model}
								type="text"
								step="100"
								required={true}
								minLength="2"
								maxLength="25"
								outline={true}
								handleChange={this.handleChange}
								hint="A1"
							/>
						</MDBCol>
						<MDBCol md="6">
							<InputComponent
								name="Year"
								id="Year"
								label="Year"
								disabled={false}
								value={this.state.formData.Year}
								type="number"
								step="1"
								required={true}
								min="1900"
								max="2099"
								outline={true}
								handleChange={this.handleChange}
								hint="2024"
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="6">
							<InputComponent
								name="Make"
								id="Make"
								label="Make"
								disabled={false}
								value={this.state.formData.Make}
								type="text"
								step="100"
								required={true}
								minLength="2"
								maxLength="20"
								outline={true}
								handleChange={this.handleChange}
								hint="Audi"
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12">
							<MDBBtn
								color=" white-text"
								className={`rounded info-color font-weight-bold ${styles.subscribeAddVehicle}`}
								onClick={() => {
									document.getElementById('subscription-form').dispatchEvent(new Event('submit'));
								}}
								disabled={!isFormValid}
							>
								Add Vehicle
							</MDBBtn>
						</MDBCol>
					</MDBRow>
					<MDBRow className="mt-4">
						<MDBCol className="col-12 col-md-12 pt-2">
							<p
								style={{
									fontWeight: 'bold',
								}}
							>
								Please note, your first vehicle is included in the subscription, subsequent vehicles
								are charged at R{amountAddedForVehicleSub}
							</p>
						</MDBCol>
					</MDBRow>
					{showCarTable}
				</FormWithConstraints>
			</div>
		);
		if (newSubscription.isLoading) {
			return <Loading />;
		} else {
			if (
				typeof newSubscription.newSubscriptionResult !== 'undefined' &&
				newSubscription.newSubscriptionResult.Status === 'Success'
			) {
				const link = newSubscription.newSubscriptionResult.Link;
				return window.location.assign(`${link}`);
			}
			const userAlreadySubscribed =
				typeof newSubscription.newSubscriptionResult !== 'undefined' &&
				newSubscription.newSubscriptionResult.status === 500
					? newSubscription.newSubscriptionResult.Message
					: '';
			const subscriptionErrorMessage = newSubscription.errMess ? (
				<ErrorComponent msg={newSubscription.errMess} />
			) : (
				''
			);
			if (selectedValue === 'Yes') {
				selectedOption = <div>{content}</div>;
			} else if (selectedValue === 'No') {
				selectedOption = (
					<FormWithConstraints
						className="subscription"
						id="subscription-form"
						ref={(formWithConstraints) => (this.form = formWithConstraints)}
						onSubmit={this.handleVehicleSubmit}
						noValidate
					>
						<MDBRow>
							<MDBCol md="7">{noOptionKredGuru}</MDBCol>
						</MDBRow>
					</FormWithConstraints>
				);
			}
			return (
				<>
					<FadeTransform
						in
						transfromProps={{
							exitTransform: 'scale(0.5) translate()-50%',
						}}
					>
						<MDBContainer>
							<Card className={`col-12 col-md-11 ${styles.subscribeCard}`}>
								<CardBody>
									<MDBRow>
										<MDBCol className="col-12 col-md-5">{subscriptionErrorMessage}</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<CardTitle className={styles.newSubscriptionTitle}>
												{monthlyOrYearlyTitle}
											</CardTitle>
											<CardText className={`pb-3 pb-md-0 ${styles.newSubscriptionSubTitle}`}>
												Enter the required details below.
											</CardText>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">{kredGuru}</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<CardTitle
												className={`text-nowrap mt-4 mt-md-0 ${styles.newSubscriptionTitle}`}
											>
												Vehicle Details
											</CardTitle>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<p className="text-info font-weight-bold">{userAlreadySubscribed}</p>
											<p className="text-info font-weight-bold">{alreadyExist}</p>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-3">
											<CardText className={styles.newSubscriptionSubTitle}>
												Would you like to add a vehicle?
											</CardText>
											<SelectComponent
												id="YesOrNo"
												name="YesOrNo"
												type="text"
												labelDisplay={false}
												getValue={this.getSelectedValue}
												handleChange={this.handleChange}
												required={true}
												options={selectOptions.YesOrNoOptions}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol>{selectedOption}</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="12">
											<p className={`pt-4 ${styles.newSubscriptionSubTitle}`}>
												I hereby request, “instruct” and authorise Splendi to draw against the above
												mentioned bank account the amounts due in terms of the services schedule.
												All such withdrawals from my card by you shall be treated as though they
												have been signed by me personally.
											</p>
											<MDBRow>
												<CheckBoxComponent
													id="Terms"
													name="Terms"
													label="I Agree to "
													disabled={false}
													value={false}
													required={true}
													filled={true}
													handleChange={this.handleCheckboxChange}
												/>
												<a href="javascript:void(0)" onClick={this.toggle} className="pt-3 pr-5">
													terms & conditions
												</a>{' '}
											</MDBRow>
											<MDBRow>
												<MDBCol className="text-center ">{subscriptionBtn}</MDBCol>
											</MDBRow>
										</MDBCol>
									</MDBRow>
								</CardBody>
							</Card>
						</MDBContainer>
					</FadeTransform>
					<MDBModal
						className="modal-dialog-scrollable"
						isOpen={this.state.modal}
						toggle={this.toggle}
						size="lg"
						backdrop={false}
					>
						<MDBModalHeader toggle={this.toggle}>
							<strong className="text-center" style={{ color: '#3f51b5' }}>
								TERMS & CONDITIONS
							</strong>
						</MDBModalHeader>
						<MDBModalBody>
							<TermsAndConditions />
						</MDBModalBody>
					</MDBModal>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		newSubscription: state.newSubscription,
	};
};
const mapDispatchToProps = (dispatch) => ({
	fetchNewSubscription: (data) => {
		dispatch(fetchNewSubscription(data));
	},
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Subscription)
);
