import React, { Component } from 'react';
import TermsAndConditions from '../Settings/TermsAndConditions';
import './TermsAndConditions.css';
class TermsAndConditionsPage extends Component {
	render() {
		return (
			<div className="container pt-5">
				<div className="terms_conditions-page pt-4 pr-5 pl-5 pb-4">
					<div className="row">
						<div className="col-md-12">
							<h3 className="terms_conditions mb-3">
								<strong> TERMS & CONDITIONS </strong>
							</h3>
							<TermsAndConditions />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TermsAndConditionsPage;
