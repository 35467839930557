import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from 'mdbreact';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import DescriptionNature from './descriptionNature';

class NatureOfDispute extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleSaveNatureInfo = async (e) => {
		e.preventDefault();
		await this.props.handleNatureSubmit(e);
	};
	saveAndContinue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};
	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};
	render() {
		const {
			isFormValid,
			handleCheckChange,
			natureOfDispute,
			handleChange,
			personalInfo,
			selectedValue,
			updateNature,
			getSelectedValue,
			invalidFormMessage,
		} = this.props;
		let showBtn = (
			<MDBBtn
				color=" white-text"
				size="sm"
				type="submit"
				disabled={!isFormValid}
				className="btn-custom-primary"
				onClick={this.handleSaveNatureInfo}
			>
				save
			</MDBBtn>
		);
		if (updateNature.isLoading) {
			return <Loading />;
		} else if (updateNature.errMess) {
			return (
				<div className="w-75 mx-auto">
					<ErrorComponent />;
				</div>
			);
		} else {
			let successMsg = '';
			if (
				typeof updateNature.updateNature.response !== 'undefined' &&
				updateNature.updateNature.response.status
			) {
				successMsg =
					updateNature.updateNature.response.message + ', click the next button to continue';
				showBtn = (
					<MDBBtn
						color=" white-text"
						size="sm"
						className="btn-custom-primary font-weight-bold "
						onClick={this.saveAndContinue}
					>
						next
					</MDBBtn>
				);
			}
			return (
				<MDBContainer>
					<MDBRow>
						<MDBCol className="col-12 col-md-12">
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold my-2 mb-5">
										Please indicate the nature of dispute by ticking the appropriate box below
									</p>
								</MDBCol>
							</MDBRow>
							{invalidFormMessage !== '' ? (
								<MDBRow>
									<MDBCol>
										<p className="text-center font-weight-bold my-2 text-danger">
											{invalidFormMessage}
										</p>
									</MDBCol>
								</MDBRow>
							) : (
								<MDBRow>
									<MDBCol>
										<p className="text-center font-weight-bold my-2 text-success">{successMsg}</p>
									</MDBCol>
								</MDBRow>
							)}
							<MDBRow className="d-flex justify-content-between flex-column flex-md-row">
								<MDBCol>
									<MDBInput
										label="ACCOUNT"
										id="account"
										name="account"
										type="radio"
										onChange={handleCheckChange}
										value={'account'}
										checked={selectedValue === 'account'}
										getValue={getSelectedValue}
										labelClass="answersCustomClass"
										className="answerCustomRadio"
										containerClass="mr-3"
									/>
								</MDBCol>
								<MDBCol>
									<MDBInput
										id="judgement"
										name="judgement"
										label="JUDGEMENT"
										disabled={false}
										value={'judgement'}
										checked={selectedValue === 'judgement'}
										type="radio"
										onChange={handleCheckChange}
										getValue={getSelectedValue}
										labelClass="answersCustomClass"
										className="answerCustomRadio"
										containerClass="mr-3"
									/>
								</MDBCol>
								<MDBCol>
									<MDBInput
										id="adminOrder"
										name="adminOrder"
										label="ADMIN ORDER"
										disabled={false}
										value={'adminOrder'}
										checked={selectedValue === 'adminOrder'}
										type="radio"
										onChange={handleCheckChange}
										getValue={getSelectedValue}
										labelClass="answersCustomClass"
										className="answerCustomRadio"
										containerClass="mr-3"
									/>
								</MDBCol>
								<MDBCol>
									<MDBInput
										id="sequestration"
										name="sequestration"
										label="SEQUESTRATION"
										disabled={false}
										value={'sequestration'}
										checked={selectedValue === 'sequestration'}
										type="radio"
										onChange={handleCheckChange}
										getValue={getSelectedValue}
										labelClass="answersCustomClass"
										className="answerCustomRadio"
										containerClass="mr-3"
									/>
								</MDBCol>
								<MDBCol>
									<MDBInput
										id="debtReview"
										name="debtReview"
										label="DEBT REVIEW"
										disabled={false}
										value={'debtReview'}
										checked={selectedValue === 'debtReview'}
										type="radio"
										onChange={handleCheckChange}
										getValue={getSelectedValue}
										labelClass="answersCustomClass"
										className="answerCustomRadio"
										containerClass="mr-3"
									/>
								</MDBCol>
							</MDBRow>
							<DescriptionNature
								handleCheckChange={handleCheckChange}
								selectedValue={selectedValue}
								personalInfo={personalInfo}
								handleChange={handleChange}
								natureOfDispute={natureOfDispute}
							/>
							<MDBRow>
								<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12 mt-5 mb-5">
									<MDBBtn
										color=" white-text"
										size="sm"
										className="btn-custom-secondary mr-md-5"
										onClick={this.back}
									>
										previous
									</MDBBtn>
									{showBtn}
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			);
		}
	}
}

export default NatureOfDispute;
