import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';

export const fetchClient = () => (dispatch) => {
	dispatch(getClientLoading(true));

	return fetch(baseUrl + 'api/keycloak-client')
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				throw new Error(error.message);
			}
		)
		.then((response) => response.json())
		.then((available_offers) => dispatch(currentClient(available_offers)))
		.catch((error) => dispatch(getClientFailed(error.message)));
};

export const getClientLoading = () => ({
	type: ActionTypes.FETCH_KC_CLIENT_LOADING,
});

export const getClientFailed = (errmess) => ({
	type: ActionTypes.FETCH_KC_CLIENT_FAILED,
	payload: errmess,
});

export const currentClient = (client) => ({
	type: ActionTypes.FETCH_KC_CLIENT_COMPLETE,
	payload: client,
});
