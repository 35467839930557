import * as ActionTypes from './ActionTypes';

export const BuyVASProductResponse = (state={
    isLoading: true,
    errMess: null,
    buyVASProductResponse: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_VAS_BUY_PRODUCT_RESPONSE:
            return {...state, isLoading: false, errMess:null, buyVASProductResponse: action.payload};
        case ActionTypes.VAS_BUY_PRODUCT_RESPONSE_LOADING:
            return {...state, isLoading: true, errMess:null, buyVASProductResponse: []};
        case ActionTypes.VAS_BUY_PRODUCT_RESPONSE_FAILED:
            return {...state, isLoading: false, errMess:action.payload, buyVASProductResponse: []};
        default:
            return state;
    }
}