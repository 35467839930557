import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';

export const JudgementDisputeNature = (props) => {
	const { handleCheckChange, natureOfDispute } = props;
	return (
		<>
			<MDBRow className="mt-4">
				<MDBCol md="4">
					<CheckBoxComponent
						id="noteOfAdverseListing"
						name="noteOfAdverseListing"
						label="I was not notified of the adverse listing"
						disabled={false}
						value={natureOfDispute.noteOfAdverseListing}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="paidJudgement"
						name="paidJudgement"
						label="I have paid the judgement"
						disabled={false}
						value={natureOfDispute.paidJudgement}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="notMyJudgement"
						name="notMyJudgement"
						label="This is not my judgement"
						disabled={false}
						value={natureOfDispute.notMyJudgement}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol md="4">
					<CheckBoxComponent
						id="judgmentIsIncorrect"
						name="judgmentIsIncorrect"
						label="Information/amount of the judgment is incorrect"
						disabled={false}
						value={natureOfDispute.judgmentIsIncorrect}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
			</MDBRow>
		</>
	);
};
