import React, { Component } from 'react';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Container } from 'mdbreact';
import TabsSection from '../../sections/Tabs/TabsSection';
import { downloadReportPDF, fetchConsumer } from '../../../redux/ActionCreators';

const mapStateToProps = state => {
	return {
		consumer: state.consumer,
		user: state.user,
	};
};
const mapDispatchToProps = dispatch => ({
	downloadReportPDF: consumer => {
		dispatch(downloadReportPDF(consumer));
	},
	fetchConsumer: () => {
		dispatch(fetchConsumer());
	},
});
class DetailedReportPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumer: [],
		};
	}

	componentDidMount() {
		if (localStorage.getItem('toDashboard') === 'true') {
			const data = localStorage.getItem('persist:persistedStore');
			const savedData = JSON.parse(data);
			const decrypted = CryptoJS.AES.decrypt(`${savedData.consumer}`.replace('"', ''), 'Splendi-ui').toString(CryptoJS.enc.Utf8);
			const decryptedDataJSONFormat = JSON.parse(decrypted);
			if (decryptedDataJSONFormat.consumer.length === undefined) {
				localStorage.setItem('toDashboard', 'false');
				this.setState({ savedData });
			} else {
				localStorage.setItem('toDashboard', 'false');
				this.props.fetchConsumer();
			}
		}
	}

	render() {
		return (
			<Container className="container">
				<TabsSection
					consumer={this.props.consumer}
					user={this.props.user}
					// downloadReportPDF={this.props.downloadReportPDF}
				/>
			</Container>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DetailedReportPage);
