import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';

export const SequestrationDisputeNature = (props) => {
	const { handleCheckChange, natureOfDispute } = props;
	return (
		<MDBRow className="mt-4">
			<MDBCol md="6" className="text-md-center">
				<CheckBoxComponent
					id="notMySequestration"
					name="notMySequestration"
					label="This is not my sequestration"
					disabled={false}
					value={natureOfDispute.notMySequestration}
					required={true}
					filled={true}
					handleChange={handleCheckChange}
					containerClass="mr-5"
				/>
			</MDBCol>
			<MDBCol md="6" className="text-md-center">
				<CheckBoxComponent
					id="rehabilitated"
					name="rehabilitated"
					label="I have been rehabilitated"
					disabled={false}
					value={natureOfDispute.rehabilitated}
					required={true}
					filled={true}
					handleChange={handleCheckChange}
					containerClass="mr-5"
				/>
			</MDBCol>
		</MDBRow>
	);
};
