import React from 'react';
import './ErrorComponent.css';

export const ErrorComponent = (props) => {
	let msg =
		'We’re working on it! Our servers and technicians are ' +
		'currently in maintenance mode. Thanks for your patience.';
	if (props.msg) {
		msg = props.msg;
	}
	return (
		<div className="alert alert-error text-center">
			<span className="alert-error-times-circle">
				<i className="fa fa-times-circle-o" />
			</span>

			<span>{msg}</span>
		</div>
	);
};
