import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { corsOptions } from '../../corsOptions';
import { requestPath } from '../../util/util';
export const fetchIdHeroUserNotification = () => (dispatch) => {
	dispatch(idHeroNotificationLoading(true));

	return fetch(baseUrl + requestPath('api/id-hero-user-notification'), corsOptions())
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Failed to retrieve ID hero notifications');
					error.response = response;
					throw error;
				}
			},
			(error) => {
				throw new Error(error.message);
			}
		)
		.then((response) => response.json())
		.then((notification) => dispatch(idHeroNotification(notification)))
		.catch((error) => dispatch(idHeroNotificationFailed(error.message)));
};
export const idHeroNotificationLoading = () => ({
	type: ActionTypes.ID_HERO_NOTIFICATION_LOADING,
});

export const idHeroNotificationFailed = (errmess) => ({
	type: ActionTypes.ID_HERO_NOTIFICATION_FAILED,
	payload: errmess,
});

export const idHeroNotification = (notification) => ({
	type: ActionTypes.ADD_ID_HERO_NOTIFICATION,
	payload: notification,
});
