import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';

export const AdminOrderDisputeNature = (props) => {
	const { handleCheckChange, natureOfDispute } = props;
	return (
		<>
			<MDBRow className="mt-4">
				<MDBCol md="4">
					<CheckBoxComponent
						id="notMyAdminOrder"
						name="notMyAdminOrder"
						label="This is not my admin order"
						disabled={false}
						value={natureOfDispute.notMyAdminOrder}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="adminIsPaid"
						name="adminIsPaid"
						label="My admin order is paid up [74U]"
						disabled={false}
						value={natureOfDispute.adminIsPaid}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="adminIsRescind"
						name="adminIsRescind"
						label="My admin order is rescinded[74Q]"
						disabled={false}
						value={natureOfDispute.adminIsRescind}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
			</MDBRow>
		</>
	);
};
