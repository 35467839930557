import React, { Component } from 'react';
import { MDBRow, MDBCol, Card, CardTitle, CardBody, TableBody, Table } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { CollapseComponent } from '../../../../../components/Collapse/CollapseComponent';
import './EmploymentHistorySection.css';

class RenderEmploymentHistory extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.employmentHistory instanceof Array) {
			const employmentHistory = this.props.employmentHistory.map((key, index) => {
				if (index !== 1) {
					return (
						<tr key={index}>
							<td>
								<strong>{key.EmployerDetail.value}</strong>
							</td>
							<td className="text-right">
								<strong>{key.LastUpdatedDate.label}:</strong> {key.LastUpdatedDate.value}
							</td>
						</tr>
					);
				} else {
					return <tr key={index} />;
				}
			});

			const employment = this.props.employmentHistory[0];

			let currentEmploymentContent = <div />;
			if (employment) {
				currentEmploymentContent = (
					<div className="table-responsive-sm">
						<Table borderless className="table-sm">
							<TableBody>
								<tr>
									<td>
										<strong>{employment.EmployerDetail.value}:</strong>
									</td>
								</tr>
								<tr>
									<td>
										<MDBRow>
											<MDBCol>
												<strong>{employment.LastUpdatedDate.label}:</strong>{' '}
												{employment.LastUpdatedDate.value}
											</MDBCol>
										</MDBRow>
									</td>
								</tr>
							</TableBody>
						</Table>
					</div>
				);
			}

			const bodyContent = (
				<Table borderless striped responsive className="table-sm">
					<TableBody>{employmentHistory}</TableBody>
				</Table>
			);
			const employmentHistoryContent = [
				{
					id: 'employmentHistory',
					headerContent: this.props.employmentHistory[0].DisplayText.value,
					bodyContent: bodyContent,
				},
			];

			return (
				<FadeTransform
					in
					transfromProps={{
						exitTransform: 'scale(0.5) translate()-50%',
					}}
				>
					<Card className="mt-3">
						<CardBody>
							<MDBRow>
								<MDBCol md="6">
									<CardTitle className="sub-title text-color-purple-2 ">EMPLOYMENT</CardTitle>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12">{currentEmploymentContent}</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12">
									<CollapseComponent accordionContent={employmentHistoryContent} />
								</MDBCol>
							</MDBRow>
						</CardBody>
					</Card>
				</FadeTransform>
			);
		} else {
			return null;
		}
	}
}

export default RenderEmploymentHistory;
