import * as ActionTypes from '../../ActionTypes';

export const UpdateNatureOfDisputeDetails = (
	state = {
		isLoading: false,
		errMess: null,
		updateNature: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.UPDATE_NATURE_DETAILS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				updateNature: action.payload
			};
		case ActionTypes.UPDATE_NATURE_DETAILS_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				updateNature: []
			};
		case ActionTypes.UPDATE_NATURE_DETAILS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				updateNature: []
			};
		default:
			return state;
	}
};
