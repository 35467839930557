import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../shared/baseUrl';
import styles from './DetailedReportPDFPaymenSuccess.module.css';

const DetailedReportPDFPaymenSuccess = (props) => {
	// State
	const [isLoading, setIsLoading] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [reportGenerated, setReportGenerated] = useState(false);
	const [reportDownloaded, setReportDownloaded] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [rendered, setRendered] = useState(false);

	// Defaults
	const transactionId = localStorage.getItem('transactionId');
	const urlParams = new URLSearchParams(window.location.search);
	const gatewayId = urlParams.get('gatewayId');
	const { idNumber, passportNumber, userId } = props.user.user;
	const idCheck =
		idNumber !== '' &&
		idNumber !== null &&
		idNumber !== undefined &&
		idNumber !== 'null' &&
		idNumber !== 'undefined';
	const passportCheck =
		passportNumber !== '' &&
		passportNumber !== null &&
		passportNumber !== undefined &&
		passportNumber !== 'null' &&
		passportNumber !== 'undefined';

	useEffect(() => {
		// Double set rendered to false to prevent the code executing twice, not proper code. Refactor at own risk!.
		setRendered(false);
		if (props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined') {
			setRendered(true);
		} else {
			setRendered(false);
		}
	}, []);

	// Double useEffect to prevent the code executing twice, not proper code. Refactor at own risk!.
	useEffect(() => {
		if (
			(props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined') &&
			rendered === true
		) {
			generateReport();
		} else {
			console.log('I am NOT rendered');
		}
	}, [rendered]);

	const generateReport = async () => {
		setIsLoading(true);
		setReportGenerated(false);
		setReportDownloaded(false);
		setErrorMessage(null);
		try {
			await axios.post(`${baseUrl}api/report-generate`, {
				userId: userId,
				transactionId: transactionId,
				gatewayId: gatewayId,
				reportType: 'credit-report',
				idNumber: idCheck ? idNumber : null,
				passport: passportCheck ? passportNumber : null,
			});
			setIsLoading(false);
			setReportGenerated(true);

			downloadReport();
		} catch (error) {
			setIsLoading(false);
			setReportGenerated(false);
			setReportDownloaded(false);
			setErrorMessage(
				'Failed to generate the credit report, for assistance contact info@splendi.co.za'
			);
		}
	};

	const downloadReport = async () => {
		setReportGenerated(false);
		setIsDownloading(true);
		setReportDownloaded(false);
		try {
			const response = await axios.post(`${baseUrl}api/report-download`, {
				userId: userId,
				transactionId: transactionId,
				gatewayId: gatewayId,
			});

			setIsDownloading(false);
			setReportDownloaded(true);
			localStorage.removeItem('transactionId');

			base64ToPDF(response.data.creditReportBase64Binary);
		} catch (error) {
			setIsDownloading(false);
			setErrorMessage(
				'Failed to download the credit report, for assistance contact info@splendi.co.za'
			);
		}
	};

	const base64ToPDF = (base64String) => {
		// Decode the base64 string and convert it to binary data
		const binaryString = window.atob(base64String);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);

		// Create a byte array with the binary data
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob from the byte array with the MIME type set to 'application/pdf'
		const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

		// Create a URL for the Blob and open it in a new window to view or download
		const pdfUrl = URL.createObjectURL(pdfBlob);

		// Create a hidden link element
		const link = document.createElement('a');
		link.href = pdfUrl;
		link.download = 'credit-report.pdf'; // This sets the default filename
		document.body.appendChild(link);

		// Programmatically click the link to trigger the download
		link.click();

		// Clean up: remove the link element and revoke the URL
		document.body.removeChild(link);
		URL.revokeObjectURL(pdfUrl);
	};

	return (
		<>
			{props.user.user.length > 0 || typeof props.user.user.userId !== 'undefined' ? (
				<div className={`card-big ${styles.cardSizeSmall}`}>
					<div className={styles.successMessageContainer}>
						<div className={styles.successGreyBox}>
							<div className={styles.successText}>
								Your payment was successful. Your PDF report will begin downloading shortly.
							</div>
							<div className={styles.kgText}>
								<i>
									<strong>KG – Splendi’s Kred Guru</strong>
								</i>
							</div>
						</div>
						<div className={styles.kgSuccess} />
					</div>
					<div className={styles.kgsuccessButtons}>
						{isLoading ? (
							<div>
								<img
									width={100}
									src={`${baseUrl}assets/img/splendi-loading-spinner.gif`}
									alt="loader"
								/>
								Generating credit report...
							</div>
						) : null}

						{isDownloading ? (
							<div>
								<img
									width={100}
									src={`${baseUrl}assets/img/splendi-loading-spinner.gif`}
									alt="loader"
								/>
								Downloading report...
							</div>
						) : null}

						{reportDownloaded ? (
							<div>
								<p className={styles.successMessage}>
									Report downloaded! Thank you for using splendi.
								</p>
								<Link to={'/credit-report'}>
									<div className={styles.kgsuccessClose}>Done</div>
								</Link>
							</div>
						) : null}

						{errorMessage ? (
							<div>
								<p className={styles.successError}>{errorMessage}</p>
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="d-flex justify-content-center align-items-center">
					<img width={150} src={`${baseUrl}assets/img/splendi-loading-spinner.gif`} alt="loader" />
					Loading...
				</div>
			)}
		</>
	);
};

export default DetailedReportPDFPaymenSuccess;
