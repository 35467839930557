import React from 'react';
import ReactMarkdown from 'react-markdown';

export default (props) => {

    let image1 = null
    let content2 = null
    let image2 = null

    if(props.content2) {
        content2 = <ReactMarkdown source={props.content2} className="px-5"/>
    }

    if(props.image1) {
        image1 = <div className="px-5 py-3">
            <img
                src={props.image1}
                alt=""
                width="100%"
            />
        </div>
    }

    if(props.image2) {
        image2 = <div className="px-5 py-3">
            <img
                src={props.image2}
                alt=""
                width="100%"
            />
        </div>
    }

    return(
        <>
            <ReactMarkdown source={props.content1} className="px-5"/>
            {image1}
            {content2}
            {image2}
        </>
    )
};