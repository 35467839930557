import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem, Fa } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import MegaDropDownComponent from '../Dropdown/MegaDropDownComponent';
import CustomDropdown from '../Dropdown/DropdownComponent';
import './NavbarComponent.css';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_LIST } from '../../shared/featureToggles';

class NavbarComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseID: '',
		};
		this.toggleCollapse = this.toggleCollapse.bind(this);
	}

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : '',
		}));

	render() {
		const userPermission =
			typeof this.props.user !== 'undefined' ? this.props.user.user.userPermissions : undefined;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter((value) => value.OfferID === 1)
				: undefined;
		const filterByOfferID2 =
			userPermission !== undefined
				? userPermission.filter((value) => value.OfferID === 2)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const offerID2 =
			filterByOfferID2 !== undefined && filterByOfferID2.length > 0
				? filterByOfferID2[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isOfferID2Active =
			filterByOfferID2 !== undefined &&
			filterByOfferID2.length > 0 &&
			filterByOfferID2[0].Permissions.length > 0
				? filterByOfferID2[0].Permissions[0].Active
				: undefined;

		const rightNavItemsLite = this.props.rightNavItemsLite.map((t) => {
			if (t.submenuItems) {
				return (
					<NavItem key={t.id} className="navigation-item">
						<MegaDropDownComponent menuItem={t} toggle={this.props.toggle} />
					</NavItem>
				);
			} else if (t.button) {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item"
					>
						<a
							id={'Popover' + t.id}
							className="nav-link"
							activeClassName="nav-link-active"
							onClick={this.props.togglePopOver}
						>
							<h5 className="h5-responsive">
								<span className="flex-center">
									{t.imageIcon && (
										<img
											src={t.imagePassive}
											onMouseEnter={(e) => (e.currentTarget.src = t.imageActive)}
											onMouseOver={(e) => (e.currentTarget.src = t.imageRollover)}
											onMouseOut={(e) => (e.currentTarget.src = t.imageActive)}
											alt={t.imageBrand}
											width="20%"
										/>
									)}
									{!t.imageIcon && <Fa icon={t.icon} />}
									{!t.imageIcon ? t.label : ''}
								</span>
							</h5>
						</a>
					</NavItem>
				);
			} else if (t.id === 5 && offerID1 !== 1 && offerID2 !== 2) {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item"
					>
						<NavLink
							className="nav-link disabled"
							activeClassName="nav-link-active"
							to={t.url}
							onClick={() => this.props.updateParentState(t.url)}
						>
							<h5 className="h5-responsive">
								<span className="">{t.label}</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			} else if (
				(t.id === 5 && offerID1 === 1 && isUserSubscribed) ||
				(offerID2 === 2 && isOfferID2Active)
			) {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item"
					>
						{t.url === '/cred-ed/resources' ? (
							<a
								className="nav-link"
								href={window._env_.REACT_APP_CRED_ED}
								target="_blank"
								rel="noopener noreferrer"
							>
								<h5 className="h5-responsive">
									<span className="">{t.label}</span>
								</h5>
							</a>
						) : (
							<NavLink
								className="nav-link"
								activeClassName="nav-link-active"
								to={t.url}
								onClick={() => this.props.updateParentState(t.url)}
							>
								<h5 className="h5-responsive">
									<span className="">{t.label}</span>
								</h5>
							</NavLink>
						)}
					</NavItem>
				);
			} else {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item"
					>
						<NavLink
							className="nav-link"
							activeClassName="nav-link-active"
							to={t.url}
							onClick={() => this.props.updateParentState(t.url)}
						>
							<h5 className="h5-responsive">
								<span className="">{t.label}</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});

		const rightNavItems = this.props.rightNavItems.map((t) => {
			if (t.submenuItems) {
				return (
					<NavItem key={t.id}>
						<MegaDropDownComponent menuItem={t} toggle={this.props.toggle} />
					</NavItem>
				);
			} else if (t.button) {
				return (
					<NavItem key={t.id}>
						<a
							id={'Popover' + t.id}
							className="nav-link"
							activeClassName="nav-link-active"
							onClick={this.props.togglePopOver}
						>
							<h5 className="h5-responsive">
								<span className="flex-center">
									{t.imageIcon && (
										<img
											src={t.imagePassive}
											onMouseEnter={(e) => (e.currentTarget.src = t.imageActive)}
											onMouseOver={(e) => (e.currentTarget.src = t.imageRollover)}
											onMouseOut={(e) => (e.currentTarget.src = t.imageActive)}
											alt={t.imageBrand}
											width="20%"
										/>
									)}
									{!t.imageIcon && <Fa icon={t.icon} />}
									{!t.imageIcon ? t.label : ''}
								</span>
							</h5>
						</a>
					</NavItem>
				);
			} else {
				return (
					<NavItem key={t.id}>
						<NavLink
							className="nav-link"
							activeClassName="nav-link-active"
							to={t.url}
							onClick={() => this.props.updateParentState(t.url)}
						>
							<h5 className="h5-responsive">
								<span className="">{t.label}</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});
		const rightPublicNavItems = this.props.rightPublicNavItems.map((t) => {
			if (t.submenuItems) {
				return (
					<NavItem key={t.id} className="navigation-item-public">
						<MegaDropDownComponent menuItem={t} toggle={this.props.toggle} />
					</NavItem>
				);
			} else if (t.id === 1) {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item-public"
					>
						<NavLink
							className="nav-link"
							to={t.url}
							onClick={() => this.props.updateParentState(t.url)}
						>
							<h5 className="h5-responsive">
								<span className="">{t.label}</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			} else {
				return (
					<NavItem
						onClick={this.toggleCollapse('navbarCollapse')}
						key={t.id}
						className="navigation-item-public"
					>
						<NavLink
							className="nav-link"
							activeClassName="nav-link-active"
							to={t.url}
							onClick={() => this.props.updateParentState(t.url)}
						>
							<h5 className="h5-responsive">
								<span className="">{t.label}</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});
		const leftNavItemsLite = this.props.leftNavItemsLite.map((t) => {
			if (t.dropdown) {
				return (
					<NavItem key={t.id}>
						<CustomDropdown
							collapseNavBar={this.toggleCollapse('navbarCollapse')}
							headerContent={this.props.headerContent}
							dropdownItems={this.props.dropdownItems}
							toggle={this.props.toggle}
						/>
					</NavItem>
				);
			} else if (t.button) {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<a
							id={'Popover' + t.id}
							className="nav-link"
							activeClassName="nav-link-active"
							onClick={this.props.togglePopOver}
						>
							<h5 className="h5-responsive">
								<span className="flex-center">
									{t.imageIcon && (
										<img
											src={t.imagePassive}
											onMouseEnter={(e) => (e.currentTarget.src = t.imageActive)}
											onMouseOver={(e) => (e.currentTarget.src = t.imageRollover)}
											onMouseOut={(e) => (e.currentTarget.src = t.imageActive)}
											alt={t.imageBrand}
											width="20%"
										/>
									)}
									{!t.imageIcon && <Fa icon={t.icon} />}
									{!t.imageIcon ? t.label : ''}
								</span>
							</h5>
						</a>
					</NavItem>
				);
			} else {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<NavLink
							className="nav-link navigation-item"
							activeClassName="nav-link-active"
							to={t.url}
						>
							<h5 className="h5-responsive">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});
		const leftPublicNavItems = this.props.leftPublicNavItems.map((t) => {
			if (t.dropdown) {
				return (
					<NavItem key={t.id}>
						<CustomDropdown
							headerContent={this.props.headerContent}
							dropdownItems={this.props.dropdownItems}
							toggle={this.props.toggle}
						/>
					</NavItem>
				);
			} else if (t.id === 1) {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<NavLink className="nav-link" activeClassName="nav-link-active" to={t.url}>
							<button className="h5-responsive sign-up navbar-button">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</button>
						</NavLink>
					</NavItem>
				);
			} else if (t.id === 2) {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<NavLink className="nav-link" activeClassName="nav-link-active" to={t.url}>
							<button className="h5-responsive log-in navbar-button">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</button>
						</NavLink>
					</NavItem>
				);
			} else if (t.id === 3 || t.id === 4 || t.id === 5) {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<NavLink
							className="nav-link"
							activeClassName="nav-link-active"
							to={t.url}
							target="_blank"
						>
							<div className="h5-responsive">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</div>
						</NavLink>
					</NavItem>
				);
			} else {
				return (
					<NavItem onClick={this.toggleCollapse('navbarCollapse')} key={t.id}>
						<NavLink className="nav-link" activeClassName="nav-link-active" to={t.url}>
							<h5 className="h5-responsive">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});
		const leftNavItems = this.props.leftNavItems.map((t) => {
			if (t.dropdown) {
				return (
					<NavItem key={t.id}>
						<CustomDropdown
							headerContent={this.props.headerContent}
							dropdownItems={this.props.dropdownItems}
							toggle={this.props.toggle}
						/>
					</NavItem>
				);
			} else if (t.button) {
				return (
					<NavItem key={t.id}>
						<a
							id={'Popover' + t.id}
							className="nav-link"
							activeClassName="nav-link-active"
							onClick={this.props.togglePopOver}
						>
							<h5 className="h5-responsive">
								<span className="flex-center">
									{t.imageIcon && (
										<img
											src={t.imagePassive}
											onMouseEnter={(e) => (e.currentTarget.src = t.imageActive)}
											onMouseOver={(e) => (e.currentTarget.src = t.imageRollover)}
											onMouseOut={(e) => (e.currentTarget.src = t.imageActive)}
											alt={t.imageBrand}
											width="20%"
										/>
									)}
									{!t.imageIcon && <Fa icon={t.icon} />}
									{!t.imageIcon ? t.label : ''}
								</span>
							</h5>
						</a>
					</NavItem>
				);
			} else {
				return (
					<NavItem key={t.id}>
						<NavLink className="nav-link" activeClassName="nav-link-active " to={t.url}>
							<h5 className="h5-responsive">
								<span className="">
									<Fa icon={t.icon} />
									{t.label}
								</span>
							</h5>
						</NavLink>
					</NavItem>
				);
			}
		});
		const privateOrPublicNavItems = () => {
			if (this.props.user.user.length > 0 || typeof this.props.user.user.userId !== 'undefined') {
				return (
					<>
						<Nav className="ml-auto flex-center">{rightNavItemsLite}</Nav>
						<Nav className="ml-auto flex-center">{leftNavItemsLite}</Nav>
					</>
				);
			} else {
				return (
					<>
						<Nav className="ml-auto flex-center">{rightPublicNavItems}</Nav>
						<Nav className="ml-auto flex-center">{leftPublicNavItems}</Nav>
					</>
				);
			}
		};
		const navbarBrandRedirect =
			this.props.user.user.length > 0 || typeof this.props.user.user.userId !== 'undefined' ? (
				<NavbarBrand className="mr-auto p-0" href="/dashboard">
					<img
						src={this.props.navBarImageUrl}
						alt={this.props.navBarBrand}
						className="splendi-logo"
					/>
				</NavbarBrand>
			) : (
				<NavbarBrand className="mr-auto p-0" href="/">
					<img
						src={this.props.navBarImageUrl}
						alt={this.props.navBarBrand}
						className="splendi-logo"
					/>
				</NavbarBrand>
			);
		return (
			<React.Fragment>
				<Navbar
					className="m-0 p-0 bg-white navbar-custom-height"
					light
					expand="md"
					fixed="top"
					scrolling
				>
					{navbarBrandRedirect}
					<NavbarToggler onClick={this.toggleCollapse('navbarCollapse')} />
					<Collapse id="navbarCollapse" isOpen={this.state.collapseID} navbar>
						<FeatureToggle
							featureName={FEATURE_TOGGLE_LIST.NAV_BAR_ITEMS_LITE}
							showOnlyWhenDisabled
						>
							<Nav className="mx-auto flex-center">{rightNavItems}</Nav>
							<Nav className="ml-auto flex-center">{leftNavItems}</Nav>
						</FeatureToggle>

						<FeatureToggle featureName={FEATURE_TOGGLE_LIST.NAV_BAR_ITEMS_LITE}>
							{privateOrPublicNavItems()}
						</FeatureToggle>
					</Collapse>
				</Navbar>
			</React.Fragment>
		);
	}
}

export default NavbarComponent;
