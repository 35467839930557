import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { baseUrl } from '../../../../shared/baseUrl';

class CovID19Article extends Component {
	constructor(props) {
		super(props);
	}

	navigateToPage = () => {
		this.setState({
			toQuote: true
		});
	};

	render() {
		const commonDisplay = (
			<>
				<Row className="px-2 pt-2">
					<Col className="col-12 col-md-6 px-2 pt-3">
						<CardTitle className="h4-responsive title pb-2 px-5">
							How To Manage Your Accounts And Finances During COVID- 19
						</CardTitle>
						<div className="px-5 pb-4">
							<p className="py-2">
								It has been an interesting few weeks for South Africa: President
								Cyril Ramaphosa announced a 21 day lockdown with effect from the
								26th of March. This meant that non-essential businesses were to
								close- leaving some people unemployed, without a salary or
								forced to take mandatory leave with the lucky few being able to
								work from home.
							</p>
							<p>
								People have a lot to worry about- but should not if they have
								stayed on top of their accounts and finances. Here are some tips
								to consider going into the coming grueling months:
							</p>
						</div>
						<div className="d-flex flex-row">
							<div className="px-5">
								<strong>
									<p>Check If You Have Credit Insurance</p>
								</strong>
							</div>
						</div>
						<div className="px-5">
							<p>
								Credit life insurance is the cover a consumer takes out in the
								event of their death, disability, terminal illness, unemployment
								or other insurable risk that is likely to impair the consumer’s
								ability to earn an income or pay their monthly instalments under
								a credit agreement.
							</p>
							<p>
								A lot of people are unaware of this insurance on existing
								accounts. If you are currently without an income due to COVID-
								19, make sure to call all your credit providers and check if
								this insurance is available to you.
							</p>
						</div>
					</Col>
					<Col style={{ paddingTop: '7rem' }} className="col-12 col-md-6">
						<div className="px-3 py-3">
							<img
								src={`${baseUrl}assets/img/covid19.jpg`}
								alt=""
								width="100%"
							/>
						</div>
					</Col>
				</Row>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Contact A Provider</p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						If you do not have credit insurance on any of your accounts. Contact
						your bank and creditors in order to explore the payment schedule
						ahead.
					</p>
					<p>
						Banks are rolling out solutions for their customers that include
						instalment cash relief, preferential interest rates for COVID- 19
						interventions, no fees on relief granted, assistance with processing
						credit insurance as well as offering individualized bridge
						facilities for those who need it.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Set Up An Automated Payment </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						If you are in a position to pay your accounts, ensure that every
						account is paid on time by setting up an automated payment. This
						removes the temptation from spending money as it comes in. Paying
						accounts late can negatively impact your credit score, making it
						unlikely that you will get approval for bigger loans, such as a home
						loan or a car in the future. Moreover, a bad credit record can have
						a negative impact on your professional life, making it difficult for
						you to find a job.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Speak To Your Landlord </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						If you are renting and you are in a position where you cannot pay
						rent, have an open and honest conversation with your landlord and
						explain the details of your situation to explore the options ahead.
						This is a difficult time to get new tenants in. Landlords are human
						too and are also feeling the pinch of the current lockdown. So-
						explore what your options are and draw up an agreement that suits
						both parties, and includes either a payment holiday or a percentage
						payment on the existing rental agreement.
					</p>
					<p>
						If an agreement can be put in place with your landlord, ensure that
						a contract is drawn up so that both parties abide by the agreement.
						If payment arrangements are not kept based on the new agreement,
						landlords and rental agents must report this missed payment to the
						relevant credit bureau and continue to follow the correct legal
						procedures so that the eviction process can take place after the
						state of disaster is lifted. Make sure that the new agreement is in
						line with what you can afford.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Budget </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						Once you have contacted your bank, landlord and creditors, you will
						have a pretty good idea of what awaits you in the months to come.
						Make a detailed list of your assets, debts, payments and living
						costs. Contact your provider to determine what needs to be paid now
						and determine what may be under threat of repossession. Keep the
						communication open between yourself and the creditors you have lend
						from. Everyone understands the difficultly COVID-19 has caused and
						are trying to best circumvent payment issues.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>
								If You Need To Borrow Money, Only Use Credit Providers
								Registered With The NCR{' '}
							</p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						The National Credit Regulator (NCR) was established as the regulator
						under the National Credit Act. It is responsible for the regulation
						of the South African credit industry; ensuring education, research,
						policy development, investigation of complaints, and most
						importantly the registration of the financial institutions we borrow
						from- thus, the enforcement of the Act. This Act requires the NCR to
						promote an accessible credit market and is responsible for the
						proper registration of credit providers, credit bureaus and debt
						counsellors.
					</p>
					<p>
						The maximum interest rate you may be charged by a money lender is
						set by the National Credit Act. Always make sure that the rate you
						will be charged is not above what is allowed.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Do Not Over Extend Your Credit </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						Remember, credit costs more. Spend wisely, borrow wisely and be
						credit smart.
					</p>
					<p>
						Avoid over burdening your credit card where possible and try not to
						stretch your credit by taking out unnecessary debt that you cannot
						pay back.
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Consider Debt Counseling </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						When you apply for debt counselling, your debt counsellor approaches
						your various creditors to negotiate a manageable repayment plan.
						This process involves your debt counsellor negotiating for a cheaper
						interest rate and extended repayment terms, which means you pay
						less.
					</p>
					<p>
						Once this agreement is in place, your only role is to ensure that
						payment is made monthly. However, if you are unemployed there is no
						guarantee that you can afford payments and the process will not work
						effectively.
					</p>
					<p>
						Should you be able to provide surety that you are receiving a
						sufficient and steady stream of income (despite being unemployed)
						that is enough to cover your debt repayments, you may still qualify.
					</p>
					<p>
						It is important to note: If you are married in community of
						property, your partners debt is your liability even if you had no
						part in accruing the debt. Both you and your partner will be liable
						to pay this debt. When applying for debt counselling, credit
						agreements from both parties must be included in the application
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Protect Your Identity </p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						Hard times make easy earnings for fraudsters. They are always
						looking for an opportunity to steal your information to use in order
						to acquire debt on your name. It is important that you protect your
						identity. If you become a victim of identity theft, chances are it
						will cause severe damage to your finances and your good name-
						especially if you don't find out about it immediately. Even if you
						do catch on quickly, you can spend months trying to repair the
						damage done to your credit rating. Worst case scenario, you can even
						find yourself accused of a crime you did not commit because someone
						used your personal information to commit the crime in your name.
						Make sure you are on top of your credit activity and sign up to{' '}
						<a href="https://www.idhero.co.za/" style={{ color: '#0000FF' }}>
							https://www.idhero.co.za/
						</a>{' '}
					</p>
					<p>
						AID Hero starts monitoring changes to your personal information
						within 24-48 hours, and the following changes reflect within 31
						days:
					</p>
					<ul>
						<li>New accounts opened on your name</li>
						<li>Property registration changes</li>
						<li>Accounts gone into arrears</li>
						<li>Legal actions taken against you</li>
						<li>Suspicious activities that occur related to your ID number</li>
					</ul>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>Get Your Credit Report For Free</p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						Instead of phoning around for your credit report, why not download
						Splendi? Splendi simplifies things by grouping all your open
						accounts and loans in one place and displays your risk profile and
						percentages of debt used and paid back. That means you will have
						your credit information at the tip of your fingers and can monitor
						your credit at any point with a simple-to-use application on your
						phone.
					</p>
					<p>
						You will have access to your 24-month account history which will
						help you easily keep track of your credit commitments. You will also
						know when new accounts open in your name or are in arrears.
					</p>
					<p>
						Knowing where you are is the first step to growing and securing a
						credit profile that supports your future goals and ambitions.{' '}
						<a href="https://www.splendi.co.za/" style={{ color: '#0000FF' }}>
							https://www.splendi.co.za/
						</a>{' '}
					</p>
				</div>
				<div className="d-flex flex-row">
					<div className="px-5">
						<strong>
							<p>To Conclude:</p>
						</strong>
					</div>
				</div>
				<div className="px-5">
					<p>
						It is sometimes easier to get a loan than it is to pay it back. This
						can lead to financial strain which causes unnecessary stress and can
						even lead to poor physical/mental health. Make sure that you are
						aware of what you can afford, make the essentials your priority for
						the next few months and be strict on your budget. Financial
						institutions have a responsibility to guide their customers in this
						difficult time. Consumers have an equal responsibility to accept
						what they can afford, settle what they cannot and analyse what they
						really need. It is a time of thinking and in times like these, it is
						important to organize your priorities and ensure that you are doing
						right by you. Let’s spend responsibly today, so that we can borrow
						responsibly tomorrow.
					</p>
					<p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
						Written by Adam Boyd
					</p>
				</div>
			</>
		);

		const desktopDisplay = (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				<Card className="mt-3">
					<CardBody>{commonDisplay}</CardBody>
				</Card>
			</FadeTransform>
		);

		const mobileDisplay = (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				{commonDisplay}
			</FadeTransform>
		);

		if (this.props.isMobile) {
			return mobileDisplay;
		} else {
			return desktopDisplay;
		}
	}
}

export default CovID19Article;
