import React, { Component } from 'react';
import './Vehicle.css';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOfferSelect } from '../../../../redux/launchOffers/actionCreators/selectedOffer';
import { Loading } from '../../../../components/Loading/LoadingComponent';

const PRODUCT = {
	// CAMPAIGN_ID: 120,
	CAMPAIGN_ID: 6,
	CAMPAIGN_NAME: 'Splendi Debt Review',
};

class DebtOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offerCreated: false,
		};

		this.createOfferSelect = this.createOfferSelect.bind(this);
	}

	async createOfferSelect() {
		const offerData = {
			campaignReference: PRODUCT.CAMPAIGN_ID,
			sourceCampaign: PRODUCT.CAMPAIGN_NAME,
		};

		try {
			await this.props.apiOfferCreateSelect(offerData);
			this.setState({ offerCreated: true });
		} catch (err) {
			console.log('Error selecting the offer', err);
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.selectedOffer && this.state.offerCreated) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}

			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}
			return (
				<div>
					<div>
						<div className="card-big">
							<div className="card-big-header">
								<div className="card-big-header-title-container">
									<div className="rocket-img" />
									<div className="card-big-header-title">
										<div className="heading">DEBT REVIEW</div>
										<div className="text10">
											Debt review is a process that helps consumers who need guidance to meet their
											debt obligations. A debt counsellor approaches your creditors and makes
											payment arrangements on your behalf, reducing your payments to a single
											monthly amount that you can afford, while protecting your assets from
											repossession.
										</div>
									</div>
								</div>
								<div className="card-big-header-button">
									<div className="btn-green" onClick={this.createOfferSelect}>
										I'm Interested
									</div>
								</div>
							</div>

							<div className="card-small">
								<div className="debt" />
								<div className="card-small-body">
									<div className="heading2">DEBT REVIEW</div>
									<div className="heading4">
										Debt Review makes your debt affordable while protecting you from asset
										repossession, legal action, and creditor harassment.
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferCreateSelect: (data) => dispatch(createOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DebtOffer);
