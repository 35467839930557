import React from 'react';
import { baseUrl } from '../../../../shared/baseUrl';
import { Animation, Col, MDBCard, MDBCardBody, Row } from 'mdbreact';
import './RiskScaleComponent.css';

export const RiskScaleComponent = (props) => {
	const { data } = props;
	let riskImage = null;
	if (data.RiskCategory.value === 'Medium Risk') {
		riskImage = <img src={`${baseUrl}assets/img/risk_category_moderate.svg`} alt="" width="85%" />;
	} else if (data.RiskCategory.value === 'Potential High Risk') {
		riskImage = <img src={`${baseUrl}assets/img/risk_category_very_high.svg`} alt="" width="85%" />;
	} else if (data.RiskCategory.value === 'Low Risk') {
		riskImage =
			Number(data.FinalScore.value) < 840 ? (
				<img src={`${baseUrl}assets/img/risk_category _low.svg`} alt="" width="85%" />
			) : (
				<img src={`${baseUrl}assets/img/risk_category _very_low.svg`} alt="" width="85%" />
			);
	}

	return (
		<Row>
			<Col className="col-12 text-nowrap text-center">
				<h4 className="h4-responsive sub-title font-bold">RISK CATEGORY</h4>
			</Col>
			<Col className="col-12">
				<MDBCard className="bg-color-purple-dark-2">
					<ul className="riskCategory">
						<li className="riskCategoryIndicator">
							<div className="riskGood">{/* Color Circle For Good */}</div>
							<span>Good</span>
						</li>
						<li className="riskCategoryIndicator">
							<div className="riskOKay">{/* Color Circle For Okay */}</div>
							<span>Okay</span>
						</li>
						<li className="riskCategoryIndicator">
							<div className="riskAverage">{/* Color Circle For Average */}</div>
							<span>Average</span>
						</li>
						<li className="riskCategoryIndicator">
							<div className="riskNeedsAttention">{/* Color Circle For Needs Attention */}</div>
							<span>Needs Attention</span>
						</li>
					</ul>
				</MDBCard>
				{/* {riskImage} */}
			</Col>
		</Row>
	);
};

export const RiskKredGuruComponent = (props) => {
	const { data } = props;
	let riskImage = null;
	if (data.RiskCategory.value === 'Medium Risk') {
		riskImage = <img src={`${baseUrl}assets/img/risk_category_moderate.svg`} alt="" width="60%" />;
	} else if (data.RiskCategory.value === 'Potential High Risk') {
		riskImage = <img src={`${baseUrl}assets/img/risk_category_very_high.svg`} alt="" width="60%" />;
	} else if (data.RiskCategory.value === 'Low Risk') {
		riskImage =
			Number(data.FinalScore.value) < 840 ? (
				<img src={`${baseUrl}assets/img/risk_category _low.svg`} alt="" width="60%" />
			) : (
				<img src={`${baseUrl}assets/img/risk_category _very_low.svg`} alt="" width="60%" />
			);
	}

	return (
		<Row>
			<Col className="col-12 text-nowrap text-center">
				<h4 className="h4-responsive">RISK CATEGORY</h4>
			</Col>
			<Col className="col-12">{riskImage}</Col>
		</Row>
	);
};
