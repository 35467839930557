import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText, MDBCol, MDBRow } from 'mdbreact';
import { FormWithConstraints } from 'react-form-with-constraints';
import InputComponent from '../../../../../components/Input/InputComponent';
import SelectComponent from '../../../../../components/Select/SelectComponent';
import { Loading } from '../../../../../components/Loading/LoadingComponent';
import SliderComponent from '../../../../../components/Slider/SliderComponent';
import { baseUrl } from '../../../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';
import { ErrorComponent } from '../../../../../components/Error/ErrorComponent';

function getOffers(offers) {
	return offers.map((offer, index) => ({
		text: offer.productField,
		value: index,
		checked: index === 0
	}));
}

class QuoteForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isResult: false,
			offersIndex: 0,
			isFormValid: false,
			invalidFormMessage: ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getValueOfSelect = this.getValueOfSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			isResult: true
		});
	};

	async handleChange(e) {
		if (e.target) {
			e.preventDefault();
			let inputName = e.target.name;
			let inputValue = e.target.value;
			console.log('Name', inputName);

			// Validates only the given fields and returns Promise<Field[]>
			const fields = await this.form.validateFields(e.target);

			const fieldIsValid = fields.every(field => field.isValid());
			if (fieldIsValid) {
				console.log(`Field '${inputName}' is valid and value is ${inputValue}`);
			} else {
				console.log(`Field '${inputName}' is invalid`);
			}

			if (this.form.isValid()) {
				console.log('The form is valid');
			} else {
				console.log('The form is invalid');
			}
		}
	}

	async handleSubmit(e) {
		const form = e.target;

		e.preventDefault();

		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every(field => field.isValid());
		this.setState({
			isFormValid: formIsValid
		});

		if (formIsValid) {
			console.log('The form is valid');
			const formData = new FormData(form);
			const dataArray = this.props.bayPortQuote.bayPortQuote.offersField;
			const index = this.state.offersIndex;
			dataArray[index].loanAmountField = formData.get('loanAmountField');
			dataArray[index].termAllowedField = formData.get('termAllowedField');
			this.props.acceptBayPortQuote({
				index: index,
				quote: this.props.bayPortQuote.bayPortQuote
			});
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid'
			});
			console.log('The form is invalid');
		}
	}

	getValueOfSelect = value => {
		this.setState({
			offersIndex: this.props.bayPortQuote.bayPortQuote.offersField
				.map(offer => offer.productField)
				.indexOf(value)
		});
		console.log(
			this.props.bayPortQuote.bayPortQuote.offersField.indexOf(value),
			value
		);
	};

	render() {
		if (this.state.isResult && this.state.isFormValid) {
			return (
				<Redirect to="/offers/featured/offer/bayport/credit-acknowledgment" />
			);
		}
		if (this.props.bayPortQuote.isLoading) {
			return <Loading />;
		} else if (this.props.bayPortQuote.errMess) {
			return <ErrorComponent />;
		} else {
			const bayPortQuote = this.props.bayPortQuote.bayPortQuote;
			if (
				bayPortQuote.errorCodeField &&
				bayPortQuote.errorMessageField.length > 0
			) {
				return (
					<Card>
						<CardBody>
							<MDBRow>
								<MDBCol className="col-12 col-md-9">
									<p className="red-text"> {bayPortQuote.errorMessageField}</p>
								</MDBCol>
								<MDBCol className="col-12 col-md-3 pb-3">
									<img
										src={`${baseUrl}assets/img/Bayport-logo-small.png`}
										alt=""
										width="100%"
									/>
								</MDBCol>
							</MDBRow>
						</CardBody>
					</Card>
				);
			} else if (bayPortQuote.offersField instanceof Array) {
				const offers = getOffers(bayPortQuote.offersField);
				return (
					<FormWithConstraints
						className="needs-validation"
						id="quote-form"
						ref={formWithConstraints => (this.form = formWithConstraints)}
						onSubmit={this.handleSubmit}
						noValidate
					>
						<MDBRow>
							<MDBCol className="col-12 col-md-11">
								<Card>
									<CardBody>
										<MDBRow>
											<MDBCol className="col-6 align-self-end">
												<MDBRow>
													<MDBCol className="col-12">
														<CardText>
															Pre-qualification Statement below.
														</CardText>
													</MDBCol>
												</MDBRow>
												<MDBRow>
													<MDBCol className="col-12">
														<CardTitle className="sub-title text-nowrap">
															ACCEPT QUOTE
														</CardTitle>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol className="col-12 col-md-3 align-self-end d-flex justify-content-center">
												<MDBRow>
													<MDBCol className="col-12">
														<p className="red-text">
															{this.state.invalidFormMessage}
														</p>
													</MDBCol>
												</MDBRow>
											</MDBCol>
											<MDBCol className="col-12 col-md-3 pb-3">
												<img
													src={`${baseUrl}assets/img/Bayport-logo-small.png`}
													alt=""
													width="100%"
												/>
											</MDBCol>
										</MDBRow>
										<MDBRow className="partner-offers">
											<MDBCol className="col-12">
												<MDBRow>
													<MDBCol md="6">
														<SelectComponent
															id="CampaignCode"
															name="CampaignCode"
															label="Loan Type"
															options={offers}
															getValue={this.getValueOfSelect}
															handleChange={this.handleChange}
															required={true}
														/>
													</MDBCol>
												</MDBRow>
												<MDBRow>
													<MDBCol md="6">
														<SliderComponent
															name="termAllowedField"
															id="termAllowedField"
															label="Term Allowed in months"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.termAllowedField
															}
															min={
																bayPortQuote.offersField[this.state.offersIndex]
																	.termAllowedField
															}
															max={84}
															handleChange={this.handleChange}
															append="months"
														/>
													</MDBCol>
													<MDBCol md="6">
														<InputComponent
															name="loanAmountField"
															id="loanAmountField"
															label="loan Amount"
															hint={
																bayPortQuote.offersField[this.state.offersIndex]
																	.loanAmountField
															}
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.loanAmountField
															}
															disabled={false}
															type="digit"
															step="100"
															handleChange={this.handleChange}
															required={true}
															min={0}
															max={
																bayPortQuote.offersField[this.state.offersIndex]
																	.disburseAmountField
															}
															minLength={0}
															maxLength={15}
															outline={true}
														/>
													</MDBCol>
													<MDBCol className="col-6">
														<InputComponent
															name="disburseAmountField"
															id="disburseAmountField"
															label="Disburse Amount"
															disabled={true}
															type="digit"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.disburseAmountField
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
													<MDBCol className="col-6">
														<InputComponent
															name="interestRateField"
															id="interestRateField"
															label="Interest Rate"
															disabled={true}
															type="text"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.interestRateField + '%'
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
													<MDBCol md="6">
														<InputComponent
															name="monthlyLoanRepaymentField"
															id="monthlyLoanRepaymentField"
															label="Monthly Loan Repayment"
															disabled={true}
															type="price"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.monthlyLoanRepaymentField
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
													<MDBCol md="6">
														<InputComponent
															name="totalLoanRepaymentField"
															id="totalLoanRepaymentField"
															label="Total Loan Repayment"
															disabled={true}
															type="price"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.totalLoanRepaymentField
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
													<MDBCol md="6">
														<InputComponent
															name="settlementsField"
															id="settlementsField"
															label="Settlements"
															disabled={true}
															type="text"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.settlementsField
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
													<MDBCol md="6">
														<InputComponent
															name="PropertyChanged"
															id="PropertyChanged"
															label="Property Changed"
															disabled={true}
															type="text"
															value={
																bayPortQuote.offersField[this.state.offersIndex]
																	.PropertyChanged
															}
															handleChange={this.handleChange}
															outline={true}
														/>
													</MDBCol>
												</MDBRow>
											</MDBCol>
										</MDBRow>
									</CardBody>
								</Card>
							</MDBCol>
							<MDBCol className="col-auto col-md-1 d-flex justify-content-center align-self-center">
								<a
									onClick={() => {
										document
											.getElementById('quote-form')
											.dispatchEvent(new Event('submit'));
										this.navigateToPage();
									}}
								>
									<img
										src={`${baseUrl}assets/img/next_arrow_active.svg`}
										alt=""
										width="100%"
									/>
								</a>
							</MDBCol>
						</MDBRow>
					</FormWithConstraints>
				);
			}
		}
	}
}

export default QuoteForm;
