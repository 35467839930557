import * as ActionTypes from '../../ActionTypes';

export const SubmitNewSubscription = (
	state = {
		isLoading: false,
		errMess: null,
		newSubscriptionResult: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_NEW_SUBSCRIPTION:
			return {
				...state,
				isLoading: false,
				errMess: null,
				newSubscriptionResult: action.payload
			};
		case ActionTypes.NEW_SUBSCRIPTION_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				newSubscriptionResult: []
			};
		case ActionTypes.NEW_SUBSCRIPTION_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				newSubscriptionResult: []
			};
		default:
			return state;
	}
};
