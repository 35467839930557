import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import insurance from '../../../assets/img/car-insurance.png';
import credit from '../../../assets/img/credit.png';
import car from '../../../assets/img/car.png';
import money from '../../../assets/img/money.png';
import '../Carousels/carousels.css';
import { fetchDisputeId } from '../../../redux/dispute/actionCreators/getDisputeId';
import { connect } from 'react-redux';

class Carousels extends Component {
	render() {
		const { user } = this.props.user;
		let sessionStorageCreditScore = sessionStorage.getItem('creditScore');

		let userIdOrPassportNr = user.idNumber;
		if (
			parseInt(sessionStorageCreditScore) === 0 ||
			(userIdOrPassportNr && /^[0-9]{9}[a-zA-Z]{1}[0-9]{1}$/.test(userIdOrPassportNr))
		) {
			return <Redirect to="/credit" />;
		}
		return (
			<div className="header">
				<div className="card1">
					<div className="front1">
						<div className="text50">Insurance</div>
						<br />
						<img src="assets/img/os_insurance.svg" alt="Logo" />
					</div>
					<div className="back1">
						<div className="links-back1">
							{/* <div className="back-text1">Insurance</div> */}
							<div className="block1">
								<Link to="/offers_and_services/vehicleinsurance">Vehicle Insurance</Link>
								<br />
								<Link to="/offers_and_services/homeinsurance">Home Insurance</Link>
								<br />
								<Link to="/offers_and_services/householdinsurance">Household Insurance</Link>
								<br />
							</div>
						</div>
					</div>
				</div>

				<div className="card2">
					<div className="front2">
						<div className="text50">Credit Repair</div>
						<br />
						<img src="assets/img/os_credit_repair.svg" alt="Logo" />
					</div>
					<div className="back2">
						<div className="links-back2">
							{/* <div className="back-text2">Credit Repair</div> */}
							<div className="block2">
								<Link to="/offers_and_services/splendiboost">Score boost</Link>
								<br />
								<Link to="/offers_and_services/debtconsolidation">Debt consolidation</Link>
								<br />
								<Link to="/offers_and_services/debtreview">Debt review</Link>
								<br />
							</div>
						</div>
					</div>
				</div>

				<div className="card3">
					<div className="front3">
						<div className="text50">Vehicle</div>
						<br />
						<img src="assets/img/os_vehicle.svg" alt="Logo" />
					</div>
					<div className="back3">
						<div className="links-back3">
							{/* <div className="back-text3">Vehicle</div> */}
							<div className="block3">
								<Link to="/offers_and_services/roadsideassist">Roadside Assistance</Link>
								<br />
								<Link to="/offers_and_services/vehicleinsurance">Vehicle insurance</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="card4">
					<div className="front4">
						<div className="text50">Get Money</div>
						<br />
						<img src="assets/img/os_get_money.svg" alt="Logo" />
					</div>
					<div className="back4">
						<div className="links-back4">
							{/* <div className="back-text4">Get Money</div> */}
							<div className="block4">
								<Link to="/offers_and_services/personalloan">Personal loan</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapDispatchToProps = (dispatch) => ({
	fetchDisputeId: () => dispatch(fetchDisputeId()),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Carousels);
