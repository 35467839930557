import React, { Component } from 'react';
import { MDBCol, MDBFileInput, MDBRow } from 'mdbreact';

class DocumentsBasedOnDisputeNature extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleDocs = () => {
		const {
			handleIDUpload,
			handleSixSample,
			natureOfDispute,
			handlePaidUpLetter,
			handleAdminOrderLetter,
			handleProofOfAddress,
			handleRehabilitation,
			handleAffidavit,
			handle10XSample,
			handleCertified17WForm,
			handleCertified19WForm,
		} = this.props;
		const {
			account,
			judgement,
			adminOrder,
			sequestration,
			debtReview,
			accountPaidFull,
			accountIsClosed,
			notMyAccount,
			paidMyAccount,
			noteOfAdverseListing,
			paidJudgement,
			notMyJudgement,
			judgmentIsIncorrect,
			notMyAdminOrder,
			adminIsPaid,
			adminIsRescind,
			notMySequestration,
			rehabilitated,
			accountIsPrescribed,
			debtReviewIsOver,
			withdrewDebtReview,
		} = natureOfDispute;

		if (account || judgement || adminOrder || sequestration || debtReview) {
			const commonDocs = (
				<>
					<MDBCol md="6">
						<MDBFileInput
							btnColor="info"
							textFieldTitle="Proof of residence"
							getValue={handleProofOfAddress}
						/>
					</MDBCol>
					<MDBCol md="6">
						<MDBFileInput
							btnColor="info"
							resetAriaLabel="Dismiss"
							textFieldTitle="ID Copy"
							getValue={handleIDUpload}
						/>
					</MDBCol>
				</>
			);

			const paidUpLetter = (
				<MDBCol md="6">
					<MDBFileInput
						btnColor="info"
						reset
						textFieldTitle="Paid Up letter"
						getValue={handlePaidUpLetter}
					/>
				</MDBCol>
			);

			const certified17WForm = (
				<MDBCol md="6">
					<MDBFileInput
						btnColor="info"
						reset
						textFieldTitle="Certified 17.W Form"
						getValue={handleCertified17WForm}
					/>
				</MDBCol>
			);

			if ((account && paidMyAccount) || accountIsClosed || accountPaidFull) {
				return (
					<MDBRow>
						{commonDocs}
						{paidUpLetter}
					</MDBRow>
				);
			} else if (account && accountIsPrescribed) {
				return <MDBRow>{commonDocs}</MDBRow>;
			} else if (account && notMyAccount) {
				return (
					<>
						<MDBRow>{commonDocs}</MDBRow>
						<MDBRow>
							<MDBCol md="6">
								<MDBFileInput
									btnColor="info"
									textFieldTitle="Affidavit referencing fraudulent account"
									getValue={handleAffidavit}
								/>
							</MDBCol>
							<MDBCol md="6">
								<MDBFileInput
									btnColor="info"
									textFieldTitle="10 x a sample of your signature"
									getValue={handleSixSample}
									reset
								/>
							</MDBCol>
						</MDBRow>
					</>
				);
			} else if ((judgement && notMyJudgement) || judgmentIsIncorrect || noteOfAdverseListing) {
				return (
					<>
						<MDBRow>{commonDocs}</MDBRow>
						<MDBRow>
							<MDBCol md="6">
								<MDBFileInput
									btnColor="info"
									textFieldTitle="Sworn Affidavit"
									getValue={handleAffidavit}
								/>
							</MDBCol>
							<MDBCol md="6">
								<MDBFileInput
									btnColor="info"
									textFieldTitle="10 x a sample of your signature"
									getValue={handle10XSample}
									reset
								/>
							</MDBCol>
						</MDBRow>
					</>
				);
			} else if (judgement && paidJudgement) {
				return (
					<>
						<MDBRow>
							{commonDocs}
							{paidUpLetter}
						</MDBRow>
					</>
				);
			} else if ((adminOrder && adminIsRescind) || adminIsPaid) {
				return (
					<>
						<MDBRow>
							{commonDocs}
							<MDBCol md="6">
								<MDBFileInput
									btnColor="info"
									textFieldTitle="Admin order letter(74Q or 74U)"
									getValue={handleAdminOrderLetter}
								/>
							</MDBCol>
						</MDBRow>
					</>
				);
			} else if (adminOrder && notMyAdminOrder) {
				return <MDBRow>{commonDocs}</MDBRow>;
			} else if (sequestration && notMySequestration) {
				return <MDBRow>{commonDocs}</MDBRow>;
			} else if (sequestration && rehabilitated) {
				return (
					<MDBRow>
						{commonDocs}
						<MDBCol md="6">
							<MDBFileInput
								btnColor="info"
								reset
								textFieldTitle="Rehabilitation Court Order"
								getValue={handleRehabilitation}
							/>
						</MDBCol>
					</MDBRow>
				);
			} else if (debtReview && withdrewDebtReview) {
				return (
					<MDBRow>
						{commonDocs}
						{certified17WForm}
					</MDBRow>
				);
			} else if (debtReview && debtReviewIsOver) {
				return (
					<>
						<MDBRow>
							{commonDocs}
							{certified17WForm}
							{paidUpLetter}
						</MDBRow>
						<MDBRow>
							<MDBCol md="12">
								<MDBFileInput
									btnColor="info"
									reset
									textFieldTitle="Certified clearance certificate(Form 19) or court order that states you are no longer over indebted"
									getValue={handleCertified19WForm}
								/>
							</MDBCol>
						</MDBRow>
					</>
				);
			}
		} else {
			return null;
		}
	};

	render() {
		return <>{this.handleDocs()}</>;
	}
}

export default DocumentsBasedOnDisputeNature;
