import React, { Component } from 'react';
import { baseUrl } from '../../../shared/baseUrl';
import { Card, CardBody, CardText, MDBBtn } from 'mdbreact';
import './ConsumerServices.css';

class PaymentGateWay extends Component {
	constructor(props) {
		super(props);
		this.state = { isOfferState: true };
	}

	render() {
		const payment = {
			TOTAL_AMOUNT: this.props.amountField
				? Number(this.props.amountField) * 100
				: 0,
			YOUR_MERCHANT_ID: 'cb82d796-6097-46fb-845b-5864d1bbc532',
			YOUR_MERCHANT_PASSWORD: '49b4a3d6-c794-4f2a-b344-8b23447a2770',
			REFERENCE_OF_TRANSACTION: this.props.transRefField
				? this.props.transRefField
				: 'Splendi payment testing',
			SUCCESS_URL: baseUrl + 'offers_and_services/featured?key=success',
			CANCEL_URL: baseUrl + 'offers_and_services/featured?key=cancel',
			FAILURE_URL: baseUrl + 'offers_and_services/featured?key=failure'
		};
		const params = new URLSearchParams(this.props.location.search);
		const key = params.get('key');

		console.log('Amount', payment.TOTAL_AMOUNT);
		return (
			<form
				action="https://www.uat.xds.co.za/paymentgateway/payentrypost"
				method="POST"
				id="payment-gateway-form"
			>
				{key && (
					<Card>
						<CardBody>
							{key === 'success' && (
								<CardText className="green-text">Payment successful</CardText>
							)}
							{key === 'cancel' && (
								<CardText className="red-text">Payment cancelled</CardText>
							)}
							{key === 'failure' && (
								<CardText className="red-text">Payment unsuccessful</CardText>
							)}
						</CardBody>
					</Card>
				)}
				<input type="hidden" name="Amount" value={payment.TOTAL_AMOUNT} />
				<input type="hidden" name="Currency" value="ZAR" />
				<input
					type="hidden"
					name="MerchantID"
					value={payment.YOUR_MERCHANT_ID}
				/>
				<input
					type="hidden"
					name="MerchantPassword"
					value={payment.YOUR_MERCHANT_PASSWORD}
				/>
				<input
					type="hidden"
					name="Reference"
					value={payment.REFERENCE_OF_TRANSACTION}
				/>
				<input type="hidden" name="accepturl" value={payment.SUCCESS_URL} />
				<input type="hidden" name="cancelurl" value={payment.CANCEL_URL} />
				<input type="hidden" name="declineurl" value={payment.FAILURE_URL} />
				<MDBBtn
					className={`${this.props.buttonStyle} btn btn-default btn-block`}
					type="submit"
				/>
			</form>
		);
	}
}

export default PaymentGateWay;
