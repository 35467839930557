import React, { Component } from 'react';
import { MDBIcon, MDBCol, MDBRow } from 'mdbreact';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import './Dropdown.css';
import { NavLink, Link } from 'react-router-dom';

class MegaDropDownComponent extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	render() {
		const dropdownItems = this.props.menuItem.submenuItems.map(
			(submenuItem, index) => {
				return (
					<MDBCol>
						<DropdownItem
							className="nav-link text-nowrap"
							key={index}
							tag={NavLink}
							to={submenuItem.url}
							activeClassName="nav-link-active"
						>
							<span>{submenuItem.label}</span>{' '}
							{submenuItem.icon && (
								<span className="d-flex align-items-center p-2">
									<MDBIcon icon={submenuItem.icon} size="2x" />
								</span>
							)}
						</DropdownItem>
					</MDBCol>
				);
			}
		);
		const active = this.props.menuItem.submenuItems
			.map(value => value.url)
			.includes(window.location.pathname)
			? 'nav-link-active'
			: '';

		return (
			<Dropdown
				className="mega-dropdown"
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
			>
				<DropdownToggle nav className={active}>
					<h5 className="h5-responsive">
						<span className="">{this.props.menuItem.label}</span>
					</h5>
				</DropdownToggle>
				<DropdownMenu className="mega-menu">
					<MDBRow className="text-center">{dropdownItems}</MDBRow>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

export default MegaDropDownComponent;
