import * as ActionTypes from './ActionTypes';
import { baseUrl } from '../shared/baseUrl';
import FileSaver from 'file-saver';
import { corsOptions } from './corsOptions';
import { requestPath } from './util/util';
export const fetchConsumer = () => (dispatch) => {
	dispatch(consumerLoading(true));
	return fetch(baseUrl + requestPath('api/report'), corsOptions())
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((consumer) => dispatch(addConsumer(consumer)))
		.catch((error) => dispatch(consumerFailed(error.message)));
};

export const consumerLoading = () => ({
	type: ActionTypes.CONSUMER_LOADING,
});

export const consumerFailed = (errmess) => ({
	type: ActionTypes.CONSUMER_FAILED,
	payload: errmess,
});

export const addConsumer = (consumer) => ({
	type: ActionTypes.ADD_CONSUMER,
	payload: consumer,
});

export const fetchCreditSimulatorAccounts = () => (dispatch) => {
	dispatch(creditSimulatorAccountsLoading(true));

	return fetch(baseUrl + requestPath('api/credit-simulator-accounts'))
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((creditSimulatorAccounts) =>
			dispatch(addCreditSimulatorAccounts(creditSimulatorAccounts))
		)
		.catch((error) => dispatch(creditSimulatorAccountsFailed(error.message)));
};

export const creditSimulatorAccountsLoading = () => ({
	type: ActionTypes.CREDIT_SIMULATOR_ACCOUNTS_LOADING,
});

export const creditSimulatorAccountsFailed = (errmess) => ({
	type: ActionTypes.CREDIT_SIMULATOR_ACCOUNTS_FAILED,
	payload: errmess,
});

export const addCreditSimulatorAccounts = (creditSimulatorAccounts) => ({
	type: ActionTypes.ADD_CREDIT_SIMULATOR_ACCOUNTS,
	payload: creditSimulatorAccounts,
});

export const updateCreditSimulatorScore = (formData) => (dispatch) => {
	dispatch(creditSimulatorScoreLoading(true));
	return fetch(baseUrl + requestPath('api/credit-simulator-submit'), {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((creditSimulatorScore) => dispatch(addCreditSimulatorScore(creditSimulatorScore)))
		.catch((error) => dispatch(creditSimulatorScoreFailed(error.message)));
};

export const creditSimulatorScoreLoading = () => ({
	type: ActionTypes.CREDIT_SIMULATOR_SCORE_LOADING,
});

export const creditSimulatorScoreFailed = (errmess) => ({
	type: ActionTypes.CREDIT_SIMULATOR_SCORE_FAILED,
	payload: errmess,
});

export const addCreditSimulatorScore = (creditSimulatorScore) => ({
	type: ActionTypes.CREDIT_SIMULATOR_SCORE_UPDATE,
	payload: creditSimulatorScore,
});

export const fetchUser = () => (dispatch) => {
	dispatch(userLoading(true));
	return fetch(baseUrl + requestPath('api/user-info'), corsOptions())
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((user) => {
			dispatch(addUser(user));
			localStorage.setItem('userPassedDovs', JSON.stringify(user.userPassedDovs));
		})
		.catch((error) => {
			dispatch(userFailed(error.message));
		});
};

export const userLoading = () => {
	localStorage.setItem('toDashboard', 'false');
	return {
		type: ActionTypes.USER_LOADING,
	};
};

export const userFailed = (errmess) => ({
	type: ActionTypes.USER_FAILED,
	payload: errmess,
});

export const addUser = (user) => {
	localStorage.setItem('toDashboard', 'true');
	// localStorage.setItem('continue', true);

	const isEmptyObject = Object.keys(user).length;

	if (isEmptyObject < 1) {
		window.location.href = '/';
	}

	return {
		type: ActionTypes.ADD_USER,
		payload: user,
	};
};

export const logout = () => (dispatch) => {
	return fetch(baseUrl + requestPath('logout'), corsOptions())
		.then((response) => {
			if (response.ok) {
				localStorage.clear();
				dispatch({
					type: ActionTypes.LOGOUT,
				});
			} else {
				let error = new Error('Error ' + response.status + ': ' + response.statusText);
				throw error;
			}
		})
		.catch((error) => {
			dispatch({
				type: ActionTypes.USER_FAILED,
				errMess: 'Error while trying to logout' + error.message,
			});
		});
};

export const downloadReportPDF = (consumer) => (dispatch) => {
	return fetch(baseUrl + requestPath('api/download-report-pdf'), {
		method: 'POST',
		body: JSON.stringify(consumer),
		// responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response.blob();
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((blob) => {
			const fileURL = URL.createObjectURL(blob);
			FileSaver.saveAs(fileURL, 'Splendi-Credit-Report.pdf');
			// window.open(fileURL);
		})
		.catch((error) => {
			console.log('Download PDF ', error.message);
			alert('Credit report PDF could not be downloaded \nError' + error.message);
		});
};

export const fetchBayPortQuote = (formData) => (dispatch) => {
	dispatch(bayPortQuoteLoading(true));
	return fetch(baseUrl + requestPath('api/bay-port-get-quote'), {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((bayPortQuote) => dispatch(addBayPortQuote(bayPortQuote)))
		.catch((error) => dispatch(bayPortQuoteFailed(error.message)));
};

export const bayPortQuoteLoading = () => ({
	type: ActionTypes.BAYPORT_QUOTE_LOADING,
});

export const bayPortQuoteFailed = (errmess) => ({
	type: ActionTypes.BAYPORT_QUOTE_FAILED,
	payload: errmess,
});

export const addBayPortQuote = (bayPortQuote) => ({
	type: ActionTypes.ADD_BAYPORT_QUOTE,
	payload: bayPortQuote,
});

export const acceptBayPortQuote = (formData) => (dispatch) => {
	dispatch(bayPortQuoteAcceptLoading(true));

	return fetch(baseUrl + requestPath('api/bay-port-submit-lead'), {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((bayPortAcknowledgment) => dispatch(addBayPortQuoteAccept(bayPortAcknowledgment)))
		.catch((error) => dispatch(bayPortQuoteAcceptFailed(error.message)));
};

export const bayPortQuoteAcceptLoading = () => ({
	type: ActionTypes.BAYPORT_QUOTE_ACCEPT_LOADING,
});

export const bayPortQuoteAcceptFailed = (errmess) => ({
	type: ActionTypes.BAYPORT_QUOTE_ACCEPT_FAILED,
	payload: errmess,
});

export const addBayPortQuoteAccept = (bayPortAcknowledgment) => ({
	type: ActionTypes.ADD_BAYPORT_QUOTE_ACCEPT,
	payload: bayPortAcknowledgment,
});

export const fetchAirtimeVASNetworks = () => (dispatch) => {
	dispatch(airtimeVASNetworksLoading(true));

	return fetch(baseUrl + requestPath('api/vas-airtime-networks'))
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((airtimeVASNetworks) => dispatch(addAirtimeVASNetworks(airtimeVASNetworks)))
		.catch((error) => dispatch(airtimeVASNetworksFailed(error.message)));
};

export const airtimeVASNetworksLoading = () => ({
	type: ActionTypes.VAS_AIRTIME_NETWORKS_LOADING,
});

export const airtimeVASNetworksFailed = (errmess) => ({
	type: ActionTypes.VAS_AIRTIME_NETWORKS_FAILED,
	payload: errmess,
});

export const addAirtimeVASNetworks = (airtimeVASNetworks) => ({
	type: ActionTypes.ADD_VAS_AIRTIME_NETWORKS,
	payload: airtimeVASNetworks,
});

export const fetchAirtimeVASProducts = (network) => (dispatch) => {
	dispatch(airtimeVASProductsLoading(true));

	return fetch(baseUrl + requestPath('api/vas-airtime-products') + '?network=' + network)
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((airtimeVASProducts) => dispatch(addAirtimeVASProducts(airtimeVASProducts)))
		.catch((error) => dispatch(airtimeVASProductsFailed(error.message)));
};

export const airtimeVASProductsLoading = () => ({
	type: ActionTypes.VAS_AIRTIME_PRODUCTS_LOADING,
});

export const airtimeVASProductsFailed = (errmess) => ({
	type: ActionTypes.VAS_AIRTIME_PRODUCTS_FAILED,
	payload: errmess,
});

export const addAirtimeVASProducts = (airtimeVASProducts) => ({
	type: ActionTypes.ADD_VAS_AIRTIME_PRODUCTS,
	payload: airtimeVASProducts,
});

export const buyVASProduct = (url, formData) => (dispatch) => {
	dispatch(buyVASProductResponseLoading(true));

	return fetch(baseUrl + url, {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((buyVASProductResponse) => dispatch(addBuyVASProductResponse(buyVASProductResponse)))
		.catch((error) => dispatch(buyVASProductResponseFailed(error.message)));
};

export const buyVASProductResponseLoading = () => ({
	type: ActionTypes.VAS_BUY_PRODUCT_RESPONSE_LOADING,
});

export const buyVASProductResponseFailed = (errmess) => ({
	type: ActionTypes.VAS_BUY_PRODUCT_RESPONSE_FAILED,
	payload: errmess,
});

export const addBuyVASProductResponse = (buyVASProductResponse) => ({
	type: ActionTypes.ADD_VAS_BUY_PRODUCT_RESPONSE,
	payload: buyVASProductResponse,
});

export const fetchShoppingCartItems = () => (dispatch) => {
	dispatch(shoppingCartItemsLoading(true));

	return fetch(baseUrl + requestPath('api/retrieve-shopping-cart-item'))
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((shoppingCart) => dispatch(addShoppingCartItems(shoppingCart)))
		.catch((error) => dispatch(shoppingCartItemsFailed(error.message)));
};

export const addShoppingCartItem = (formData) => (dispatch) => {
	return fetch(baseUrl + requestPath('api/add-shopping-cart-item'), {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((shoppingCart) => dispatch(addShoppingCartItems(shoppingCart)))
		.catch((error) => dispatch(shoppingCartItemsFailed(error.message)));
};

export const updateShoppingCartItem = (formData) => (dispatch) => {
	return fetch(baseUrl + requestPath('api/update-shopping-cart-item'), {
		method: 'PUT',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((shoppingCart) => dispatch(addShoppingCartItems(shoppingCart)))
		.catch((error) => dispatch(shoppingCartItemsFailed(error.message)));
};

export const deleteShoppingCartItem = (formData) => (dispatch) => {
	return fetch(baseUrl + requestPath('api/delete-shopping-cart-item'), {
		method: 'DELETE',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((shoppingCart) => dispatch(addShoppingCartItems(shoppingCart)))
		.catch((error) => dispatch(shoppingCartItemsFailed(error.message)));
};

export const deleteAllShoppingCartItems = () => (dispatch) => {
	return fetch(baseUrl + requestPath('api/delete-all-shopping-cart-items'), {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((shoppingCart) => dispatch(addShoppingCartItems(shoppingCart)))
		.catch((error) => dispatch(shoppingCartItemsFailed(error.message)));
};

export const shoppingCartItemsLoading = () => ({
	type: ActionTypes.SHOPPING_CART_ITEMS_LOADING,
});

export const shoppingCartItemsFailed = (errmess) => ({
	type: ActionTypes.SHOPPING_CART_ITEMS_FAILED,
	payload: errmess,
});

export const addShoppingCartItems = (shoppingCart) => ({
	type: ActionTypes.ADD_SHOPPING_CART_ITEMS,
	payload: shoppingCart,
});
