export const ADD_CONSUMER = 'ADD_CONSUMER';
export const CONSUMER_LOADING = 'CONSUMER_LOADING';
export const CONSUMER_FAILED = 'CONSUMER_FAILED';
export const ADD_CREDIT_SIMULATOR_ACCOUNTS = 'ADD_CREDIT_SIMULATOR_ACCOUNTS';
export const CREDIT_SIMULATOR_ACCOUNTS_LOADING = 'CREDIT_SIMULATOR_ACCOUNTS_LOADING';
export const CREDIT_SIMULATOR_ACCOUNTS_FAILED = 'CREDIT_SIMULATOR_ACCOUNTS_FAILED';
export const CREDIT_SIMULATOR_SCORE_UPDATE = 'CREDIT_SIMULATOR_SCORE_UPDATE';
export const CREDIT_SIMULATOR_SCORE_LOADING = 'CREDIT_SIMULATOR_SCORE_LOADING';
export const CREDIT_SIMULATOR_SCORE_FAILED = 'CREDIT_SIMULATOR_SCORE_FAILED';
export const ADD_USER = 'ADD_USER';
export const USER_LOADING = 'USER_LOADING';
export const USER_FAILED = 'USER_FAILED';
export const ADD_BAYPORT_QUOTE = 'ADD_BAYPORT_QUOTE';
export const BAYPORT_QUOTE_LOADING = 'BAYPORT_QUOTE_LOADING';
export const BAYPORT_QUOTE_FAILED = 'BAYPORT_QUOTE_FAILED';
export const ADD_BAYPORT_QUOTE_ACCEPT = 'ADD_BAYPORT_QUOTE_ACCEPT';
export const BAYPORT_QUOTE_ACCEPT_LOADING = 'BAYPORT_QUOTE_ACCEPT_LOADING';
export const BAYPORT_QUOTE_ACCEPT_FAILED = 'BAYPORT_QUOTE_ACCEPT_FAILED';
export const ADD_VAS_AIRTIME_NETWORKS = 'ADD_VAS_AIRTIME_NETWORKS';
export const VAS_AIRTIME_NETWORKS_LOADING = 'VAS_AIRTIME_NETWORKS_LOADING';
export const VAS_AIRTIME_NETWORKS_FAILED = 'VAS_AIRTIME_NETWORKS_FAILED';
export const ADD_VAS_AIRTIME_PRODUCTS = 'ADD_VAS_AIRTIME_PRODUCTS';
export const VAS_AIRTIME_PRODUCTS_LOADING = 'VAS_AIRTIME_PRODUCTS_LOADING';
export const VAS_AIRTIME_PRODUCTS_FAILED = 'VAS_AIRTIME_PRODUCTS_FAILED';
export const ADD_VAS_BUY_PRODUCT_RESPONSE = 'ADD_VAS_BUY_PRODUCT_RESPONSE';
export const VAS_BUY_PRODUCT_RESPONSE_LOADING = 'VAS_BUY_PRODUCT_RESPONSE_LOADING';
export const VAS_BUY_PRODUCT_RESPONSE_FAILED = 'VAS_BUY_PRODUCT_RESPONSE_FAILED';
export const ADD_SHOPPING_CART_ITEMS = 'ADD_SHOPPING_CART_ITEMS';
export const SHOPPING_CART_ITEMS_LOADING = 'SHOPPING_CART_ITEMS_LOADING';
export const SHOPPING_CART_ITEMS_FAILED = 'SHOPPING_CART_ITEMS_FAILED';

/**SUBMIT NEW_SUBSCRIPTION **/
export const ADD_NEW_SUBSCRIPTION = 'ADD_NEW_SUBSCRIPTION';
export const NEW_SUBSCRIPTION_LOADING = 'NEW_SUBSCRIPTION_LOADING';
export const NEW_SUBSCRIPTION_FAILED = 'NEW_SUBSCRIPTION_FAILED';

/**SEND SMS **/
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_LOADING = 'SEND_SMS_LOADING';
export const SEND_SMS_FAILED = 'SEND_SMS_FAILED';
export const CLEAR_VERIFY_STATE = 'CLEAR_VERIFY_STATE';

/**VERIFY SMS**/
export const VERIFY_SMS = 'VERIFY_SMS';
export const VERIFY_SMS_LOADING = 'VERIFY_SMS_LOADING';
export const VERIFY_SMS_FAILED = 'VERIFY_SMS_FAILED';

/**AVAILABLE OFFERS**/
export const AVAILABLE_OFFERS = 'AVAILABLE_OFFERS';
export const AVAILABLE_OFFERS_LOADING = 'AVAILABLE_OFFERS_LOADING';
export const AVAILABLE_OFFERS_FAILED = 'AVAILABLE_OFFERS_FAILED';

/**SELECT KC CLIENT**/
export const FETCH_KC_CLIENT = 'FETCH_KC_CLIENT';
export const FETCH_KC_CLIENT_LOADING = 'FETCH_KC_CLIENT_LOADING';
export const FETCH_KC_CLIENT_FAILED = 'FETCH_KC_CLIENT_FAILED';
export const FETCH_KC_CLIENT_COMPLETE = 'FETCH_KC_CLIENT_COMPLETE';

/**SELECT OFFERS**/
export const CREATE_OFFER_SELECT = 'CREATE_OFFER_SELECT';
export const OFFER_SELECT_CREATE_LOADING = 'OFFER_SELECT_CREATE_LOADING';
export const OFFER_SELECT_CREATE_FAILED = 'OFFER_SELECT_CREATE_FAILED';
export const OFFER_SELECT_CREATED = 'OFFER_SELECT_CREATED';

export const UPDATE_OFFER_SELECT = 'UPDATE_OFFER_SELECT';
export const OFFER_SELECT_UPDATE_LOADING = 'OFFER_SELECT_UPDATE_LOADING';
export const OFFER_SELECT_UPDATE_FAILED = 'OFFER_SELECT_UPDATE_FAILED';
export const OFFER_SELECT_UPDATED = 'OFFER_SELECT_UPDATED';

/**SUBSCRIPTION**/
export const SUBSCRIPTIONS = 'SUBSCRIPTION';
export const SUBSCRIPTIONS_LOADING = 'SUBSCRIPTION_LOADING';
export const SUBSCRIPTIONS_FAILED = 'SUBSCRIPTION_FAILED';

/**ID HERO NOTIFICATION**/
export const ADD_ID_HERO_NOTIFICATION = 'ADD_ID_HERO_NOTIFICATION';
export const ID_HERO_NOTIFICATION_LOADING = 'ID_HERO_NOTIFICATION_LOADING';
export const ID_HERO_NOTIFICATION_FAILED = 'ID_HERO_NOTIFICATION_FAILED';

/*** LOGOUT **/
export const LOGOUT = 'LOGOUT';

/*** Env Variables **/
export const ENV_VARIABLES_SUCCESS = 'ENV_VARIABLES_SUCCESS';
export const ENV_VARIABLES_FAILED = 'ENV_VARIABLES_FAILED';
export const ENV_VARIABLES_LOADING = 'ENV_VARIABLES_LOADING';

/**UPLOAD FILES **/
export const UPLOAD_DISPUTE_FILE = 'UPLOAD_DISPUTE_FILE';
export const UPLOAD_DISPUTE_FILE_LOADING = 'UPLOAD_DISPUTE_FILE_LOADING';
export const UPLOAD_DISPUTE_FILE_FAILED = 'UPLOAD_DISPUTE_FILE_FAILED';

/** GENERATE PDF **/
export const GENERATE_PDF = 'GENERATE_PDF';
export const GENERATE_PDF_LOADING = 'GENERATE_PDF_LOADING';
export const GENERATE_PDF_FAILED = 'GENERATE_PDF_FAILED';

/** GENERATE DISPUTE ID **/
export const GENERATE_DISPUTE_ID = 'GENERATE_DISPUTE_ID';
export const GENERATE_DISPUTE_ID_LOADING = 'GENERATE_DISPUTE_ID_LOADING';
export const GENERATE_DISPUTE_ID_FAILED = 'GENERATE_DISPUTE_ID_FAILED';

/** UPDATE NATURE DETAILS **/
export const UPDATE_NATURE_DETAILS = 'UPDATE_NATURE_DETAILS';
export const UPDATE_NATURE_DETAILS_LOADING = 'UPDATE_NATURE_DETAILS_LOADING';
export const UPDATE_NATURE_DETAILS_FAILED = 'UPDATE_NATURE_DETAILS_FAILED';

/** UPDATE CONTACT DETAILS **/
export const UPDATE_CONTACT_DETAILS = 'UPDATE_CONTACT_DETAILS';
export const UPDATE_CONTACT_DETAILS_LOADING = 'UPDATE_CONTACT_DETAILS_LOADING';
export const UPDATE_CONTACT_DETAILS_FAILED = 'UPDATE_CONTACT_DETAILS_FAILED';

/** CREATE DISPUTE **/
export const CREATE_DISPUTE = 'CREATE_DISPUTE';
export const CREATE_DISPUTE_LOADING = 'CREATE_DISPUTE_LOADING';
export const CREATE_DISPUTE_FAILED = 'CREATE_DISPUTE_FAILED';

/** GET KG CRED ED CMS ARTICLES */
export const GET_KG_CRED_ED_ARTICLES_SUCCESS = 'GET_KG_CRED_ED_ARTICLES_SUCCESS';
export const GET_KG_CRED_ED_ARTICLES_FAILED = 'GET_KG_CRED_ED_ARTICLES_FAILED';
export const GET_KG_CRED_ED_ARTICLES_LOADING = 'GET_KG_CRED_ED_ARTICLES_LOADING';

/** PDF SEND EMAIL DOWNLOAD REQUEST **/
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_LOADING = 'SEND_EMAIL_LOADING';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';
