import React from 'react';
import { MDBInput } from 'mdbreact';

class CheckBoxComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checkbox: props.value
		};
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	handleCheckboxChange(event) {
		this.setState({ checkbox: !this.state.checkbox });
		this.props.handleChange(event);
	}

	getValue = value => {
		console.log(value);
	};

	render() {
		return (
			<div>
				<MDBInput
					name={this.props.name}
					id={this.props.id}
					label={this.props.label}
					disabled={this.props.disabled}
					type="checkbox"
					checked={this.state.checkbox}
					getValue={this.getValue}
					value={this.props.name}
					onChange={this.handleCheckboxChange}
					filled={this.props.filled}
				/>
			</div>
		);
	}
}

export default CheckBoxComponent;
