import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const fetchVerifySms = (data) => (dispatch) => {
	dispatch(verifySmsLoading(true));
	return fetch(baseUrl + requestPath('api/verifyOtp'), {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					// we should not be using session storage for otp. should hash it, store in db and compare against that
					sessionStorage.setItem('validSplendiOTP', data.Code);
					sessionStorage.setItem('validSplendiOTPDate', new Date());
					return response;
				} else {
					let error;
					if (response.status === 400) {
						error = new Error(
							'The code is invalid, please enter the correct code or resend a new code'
						);
					} else if (response.status === 500) {
						error = new Error('The OTP entered is incorrect, please enter it again.');
					}
					error.response = response;
					console.log('error from action ', error);
					throw error;
				}
			},
			(error) => {
				throw new Error(error.message);
			}
		)
		.then((response) => response.json())
		.then((verifySms) => dispatch(addverifydSms(verifySms)))
		.catch((error) => dispatch(verifySmsFailed(error.message)));
};

export const verifySmsLoading = () => ({
	type: ActionTypes.VERIFY_SMS_LOADING,
});

export const verifySmsFailed = (errmess) => ({
	type: ActionTypes.VERIFY_SMS_FAILED,
	payload: errmess,
});

export const addverifydSms = (verifySms) => ({
	type: ActionTypes.VERIFY_SMS,
	payload: verifySms,
});
export const clearVerifyState = (clear) => ({
	type: ActionTypes.CLEAR_VERIFY_STATE,
	payload: clear,
});
