import React from 'react';
import { Container, Row, Col } from 'mdbreact';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import { PreviousEnquiries } from './previuos-enquiries';

const EnquiryHistoryContent = (props) => {
	if (props.consumer.isLoading) {
		return <Loading />;
	} else if (props.consumer.errMess) {
		return <ErrorComponent />;
	} else {
		const consumer = props.consumer.consumer;
		return (
			<Container>
				<Row>
					<Col className="col-12 col-lg-6">
						<Row>
							<Col className="col-10 col-md-8 col-lg-8">
								<h4 className="h4-responsive credit-report-header">PREVIOUS ENQUIRIES</h4>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col className="col-12">
						<PreviousEnquiries consumer={consumer.ConsumerEnquiryHistory} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default EnquiryHistoryContent;
