import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { corsOptions } from '../../corsOptions';
import { requestPath } from '../../util/util';

export const fetchDisputeId = () => (dispatch) => {
	dispatch(disputeIdLoading(true));
	return fetch(baseUrl + requestPath('api/disputeId'), {
		method: 'GET',
		...corsOptions().mode,
		headers: {
			'Content-Type': 'application/json',
			...corsOptions().headers,
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((data) => dispatch(getDisputeId(data)))
		.catch((error) => dispatch(disputeIdFailed(error.message)));
};

export const disputeIdLoading = () => ({
	type: ActionTypes.GENERATE_DISPUTE_ID_LOADING,
});

export const disputeIdFailed = (errmess) => ({
	type: ActionTypes.GENERATE_DISPUTE_ID_FAILED,
	payload: errmess,
});

export const getDisputeId = (data) => ({
	type: ActionTypes.GENERATE_DISPUTE_ID,
	payload: data,
});
