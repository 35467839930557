import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { baseUrl } from '../../shared/baseUrl';
import styles from './KredGuruSectionEmailVerify.module.css';

class KredGuruSectionEmailVerify extends Component {
	constructor() {
		super();
		this.state = {
			successfullySent: false,
			errorMessage: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		this.setState({
			successfullySent: false,
			errorMessage: false,
			isLoading: false,
		});
	}

	verifyEmail = async () => {
		this.setState({
			...this.state,
			isLoading: true,
			successfullySent: false,
			errorMessage: false,
		});

		await fetch(`${baseUrl}api/send-verify-email`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: this.props.user.user.userId,
				email: this.props.user.user.email,
			}),
		})
			.then((res) => {
				if (res.status >= 200 && res.status <= 299) {
					this.setState({
						...this.state,
						isLoading: false,
						successfullySent: true,
						errorMessage: false,
					});

					setTimeout(() => {
						this.props.toggleModal();
					}, [3000]);
				} else {
					this.setState({
						successfullySent: false,
						errorMessage: true,
						isLoading: false,
					});
				}
			})
			.catch((err) => {
				this.setState({
					successfullySent: false,
					errorMessage: true,
					isLoading: false,
				});
			});
	};

	render() {
		return (
			<div className={styles.dovsModalContainer}>
				<div onClick={() => this.props.toggleModal()} className={styles.closeDovsIcon}>
					<img src="assets/img/xmark-solid.svg" alt="close icon" width={20} />
				</div>
				<MDBRow className="d-flex align-items-center">
					<div className="speech-bubble pt-3 pl-3 pr-3 pb-1 w-75">
						<p>Please verify your email.</p>

						<ul className={`mt-4 ${styles.listStyle} d-flex flex-column gap-2`}>
							<li>Click on the button below to get an email to verify your email address.</li>
							<li>Open your email inbox and click on the link.</li>
							<li>Your email will then be verified.</li>
						</ul>
					</div>
					<div className="w-25 text-center">
						<img
							src={`/assets/img/kred_guru_+2.svg`}
							className="img-fluid kredGuruImg"
							alt="kred guru"
							width={104}
						/>
					</div>
				</MDBRow>
				<MDBRow className="d-flex justify-content-center">
					<button className={styles.resetButton} onClick={this.verifyEmail}>
						Verify Email
					</button>
				</MDBRow>

				{this.state.isLoading ? (
					<MDBRow className="d-flex justify-content-center mt-3">
						<img
							src={`${baseUrl}assets/img/splendi-loading-spinner.gif`}
							className="loading-image"
							alt=""
							width={70}
						/>
					</MDBRow>
				) : null}

				{this.state.successfullySent ? (
					<MDBRow className="d-flex justify-content-center mt-3">
						<p style={{ color: '#5151f9' }}>
							Verification email want sent! Please check your inbox.
						</p>
					</MDBRow>
				) : null}

				{this.state.errorMessage ? (
					<MDBRow className="d-flex justify-content-center mt-3">
						<p style={{ color: 'red' }}>
							Verification email was not sent! Please contact{' '}
							<a href="mailto:info@splendi.co.za">info@splendi.co.za</a> to get some assistance.
						</p>
					</MDBRow>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
const mapDispatchToProps = () => ({});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(KredGuruSectionEmailVerify)
);
