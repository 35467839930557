import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, TableHead, TableBody, Table } from 'mdbreact';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import { CollapseComponent } from '../../../../components/Collapse/CollapseComponent';
import KredGuruSection from '../../../sections/KredGuru/KredGuruSection';

class AdverseListingContent extends Component {
	constructor(props) {
		super(props);
	}

	renderAdverseListing(type) {
		if (type instanceof Array) {
			const data_collspan = {
				columns: [],
				rows: [],
			};
			for (let [key, value] of Object.entries(type[0])) {
				if (key !== 'DisplayText') {
					data_collspan.columns.push({
						label: value.label,
						field: key,
						sort: 'asc',
					});
				}
			}

			type.forEach((key) => {
				let cols = data_collspan.columns.map((column, index) => {
					if (!key[column.field]) {
						return;
					} else {
						return column.field === 'Amount'
							? `R ${key[column.field].value}`
							: `${key[column.field].value}`;
					}
				});
				data_collspan.rows.push(cols);
			});
			let bodyContent = (
				<Table className="mw-100 mh-100 text-nowrap" borderless responsive>
					<TableHead
						columns={data_collspan.columns}
						data-toggle="collapse"
						data-target="#accordion"
						className="clickable"
					/>
					<TableBody rows={data_collspan.rows} id="accordion" className="collapse" />
				</Table>
			);
			let accordionContent = [
				{
					id: 'adverseListing',
					headerContent: type[0].DisplayText
						? type[0].DisplayText.value
						: 'Consumer Adverse Information',
					bodyContent: bodyContent,
				},
			];
			return (
				<Card className="mt-3">
					<CardBody>
						<CollapseComponent accordionContent={accordionContent} />
					</CardBody>
				</Card>
			);
		} else {
			return;
		}
	}

	render() {
		const { consumer } = this.props;
		if (consumer.isLoading) {
			return <Loading />;
		} else if (consumer.errMess) {
			return <ErrorComponent />;
		} else {
			let kredImage = 'kred_guru_+2.svg';
			let kredContent =
				'Well done! You have no adverse records. Your credit reputation is looking solid, and your\n' +
				'payment history is clear of any harmful listings.';
			if (
				consumer.consumer.ConsumerAdverseInformation instanceof Array ||
				consumer.consumer.ConsumerAdverseInformation instanceof Array ||
				consumer.consumer.ConsumerJudgement instanceof Array ||
				consumer.consumer.ConsumerAdminOrder instanceof Array ||
				consumer.consumer.ConsumerJudgement instanceof Array ||
				consumer.consumer.ConsumerSequestration instanceof Array ||
				consumer.consumer.ConsumerRehabilitationOrder instanceof Array ||
				consumer.consumer.ConsumerDefaultAlert instanceof Array
			) {
				kredImage = 'kred_guru_-3.svg';
				kredContent = `Be aware. Negative listings such as judgements and defaults can harm your credit score.
Paying off all debt will empower your credit rating. Once a default has been paid off -
contact Splendi to mark it as Paid Up.`;
			} else if (
				Number(consumer.consumer.ConsumerCPANLRDebtSummary.NoOfAccountInBadStanding.value) > 0
			) {
				kredImage = 'kred_guru_-2.svg';
				kredContent =
					'Settling your accounts in bad standing - is a good idea. The earlier, the better for the sake of your\n' +
					' credit reputation. Check out Splendi’s Cred Ed resources for tips to boost your Cred Rep.';
			}
			return (
				<Container>
					<Row>
						<Col className="col-12 col-md-6">
							<h4 className="h4-responsive credit-report-header">ADVERSE RECORDS</h4>
						</Col>
						<Col className="col-12 col-md-6">
							<KredGuruSection id="OverviewKG" kredGuruImage={kredImage} content={kredContent} />
						</Col>
					</Row>
					<Row>
						<Col className="col-12">
							{this.renderAdverseListing(consumer.consumer.ConsumerAdverseInformation)}
							{this.renderAdverseListing(consumer.consumer.ConsumerJudgement)}
							{this.renderAdverseListing(consumer.consumer.ConsumerAdminOrder)}
							{this.renderAdverseListing(consumer.consumer.ConsumerSequestration)}
							{this.renderAdverseListing(consumer.consumer.ConsumerRehabilitationOrder)}
							{this.renderAdverseListing(consumer.consumer.ConsumerDefaultAlert)}
						</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default AdverseListingContent;
