import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import InputComponent from '../../../components/Input/InputComponent';
import './dispute.css';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';

class PersonalDetails extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	handleSubmit = async (e) => {
		e.preventDefault();
		await this.props.submitPersonalInfo(e);
	};
	saveAndContinue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};

	render() {
		const { isFormValid, addDispute, handleChange, personalInfo, disputeId } = this.props;
		let showBtn = (
			<MDBBtn
				color=" white-text"
				size="sm"
				className="btn-custom-primary mt-5"
				onClick={this.handleSubmit}
				// disabled={!isFormValid}
			>
				Save
			</MDBBtn>
		);
		if (addDispute.isLoading || disputeId.isLoading) {
			return <Loading />;
		} else if (addDispute.errMess) {
			return (
				<div className="w-75 mx-auto">
					<ErrorComponent />;
				</div>
			);
		} else {
			let successMsg = '';
			if (
				typeof addDispute.addDispute.response !== 'undefined' &&
				addDispute.addDispute.response.disputeId !== undefined
			) {
				successMsg = addDispute.addDispute.response.message + ', click the next button to continue';
				showBtn = (
					<MDBBtn
						color=" white-text"
						size="sm"
						className="btn-custom-primary font-weight-bold "
						onClick={this.saveAndContinue}
					>
						Next
					</MDBBtn>
				);
			}
			return (
				<MDBContainer className="personalInfo">
					<MDBRow>
						<MDBCol className="col-12 col-md-12 mb-5">
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold my-2 mb-5">Personal Information</p>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold my-2 text-success">{successMsg}</p>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="4">
									<InputComponent
										name="fullName"
										id="fullName"
										label="Full Name"
										hint="Joseph Smith"
										readOnly={true}
										disabled={false}
										value={personalInfo.fullName}
										type="text"
										step="100"
										minLength="4"
										maxLength="30"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="surname"
										id="surname"
										label="Surname"
										hint="eg. Smith"
										readOnly={true}
										disabled={false}
										value={personalInfo.surname}
										type="text"
										step="100"
										required={true}
										minLength="3"
										maxLength="30"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="idNumber"
										id="idNumber"
										label={personalInfo.idNumber ? `Id Number` : `Passport Number`}
										hint="eg. 9812025493088"
										readOnly={true}
										disabled={false}
										value={
											personalInfo.idNumber ? personalInfo.idNumber : personalInfo.passportNumber
										}
										type="text"
										step="100"
										minLength="13"
										maxLength="13"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="4">
									<InputComponent
										name="cellNumber"
										id="cellNumber"
										label="Mobile Number"
										hint="eg. 082312321"
										readOnly={true}
										disabled={false}
										value={personalInfo.cellNumber}
										type="text"
										step="100"
										required={true}
										minLength="10"
										maxLength="10"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="workNumber"
										id="workNumber"
										label="Work Number"
										hint="eg. 0782312367"
										disabled={false}
										value={personalInfo.workNumber}
										type="text"
										step="1"
										minLength="10"
										maxLength="10"
										required={false}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="homeNumber"
										id="homeNumber"
										label="Home Number"
										hint="eg. 0218237273"
										disabled={false}
										value={personalInfo.homeNumber}
										type="text"
										step="100"
										required={false}
										minLength="10"
										maxLength="20"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								{/* <MDBCol md="4">
									<InputComponent
										name="faxNumber"
										id="faxNumber"
										label="Fax Number"
										hint="eg. 0112328238"
										disabled={false}
										value={personalInfo.faxNumber}
										type="text"
										step="100"
										minLength="10"
										maxLength="10"
										required={false}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol> */}
								<MDBCol md="4">
									<InputComponent
										name="emailAddress"
										id="emailAddress"
										label="Email Address"
										hint="eg. example@gmail.com"
										readOnly={true}
										disabled={false}
										value={personalInfo.emailAddress}
										type="email"
										step="100"
										required={true}
										minLength="2"
										maxLength="25"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								{/* <MDBCol md="4">
									<InputComponent
										name="postalAddress"
										id="postalAddress"
										label="Postal Address"
										hint="eg. 234 Unit 8"
										disabled={false}
										value={personalInfo.postalAddress}
										type="text"
										step="100"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol> */}
							</MDBRow>
							<MDBRow>
								<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12">
									{showBtn}
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			);
		}
	}
}

export default PersonalDetails;
