import React from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	CardTitle,
	CardText,
	CardBody,
	Table,
	TableBody,
} from 'mdbreact';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import RenderPersonalDetails from './PersonalDetails/PersonalDetailsSection';
import RenderEmploymentHistory from './EmploymentHistory/EmploymentHistorySection';
import { FadeTransform } from 'react-animation-components';
import { CollapseComponent } from '../../../../components/Collapse/CollapseComponent';
import PopoverComponent from '../../../../components/Popover/PopoverComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import './PersonalInformationContent.css';
let quotesData = require('../../../../shared/data');

function RenderAddress({ addressHistory }) {
	if (addressHistory instanceof Array) {
		const addressHistoryRows = addressHistory.map((key, index) => {
			if (index !== 0) {
				return (
					<tr key={index}>
						<th>{key.AddressType.value}</th>
						<td>{key.Address.value}</td>
					</tr>
				);
			}
			return <tr key={index} />;
		});

		const currentAddress = addressHistory[0];
		const bodyContent = (
			<Table borderless striped responsive className="table-sm">
				<TableBody>{addressHistoryRows}</TableBody>
			</Table>
		);
		const addressHistoryContent = [
			{
				id: 'addressHistory',
				headerContent: addressHistory[0].DisplayText.value,
				bodyContent: bodyContent,
			},
		];

		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%',
				}}
			>
				<Card>
					<CardBody>
						<CardTitle className="sub-title text-color-purple-2">ADDRESS</CardTitle>
						<Row>
							<Col className="col-12">
								<div className="table-responsive-sm">
									<Table borderless className="table-sm">
										<TableBody>
											<tr>
												<td className="col-12 col-md-7">
													<strong>{currentAddress.AddressType.value}:</strong>{' '}
													{currentAddress.Address.value}
												</td>
											</tr>
											<tr>
												<td className="col-12 col-md-5 mw-100 mh-100 text-nowrap">
													<strong>{currentAddress.LastUpdatedDate.label}:</strong>{' '}
													{currentAddress.LastUpdatedDate.value}
												</td>
											</tr>
										</TableBody>
									</Table>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="col-12">
								<CollapseComponent accordionContent={addressHistoryContent} />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</FadeTransform>
		);
	} else {
		return null;
	}
}
function RenderEmailAddresses({ emailAddresses }) {
	if (emailAddresses instanceof Array) {
		const emailsAddressRows = emailAddresses.map((key, index) => {
			if (index !== 0) {
				return (
					<tr key={index}>
						<th>{key.EmailAddress.label}</th>
						<td>{key.EmailAddress.value}</td>
					</tr>
				);
			}
		});

		const currentEmailsAddress = emailAddresses[0];

		const bodyContent = (
			<div>
				<Row>
					<Col className="col-12 mt-2">
						<CardText className="mb-3 text-center">
							A list of email addresses over the last 3 years that are linked to your credit record.
						</CardText>
					</Col>
				</Row>
				<Row>
					<Col className="col-12">
						<Card>
							<CardBody>
								<Table borderless striped responsive className="table-sm">
									<TableBody>{emailsAddressRows}</TableBody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
		const emailAddressesContent = [
			{
				id: 'emailAddresses',
				headerContent: emailAddresses[0].DisplayText.value,
				bodyContent: bodyContent,
			},
		];

		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%',
				}}
			>
				<Card className="mt-3">
					<CardBody>
						<CardTitle className="sub-title text-color-purple-2">EMAIL ADDRESS</CardTitle>
						<Row>
							<Col className="col-12">
								<div className="table-responsive-sm">
									<Table borderless className="table-sm">
										<TableBody>
											<tr>
												<td>
													<strong>{currentEmailsAddress.EmailAddress.label}:</strong>{' '}
													{currentEmailsAddress.EmailAddress.value}
												</td>
											</tr>
										</TableBody>
									</Table>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="col-12">
								<CollapseComponent accordionContent={emailAddressesContent} />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</FadeTransform>
		);
	} else {
		return null;
	}
}
function RenderContactNumbers({ contactNumberDetails }) {
	if (contactNumberDetails instanceof Array) {
		const contactNumbersRows = contactNumberDetails.map((key, index) => {
			if (index !== 0) {
				return (
					<tr key={index}>
						<th>{key.TelephoneType.value}</th>
						<td>{key.TelephoneNo.value}</td>
					</tr>
				);
			}
		});

		const currentContactNumber = contactNumberDetails[0];
		const bodyContent = (
			<div>
				<Row>
					<Col className="col-12 mt-2">
						<CardText className="mb-3 text-center">
							A list of telephone numbers over the last 3 years that are linked to your credit
							record.
						</CardText>
					</Col>
				</Row>
				<Row>
					<Col className="col-12">
						<Card>
							<CardBody>
								<Table borderless striped responsive className="table-sm">
									<TableBody>{contactNumbersRows}</TableBody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
		const contactNumberDetailsContent = [
			{
				id: 'contactNumberDetails',
				headerContent: contactNumberDetails[0].DisplayText.value,
				bodyContent: bodyContent,
			},
		];

		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%',
				}}
			>
				<Card className="mt-3">
					<CardBody>
						<CardTitle className="sub-title text-color-purple-2">CONTACT NUMBER</CardTitle>
						<Row>
							<Col className="col-12">
								<div className="table-responsive-sm">
									<Table borderless className="table-sm">
										<TableBody>
											<tr>
												<td className="col-12 col-md-7">
													<strong>{currentContactNumber.TelephoneType.value}:</strong>{' '}
													{currentContactNumber.TelephoneNo.value}
												</td>
											</tr>
											<tr>
												<td className="col-12 col-md-5 mw-100 mh-100 text-nowrap">
													<strong>{currentContactNumber.LastUpdatedDate.label}:</strong>{' '}
													{currentContactNumber.LastUpdatedDate.value}
												</td>
											</tr>
										</TableBody>
									</Table>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className="col-12">
								<CollapseComponent accordionContent={contactNumberDetailsContent} />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</FadeTransform>
		);
	} else {
		return null;
	}
}

const PersonalInformationContent = (props) => {
	if (props.consumer.isLoading) {
		return <Loading />;
	} else if (props.consumer.errMess) {
		return <ErrorComponent />;
	} else {
		const consumer = props.consumer.consumer;
		return (
			<Container>
				<Row>
					<Col className="col-12 col-md-6 pl-3">
						<Row>
							<Col className="col-12 col-md-9c creditReportTitle">
								<h4 className="h4-responsive credit-report-header">YOUR PERSONAL INFORMATION</h4>
								{/* <div>
									<PopoverComponent content={quotesData.D12} id={'D12'} popoverImgType={'dark'} />
								</div> */}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col className="col-12 col-md-5 pb-3">
						<RenderPersonalDetails
							consumerDetail={consumer.ConsumerDetail}
							updatePersonDetails={consumer.updatePersonDetails}
						/>
					</Col>
					<Col className="col-12 col-md-7">
						<RenderAddress addressHistory={consumer.ConsumerAddressHistory} />
						<RenderEmailAddresses emailAddresses={consumer.ConsumerEmailHistory} />
						<RenderContactNumbers contactNumberDetails={consumer.ConsumerTelephoneHistory} />
						<RenderEmploymentHistory
							employmentHistory={consumer.ConsumerEmploymentHistory}
							updateEmployment={consumer.updateEmployment}
						/>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default PersonalInformationContent;

export { RenderAddress, RenderEmailAddresses, RenderContactNumbers };
