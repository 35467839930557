import React, { Component } from 'react';
import { CardTitle } from 'mdbreact';

class TermsAndConditions extends Component {
	render() {
		return (
			<div>
				<CardTitle>
					<strong>Debit order terms:</strong>
				</CardTitle>
				<ul>
					<li>
						You receive cover from the start of your first successful debit
						order payment.
					</li>
					<li>
						If you default on your subscription fee of R24,99 per month your
						cover will only resume once you have sent proof of payment to{' '}
						<a href="mailto: info@splendi.co.za">info@splendi.co.za</a>
					</li>
				</ul>
				<CardTitle style={{ color: '#3f51b5' }}>
					<strong>AA ROADSIDE ASSISTANCE</strong>
				</CardTitle>
				<CardTitle>
					<strong>AA Out-Of-Fuel Rescue and Battery Services</strong>
				</CardTitle>
				<p>
					This service is available 24/7. The primary objective of the AA
					Out-Of-Fuel Rescue and Battery Services fleet is to effect on-the-spot
					mobility at the side of the road. This service is available in urban
					areas up to 100km. Thereafter, any additional km’s will be for the
					member’s account. The service offering includes:
				</p>
				<ul>
					<li>Flat tyre change</li>
					<li>
						Out of fuel - Provision of fuel to a maximum of 10 litres (Member to
						pay for fuel)
					</li>
					<li>Jump-start</li>
					<li>Mobile battery sales (battery for members account)</li>
					<li>Key lockout service</li>
					<li>
						Minor roadside running repairs includes electrical, coil,
						immobilizer, battery related breakdowns where possible.
					</li>
				</ul>
				<CardTitle>
					<strong> Locksmith Services:</strong>
				</CardTitle>
				<p>
					This service is available 24/7. In the event that an AA Patrol Service
					is unable to open a vehicle to retrieve the keys, or should the
					incident have occurred outside of an AA Patrol Service operating area,
					the AA will dispatch an AA accredited locksmith service provider to
					the incident scene to open the vehicle. 
				</p>
				<p>
					The service is limited to 100km, thereafter the member will be
					required to pay the additional km’s. Maximum cover per call-out is
					R500 excl VAT.
				</p>
				<CardTitle>
					<strong>AA Mechanical and Electrical Breakdown Tow</strong>
				</CardTitle>
				<p>
					In the event of a mechanical or electrical breakdown and requires a
					tow, the AA will arrange for the vehicle to be towed to the nearest
					accredited/preferred dealer/repairer/yard from the breakdown scene.
					The member may also choose their destination within the 100km service
					radius. The service is limited to 100km, thereafter the member will be
					required to pay the additional km’s.
				</p>
				<CardTitle>
					<strong>AA Accident Service</strong>
				</CardTitle>
				<p>
					In the event of an accident, the AA will arrange for the vehicle to be
					towed from the accident scene to the destination indicated by the
					Insurance Company or by the member. Service is limited to 100km,
					thereafter, member to pay per km rate. This benefit is 
					<strong> on a pay on use basis,</strong>
					i.e. if the client requires an accident tow, the client will have to
					pay an additional amount for this service.
				</p>
				<CardTitle>
					<strong>Stand By You</strong>
				</CardTitle>
				<p>
					The objective of the roadside security response service is to provide
					security to customers who require roadside assistance. This will
					consist of an armed Security response officer at the incident scene,
					to secure the scene and wait with the customer until the AA technical
					service arrives.
					<br />
					The service is available in the Major Metropolitan areas of South
					Africa.
				</p>
				<CardTitle>
					<strong>Taxi after tow</strong>
				</CardTitle>
				<p>
					AA Taxi-After-Tow service provides the customer with transport in the
					event of an accident or breakdown incident resulting in their vehicle
					requiring a tow. The service is dispatched concurrently with an AA Tow
					Truck to respond timeously to a breakdown scene.
					<br />
					The taxi service will safely transport the driver and the vehicle
					occupants to a destination of their choosing, whether it be work,
					home, a place of safety or a doctor in the event of an accident. The
					service is limited to R200 per incident. There after the driver will
					be charged a rate per km.
					<br />
					The AA dispatches the Uber services, no need for the customer to
					download the Uber app or voucher system.
				</p>
			</div>
		);
	}
}
export default TermsAndConditions;
