import React, { Component } from 'react';
import './Vehicle.css';
import { Redirect } from 'react-router-dom';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { connect } from 'react-redux';
import { createOfferSelect } from '../../../../redux/launchOffers/actionCreators/selectedOffer';

const PRODUCT = {
	CAMPAIGN_ID: 7,
	// CAMPAIGN_ID: 121,
	CAMPAIGN_NAME: 'Splendi Boost',
};

class BoostOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offerCreated: false,
		};

		this.createOfferSelect = this.createOfferSelect.bind(this);
	}

	async createOfferSelect() {
		const offerData = {
			campaignReference: PRODUCT.CAMPAIGN_ID,
			sourceCampaign: PRODUCT.CAMPAIGN_NAME,
		};

		try {
			await this.props.apiOfferCreateSelect(offerData);
			this.setState({ offerCreated: true });
		} catch (err) {
			console.log('Error selecting the offer', err);
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.selectedOffer && this.state.offerCreated) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}

			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			return (
				<div>
					<div>
						<div className="card-big">
							<div className="card-big-header">
								<div className="card-big-header-title-container">
									<div className="rocket-img" />
									<div className="card-big-header-title">
										<div className="heading">SPLENDI BOOST</div>
										<div className="text10">
											Specific steps that can help you improve your credit situation will depend on
											your unique circumstances.
										</div>
									</div>
								</div>
								<div className="card-big-header-button">
									<div className="btn-green" onClick={this.createOfferSelect}>
										Get Help
									</div>
								</div>
							</div>

							<div className="card-small">
								<div className="boost" />
								<div className="card-small-body">
									<div className="heading2">SPLENDI BOOST</div>
									<div className="heading3"> Get this cover:</div>
									<div className="heading4">
										{' '}
										Speak to a consultant on how to improve your overall credit situation and
										disposable income. This consultation is free.
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferCreateSelect: (data) => dispatch(createOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BoostOffer);
