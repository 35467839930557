import * as ActionTypes from '../../ActionTypes';

export const SendSms = (
	state = {
		isLoading: true,
		errMess: null,
		sendSms: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.SEND_SMS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				sendSms: action.payload
			};
		case ActionTypes.SEND_SMS_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				sendSms: []
			};
		case ActionTypes.SEND_SMS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				sendSms: []
			};
		default:
			return state;
	}
};
