import React, { Component } from 'react';
import { Card, CardBody, MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { disputeFileUpload } from '../../../redux/dispute/actionCreators/file-uploads';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import DocumentsBasedOnDisputeNature from './documentBasedOnDisputeNature';

class SupportingDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idCopy: null,
			proofOfAddress: null,
			proofOfPayment: null,
			courtOrder: null,
			affidavit: null,
			caseNumber: null,
			bankStatement: null,
			payslip: null,
			other: null,
			sampleOfSignature: null,
			paidUpLetter: null,
			adminOrderLetter: null,
			rehabilitation: null,
			certified17WForm: null,
			certified19WForm: null,
			errorMsg: '',
		};
	}

	handleSixSample = (file) => {
		this.setState({
			sampleOfSignature: file[0],
		});
	};
	handlePaidUpLetter = (file) => {
		this.setState({
			paidUpLetter: file[0],
		});
	};
	handleAdminOrderLetter = (file) => {
		this.setState({
			adminOrderLetter: file[0],
		});
	};
	handleRehabilitation = (file) => {
		this.setState({
			rehabilitation: file[0],
		});
	};
	handleIDUpload = (file) => {
		this.setState({
			idCopy: file[0],
		});
	};
	handleProofOfAddress = (file) => {
		this.setState({
			proofOfAddress: file[0],
		});
	};
	handleProofOfPayment = (file) => {
		this.setState({
			proofOfPayment: file[0],
		});
	};
	handleCourtOrder = (file) => {
		this.setState({
			courtOrder: file[0],
		});
	};
	handleAffidavit = (file) => {
		this.setState({
			affidavit: file[0],
		});
	};
	handle10XSample = (file) => {
		this.setState({
			sampleOfSignature: file[0],
		});
	};
	handleBankStatement = (file) => {
		this.setState({
			bankStatement: file[0],
		});
	};
	handlePayslip = (file) => {
		this.setState({
			payslip: file[0],
		});
	};
	handleCaseNumber = (file) => {
		this.setState({
			caseNumber: file[0],
		});
	};
	handleCertified19WForm = (file) => {
		this.setState({
			certified19WForm: file[0],
		});
	};
	handleCertified17WForm = (file) => {
		this.setState({
			certified17WForm: file[0],
		});
	};
	validateFiles = async (files) => {
		const {
			account,
			judgement,
			adminOrder,
			sequestration,
			accountPaidFull,
			accountIsClosed,
			notMyAccount,
			paidMyAccount,
			noteOfAdverseListing,
			paidJudgement,
			notMyJudgement,
			judgmentIsIncorrect,
			notMyAdminOrder,
			adminIsPaid,
			adminIsRescind,
			notMySequestration,
			rehabilitated,
			accountIsPrescribed,
			withdrewDebtReview,
			debtReviewIsOver,
			debtReview,
		} = this.props.natureOfDispute;
		const filteredFiles = files.filter((file) => file !== null);

		let fileSize = 0;
		filteredFiles.forEach((ob) => {
			fileSize = fileSize + ob.size;
		});

		if (fileSize <= 35000000) {
			if (
				(filteredFiles.length === 3 &&
					((account && paidMyAccount) || accountIsClosed || accountPaidFull)) ||
				((adminOrder && adminIsPaid) || adminIsRescind) ||
				(sequestration && rehabilitated)
			) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (
				(judgement && paidJudgement) ||
				notMyJudgement ||
				judgmentIsIncorrect ||
				(noteOfAdverseListing && filteredFiles.length === 4)
			) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (
				(filteredFiles.length === 2 && (adminOrder && notMyAdminOrder)) ||
				(filteredFiles.length === 2 && (sequestration && notMySequestration)) ||
				(filteredFiles.length === 2 && accountIsPrescribed)
			) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (account && notMyAccount && filteredFiles.length === 4) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (filteredFiles.length === 3 && debtReview && withdrewDebtReview) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (filteredFiles.length === 5 && debtReview && debtReviewIsOver) {
				await this.uploadFiles(filteredFiles);
				this.setState({
					errorMsg: '',
				});
			} else if (filteredFiles.length === 0) {
				this.setState({
					errorMsg: 'Please upload all the files below',
				});
			}
		} else {
			this.setState({
				errorMsg: 'All files combined must be less than 35MB',
			});
		}
	};
	handleSubmit = async (e) => {
		e.preventDefault();

		const {
			sampleOfSignature,
			affidavit,
			courtOrder,
			proofOfPayment,
			proofOfAddress,
			idCopy,
			payslip,
			caseNumber,
			bankStatement,
			paidUpLetter,
			adminOrderLetter,
			rehabilitation,
			certified17WForm,
			certified19WForm,
		} = this.state;

		const files = [
			sampleOfSignature,
			affidavit,
			courtOrder,
			proofOfAddress,
			proofOfPayment,
			idCopy,
			payslip,
			caseNumber,
			bankStatement,
			paidUpLetter,
			adminOrderLetter,
			rehabilitation,
			certified17WForm,
			certified19WForm,
		];

		await this.validateFiles(files);
	};
	uploadFiles = async (files) => {
		const BUCKET_NAME = window._env_.BUCKET_NAME;
		const FILES_DIRECTORY = window._env_.FILES_DIRECTORY;
		const formData = new FormData();
		const { userId, disputeId } = this.props;
		const tags = {
			disputeId: disputeId,
			userId: userId,
		};

		for (let i = 0; i < files.length; i++) {
			formData.append(`file`, files[i], files[i]['name']);
		}

		formData.append('bucket', BUCKET_NAME);
		formData.append('directory', FILES_DIRECTORY);
		formData.append('tags', JSON.stringify(tags));

		this.props.disputeFileUpload(formData);
	};

	saveAndContinue = async (e) => {
		e.preventDefault();
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { fileUpload, natureOfDispute } = this.props;
		let showBtn = (
			<MDBBtn
				color=" white-text"
				size="sm"
				type="submit"
				onClick={this.handleSubmit}
				className="btn-custom-primary"
			>
				upload files
			</MDBBtn>
		);

		if (fileUpload.isLoading) {
			return <Loading />;
		} else if (fileUpload.errMess) {
			return <ErrorComponent />;
		} else {
			let successMsg = '';
			if (
				typeof fileUpload.fileUpload.response !== 'undefined' &&
				fileUpload.fileUpload.response.isUploaded
			) {
				successMsg = 'Files are successful uploaded' + ', click the next button to continue';
				showBtn = (
					<MDBBtn
						color=" white-text"
						size="sm"
						className="btn-custom-primary font-weight-bold "
						onClick={this.saveAndContinue}
					>
						Next
					</MDBBtn>
				);
			}

			return (
				<form enctype="multipart/form-data">
					<MDBContainer>
						<MDBRow>
							<MDBCol md={12}>
								<MDBRow>
									<MDBCol>
										<p className="text-center font-weight-bold my-2">
											Upload the following supporting documents{' '}
										</p>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>
										<p className="text-center font-weight-bold my-2 text-success">{successMsg}</p>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>
										<p className="text-center font-weight-bold my-2 text-danger">
											{this.state.errorMsg}
										</p>
									</MDBCol>
								</MDBRow>
								<DocumentsBasedOnDisputeNature
									natureOfDispute={natureOfDispute}
									handleProofOfPayment={this.handleProofOfPayment}
									handleIDUpload={this.handleIDUpload}
									handlePayslip={this.handlePayslip}
									handleAffidavit={this.handleAffidavit}
									handleProofOfAddress={this.handleProofOfAddress}
									handle10XSample={this.handle10XSample}
									handleAdminOrderLetter={this.handleAdminOrderLetter}
									handleSixSample={this.handleSixSample}
									handlePaidUpLetter={this.handlePaidUpLetter}
									handleRehabilitation={this.handleRehabilitation}
									handleCaseNumber={this.handleCaseNumber}
									handleBankStatement={this.handleBankStatement}
									handleCertified19WForm={this.handleCertified19WForm}
									handleCertified17WForm={this.handleCertified17WForm}
								/>
								<MDBRow>
									<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12 mt-5 mb-5">
										<MDBBtn
											color=" white-text"
											size="sm"
											className="btn-custom-secondary mr-md-5"
											onClick={this.back}
										>
											previous
										</MDBBtn>
										{showBtn}
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</form>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	disputeFileUpload: (data) => dispatch(disputeFileUpload(data)),
});

const mapStateToProps = (state) => {
	return {
		fileUpload: state.fileUpload,
		environmentV: state.environmentV,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupportingDocuments);
