import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Success.module.css';

const Success = (props) => {
	const appointmentData = props.location.state;
	console.log('Success render: ', appointmentData);
	return (
		<>
			<div className={`card-big ${styles.cardSizeSmall}`}>
				<div className={styles.successMessageContainer}>
					<div className={styles.successGreyBox}>
						<div className={styles.successText}>
							Your request has been successfully submitted. Please expect a call from our partner.
						</div>
						<div className={styles.kgText}>
							<i>
								<strong>KG – Splendi’s Kred Guru</strong>
							</i>
						</div>
					</div>
					<div className={styles.kgSuccess} />
				</div>
				<div className={styles.kgsuccessButtons}>
					<Link to="/offers_and_services">
						<div className={styles.kgsuccessClose}>Close</div>
					</Link>
				</div>
			</div>
		</>
	);
};

export default Success;
