import React, { Component } from 'react';
import { MDBTable, MDBTableBody, MDBBtn, MDBTableHead } from 'mdbreact';
import styles from './Subscription.module.css';

class SubscriptionTable extends Component {
	render() {
		let { vehicleDetails, handleDelete } = this.props;
		return (
			<div className="mt-3">
				<h4 className={styles.newSubscriptionTitle}>List of your vehicles</h4>
				<MDBTable striped bordered scrollY className="mt-2">
					<MDBTableHead>
						<tr>
							<th>Make</th>
							<th>Colour</th>
							<th>Model</th>
							<th>Year</th>
							<th>Vehicle Reg No</th>
							<th style={{ width: '30px' }}>Action</th>
						</tr>
					</MDBTableHead>
					<MDBTableBody>
						{vehicleDetails.map((details, key) => {
							return (
								<tr key={key}>
									<td>{details.Make}</td>
									<td>{details.Colour}</td>
									<td>{details.Model}</td>
									<td>{details.Year}</td>
									<td>{details.VehicleRegistration}</td>
									<td style={{ textAlign: 'right' }}>
										<MDBBtn
											color=" white-text"
											className="pt-2 rounded red"
											size="sm"
											onClick={() => handleDelete(key)}
										>
											Remove
										</MDBBtn>
									</td>
								</tr>
							);
						})}
					</MDBTableBody>
				</MDBTable>
			</div>
		);
	}
}

export default SubscriptionTable;
