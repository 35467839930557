import * as ActionTypes from '../../ActionTypes';

export const UserSubscriptions = (
	state = {
		isLoading: true,
		errMess: null,
		subscription: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.SUBSCRIPTIONS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				subscription: action.payload
			};
		case ActionTypes.SUBSCRIPTIONS_LOADING:
			return { ...state, isLoading: true, errMess: null, subscription: [] };
		case ActionTypes.SUBSCRIPTIONS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				subscription: []
			};
		default:
			return state;
	}
};
