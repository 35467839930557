import React, { Component } from 'react';
import { Card, CardBody, MDBCol, MDBRow } from 'mdbreact';
import { Loading } from '../../../../../components/Loading/LoadingComponent';
import { baseUrl } from '../../../../../shared/baseUrl';
import { ErrorComponent } from '../../../../../components/Error/ErrorComponent';

class ResultComponent extends Component {
	render() {
		if (this.props.bayPortAcknowledgment.isLoading) {
			return <Loading />;
		} else if (this.props.bayPortAcknowledgment.errMess) {
			return <ErrorComponent />;
		} else {
			const acknowledgement = this.props.bayPortAcknowledgment
				.bayPortAcknowledgment;
			return (
				<Card>
					<CardBody>
						<MDBRow>
							<MDBCol className="col-9">
								{acknowledgement.errorCodeField && (
									<p className="red-text">
										{' '}
										{acknowledgement.errorMessageField}
									</p>
								)}
								{acknowledgement.resultField.length > 0 && (
									<p className="green-text"> {acknowledgement.resultField}</p>
								)}
							</MDBCol>
							<MDBCol className="col-3 pb-3">
								<img
									src={`${baseUrl}assets/img/Bayport-logo-small.png`}
									alt=""
									width="100%"
								/>
							</MDBCol>
						</MDBRow>
					</CardBody>
				</Card>
			);
		}
	}
}

export default ResultComponent;
