import * as ActionTypes from '../../ActionTypes';

export const FileUpload = (
	state = {
		isLoading: false,
		errMess: null,
		fileUpload: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.UPLOAD_DISPUTE_FILE:
			return {
				...state,
				isLoading: false,
				errMess: null,
				fileUpload: action.payload
			};
		case ActionTypes.UPLOAD_DISPUTE_FILE_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				fileUpload: []
			};
		case ActionTypes.UPLOAD_DISPUTE_FILE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				fileUpload: []
			};
		default:
			return state;
	}
};
