import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faInstagram,
	faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import MobileStoreButton from 'react-mobile-store-button';

class SocialMedia extends Component {
	render() {
		const mobileStoreURL = 'http://bit.ly/33gHGJq';
		return (
			<>
				<div className="d-flex flex-row">
					<a
						href="https://www.facebook.com/Splendicredit/?view_public_for=112462846827458"
						className="facebook social ps-0 pe-2"
						target="_blank"
					>
						<FontAwesomeIcon icon={faFacebook} size="2x" />
					</a>
					<a
						href="https://www.linkedin.com/company/30711607"
						className="linkedin social px-2"
						target="_blank"
					>
						<FontAwesomeIcon icon={faLinkedin} size="2x" />
					</a>
					<a
						href="https://www.instagram.com/splendicredit/"
						className="instagram social px-2"
						target="_blank"
					>
						<FontAwesomeIcon icon={faInstagram} size="2x" />
					</a>
				</div>

				<div className="d-flex flex-column mt-3">
					<MobileStoreButton
						store="ios"
						className="mobileStoreButton"
						url={mobileStoreURL}
					/>
					<MobileStoreButton
						store="android"
						className="mobileStoreButton"
						url={mobileStoreURL}
					/>
				</div>
			</>
		);
	}
}

export default SocialMedia;
