import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import CredEdArticle from './CredEdArticle';
import CovID19Article from '../../../sections/CredED/CredEDResources/CovID19Article';
import { baseUrl } from '../../../../shared/baseUrl';
import { Link } from 'react-router-dom';

export default props => {
	const article =
		props.articleType === 'CovidArticle' ? (
			<CovID19Article isMobile={true} />
		) : (
			<CredEdArticle
				pathname={props.location}
				credEdCmsArticles={props.articles}
				isMobile={true}
			/>
		);

	return (
		<MDBCard>
			<MDBCardBody>
				<MDBRow>
					<MDBCol className="col-auto col-md-1 d-flex justify-content-center align-self-center">
						<MDBRow>
							<MDBCol className="col-12 col-md-11 back-button-container">
								<Link to="/cred-ed/resources">
									<img
										src={`${baseUrl}assets/img/previous_arrow_active.svg`}
										alt=""
										className="back-button"
									/>
								</Link>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol className="col-12 col-md-11">{article}</MDBCol>
				</MDBRow>
			</MDBCardBody>
		</MDBCard>
	);
};
