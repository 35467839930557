import React, { Component } from 'react';
import { FadeTransform } from 'react-animation-components';
import { Card, CardBody, MDBBtn, CardTitle, MDBCol, MDBRow, MDBContainer } from 'mdbreact';
import { FormWithConstraints } from 'react-form-with-constraints';
import InputComponent from '../../../components/Input/InputComponent';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { Redirect } from 'react-router-dom';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import { connect } from 'react-redux';
import './SmsValidation.css';

const formData = require('./Sms');

class SmsValidation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: formData,
			isFormValid: false,
			invalidFormMessage: '',
			isDisabled: true,
			smsValidationKGCount: 0,
			validateCode: this.props.validateCode,
			otpVerified: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateOtpVerified = this.updateOtpVerified.bind(this);
	}

	async componentDidMount() {
		const localStorageValue = sessionStorage.getItem('validSplendiOTP');
		const localStorageValueDate = sessionStorage.getItem('validSplendiOTPDate');

		if (localStorageValue && localStorageValueDate) {
			const storedDate = new Date(localStorageValueDate);
			const currentDate = new Date();
			const timeDifferenceMinutes = (currentDate - storedDate) / (1000 * 60);

			if (timeDifferenceMinutes < 5) {
				this.setState({
					otpVerified: true,
				});
			} else {
				sessionStorage.removeItem('validSplendiOTP');
				sessionStorage.removeItem('validSplendiOTPDate');
			}
		}
	}

	async handleChange(event) {
		if (event.target) {
			event.preventDefault();
			let inputName = event.target.name;
			let inputValue = event.target.value;
			console.log('name ', inputName);
			console.log('name ', inputValue);
			const fields = await this.form.validateFields(event.target);
			const fieldIsValid = fields.every((field) => field.isValid);
			if (inputValue.length === 6) {
				this.setState({
					isDisabled: false,
				});
			}
			if (fieldIsValid) {
				console.log(`Field '${inputName}' is valid and value is ${inputValue}`);
			} else {
				console.log(`Field '${inputName}' is invalid`);
			}
			if (this.form.isValid()) {
				// conditional Statement handleByPass, if flag is true, set the state of byPass to true

				console.log('The form is valid');
			} else {
				console.log('The form is invalid');
			}
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		const form = event.target;
		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every((field) => field.isValid());
		this.setState({
			isFormValid: formIsValid,
		});
		if (formIsValid) {
			const formData = new FormData(form);
			let data = this.state.formData;
			data.Code = formData.get('Code');

			if (this.props.selectedOffer.selectedOffer) {
				data.offerSelectId = this.props.selectedOffer.selectedOffer.id;
			}

			this.props.fetchVerifySms(data);
		} else {
			this.setState({
				invalidFormMessage:
					'The code is invalid, please enter the correct code or resend a new code',
			});
		}
	}

	async updateOtpVerified() {
		try {
			await this.props.apiOfferUpdateSelect({
				...this.props.selectedOffer.selectedOffer,
				otpVerified: true,
			});
		} catch (err) {
			console.log('Error updating the offer', err);
		}
	}

	handleResendOtp = (event) => {
		event.preventDefault();
		this.props.fetchSms();
	};

	render() {
		let kredGuru;
		let kredImage = 'kred_guru_+2.svg';
		let kgMessage = "Let's verify that your mobile number is still correct";
		let header = '';
		let signature = this.state.smsValidationKGCount === 0 ? "KG - Splendi's Kred Guru" : null;
		kredGuru = (
			<KredGuruSection
				id="BankVerificationSuccessKG"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
			/>
		);

		if (this.props.verifySms.isLoading) {
			return <Loading />;
		}
		// else if (this.props.verifySms.errMess) {
		// 	return (
		// 		<FadeTransform
		// 			in
		// 			transfromProps={{
		// 				exitTransform: 'scale(0.5) translate()-50%',
		// 			}}
		// 		>
		// 			<MDBContainer>
		// 				<MDBRow>
		// 					<MDBCol className="col-12 col-md-11">
		// 						<Card className="pl-3 pr-3">
		// 							<CardBody>
		// 								<FormWithConstraints
		// 									className="needs-validation"
		// 									id="otp-verification-form"
		// 									ref={(formWithConstraints) => (this.form = formWithConstraints)}
		// 									onSubmit={this.handleSubmit}
		// 									noValidate
		// 								>
		// 									<MDBRow>
		// 										<MDBCol className="col-12 col-md-12 align-self-center">
		// 											<ErrorComponent msg={this.props.verifySms.errMess} />
		// 										</MDBCol>
		// 									</MDBRow>
		// 									<MDBRow>
		// 										<MDBCol className="col-12 col-md-12">
		// 											<MDBRow>
		// 												<MDBCol className="col-12 col-md-6">
		// 													<p className="sub-title text-nowrap otp-header">Enter OTP</p>
		// 													<p className='otp-text'>Enter the verification code sent to you via SMS </p>
		// 												</MDBCol>
		// 												<MDBCol className="col-12 col-md-6">{kredGuru}</MDBCol>
		// 											</MDBRow>
		// 										</MDBCol>
		// 									</MDBRow>
		// 									<MDBRow className="sms-code">
		// 										<MDBCol className="col-6">
		// 											<MDBRow>
		// 												<MDBCol className="col-12 col-md-12">
		// 													<div className='otp-input'>
		// 														<InputComponent
		// 															name="Code"
		// 															id="Code"
		// 															label="SMS Code"
		// 															disabled={false}
		// 															type="text"
		// 															step="100"
		// 															required={true}
		// 															minLength="6"
		// 															maxLength="6"
		// 															outline={true}
		// 															handleChange={this.handleChange}
		// 														/>
		// 													</div>
		// 												</MDBCol>
		// 											</MDBRow>
		// 										</MDBCol>
		// 									</MDBRow>
		// 								</FormWithConstraints>
		// 								<div className="text-center py-4 mt-3">
		// 									<MDBBtn
		// 										className="btn btn-outline-purple"
		// 										type="submit"
		// 										onClick={this.handleResendOtp}
		// 									>
		// 										Resend
		// 									</MDBBtn>
		// 									<MDBBtn
		// 										className="btn btn-outline-purple"
		// 										type="submit"
		// 										disabled={this.state.isDisabled}
		// 										onClick={() => {
		// 											document
		// 												.getElementById('otp-verification-form')
		// 												.dispatchEvent(new Event('submit'));
		// 										}}
		// 									>
		// 										Continue
		// 									</MDBBtn>
		// 								</div>
		// 							</CardBody>
		// 						</Card>
		// 					</MDBCol>
		// 				</MDBRow>
		// 			</MDBContainer>
		// 		</FadeTransform>
		// 	);
		// }
		else {
			if (
				this.props.verifySms.verifySms &&
				this.props.verifySms.verifySms.statusCode === 200 &&
				this.state.otpVerified
			) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}
			return (
				<FadeTransform
					in
					transfromProps={{
						exitTransform: 'scale(0.5) translate()-50%',
					}}
				>
					<MDBContainer>
						<MDBRow>
							<MDBCol className="col-12 col-md-11">
								<Card className="otp-card-body">
									<CardBody>
										<FormWithConstraints
											className="needs-validation"
											id="otp-verification-form"
											ref={(formWithConstraints) => (this.form = formWithConstraints)}
											onSubmit={this.handleSubmit}
											noValidate
										>
											<MDBRow>
												<MDBCol className="col-12 col-md-12">
													{this.props.verifySms.errMess ? (
														<MDBRow>
															<MDBCol className="col-12 col-md-5">
																<ErrorComponent msg={this.props.verifySms.errMess} />
															</MDBCol>
														</MDBRow>
													) : null}
													<MDBRow className="items-center">
														<MDBCol className="col-12 col-md-6">
															<p className="sub-title text-nowrap otp-header">Enter OTP</p>
															<p className="otp-text">
																Enter the verification code sent to you via SMS{' '}
															</p>
															<div className="otp-input">
																<InputComponent
																	name="Code"
																	id="Code"
																	label="SMS Code"
																	disabled={false}
																	type="text"
																	step="100"
																	required={true}
																	minLength="6"
																	maxLength="6"
																	outline={true}
																	handleChange={this.handleChange}
																	hint="eg. 231321"
																/>
															</div>
														</MDBCol>
														<MDBCol className="col-12 col-md-6">{kredGuru}</MDBCol>
													</MDBRow>
												</MDBCol>
											</MDBRow>
										</FormWithConstraints>
										<div className="otp-action-buttons">
											<MDBBtn
												className="btn otp-action-buttons-resend"
												type="submit"
												onClick={this.handleResendOtp}
											>
												Resend
											</MDBBtn>
											<MDBBtn
												className="btn otp-action-buttons-continue"
												type="submit"
												disabled={this.state.isDisabled}
												onClick={() => {
													document
														.getElementById('otp-verification-form')
														.dispatchEvent(new Event('submit'));
												}}
											>
												Continue
											</MDBBtn>
										</div>
									</CardBody>
								</Card>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</FadeTransform>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => {
	return {
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmsValidation);
