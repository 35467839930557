import React, { Component } from 'react';
import './PrivacyPolicy.css';
import zen_paia_manual from '../../../../src/assets/files/ZENAPTIX PAIA MANUAL.pdf';
import zen_privacy_policy from '../../../../src/assets/files/ZENAPTIX PRIVACY POLICY.pdf';
import zen_cookie_policy from '../../../../src/assets/files/ZENAPTIX COOKIE POLICY.pdf';

class PrivacyAndPolicy extends Component {
	render() {
		return (
			<div className="privacy-policy-main pt-5">
				<div className="container pt-5 mt-5 mb-5 pb-5">
					<div className="privacy-page pt-4">
						<div className="row">
							<div className="col-md-12 pl-5 pr-5 pt-3 pb-3">
								<h4 style={{ color: '#3f51b5' }} className="pr-3 pl-3 pb-2">
									<strong>Splendi Privacy and Cookie Policy</strong>
								</h4>
								<p>
									Splendi is a product of XPERT DECISION SYSTEMS (PTY) LTD
									(&quot;XDS&quot;). XDS is a registered credit bureau who
									values the protection of consumer privacy. Please read this
									Privacy and Cookie Policy carefully before using our website
									or registering to use our services in order to understand our
									views and practices regarding your personal data and how we
									will treat it. This Privacy Policy sets out the basis on which
									any personal data will be processed by us.
								</p>
								<p>
									References in this Privacy Policy and on our website to “we”,
									“our” or “us” are references to Splendi and or XDS. References
									to “you” and “your” means each natural or legal person who
									uses our website or the associated services.
								</p>
								<p>
									This Privacy Policy is incorporated into and is subject to
									Splendi's Terms and Conditions as reflected on{' '}
									<a href="www.splendi.co.za">www.splendi.co.za.</a>
								</p>
								<p>
									By visiting our website, you are accepting and consenting to
									the practices described in this Privacy Policy. For the
									purpose of this Privacy Policy “applicable data protection
									law” means any laws in your jurisdiction in relation to data
									protection and privacy.
								</p>
								<p>
									We specifically comply with the National Credit Act, 2005
									(No.34 of 2005) (“NCA”) ,the Protection of Personal
									Information Act, 2013 (No. 4 of 2013) (“POPIA”) and the
									principles outlined in Sections 50 and 51 of the Electronic
									Communications and Transactions Act, 2002 (No.25 of 2002)
									which govern your right to having your personal information
									kept private.
								</p>
								<p>
									For the purpose of this Privacy Policy, &quot;Consumer Credit
									Information&quot; means consumer credit information as defined
									in Section 70 of the NCA; and “Personal Information” means
									personal information as defined in Section 1 of the POPIA. You
									will be asked to provide your Personal Information when
									registering, accessing or using any Services (as defined under
									the Terms and Conditions). In this regard, we may collect a
									variety of information, including your:
								</p>
								<ul>
									<li>Full name;</li>
									<li>Contact numbers;</li>
									<li>Email address;</li>
									<li>SA ID number and/or Date of Birth;</li>
									<li>Non-RSA ID number and/or Date of Birth;</li>
									<li>Address details;</li>
									<li>Marital status;</li>
									<li>Family relationships; and or</li>
									<li>
										Answers to security questions relating to your Consumer
										Credit Information on your Splendi Account.
									</li>
								</ul>
								<h5>
									<strong>Internet Information</strong>
								</h5>
								<p>
									When you visit and use any products or functions on Splendi’s
									site (“Site”), we may collect the behavioural data and general
									internet data, including your internet protocol
									(&quot;IP&quot;) address, metadata, location data, date and
									time you visit by:
								</p>

								<ul>
									<li>our use of cookies</li>
									<p>
										A "cookie" is also set on your computer to allow us to
										recognize you whenever you visit and collect information,
										like the pages you visit and the preferences you choose. We
										use the information we collect for statistical purposes and
										to study how the Site is used so that we may improve and
										enhance your experience on the Site. We may provide such
										information collected to outside vendors located overseas
										for such purposes. No personal information is stored in
										cookies. It is possible for you not to accept our cookies
										while using the Site by setting the preference in your web
										browser. If you do so, you may not be able to apply for
										credit reports online, but you can still visit the pages on
										the Site;
									</p>
									<li>our use of third parties</li>
									<p>
										We may use domestic or overseas third-party advertising
										companies to serve ads on the Site or on other sites which
										we use for advertising. These companies may employ cookies
										and action tags (also known as single pixel gifs or web
										beacons) to measure advertising effectiveness; and they are
										not authorized to use the information we share with them for
										any other purpose. Any information that these third parties
										collect via cookies and action tags is completely anonymous;
									</p>
									<li>our use of Analytics</li>
									<p>
										Google analytics cookies may be used to: distinguish unique
										users, throttle the request rate, determine which domain to
										measure, remember the number and time of previous visits,
										remember traffic source information, determine the start and
										end of a session, remember the value of visitor-level custom
										variables, determine a user's inclusion in an experiment,
										determine the expiry of experiments a user has been included
										in and for campaign mappings.
									</p>
									<li>our use of anonymous data</li>
									<p>
										In order to enhance the usefulness of the Site and our
										Services, Splendi will collect data about your use of the
										Site, in a manner that does not include any Personal
										Information. While you browse the Site, we note which pages
										of the Site you visit and note the IP address of your
										computer (as all web sites do automatically), but we do not
										link that address information to your personal information,
										other than for purposes of authentication and protection of
										your personal information when you access the Site
										electronically. The general information we gather enables us
										to serve you better by continually enhancing the Site based
										on up-to-date use patterns. We are provided anonymous
										information collected from other web sites on which we
										display advertisements that enables us to understand how
										visitors to the Site, who were exposed to ads we have placed
										on those other web sites, interact with the Site. We may use
										this information to enhance or modify our marketing that are
										designed to bring web visitors to the Site. You may opt-out
										of this anonymous data collection and sharing activity by:
									</p>
								</ul>
								<p>Our Opt-Out service</p>
								<p>
									In case you no longer want to receive our news or information,
									you may opt-out of receiving such by responding to the
									newsletter and asking to be unsubscribed, or by sending a
									request to{' '}
									<a href="mailto:informationofficer@xds.co.za" target="_top">
										informationofficer@xds.co.za
									</a>
									requesting to be taken off the list. If you signed up for our
									newsletter by selecting the special offers opt-in box during
									the ordering process, you may unsubscribe through the above
									two processes.
								</p>
								<h5>
									<strong>Our marketing data</strong>
								</h5>
								<p>
									The information we collect for our digital marketing business
									is segregated from Consumer Credit Information. Marketing data
									may include information on consumers, households, and
									businesses as well as non-personal aggregated information and
									advertising program performance data. Marketing data may
									include information about you, such as demographics, life
									events, public records, or firmographics.
								</p>
								<h5>
									<strong>Use and Disclosure of Personal Information</strong>
								</h5>
								<p>
									Splendi uses and discloses personal information to include
									only what is permitted and prescribed in terms of the NCA,
									POPIA or any other applicable laws or in instances where
									consumers have consented to such collection, use and
									disclosure.
								</p>
								<p>
									Confidentiality of your Personal Information is important to
									us. Unless we have your consent or permitted use under the
									national laws, we disclose your Personal Information to
									others. We will not use or share your Personal Information in
									ways unrelated to the circumstances described in this Privacy
									Policy.
								</p>
								<p>
									Specifically, we use and disclose information in the following
									circumstances:
								</p>
								<p>
									(a) Information you give to us (or we collect from credit
									bureaux) or other third parties on your behalf as part of our
									services which we will use to:
								</p>
								<ul>
									<li>
										administer your account and relationship with us and, to
										communicate with you by telephone, mail, email, text (SMS)
										message, instant messaging or other electronic means;
									</li>
									<li>
										verify your identity as part of our identity authentication
										process (we will use the credit card or bank account
										information only for the purposes of verifying your identity
										(and to prevent, detect and prosecute fraud and crime and
										comply with legal or regulatory requirements) and will not
										retain it);
									</li>
									<li>
										provide you with the information, products and services that
										you request from us;
									</li>
									<li>
										provide you with information about other products and
										services we offer that are similar to those that you have
										already purchased;
									</li>
									<li>
										provide you with information about products or services we
										feel may interest you or be best for you, where you have
										consented to receive such information;
									</li>
									<li>
										your data may be shared with product providers to validate
										if you are an existing customer (which may affect whether
										you can be accepted for one of their products) or for fraud
										prevention purposes. The product provider does not have
										permission to use these data for any other purpose including
										marketing.
									</li>
									<li>notify you about changes to our services;</li>
									<li>
										ensure that content from our website is presented in the
										most effective manner for you and your device;
									</li>
									<li>
										aggregate it on an anonymous basis with other data for data
										analytical and reporting purposes; and
									</li>
									<li>
										undertake analysis and profiling of your credit information
										(and any other data provided by you or on your behalf or
										which we collect on your behalf) in order to identify and
										inform you of credit products (including personal loans and
										credit cards) that we consider are likely to interest you or
										be suited to your credit circumstances, where you have
										consented to receive such information.
									</li>
								</ul>
								<p>
									(b) Information we collect about you which we will use to:
								</p>
								<ul>
									<li>
										administer our website and for internal operations,
										including troubleshooting, data analysis, testing, research,
										statistical and survey purposes;
									</li>
									<li>
										build up a picture of your interests so that you don’t miss
										information relevant to you when you visit our website;
									</li>
									<li>
										improve the service we offer you and to try and ensure that
										you get the best from our website, ensuring that content is
										presented in the most effective manner for you and for the
										devices you use to access it;
									</li>
									<li>
										allow you to participate in interactive features of our
										service, when you choose to do so;
									</li>
									<li>keep our website safe and secure;</li>
									<li>
										measure or understand the effectiveness of advertising we
										serve to you and others, and to deliver relevant advertising
										to you;
									</li>
									<li>
										make suggestions and recommendations to you and other users
										of our website about products or services that may interest
										you or them, subject to having received your consent where
										necessary.
									</li>
								</ul>
								<p>
									(c) Information we receive from other sources that we may
									combine such information with information you give to us and
									which we may use for the purposes set out above.
								</p>
								<p>
									(d) monitor or record telephone conversations or other
									communications between you and us which we will use to check
									your instructions to us, analyse, assess and improve our
									services to you, for training and quality purposes and for the
									purposes of investigating any complaint you may make, or as
									evidence in any dispute or anticipated disputes between you
									and us.
								</p>

								<h5>
									<strong>
										Consumer Information included in your credit report
									</strong>
								</h5>
								<p>Our credit report may include the following information:</p>
								<ul>
									<li>
										identifying information, such as first name, surname, ID
										number, physical and postal address, contact numbers,
										marital status, spouse details, and current employer and
										occupation;
									</li>
									<li>
										account history or payment profile information a 24 month
										record of all your accounts with credit or service providers
										and a history of how you pay these accounts on a monthly
										basis;
									</li>
									<li>
										enquiries which includes a list of credit or service
										providers authorized by yourself or permitted in terms of
										the NCA to receive your credit report;
									</li>
									<li>
										public records such as judgments, administration orders,
										sequestrations, and rehabilitation;
									</li>
									<li>
										default data as recorded on your credit profile when you
										fail to make the payment of money owed and which default
										data is submitted by the credit or service providers to us;
										and
									</li>
									<li>
										any other information that is permitted to be included under
										the NCA.
									</li>
								</ul>
								<h5>
									<strong>Our consumer business</strong>
								</h5>
								<p>
									With your consent, we may use your Personal Information to
									promote and market additional products and services. If you
									provide your consent, we may share your Personal Information,
									e.g. name and email address, with one or more third parties in
									order to administer the programme and present such information
									to you. If you do not register your ID Number or Passport
									Number, we will not collect any Personal Information on you
									while you visit the Site. In connection with the email
									registration program, our third party email processor may use
									action tags (also known as single pixel gif or web beacons) to
									collect completely anonymous information concerning your use
									of the Site. Such a third party administrator is not
									authorized to use any such information for any other purpose.
									If you decide to register for emails, you may opt-out from
									receiving such communications, at any time as specified above.
								</p>
								<p>
									In the majority of instances, Splendi is not capable of being
									provided with direct consent by consumers. Such consent is
									directly provided by consumers to Splendi's data suppliers.
									Splendi however takes all reasonable measures to ensure that
									all data suppliers are contractually bound to ensure that all
									legally required consents are obtained prior to accessing any
									Consumer Credit Information contained in our credit reports.
								</p>
								<h5>
									<strong>Digital marketing business</strong>
								</h5>
								<p>
									Through Splendi’s digital marketing services, we enable our
									business partners to communicate with consumers in a digital
									environment. In an effort to provide relevant products and
									services to consumers, these businesses use our data and
									technology to create, deploy, and measure targeted advertising
									programs digitally. We collect information as outlined below
									and use it for permitted marketing uses. In addition, we may
									aggregate and depersonalize data for analytical purposes, to
									provide reporting to our business partners, and for measuring
									advertising performance. As a part of enabling digital
									marketing services, we may use service providers.
								</p>
								<h5>
									<strong>Compliance with laws and regulations</strong>
								</h5>
								<p>
									We may only access, use and/or disclose your Personal
									Information without your consent in exceptional circumstances.
									These include circumstances where we believe such action is
									necessary to:
								</p>
								<ul>
									<li>comply with the law or legal process served on us;</li>
									<li>
										comply with requests for information from police or
										government authorities;
									</li>
									<li>
										protect and defend our rights or property (including the
										enforcement of our agreements);
									</li>
									<li>protect the public interest;</li>
									<li>
										act in urgent circumstances to protect the personal safety
										of our employees or members of the public;
									</li>
									<li>where applicable, with your implied consent; and</li>
									<li>
										to the extent permitted or required in terms of the NCA,
										POPIA and other relevant national legislation.
									</li>
								</ul>
								<h5>
									<strong>Market research</strong>
								</h5>
								<p>
									Your participation in market research activities is optional.
									Should you choose to participate, we may use information you
									provide to improve your experience with our products and
									services. We may engage third parties to assist us with our
									market research efforts who may retain non-personal aggregated
									information. However, all Personal Information you provide
									will only be used for our research purposes.
								</p>
								<h5>
									<strong>Retention of your information</strong>
								</h5>
								<p>
									We retain your Personal Information for as long as reasonably
									necessary to fulfil the purpose(s) for which it was collected
									and to comply with applicable laws and your consent to such
									purpose(s) remains valid after termination of our relationship
									with you.
									<br />
									Furthermore, we retain your Consumer Credit Information in our
									credit information database in accordance with the data
									retention periods prescribed by the NCA.
								</p>
								<h5>
									<strong>Transfer of Personal Information Outside</strong>
								</h5>
								<p>
									Unless permitted under the law or your consent is obtained,
									Splendi will not transfer your Personal Information outside
									the Republic of South Africa.
								</p>
								<h5>
									<strong>Your rights and participation</strong>
								</h5>
								<p>
									Splendi aims to ensure that your Personal Information is
									accurately recorded. To be able to achieve this, we adhere to
									processes that help ensure and maintain data accuracy. We
									provide individuals with reasonable access to review and
									correct their Personal Information, as detailed in our Site.
								</p>
								<p>
									When gathering your Consumer Credit Information Splendi works
									with our data suppliers to increase their awareness of the
									importance of providing only Consumer Credit Information that
									is accurate, complete, and up to date. Splendi however, cannot
									alter the information reported by such data suppliers, unless
									the information is determined to be wrong, incomplete or
									inaccurate. If you do not agree with the accuracy of
									information Splendi has on file, we have procedures in place
									to ensure that such information is verified, and where
									appropriate, amended or corrected. We however rely on you to
									bring any such information challenges to our attention.
								</p>
								<p>
									Unless otherwise provided in any laws, you have the right to
									access, update, correct or object to processing your Personal
									Information. You may do this by emailing{' '}
									<a href="mailto:informationofficer@xds.co.za" target="_top">
										informationofficer@xds.co.za
									</a>
								</p>
								<p>
									When you contact us please let us know your name, address, any
									email address you have provided on the Site and a description
									of the circumstances under which you provided the data. We
									will make reasonable efforts to incorporate as soon as
									practicable the changes in Personal Information that we
									maintain.
								</p>
								<h5>
									<strong>Security of your information</strong>
								</h5>
								<p>
									Splendi takes security and the protection of Personal
									Information and Consumer Credit Information seriously. We
									maintain physical, technical and organizational safeguards to
									protect any data that we collect. We have adopted procedures
									to secure storage of Personal Information and are committed to
									working with our data suppliers to protect the security of
									Personal Information during any transfer to or from us.
									Moreover, we have also instituted a number of safeguards to
									identify and help prevent the fraudulent use of Consumer
									Credit information Furthermore, your Personal Information is
									only accessible to those Splendi employees, agents or
									contractors for business purposes and on a strictly need to
									know basis. For security purposes all of the Personal
									Information that we collect or obtain about you, including
									your credit report, is housed in secure environments that have
									controls in place to limit access to and secure the data
									maintained therein.
								</p>
								<p>
									The Site, takes precautions to secure your Personal
									Information. If and when we ask you to provide your Personal
									Information, we will do so through a site that uses relevant
									industry standard authentication and authorization protocols
									as well as TLS (Transport Layer Security). TLS provides
									security for your information by encrypting it as it travels
									from your computer to our environments.
								</p>
								<p>
									To protect your privacy and security, we will also take
									reasonable steps to verify your identity before granting
									access to or making alterations to data we maintain.
								</p>
								<h5>
									<strong>Children's privacy</strong>
								</h5>
								<p>
									We define children as individuals under the age of 18. The
									Site is not intended for the use of children and we do not
									intend to collect information about children through the Site.
									Splendi does not knowingly collect information from children
									under the age of 18 and our sites do not target children under
									18. We encourage parents and guardians to take an active role
									in their children's online activities and interests.
								</p>
								<p>
									You must be at least 18 to access or use any products or
									services through the Site or become a subscriber of any
									subscription program entitled to subscription benefits.
									Specific to our direct marketing service, we define children
									as individuals age 18 or younger.
								</p>
								<h5>
									<strong>Third party sites</strong>
								</h5>
								<p>
									The Site may offer links to third party web sites including
									payment gateways for credit card payment. You should be aware
									that operators of linked web sites may also collect your
									Personal Information (including information generated through
									the use of cookies) when you link to their web sites.
								</p>
								<p>
									Splendi is not responsible for how such parties collect, use
									or disclose your information and it is important for you to
									familiarize yourself with their privacy policies before
									providing them with your Personal Information.
								</p>
								<h5>
									<strong>Enforcement of this Privacy Policy</strong>
								</h5>
								<p>
									Our compliance with this Privacy Policy will be monitored on a
									regular basis. Splendi reserves the right to modify this
									Privacy Policy. The Privacy Policy posted at any time via the
									Site shall be deemed to be the Privacy Policy then in effect.
								</p>
								<h5>
									<strong>Data Subject Rights</strong>
								</h5>
								<p>
									You have the right to access your personal information,
									dispute incorrect information, and request for your profile to
									be deleted. For more information please visit{' '}
									<a href="https://www.xds.co.za">XDS</a> website.
								</p>

								<div>
									<ul className="list-unstyled footer-terms">
										<li>
											<a href={zen_paia_manual} target="_blank">
												&nbsp;| PAIA manual
											</a>
										</li>
										<li>
											<a href={zen_privacy_policy} target="_blank">
												&nbsp;| Privacy policy
											</a>
										</li>
										<li>
											<a href={zen_cookie_policy} target="_blank">
												&nbsp;| Cookie policy
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PrivacyAndPolicy;
