import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createForms } from 'react-redux-form';
import { Consumer } from './consumer';
import { CreditSimulatorAccounts } from './creditSimulatorAccounts';
import { CreditSimulatorScore } from './creditSimulatorScore';
import { User } from './user';
import { BayPortQuote } from './bayPortQuote';
import { BayPortQuoteAcknowledgement } from './bayPortQuoteAcknowldgement';
import { AirtimeVASNetworks } from './airtimeVASNetworks';
import { AirtimeVASProducts } from './airtimeVASProducts';
import { BuyVASProductResponse } from './buyVASProductResponse';
import { ShoppingCart } from './shoppingCart';
import { SubmitNewSubscription } from './launchOffers/reducers/submitNewSubscription';
import { SendSms } from './launchOffers/reducers/sendSms';
import { VerifySms } from './launchOffers/reducers/verifySms';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AvailableOffers } from './launchOffers/reducers/availableOffers';
import { UserSubscriptions } from './launchOffers/reducers/subcriptions';
import { IDHeroNotification } from './idHeroNotifications/reducers/getUserNotifications';
import { EnvironmentVariables } from './envVariables/reducer/envVariables';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createExpirationTransform from 'redux-persist-transform-expire';
import CryptoJS from 'crypto-js';
import { FileUpload } from './dispute/reducer/file-upload';
import { GeneratePDF } from './dispute/reducer/generate-pdf';
import { SelectedOffer } from './launchOffers/reducers/selectedOffer';
import { KeycloakClient } from './auth/reducers/keycloakClient';
import { GenerateDisputeId } from './dispute/reducer/generate-disputeId';
import { UpdateNatureOfDisputeDetails } from './dispute/reducer/update-natureOfDispute-details';
import { AddDispute } from './dispute/reducer/create-dispute';
import { UpdateContactWayDetails } from './dispute/reducer/update-contact-details';
import { GetKgCredEdCmsArticles } from './kg-cred-ed-cms-articles/reducer/getKgCredEdCmsArticles';
import { SendEmailReducer } from './pdfFormDownloadRequest/reducer/send-email-reducer';

// Redux persist config.
const expireTransform = createExpirationTransform({
	expireKey: 'persistExpiresAt',
	defaultState: {},
});

const encrypt = createTransform(
	(inboundState, key) => {
		if (!inboundState) return inboundState;
		return CryptoJS.AES.encrypt(JSON.stringify(inboundState), 'Splendi-ui').toString();
	},
	(outboundState, _key) => {
		if (!outboundState) return outboundState;
		const decrypted = CryptoJS.AES.decrypt(outboundState, 'Splendi-ui').toString(CryptoJS.enc.Utf8);
		return JSON.parse(decrypted);
	}
);

const persistConfig = {
	key: 'persistedStore',
	storage: storage,
	transforms: [expireTransform, encrypt],
	whitelist: ['user', 'environmentV', 'idHeroNotification', 'consumer', 'disputeId', 'articles'],
	stateReconciler: autoMergeLevel2,
};

export const ConfigureStore = () => {
	const appReducer = combineReducers({
		consumer: Consumer,
		sendEmail: SendEmailReducer,
		creditSimulatorAccounts: CreditSimulatorAccounts,
		creditSimulatorScore: CreditSimulatorScore,
		user: User,
		bayPortQuote: BayPortQuote,
		bayPortAcknowledgment: BayPortQuoteAcknowledgement,
		airtimeVASNetworks: AirtimeVASNetworks,
		airtimeVASProducts: AirtimeVASProducts,
		buyVASProductResponse: BuyVASProductResponse,
		shoppingCart: ShoppingCart,
		newSubscription: SubmitNewSubscription,
		sendSms: SendSms,
		pdfGenerator: GeneratePDF,
		fileUpload: FileUpload,
		disputeId: GenerateDisputeId,
		selectedOffer: SelectedOffer,
		keycloakClient: KeycloakClient,
		updateNature: UpdateNatureOfDisputeDetails,
		updateContact: UpdateContactWayDetails,
		addDispute: AddDispute,
		verifySms: VerifySms,
		subscription: UserSubscriptions,
		availableOffers: AvailableOffers,
		articles: GetKgCredEdCmsArticles,
		idHeroNotification: IDHeroNotification,
		environmentV: EnvironmentVariables,
		...createForms({}),
	});

	const rootReducer = (state, action) => {
		if (action.type === 'LOGOUT') {
			localStorage.removeItem('persist:persistedStore');
			return {
				environmentV: state.environmentV,
				user: {
					user: [],
				},
			};
		}
		return appReducer(state, action);
	};

	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const middleware =
		process.env.NODE_ENV !== 'development'
			? composeWithDevTools(applyMiddleware(thunk, logger))
			: applyMiddleware(thunk);

	const store = createStore(persistedReducer, middleware);

	const persistor = persistStore(store);

	return { store, persistor };
};
