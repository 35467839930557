import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const fetchSendEmail = (data) => (dispatch) => {
	dispatch(sendEmailLoading(true));
	return fetch(baseUrl + requestPath('api/send-credit-report-request'), {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((data) => dispatch(sendEmail(data)))
		.catch((error) => dispatch(sendEmailFailed(error.message)));
};

export const sendEmailLoading = () => ({
	type: ActionTypes.SEND_EMAIL_LOADING,
});

export const sendEmailFailed = (errmess) => ({
	type: ActionTypes.SEND_EMAIL_FAILED,
	payload: errmess,
});

export const sendEmail = (data) => ({
	type: ActionTypes.SEND_EMAIL_START,
	payload: data,
});
