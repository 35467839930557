import React, { Component } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import InputComponent from '../../../../../components/Input/InputComponent';
import { Loading } from '../../../../../components/Loading/LoadingComponent';
import SelectComponent from '../../../../../components/Select/SelectComponent';
import { ErrorComponent } from '../../../../../components/Error/ErrorComponent';

function getProductList(products) {
	const options = [];
	products.forEach((product, index) => {
		if (
			options.filter(option => option.text === product.ProductType).length === 0
		) {
			options.push({
				text: product.ProductType,
				value: index,
				checked: index === 0
			});
		}
	});
	return options;
}

function getBundles(products, productType) {
	const options = [];
	products.forEach((product, index) => {
		if (product.ProductType === productType) {
			options.push({
				text: product.AirtimeProductName,
				value: index,
				checked: index === 0
			});
		}
	});
	return options;
}
class AirtimeVASBundledFormInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			productType: null
		};
		this.getProductValue = this.getProductValue.bind(this);
		this.getProductFieldValue = this.getProductFieldValue.bind(this);
	}

	getProductValue = value => {
		this.setState({
			productType: value
		});
		console.log(value);
	};

	getProductFieldValue = value => {
		console.log(value);
	};

	render() {
		if (this.props.airtimeVASProducts.isLoading) {
			return <Loading />;
		} else if (this.props.airtimeVASProducts.errMess) {
			return <ErrorComponent />;
		} else {
			const airtimeVASProducts = this.props.airtimeVASProducts
				.airtimeVASProducts;
			if (airtimeVASProducts instanceof Array) {
				const productList = getProductList(airtimeVASProducts);
				let bundles = getBundles(airtimeVASProducts, this.state.productType);

				return (
					<MDBRow>
						<MDBCol className="col-12">
							<MDBRow>
								<MDBCol md="6">
									<InputComponent
										name="PhoneNumber"
										id="PhoneNumber"
										label="Phone Number"
										hint="+27"
										disabled={false}
										type="tel"
										handleChange={this.props.handleChange}
										outline={true}
										required={true}
										minLength={10}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="6">
									<SelectComponent
										id="ProductType"
										name="ProductType"
										label="Products"
										options={productList}
										getValue={this.getProductValue}
										handleChange={this.props.handleChange}
										required={true}
									/>
								</MDBCol>
								<MDBCol md="6">
									<SelectComponent
										id="AirtimeProductName"
										name="AirtimeProductName"
										label="Bundles"
										options={bundles}
										getValue={this.getProductFieldValue}
										handleChange={this.props.handleChange}
										required={true}
									/>
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				);
			}
		}
	}
}

export default AirtimeVASBundledFormInput;
