import React, { Component } from 'react';
import '../OffersAndServices/Offer/Vehicle.css';
import { connect } from 'react-redux';
import { fetchClient } from '../../../redux/auth/actionCreators/getClient';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { baseUrl } from '../../../shared/baseUrl';

const loginRedirect = window._env_.REACT_APP_LOGIN_URL;

class SignUp extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.fetchClient();
	}

	render() {
		const currentPath = window.location.href;

		if (this.props.keycloakClient.isLoading) {
			return <Loading />;
		} else if (this.props.keycloakClient.keycloakClient.client) {
			window.location.href = `${loginRedirect}/register?redirect_uri=${currentPath}`;
			return;
		} else {
			window.location.href = `${loginRedirect}/register?redirect_uri=${currentPath}`;
			return (
				<>
					<p1>Failed to loaded auth client.</p1>
				</>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchClient: (data) => dispatch(fetchClient(data)),
});
const mapStateToProps = (state) => {
	return {
		keycloakClient: state.keycloakClient,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUp);
