import React, { Component } from 'react';
import {
	Card,
	CardBody,
	Col,
	Container,
	MDBBtn,
	MDBContainer,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBTooltip,
	Row,
} from 'mdbreact';
import './Offers.css';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../shared/baseUrl';
import TermsAndConditions from '../../sections/TermsAndConditions/TermsAndConditions';
import MainComponent from '../../components/Main/MainComponent';
import { fetchDisputeId } from '../../../redux/dispute/actionCreators/getDisputeId';
import { connect } from 'react-redux';

const cursorStyle = {
	cursor: 'pointer',
};

class OffersAndServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offer: false,
			url: '',
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	state = {
		modal: false,
	};
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	subscribeNow = () => {
		this.setState({
			offer: true,
		});
	};
	navigateToPage = (url) => {
		this.setState({
			url: url,
		});
	};

	render() {
		const userPermission = this.props.user.user.userPermissions;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter((value) => value.OfferID === 1)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isSubscribed =
			offerID1 === 1 && isUserSubscribed ? (
				<Col className="col-12 col-md-4 pt-4 text-right text-md-left">
					<h5 className="pt-4">You have already subscribed for this offer</h5>
				</Col>
			) : (
				<div className="launch-offer-action-buttons">
					<div>
						<MDBTooltip placement="top">
							<Link to="/new-subscription/1">
								<MDBBtn size="md" className="action-button font-weight-bold">
									R24.99 P/M
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</div>
					<div>
						<MDBTooltip placement="top">
							<Link to="/new-subscription/3">
								<MDBBtn size="md" className="action-button font-weight-bold">
									R299.88 P/A
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</div>
				</div>
			);

		const offers = [
			// {
			// 	image: 'assets/img/lifestyle_ad.svg',
			// 	url: '/offers/featured/offer/lifestyle-rewards/req-form'
			// },
			{
				image: 'assets/img/aa_roadside_bundled_offer_ad_680x220px.svg',
				url: '/offers/featured/offer/aa-roadside-bundled/req-form',
			},
			// {
			// 	image: 'assets/img/vehicle.png',
			// 	// url: '/offers/featured/offer/bail-assist/req-form'
			// },
			// {
			// 	image: 'assets/img/crime_assist_ad.svg',
			// 	url: '/offers/featured/offer/crime-and-security-assist/req-form'
			// },
			// {
			// 	image: 'assets/img/boost.png',
			// 	url: '/offers/featured/offer/crime-and-security-assist/req-form'
			// }
		];

		const offersContent = offers.map((offer, index) => {
			return (
				<Col className="col-12 col-md-12" key={index}>
					<Card className="h-100 card-big-image">
						<Link to={offer.url}>
							<div className="card-big-image-container">
								<div className="splendi-launch-image" />
								<div className="card-big-image-details">
									<h4 className="card-big-image-details-title">
										ROADSIDE ASSIST
										<img
											src={`${baseUrl}assets/img/aa_logo.svg`}
											width={83}
											alt="AA Logo"
											className="roadside-assist-logo"
										/>
									</h4>
									<p className="card-roadside-services-title">Get all these services:</p>
									<div className="card-roadside-services">
										Patrol & Battery | Locksmith | Towing | Stand by You | Emergency Medical | Taxi
										After Tow
									</div>
									<div className="services-icon-box">
										<div className="services-icon1" />
										<div className="services-icon2" />
										<div className="services-icon3" />
										<div className="services-icon4" />
										<div className="services-icon5" />
										<div className="services-icon6" />
										<div className="services-icon7" />
										<div className="services-icon8" />
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</Col>
			);
		});
		return (
			<>
				<Container className="mt-2">
					<Card className="p-md-4">
						<CardBody>
							<Row>
								<Col>
									<Row className="launch-offer-container">
										<Col className="col-12 col-md-6">
											<h4 className="title  launch-offer-title">SPLENDI LAUNCH OFFER</h4>
											<p className="launch-offer-text">
												Splendi-fy your life on multiple fronts with this exclusive launch offer.
												The service is activated by your first debit order payment. This amazing
												deal is available for a limited time only. If you snooze, you lose...
											</p>
										</Col>
										{isSubscribed}
									</Row>
									<Row className="mt-4">{offersContent}</Row>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Container>
				<MDBModal
					className="modal-dialog-scrollable"
					isOpen={this.state.modal}
					toggle={this.toggle}
					size="lg"
					backdrop={false}
				>
					<MDBModalHeader toggle={this.toggle}>
						<strong className="text-center" style={{ color: '#3f51b5' }}>
							TERMS & CONDITIONS
						</strong>
					</MDBModalHeader>
					<MDBModalBody className="text-justify">
						<TermsAndConditions />
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}
const mapDispatchToProps = (dispatch) => ({
	fetchDisputeId: () => dispatch(fetchDisputeId()),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OffersAndServices);
