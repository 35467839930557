import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, CardBody } from 'mdbreact';

export default (props) => {

    let banner = null

    if (props.banner) (
        banner = <Card className="bg-color-gray-dark text-white py-3 px-5">
            <CardBody>
                <ReactMarkdown source={props.banner} />
            </CardBody>
        </Card>
    );

    return(
        <>
            <div className="px-5 py-3">
                <img
                    src={props.image}
                    alt=""
                    width="100%"
                />
            </div>
            {banner}
        </>
    )
}