import React from 'react';
import { Card, CardBody, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { CollapseComponent } from '../../../../components/Collapse/CollapseComponent';

const PreviousEnquiries = ({ consumer }) => {
	if (consumer instanceof Array) {
		const sortedEnquiryHistory = consumer
			.slice()
			.sort(
				(a, b) => new Date(b.EnquiryDate.value) - new Date(a.EnquiryDate.value)
			);
		const bodyContent = (
			<MDBTable scrollY maxHeight="400px" striped>
				<MDBTableHead>
					<tr>
						<th>Enquiry Date</th>
						<th>Subscriber</th>
						<th>Contact Number</th>
					</tr>
				</MDBTableHead>
				<MDBTableBody>
					{sortedEnquiryHistory.map((enquiry, key) => {
						const { EnquiryDate, SubscriberName, SubscriberContact } = enquiry;
						return (
							<tr key={key}>
								<td>{EnquiryDate.value}</td>
								<td>{SubscriberName.value}</td>
								<td>{SubscriberContact.value}</td>
							</tr>
						);
					})}
				</MDBTableBody>
			</MDBTable>
		);
		const accordionContent = [
			{
				id: 'Enquiry',
				headerContent: 'Consumer Enquiry History',
				bodyContent: bodyContent
			}
		];
		return (
			<Card className="mt-3">
				<CardBody>
					<CollapseComponent accordionContent={accordionContent} />
				</CardBody>
			</Card>
		);
	}
	return (
		<Card className="mt-3">
			<CardBody>
				<p>You currently do not have any previous enquiry</p>
			</CardBody>
		</Card>
	);
};

export { PreviousEnquiries };
