import * as ActionTypes from './ActionTypes';

export const CreditSimulatorScore = (
	state = {
		isLoading: true,
		errMess: null,
		creditSimulatorScore: []
	},
	action

) => {

	switch (action.type) {
		case ActionTypes.CREDIT_SIMULATOR_SCORE_UPDATE:
			return {
				...state,
				isLoading: false,
				errMess: null,
				creditSimulatorScore: action.payload
			};
		case ActionTypes.CREDIT_SIMULATOR_SCORE_LOADING:
			return { ...state, isLoading: true, errMess: null, creditSimulatorScore: [] };
		case ActionTypes.CREDIT_SIMULATOR_SCORE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				creditSimulatorScore: []
			};
		default:
			return state;
	}
};
