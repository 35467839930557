import React from 'react';
import { baseUrl } from '../../shared/baseUrl';
import './LoadingComponent.css';

export const Loading = () => {
	return (
		<div className="col-12 flex-center">
			<img
				src={`${baseUrl}assets/img/splendi-loading-spinner.gif`}
				className="loading-image"
				alt=""
			/>
			<p>Loading ...</p>
		</div>
	);
};
