import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSms } from '../../../redux/launchOffers/actionCreators/sendSms';
import './MobileNumber.css';
import { updateOfferSelect } from '../../../redux/launchOffers/actionCreators/selectOfferUpdate';
import { Loading } from '../../../components/Loading/LoadingComponent';

class classyMobileNumber extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			otp: '',
			error: '',
			isInputValid: false,
			otpPreviouslyVerified: false,
			verifying: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateOtpVerified = this.updateOtpVerified.bind(this);
	}

	async componentDidMount() {
		const localStorageValue = sessionStorage.getItem('validSplendiOTP');
		const localStorageValueDate = sessionStorage.getItem('validSplendiOTPDate');

		if (localStorageValue && localStorageValueDate) {
			const storedDate = new Date(localStorageValueDate);
			const currentDate = new Date();
			const timeDifferenceMinutes = (currentDate - storedDate) / (1000 * 60);

			if (timeDifferenceMinutes < 5) {
				await this.updateOtpVerified();
			} else {
				sessionStorage.removeItem('validSplendiOTP');
				sessionStorage.removeItem('validSplendiOTPDate');
			}
		}
	}

	handleChange(e) {
		const input = e.target.value;
		if (/^\d{0,10}$/.test(input)) {
			this.setState({
				otp: input,
				error: '',
				isInputValid: input.length === 10,
			});
		} else {
			this.setState({
				error: 'Please enter a maximum of 10 numerical digits.',
				isInputValid: false,
			});
		}
	}

	handleSubmit() {
		this.props.fetchSms();
	}

	async updateOtpVerified() {
		this.setState({ verifying: true });
		try {
			await this.props.apiOfferUpdateSelect({
				...this.props.selectedOffer.selectedOffer,
				otpVerified: true,
			});
			this.setState({
				otpPreviouslyVerified: true,
			});
		} catch (err) {
			console.log('Error updating the offer', err);
		}
		this.setState({ verifying: false });
	}

	render() {
		const { mobileNumber } = this.props;

		if (this.props.selectedOffer.isLoading || this.state.verifying) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			if (this.state.otpPreviouslyVerified) {
				return <Redirect to="/offers_and_services/verify-otp" />;
			}

			function replaceCharactersWithAsterisks(input) {
				const characters = input.split('');
				for (let i = 3; i < 7; i++) {
					characters[i] = '*';
				}
				return characters.join('');
			}
			const redactedMobileNumber = replaceCharactersWithAsterisks(mobileNumber);
			return (
				<div>
					<div className="card-big">
						<h1 className="mobileNr">{redactedMobileNumber}</h1>
						{this.state.error && <p className="error">{this.state.error}</p>}
						<div className="correct2">
							An OTP will be sent to the number provided for verification. Please note that your
							mobile number used when registering on Splendi will be used to send the OTP to.
						</div>
						<div className="buttons3">
							<Link to="/offers_and_services/verify-otp">
								{' '}
								<div className="btn-custom-secondary" onClick={() => this.handleSubmit()}>
									Submit
								</div>
							</Link>
							<Link to="/offers_and_services">
								<div className="btn-custom-primary">Cancel</div>
							</Link>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		mobileNumber: state.user.user.cellphoneNumber,
		selectedOffer: state.selectedOffer,
		sendSms: state.sendSms,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSms: () => dispatch(fetchSms()),
		apiOfferUpdateSelect: (data) => dispatch(updateOfferSelect(data)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(classyMobileNumber);
