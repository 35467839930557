import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, MDBBtn, MDBTooltip, Row } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';
import './AARoadsideAssist.css';

class AARoadsideAssistOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offer: false,
		};
	}

	navigateToPage = () => {
		this.setState({
			toQuote: true,
		});
	};
	subscribeNow = () => {
		this.setState({
			offer: true,
		});
	};
	render() {
		const userPermission = this.props.user.user.userPermissions;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter((value) => value.OfferID === 1)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isSubscribed =
			offerID1 === 1 && isUserSubscribed ? (
				<Col className="col-12 col-md-4 pt-4 text-right text-md-left">
					<h5 className="pt-4">You have already subscribed</h5>
				</Col>
			) : (
				<div className="launch-offer-action-buttons">
					<div>
						<MDBTooltip placement="top">
							<Link to="/new-subscription/1">
								<MDBBtn size="md" className="action-button font-weight-bold">
									R24.99 P/M
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</div>
					<div>
						<MDBTooltip placement="top">
							<Link to="/new-subscription/3">
								<MDBBtn size="md" className="action-button font-weight-bold">
									R299.88 P/A
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</div>
				</div>
			);
		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%',
				}}
			>
				<Row>
					<Col>
						<Card>
							<CardBody className="card-body-req-form">
								<Row className="launch-offer-container px-4 px-md-0">
									<Col className="col-12 col-md-6">
										<h4 className="title  launch-offer-title">SPLENDI LAUNCH OFFER</h4>
										<p className="launch-offer-text">
											Splendi-fy your life on multiple fronts with this exclusive launch offer. The
											service is activated by your first debit order payment. This amazing deal is
											available for a limited time only. If you snooze, you lose...
										</p>
									</Col>
									{isSubscribed}
								</Row>
								<Row className="mt-5">
									<Col className="col-12">
										<Row>
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<img
															src={`${baseUrl}assets/img/aa_patrol_assist_icons.svg`}
															alt=""
															width="280px"
														/>
														<p className="white-text">AAPatrolRoadSideAssistIcons</p>
													</div>
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															AA Out-of-Fuel Rescue & Battery Service
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														A 24-hour service that provides on-the-spot roadside mobility from tyre
														changes to jump-starts and assistance with key lockout situations. This
														service is available in urban areas for up to 40km. After that, any
														additional km’s will be added to the member’s account.
													</p>
													<label className="font-weight-bolder">Benefits:</label>
													<ul>
														<li>Flat tyre change</li>
														<li>
															Out of fuel - Provision of fuel to a maximum of 10 litres (Member to
															pay for fuel)
														</li>
														<li>Jump-start</li>
														<li>Mobile battery sales (battery cost submitted to your account)</li>
														<li>Key lockout service</li>
														<li>
															Minor roadside running repairs includes electrical, coil, immobilizer,
															battery related breakdowns where possible.
														</li>
													</ul>
												</div>
											</Col>
											<Col className="col-12 col-md-6">
												<img
													src={`${baseUrl}assets/img/aa_assist_image_big.svg`}
													alt=""
													width="100%"
												/>
											</Col>
										</Row>
										<Row className="mt-5">
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<img
															src={`${baseUrl}assets/img/aa_key_assist_icon.svg`}
															alt=""
															width="60px"
														/>
														<p className="white-text">AAPatrolRoadSideAssist</p>
													</div>
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															AA Locksmith Services
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														A 24-hour service arranged by an AA Patrol or an AA accredited locksmith
														to unlock your vehicle - at the location of the incident. The service is
														limited to 40km. You will be required to pay for additional km’s beyond
														40km. Maximum cover per call-out is R500 excl VAT.
													</p>
												</div>
											</Col>
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<img
															src={`${baseUrl}assets/img/aa_towing_service_icon.svg`}
															alt=""
															width="60px"
														/>
														<p className="white-text">AAPatrolRoadSideAssist</p>
													</div>
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															Towing Services: Mechanical or electrical breakdown
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														In the event of a mechanical or electrical breakdown in rural or urban
														areas, AA will arrange for the towing of your vehicle to the nearest
														accredited/preferred dealer/repairer/yard from the breakdown scene. You
														may also choose your destination within the 40km service area. This
														service is limited to 40km, and thereafter, you will be required to pay
														the additional km’s.
													</p>
												</div>
											</Col>
										</Row>
										<Row className="mt-5">
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<img
															src={`${baseUrl}assets/img/aa_towing_service_icon.svg`}
															alt=""
															width="60px"
														/>
														<p className="white-text">AAPatrolRoadSideAssist</p>
													</div>
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															Towing Services: AA Accident Service
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														In the event of an accident, the AA will arrange for a tow to the
														destination indicated by the Insurance Company or by the member. This
														benet is available on a pay on use basis, i.e. if you require an
														accident tow you will have to pay an additional amount for this service.
														You may also choose your destination within the 40km service area. After
														that, extra km's will incur additional charges.
													</p>
												</div>
											</Col>
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<img
															src={`${baseUrl}assets/img/aa_stand_by_you_icon.svg`}
															alt=""
															width="60px"
														/>
														<p className="white-text">AAPatrolRoadSideAssist</p>
													</div>
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															Stand By You Service
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														An Armed Security response ocer secures the scene and waits with you
														until the AA technical service arrives. This service is available in the
														Major Metropolitan areas of South Africa.
													</p>
												</div>
												<div className="px-2">
													<p>
														A 24-hour AA Mayday emergency medical assistance service for unforeseen
														medical emergencies. The AA will arrange for emergency medical
														evacuation to a suitable medical facility – provided nationally by
														Netcare 911.
													</p>
												</div>
											</Col>
										</Row>
										<Row className="mt-5">
											<Col className="col-12 col-md-6 px-4 px-md-0">
												<div className="roadside-req-form-title">
													<div className="px-2">
														<CardTitle className="h4-responsive font-weight-bolder">
															AA Taxi-After-Tow
														</CardTitle>
													</div>
												</div>
												<div className="px-2">
													<p>
														In the event of an incident resulting in your vehicle requiring a tow,
														you and your passengers will receive transport to your chosen
														destination.
													</p>
													<p>
														This service is dispatched simultaneously with the AA Tow Truck to
														respond timeously to your breakdown situation. The service is limited to
														R200 per incident. After that, the driver will charge a rate per km. The
														AA dispatches the Uber service on your behalf – no need to download the
														app.
													</p>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					{this.props.location.pathname === '/offers/featured/offer/aa-roadside/req-form' && (
						<Col className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img src={`${baseUrl}assets/img/next_arrow_active.svg`} alt="" width="100%" />
								<p className="white-text">RoadSideAssist</p>
							</a>
						</Col>
					)}
				</Row>
			</FadeTransform>
		);
	}
}

export default AARoadsideAssistOffer;
