import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import CredEdResourcePage from './CredEdResourcePage';
import { baseUrl } from '../../../shared/baseUrl';
import { Loading } from '../../../../src/components/Loading/LoadingComponent';
import { connect } from 'react-redux';
import { fetchKgCredEdCmsArticles } from '../../../redux/kg-cred-ed-cms-articles/actionCreators/getKgCredEdCmsArticles';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import './CredEd.css';
import CredEdMobileArticle from './components/CredEdMobileArticle';
import CredEdDesktopArticle from './components/CredEdDesktopArticle';

class CredEdPage extends Component {
	state = {
		covID19ImageUrl: `${baseUrl}assets/img/covid-sa-logo.png`,
		isMobile: false,
	};
	componentDidMount() {
		if (
			this.props.articles &&
			this.props.articles.articles.length === 0 &&
			!this.props.articles.isLoading
		) {
			this.props.fetchCmsCredEdArticles();
		}

		window.scrollTo(0, 0);

		if (window.innerWidth > 576) {
			this.setState({ isMobile: false });
		} else {
			this.setState({ isMobile: true });
		}

		const handleResize = () => {
			if (window.innerWidth > 576) {
				this.setState({ isMobile: false });
			} else {
				this.setState({ isMobile: true });
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}

	render() {
		let credEdArticle = null;
		if (this.props.articles.isLoading) {
			return <Loading />;
		} else if (this.props.articles.errMess !== null) {
			return <ErrorComponent />;
		}

		if (
			this.props.location.pathname !== '/cred-ed/resources' &&
			this.props.location.pathname !== '/cred-ed/covid19' &&
			RegExp(/\/cred-ed\/.+/g).test(this.props.location.pathname) &&
			this.props.articles.isLoading === true
		) {
			credEdArticle = <Loading />;
		}

		if (
			this.props.location.pathname !== '/cred-ed/resources' &&
			this.props.location.pathname !== '/cred-ed/covid19' &&
			RegExp(/\/cred-ed\/.+/g).test(this.props.location.pathname) &&
			this.props.articles.isLoading === false
		) {
			if (this.state.isMobile === true) {
				credEdArticle = (
					<CredEdMobileArticle
						articleType="cmsArticle"
						location={this.props.location.pathname}
						articles={this.props.articles}
					/>
				);
			} else {
				credEdArticle = (
					<CredEdDesktopArticle
						articleType="cmsArticle"
						location={this.props.location.pathname}
						articles={this.props.articles}
					/>
				);
			}
		}

		return (
			<MDBContainer className="mt-2">
				<p className="text-center">
					<a href="https://sacoronavirus.co.za/" target="_blank">
						<img
							src={this.state.covID19ImageUrl}
							alt="COVID19"
							width="auto"
							height="auto"
							className="covid-image"
						/>
					</a>
				</p>
				{/*{this.props.location.pathname === '/cred-ed/simulator' && (*/}
				{/*	<SimulatorPage*/}
				{/*		consumer={this.props.consumer}*/}
				{/*		updateScore={this.props.updateScore}*/}
				{/*		isScoreUpdated={this.props.isScoreUpdated}*/}
				{/*		creditSimulatorAccounts={this.props.creditSimulatorAccounts}*/}
				{/*		creditSimulatorScore={this.props.creditSimulatorScore}*/}
				{/*		updateCreditSimulatorScore={this.props.updateCreditSimulatorScore}*/}
				{/*	/>*/}
				{/*)}*/}

				{this.props.location.pathname === '/cred-ed/resources' && (
					<CredEdResourcePage
						credEdCmsArticles={this.props.articles}
						credEdCmsArticlesIsLoading={this.props.articles.isLoading}
					/>
				)}

				{this.props.location.pathname === '/cred-ed/covid19' && this.state.isMobile === true && (
					<CredEdMobileArticle articleType="CovidArticle" />
				)}

				{this.props.location.pathname === '/cred-ed/covid19' && this.state.isMobile === false && (
					<CredEdDesktopArticle articleType="CovidArticle" />
				)}

				{this.props.articles && this.props.articles.articles.length > 0 && credEdArticle}
			</MDBContainer>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		articles: state.articles,
	};
};
const mapDispatchToProps = (dispatch) => ({
	fetchCmsCredEdArticles: () => {
		//dispatch(fetchKgCredEdCmsArticles());
	},
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CredEdPage);
