import React from 'react';
import { Card, CardBody, MDBBtn } from 'mdbreact';
import { fetchSendEmail } from '../../../../redux/pdfFormDownloadRequest/actionCreators/send-email';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import { connect } from 'react-redux';
import SendEmailSuccess from './sendEmailSuccess';

const CreditReportDownloadPdfFormRequest = props => {
	const { sendEmail } = props.sendEmail;
	const handleCreditReportRequest = () => {
		const { user } = props.user;
		const data = {
			idNumber: user.idNumber,
			firstName: user.givenName,
			lastName: user.familyName,
			mobile_number: user.cellphoneNumber,
			email: user.email
		};
		props.fetchSendEmail(data);
	};
	if (props.sendEmail.isLoading) {
		return <Loading />;
	} else if (props.sendEmail.errMess) {
		return <ErrorComponent />;
	} else if (
		typeof sendEmail !== 'undefined' &&
		typeof sendEmail.response !== 'undefined' &&
		sendEmail.response.messageId
	) {
		return <SendEmailSuccess />;
	} else {
		return (
			<Card className="mt-3">
				<CardBody>
					<p>
						You are about to request a PDF copy of your credit profile from XDS.
						Please click the button below to proceed.
					</p>
					<MDBBtn onClick={() => handleCreditReportRequest()}>
						Get Credit Report
					</MDBBtn>
				</CardBody>
			</Card>
		);
	}
};
const mapDispatchToProps = dispatch => ({
	fetchSendEmail: data => dispatch(fetchSendEmail(data))
});
const mapStateToProps = state => {
	return {
		sendEmail: state.sendEmail,
		user: state.user
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditReportDownloadPdfFormRequest);
