import React from 'react';
import {
	MDBCarousel,
	MDBCarouselCaption,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBContainer
} from 'mdbreact';
class CarouselComponent extends React.Component {
	render() {
		const carouselItems = this.props.items.map((item, index) => {
			return (
				<MDBCarouselItem key={index} itemId={index + 1}>
					{item.body}
					{/*<MDBCarouselCaption>*/}
					{/*	/!*<h3 className="h3-responsive">{item.header}</h3>*!/*/}
					{/*	/!*<p>{item.captionParagraph}</p>*!/*/}
					{/*</MDBCarouselCaption>*/}
				</MDBCarouselItem>
			);
		});
		return (
			<MDBContainer>
				<MDBCarousel
					activeItem={1}
					length={this.props.items.length}
					showControls={false}
					showIndicators={true}
					className="z-depth-1"
				>
					<MDBCarouselInner>{carouselItems}</MDBCarouselInner>
				</MDBCarousel>
			</MDBContainer>
		);
	}
}

export default CarouselComponent;
