import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'mdbreact';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import AccountsComponent from '../../../components/Accounts/AccountsComponent';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';

class ShortTermAndRevolvingCreditAccounts extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { consumer } = this.props;
		if (consumer.isLoading) {
			return <Loading />;
		} else if (consumer.errMess) {
			return <ErrorComponent />;
		} else {
			const cpaConsumerAccounts =
				consumer.consumer.ConsumerAccountStatus instanceof Array
					? consumer.consumer.ConsumerAccountStatus
					: [];
			const nlrConsumerAccounts =
				consumer.consumer.ConsumerNLRAccountStatus instanceof Array
					? consumer.consumer.ConsumerNLRAccountStatus
					: [];
			const cpaAccountTypeLegend =
				consumer.consumer.AccountTypeLegend instanceof Array
					? consumer.consumer.AccountTypeLegend
					: [];
			const nlrAccountTypeLegend =
				consumer.consumer.NLRAccountTypeLegend instanceof Array
					? consumer.consumer.NLRAccountTypeLegend
					: [];
			const cpaConsumer24MonthlyPayment = consumer.consumer.Consumer24MonthlyPayment;
			const nrlConsumer24MonthlyPayment = consumer.consumer.ConsumerNLR24MonthlyPayment;
			const cpaConsumer24MonthlyPaymentHeader = consumer.consumer.Consumer24MonthlyPaymentHeader;
			const nrlConsumer24MonthlyPaymentHeader = consumer.consumer.ConsumerNLR24MonthlyPaymentHeader;
			const cpaDescription = consumer.consumer.ConsumerDefinition;
			const nlrDescription = consumer.consumer.ConsumerNLRDefinition;
			const cpaAccount = cpaAccountTypeLegend.map((key, index) => {
				let accounts = cpaConsumerAccounts.filter(
					(account) =>
						account.AccountType.value === key.AccountTypeCode.value &&
						[
							'Z',
							'S',
							'R',
							'F',
							'G',
							'I',
							'M',
							'N',
							'O',
							'P',
							'V',
							'K',
							'1',
							'2',
							'3',
							'4',
						].includes(account.AccountType.value)
				);
				if (accounts.length > 0) {
					return (
						<Card className="mt-3" key={index}>
							<CardBody>
								<CardTitle className="sub-title font-medium">{key.AccountTypeDesc.value}</CardTitle>
								<AccountsComponent
									accounts={accounts}
									paymentHeaders={cpaConsumer24MonthlyPaymentHeader}
									monthlyPayment={cpaConsumer24MonthlyPayment}
									cpaDescription={cpaDescription}
									nlrDescription={nlrDescription}
								/>
							</CardBody>
						</Card>
					);
				} else {
					return null;
				}
			});
			const nlrAccount = nlrAccountTypeLegend.map((key, index) => {
				let accounts = nlrConsumerAccounts.filter(
					(account) =>
						account.AccountType.value === key.AccountTypeCode.value &&
						[
							'Z',
							'S',
							'R',
							'F',
							'G',
							'I',
							'M',
							'N',
							'O',
							'P',
							'V',
							'K',
							'1',
							'2',
							'3',
							'4',
						].includes(account.AccountType.value)
				);
				if (accounts.length > 0) {
					return (
						<Card className="mt-3" key={index}>
							<CardBody>
								<CardTitle className="sub-title">{key.AccountTypeDesc.value}</CardTitle>
								<AccountsComponent
									accounts={accounts}
									paymentHeaders={nrlConsumer24MonthlyPaymentHeader}
									monthlyPayment={nrlConsumer24MonthlyPayment}
									nlrDescription={nlrDescription}
									cpaDescription={cpaDescription}
								/>
							</CardBody>
						</Card>
					);
				} else {
					return null;
				}
			});
			return (
				<Container>
					<Row>
						<Col className="col-12">
							<h4 className="h4-responsive credit-report-header">
								REVOLVING AND SHORT TERM CREDIT
							</h4>
						</Col>
					</Row>
					<Row>
						<Col className="col-12">{cpaConsumerAccounts instanceof Array ? cpaAccount : null}</Col>
						<Col className="col-12">{nlrConsumerAccounts instanceof Array ? nlrAccount : null}</Col>
					</Row>
				</Container>
			);
		}
	}
}

export default ShortTermAndRevolvingCreditAccounts;
