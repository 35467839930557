import * as ActionTypes from '../../ActionTypes';

export const SendEmailReducer = (
	state = {
		isLoading: false,
		errMess: null,
		sendEmail: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.SEND_EMAIL_START:
			return {
				...state,
				isLoading: false,
				errMess: null,
				sendEmail: action.payload
			};
		case ActionTypes.SEND_EMAIL_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				sendEmail: []
			};
		case ActionTypes.SEND_EMAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				sendEmail: []
			};
		default:
			return state;
	}
};
