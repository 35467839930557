import React from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemTitle,
	AccordionItemBody,
} from 'react-accessible-accordion';
import { Row, Col } from 'mdbreact';
import 'react-accessible-accordion/dist/fancy-example.css';

export const CollapseComponent = (props) => {
	const accordionContent = props.accordionContent.map((key, index) => {
		return (
			<AccordionItem key={index}>
				<AccordionItemTitle>
					<Row>
						<Col className="col-10">
							<h5 className="title text-color-purple-2">{key.headerContent}</h5>
						</Col>
						<Col className="col-2 ">
							<div className="accordion__arrow" role="presentation" />
						</Col>
					</Row>
				</AccordionItemTitle>
				<AccordionItemBody>{key.bodyContent}</AccordionItemBody>
			</AccordionItem>
		);
	});
	return <Accordion>{accordionContent}</Accordion>;
};
