import React, { Component } from 'react';
import {
	Card,
	CardBody,
	MDBCardTitle,
	MDBCardText,
	MDBCol,
	MDBRow
} from 'mdbreact';
import { Loading } from '../../../../../components/Loading/LoadingComponent';
import { baseUrl } from '../../../../../shared/baseUrl';
import PaymentGateWay from '../../../../pages/OffersAndServices/PaymentGateWay';
import { ErrorComponent } from '../../../../../components/Error/ErrorComponent';

class BuyVASProductResult extends Component {
	render() {
		if (this.props.buyVASProductResponse.isLoading) {
			return <Loading />;
		} else if (this.props.buyVASProductResponse.errMess) {
			return <ErrorComponent />;
		} else {
			const buyVASProductResponse = this.props.buyVASProductResponse
				.buyVASProductResponse;
			const printLinesContent =
				buyVASProductResponse.dataField.printLinesField instanceof Array
					? buyVASProductResponse.dataField.printLinesField.map(
							(printLine, index) => {
								return (
									<MDBCardText key={index}>{printLine.valueField}</MDBCardText>
								);
							}
					  )
					: null;

			return (
				<Card>
					<CardBody>
						<MDBRow>
							<MDBCol className="col-9">
								{buyVASProductResponse.dataField.errorCodeField !== null && (
									<p className="red-text">
										{' '}
										{buyVASProductResponse.dataField.errorTextField}
									</p>
								)}
								{buyVASProductResponse.dataField.errorCodeField === null && (
									<MDBRow>
										<MDBCol className="col-12">
											<MDBCardTitle className="text-color-green">
												Invoice ref:
												{buyVASProductResponse.dataField.transRefField +
													' ' +
													buyVASProductResponse.dataField.phoneNumberField +
													' R' +
													buyVASProductResponse.dataField.amountField +
													' ' +
													buyVASProductResponse.dataField.dateField}{' '}
											</MDBCardTitle>
											{printLinesContent}
										</MDBCol>
										<MDBCol className="col-6">
											<PaymentGateWay
												location={this.props.location}
												buttonStyle={'img-checkout'}
												amountField={
													buyVASProductResponse.dataField.amountField
												}
												transRefField={
													buyVASProductResponse.dataField.transRefField
												}
											/>
										</MDBCol>
									</MDBRow>
								)}
							</MDBCol>
							<MDBCol className="col-3 pb-3">
								<img
									src={`${baseUrl}assets/img/blue_label_telecoms_logo.png`}
									alt=""
									width="100%"
								/>
							</MDBCol>
						</MDBRow>
					</CardBody>
				</Card>
			);
		}
	}
}

export default BuyVASProductResult;
