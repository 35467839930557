import React, { Component } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import InputComponent from '../../../components/Input/InputComponent';
import SelectComponent from '../../../components/Select/SelectComponent';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { Redirect } from 'react-router-dom';

const titleList = require('./titlesList');

class Authorization extends Component {
	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit(e);
	};

	render() {
		const {
			isFormValid,
			handleChange,
			personalInfo,
			getTitleValue,
			pdfGenerator,
			updateContact,
			addDispute,
			updateNature,
			fileUpload,
		} = this.props;

		if (pdfGenerator.isLoading) {
			return <Loading />;
		} else if (pdfGenerator.errMess) {
			return <ErrorComponent />;
		} else {
			if (
				typeof pdfGenerator.pdfGenerator.response !== 'undefined' &&
				pdfGenerator.pdfGenerator.response.status
			) {
				updateNature.updateNature = [];
				addDispute.addDispute = [];
				fileUpload.fileUpload = [];
				updateContact.updateContact = [];
				pdfGenerator.pdfGenerator = [];
				return <Redirect to="/dispute-success" />;
			}
			return (
				<MDBContainer>
					<MDBRow>
						<MDBCol className="col-12 col-md-12">
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold my-2">
										Authorization to obtain personal information
									</p>
								</MDBCol>
							</MDBRow>
							<MDBRow className="mt-5">
								<MDBCol md="4">
									<SelectComponent
										id="title"
										name="title"
										label="Title"
										value={personalInfo.title}
										getValue={getTitleValue}
										required={true}
										options={titleList.titles}
										handleChange={handleChange}
										labelDisplay={true}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="fullName"
										id="fullName"
										label="Name and Surname"
										readOnly={true}
										disabled={false}
										value={personalInfo.fullName}
										type="text"
										step="100"
										required={true}
										minLength="3"
										maxLength="30"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="idNumber"
										id="idNumber"
										label="Id Number"
										disabled={false}
										readOnly={true}
										value={personalInfo.idNumber}
										type="text"
										step="100"
										minLength="13"
										maxLength="13"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="4">
									<InputComponent
										name="cellNumber"
										id="cellNumber"
										label="Cellphone Number"
										readOnly={true}
										disabled={false}
										value={personalInfo.cellNumber}
										type="text"
										step="100"
										required={true}
										minLength="10"
										maxLength="10"
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
								<MDBCol md="4">
									<InputComponent
										name="residentialAddress"
										id="residentialAddress"
										label="Residing at"
										disabled={false}
										value={personalInfo.residentialAddress}
										type="text"
										step="1"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="12">
									<p>
										Do hereby authorize Xpert Decision System (Pty) Ltd, as a registered credit
										bureau to obtain my personal information, including a copy of my full credit
										report/bank statement or any other credible evidence pertaining to my account.
									</p>
									<p>
										I know and understand that the information contained therein is of confidential
										nature and entrust XDS as my agent to obtain the same on my behalf.
									</p>
									<p>
										I understand that I may revoke this authorization in writing at any time except
										the information already released as a result of this authorization, and that
										unless revoked this authorization in writing it will remain in force an effect.
									</p>
									<p>I hereby certify that the following documents has been attached hereto:</p>
									<ol>
										<li>A clear copy of my identity document.</li>
										<li>Valid proof of residential address (not older than 3 months).</li>
										<li>
											The dispute form will be generated electronically and attached to your
											dispute/query.
										</li>
									</ol>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol md="4">
									<InputComponent
										name="date"
										id="date"
										label="Date"
										disabled={false}
										value={personalInfo.date}
										readOnly={true}
										type="text"
										step="1"
										required={true}
										outline={true}
										handleChange={handleChange}
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12 mt-5 mb-5">
									<MDBBtn
										color="white-text"
										size="sm"
										className="btn-custom-secondary mr-md-5"
										onClick={this.back}
									>
										previous
									</MDBBtn>
									<MDBBtn
										color="white-text"
										size="sm"
										className="btn-custom-primary"
										onClick={this.onSubmit}
										disabled={!isFormValid}
									>
										Submit Dispute
									</MDBBtn>
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			);
		}
	}
}

export default Authorization;
