import * as ActionTypes from '../../ActionTypes';

export const KeycloakClient = (
	state = {
		isLoading: false,
		errMess: null,
		keycloakClient: [],
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.FETCH_KC_CLIENT_COMPLETE:
			return {
				...state,
				isLoading: false,
				errMess: null,
				keycloakClient: action.payload,
			};
		case ActionTypes.FETCH_KC_CLIENT_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				keycloakClient: [],
			};
		case ActionTypes.FETCH_KC_CLIENT_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				keycloakClient: [],
			};
		default:
			return state;
	}
};
