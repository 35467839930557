import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SocialMedia from '../SocialMedia/social-medial';
import zen_paia_manual from '../../../../src/assets/files/ZENAPTIX PAIA MANUAL.pdf';
import zen_privacy_policy from '../../../../src/assets/files/ZENAPTIX PRIVACY POLICY.pdf';
import zen_cookie_policy from '../../../../src/assets/files/ZENAPTIX COOKIE POLICY.pdf';

class LandingFooter extends Component {
	render() {
		return (
			<footer className="page-footer mt-0 wow fadeIn">
				<div className="footer-top p-y-2">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 pl-5 pr-5">
								<ul className="list-unstyled footer-terms ">
									<li>
										<Link to="/terms-and-conditions">Terms and Conditions</Link>
									</li>

									<li>
										<Link to="/faqs">&nbsp;| FAQs</Link>
									</li>
									<li>
										<Link to="/privacy_policy">
											&nbsp;| Privacy & Cookies Policy
										</Link>
									</li>
								</ul>

								<div>
									<ul className="list-unstyled footer-terms">
										<li>
											<a href={zen_paia_manual} target="_blank">
												&nbsp;| PAIA manual
											</a>
										</li>
										<li>
											<a href={zen_privacy_policy} target="_blank">
												&nbsp;| Privacy policy
											</a>
										</li>
										<li>
											<a href={zen_cookie_policy} target="_blank">
												&nbsp;| Cookie policy
											</a>
										</li>
									</ul>
								</div>

								<div className="pt-5 m-0">
									<p>&#9400; {new Date().getFullYear()} zenAptix</p>
								</div>
							</div>

							<div className="col-md-3 pl-5 pr-5 m-0">
								<h6>CONTACT US</h6>
								<p>Atrium on 5th, Sandton City</p>
								<p>Sandton, Johannesburg</p>
								<p>2196</p>
								<p>info@splendi.co.za</p>
							</div>
							<div className="col-md-2 social-container px-5 px-md-3">
								<h6 className="followUs mb-2 mx-0">Follow US</h6>
								<SocialMedia />
							</div>
							<div className="col-md-3 pt-0 pt-md-5">
								<img
									className="xds-banner pl-5 pr-5"
									src="assets/img/powered-by-xds-logo.svg"
									alt="xds-banner"
								/>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default LandingFooter;
