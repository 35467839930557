import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText, MDBCol, MDBRow } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { FormWithConstraints } from 'react-form-with-constraints';
import InputComponent from '../../../../components/Input/InputComponent';
import GroupRadioButtonComponent from '../../../../components/RadioButton/GroupRadioButtonComponent';
import SelectComponent from '../../../../components/Select/SelectComponent';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';
import SliderComponent from '../../../../components/Slider/SliderComponent';
import { Redirect } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';

const formData = require('./bayPortFormData');
const selectOptions = require('./BayFormSelectOptions');

class BayPortContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toQuote: false,
			formData: formData,
			isFormValid: false,
			invalidFormMessage: ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.navigateToPage = this.navigateToPage.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			toQuote: true
		});
	};

	async handleCheckboxChange(e) {
		let inputName = e.target.name;
		let inputValue = e.target.value;
		console.log('Name', inputName);
		console.log('Value', inputValue);
		if (document.getElementById(inputName).checked) {
			this.setState({
				isFormValid: true
			});
		}
	}

	async handleChange(e) {
		if (e.target) {
			e.preventDefault();
			let inputName = e.target.name;
			let inputValue = e.target.value;
			console.log('Name', inputName);

			// Validates only the given fields and returns Promise<Field[]>
			const fields = await this.form.validateFields(e.target);

			const fieldIsValid = fields.every(field => field.isValid());
			if (fieldIsValid) {
				console.log(`Field '${inputName}' is valid and value is ${inputValue}`);
			} else {
				console.log(`Field '${inputName}' is invalid`);
			}

			if (this.form.isValid()) {
				console.log('The form is valid');
			} else {
				console.log('The form is invalid');
			}
		}
	}

	async handleSubmit(e) {
		e.preventDefault();
		const form = e.target;
		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every(field => field.isValid());
		this.setState({
			isFormValid: formIsValid
		});
		if (formIsValid) {
			console.log('The form is valid');
			const formData = new FormData(form);
			let data = this.state.formData;
			data.MoneyUsedFor = selectOptions.MoneyUserFor.map(
				value => value.text
			).indexOf(formData.get('MoneyUsedFor'));
			data.AmountToBorrow = parseFloat(formData.get('AmountToBorrow')).toFixed(
				2
			);
			data.TermToBorrow = formData.get('TermToBorrow');
			data.PermanentEmployed = formData.get('PermanentEmployedYes');
			data.HomeOwnerStatus = selectOptions.HomeOwnerStatus.map(
				value => value.text
			).indexOf(formData.get('HomeOwnerStatus'));
			data.HowIsIncomePaid = selectOptions.HowIsIncomePaid.map(
				value => value.text
			).indexOf(formData.get('HowIsIncomePaid'));
			data.NumDependants = formData.get('NumDependants');
			data.CurrentBank = selectOptions.CurrentBank.map(
				value => value.text
			).indexOf(formData.get('CurrentBank'));
			data.GrossMonthlyIncome = parseFloat(
				formData.get('GrossMonthlyIncome')
			).toFixed(2);
			data.MonthlyExpenses = parseFloat(
				formData.get('MonthlyExpenses')
			).toFixed(2);
			data.NettMonthlyIncome = parseFloat(
				formData.get('NettMonthlyIncome')
			).toFixed(2);
			data.DebtReview = document.getElementById('DebtReview').checked;
			data.Insolvent = document.getElementById('Insolvent').checked;
			data.CreditConsent = document.getElementById('CreditConsent').checked;
			this.props.fetchBayPortQuote(data);
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid'
			});
			console.log('The form is invalid');
		}
	}

	getValueOfSelect = value => {
		console.log(value);
	};

	render() {
		if (this.state.toQuote && this.state.isFormValid) {
			return <Redirect to="/offers/featured/offer/bayport/accept-guote-form" />;
		}
		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				<FormWithConstraints
					className="needs-validation"
					id="request-quote-form"
					ref={formWithConstraints => (this.form = formWithConstraints)}
					onSubmit={this.handleSubmit}
					noValidate
				>
					<MDBRow>
						<MDBCol className="col-12 col-md-11">
							<Card className="pl-3 pr-3">
								<CardBody>
									<MDBRow>
										<MDBCol className="col-12 col-md-6 align-self-end">
											<MDBRow>
												<MDBCol className="col-12">
													<CardText>
														Enter the required information below for a
														hassle-free quote.
													</CardText>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol className="col-12">
													<CardTitle className="sub-title text-nowrap">
														GET A QUOTE
													</CardTitle>
												</MDBCol>
											</MDBRow>
										</MDBCol>
										<MDBCol className="col-12 col-md-3 align-self-end d-flex justify-content-center">
											<MDBRow>
												<MDBCol className="col-12">
													<p className="red-text">
														{this.state.invalidFormMessage}
													</p>
												</MDBCol>
											</MDBRow>
										</MDBCol>
										<MDBCol className="col-12 col-md-3 pb-3">
											<img
												src={`${baseUrl}assets/img/Bayport-logo-small.png`}
												alt=""
												width="100%"
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow className="partner-offers">
										<MDBCol className="col-12">
											<MDBRow>
												<MDBCol md="6">
													<SelectComponent
														id="MoneyUsedFor"
														name="MoneyUsedFor"
														label="Loan for"
														getValue={this.getValueOfSelect}
														handleChange={this.handleChange}
														required={true}
														options={selectOptions.MoneyUserFor}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="6">
													<InputComponent
														name="AmountToBorrow"
														id="AmountToBorrow"
														label="Amount To Borrow"
														hint="0"
														disabled={false}
														type="digit"
														step="100"
														required={true}
														outline={true}
														handleChange={this.handleChange}
													/>
												</MDBCol>
												<MDBCol md="6">
													<SliderComponent
														name="TermToBorrow"
														id="TermToBorrow"
														label="Term"
														value={0}
														min={1}
														max={84}
														append="months"
														handleChange={this.handleChange}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="6">
													<GroupRadioButtonComponent
														id="PermanentEmployed"
														name="PermanentEmployed"
														label="Are you permanently employed?"
														getValue={this.getValueOfSelect}
														radioButtons={[
															{
																name: 'PermanentEmployedYes',
																id: 'PermanentEmployedYes',
																label: 'Yes',
																value: 0
															},
															{
																name: 'PermanentEmployedNo',
																id: 'PermanentEmployedNo',
																label: 'No',
																value: 1
															}
														]}
													/>
												</MDBCol>
												<MDBCol md="6">
													<SelectComponent
														id="HowIsIncomePaid"
														name="HowIsIncomePaid"
														label="How is your salary paid?"
														outline={true}
														getValue={this.getValueOfSelect}
														handleChange={this.handleChange}
														required={true}
														options={selectOptions.HowIsIncomePaid}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="6">
													<SelectComponent
														id="HomeOwnerStatus"
														name="HomeOwnerStatus"
														label={'Home owner status'}
														getValue={this.getValueOfSelect}
														handleChange={this.handleChange}
														required={true}
														options={selectOptions.HomeOwnerStatus}
													/>
												</MDBCol>
												<MDBCol md="6">
													<SliderComponent
														name="NumDependants"
														id="NumDependants"
														label="Number of dependants"
														value={0}
														min={0}
														max={10}
														handleChange={this.handleChange}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="6">
													<SelectComponent
														id="CurrentBank"
														name="CurrentBank"
														label={'Current Bank'}
														getValue={this.getValueOfSelect}
														handleChange={this.handleChange}
														required={true}
														options={selectOptions.CurrentBank}
													/>
												</MDBCol>
												<MDBCol md="6">
													<InputComponent
														name="GrossMonthlyIncome"
														id="GrossMonthlyIncome"
														label="Gross Monthly Income"
														hint="0.0"
														step="0.1"
														pattern="^\d*(\.\d{0,1})?$"
														disabled={false}
														type="price"
														required={true}
														outline={true}
														handleChange={this.handleChange}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="6">
													<InputComponent
														name="MonthlyExpenses"
														id="MonthlyExpenses"
														label="Monthly Expenses"
														hint="0.0"
														step="0.1"
														pattern="^\d*(\.\d{0,1})?$"
														disabled={false}
														type="price"
														required={true}
														outline={true}
														handleChange={this.handleChange}
													/>
												</MDBCol>
												<MDBCol md="6">
													<InputComponent
														name="NettMonthlyIncome"
														id="NettMonthlyIncome"
														label="Nett Monthly Income"
														hint="0.0"
														step="0.1"
														pattern="^\d*(\.\d{0,1})?$"
														disabled={false}
														type="price"
														required={true}
														outline={true}
														handleChange={this.handleChange}
													/>
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol md="12">
													<label className="text-color-gray-light form-label-text">
														Are any of the following applicable?
													</label>
													<MDBRow>
														<MDBCol md="4">
															<CheckBoxComponent
																id="DebtReview"
																name="DebtReview"
																label="Debt Review"
																disabled={false}
																value={false}
																required={false}
																filled={true}
																handleChange={this.handleCheckboxChange}
															/>
														</MDBCol>
														<MDBCol md="4">
															<CheckBoxComponent
																id="Insolvent"
																name="Insolvent"
																label="Insolvent"
																disabled={false}
																value={false}
																required={false}
																filled={true}
																handleChange={this.handleCheckboxChange}
															/>
														</MDBCol>
														<MDBCol md="4">
															<CheckBoxComponent
																id="CreditConsent"
																name="CreditConsent"
																label="Credit Consent"
																disabled={false}
																value={false}
																required={false}
																filled={true}
																handleChange={this.handleCheckboxChange}
															/>
														</MDBCol>
													</MDBRow>
												</MDBCol>
											</MDBRow>
										</MDBCol>
									</MDBRow>
								</CardBody>
							</Card>
						</MDBCol>
						<MDBCol className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document
										.getElementById('request-quote-form')
										.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img
									src={`${baseUrl}assets/img/next_arrow_active.svg`}
									alt=""
									width="100%"
								/>
							</a>
						</MDBCol>
					</MDBRow>
				</FormWithConstraints>
			</FadeTransform>
		);
	}
}

export default BayPortContent;
