import * as ActionTypes from '../../ActionTypes';

export const SelectedOffer = (
	state = {
		isLoading: false,
		errMess: null,
		selectedOffer: [],
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.OFFER_SELECT_CREATED:
			return {
				...state,
				isLoading: false,
				errMess: null,
				selectedOffer: action.payload,
			};
		case ActionTypes.OFFER_SELECT_CREATE_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				selectedOffer: [],
			};
		case ActionTypes.OFFER_SELECT_CREATE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				selectedOffer: [],
			};
		case ActionTypes.OFFER_SELECT_UPDATED:
			return {
				...state,
				isLoading: false,
				errMess: null,
				selectedOffer: action.payload,
			};
		case ActionTypes.OFFER_SELECT_UPDATE_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				selectedOffer: [],
			};
		case ActionTypes.OFFER_SELECT_UPDATE_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				selectedOffer: [],
			};
		default:
			return state;
	}
};
