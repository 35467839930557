import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NoCreditPage.module.css';

const NoCreditPage = () => {
	return (
		<div className={`card-big ${styles.cardSizeSmall}`}>
			<div className={styles.noOffers}>
				<h3 className={styles.noOffersTitle}>No Offers Available</h3>
				<p className={styles.noOffersText}>
					Sorry, you're not eligible for any products right now. This might be because there isn't
					enough information on your credit profile or it could be that we couldn't match you with
					any products based on your credit score
				</p>
			</div>
			<div className={styles.noOffersActionButton}>
				<Link to="/dashboard">
					<div className={styles.goToDashboard}>Back To Dashboard</div>
				</Link>
			</div>
		</div>
	);
};

export default NoCreditPage;
