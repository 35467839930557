import * as ActionTypes from './ActionTypes';

export const AirtimeVASNetworks = (state={
    isLoading: true,
    errMess: null,
    airtimeVASNetworks: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_VAS_AIRTIME_NETWORKS:
            return {...state, isLoading: false, errMess:null, airtimeVASNetworks: action.payload};
        case ActionTypes.VAS_AIRTIME_NETWORKS_LOADING:
            return {...state, isLoading: true, errMess:null, airtimeVASNetworks: []};
        case ActionTypes.VAS_AIRTIME_NETWORKS_FAILED:
            return {...state, isLoading: false, errMess:action.payload, airtimeVASNetworks: []};
        default:
            return state;
    }
}
