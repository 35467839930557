import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './pageNotFound.css';
class NoMatchPage extends Component {
	render() {
		return (
			<div className="pageNotFound">
				<img
					className="pageNotFoundImage"
					src="assets/img/404.png"
					alt="page not found"
				/>
				<center>
					<Link to="/">Return to Home Page</Link>
				</center>
			</div>
		);
	}
}
export default NoMatchPage;
