import React, { Component } from 'react';
import Carousels from '../../pages/Carousels/Carousels';
import NavbarComponent from '../../../components/Navbar/NavbarComponent';
import { baseUrl } from '../../../shared/baseUrl';
import './HeaderSection.css';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavOpen: false,
			navBarBrand: 'Spendi',
			navBarImageUrl: `${baseUrl}assets/img/Splendi Logo for menubar.svg`,
			covID19ImageUrl: `${baseUrl}assets/img/covid-sa-logo.png`,

			rightNavItemsLite: [
				{ id: 1, label: 'DASHBOARD', url: '/dashboard' },
				{ id: 2, label: 'CREDIT REPORT', url: '/credit-report' },
				{
					id: 3,
					label: 'OFFERS & SERVICES',
					// url: '/offers_and_services/featured',
					url: '/offers_and_services',
				},
				{ id: 4, label: 'CRED ED', url: '/cred-ed/resources' },
				{ id: 5, label: 'SUPPORT', url: '/support' },
				{
					id: 6,
					label: 'DISPUTE',
					url: '/dispute',
				},
			],

			leftNavItemsLite: [{ id: 2, dropdown: true }],
			dropdownItemsLite: [
				{ id: 1, label: 'Edit Profile', url: '/profile', externalRoute: true },
				{ id: 2, divider: true },
				{
					id: 3,
					label: 'Logout',
					url: '/sign-out',
					icon: 'sign-out',
					externalRoute: false,
				},
			],
			rightPublicNavItems: [
				{ id: 1, label: 'HOME', url: '/' },
				{ id: 2, label: 'ABOUT', url: '/about' },
				// { id: 3, label: 'CRED ED', url: '/cred-ed/resources' },
				{ id: 4, label: 'SUPPORT', url: '/support' },
			],
			leftPublicNavItems: [
				{
					id: 3,
					label: <FontAwesomeIcon icon={faFacebook} size="1x" />,
					url: 'https://www.facebook.com/Splendicredit/?view_public_for=112462846827458',
				},
				{
					id: 4,
					label: <FontAwesomeIcon icon={faLinkedin} size="1x" />,
					url: 'https://www.linkedin.com/company/30711607',
				},
				{
					id: 5,
					label: <FontAwesomeIcon icon={faInstagram} size="1x" />,
					url: 'https://www.instagram.com/splendicredit/',
				},
				{
					id: 1,
					label: 'SIGN UP',
					url: '/sign-up',
				},
				{ id: 2, label: 'LOGIN', url: '/login' },
			],

			rightNavItems: [
				{ id: 1, label: 'DASHBOARD', url: '/dashboard' },
				{ id: 2, label: 'CREDIT REPORT', url: '/credit-report' },
				{
					id: 3,
					label: 'OFFERS & SERVICES',
					url: '/featured',
					submenuItems: [
						{ id: 1, label: 'FEATURED', url: '/offers_and_services/featured' },
						{ id: 2, label: 'JUST FOR ME', url: '/just-for-me' },
						{ id: 3, label: 'INSURANCE', url: '/insurance' },
						{ id: 4, label: 'LOAN & CREDIT', url: '/loan-&-credit' },
						{ id: 5, label: 'HOME LOANS', url: '/home-loans' },
						{ id: 6, label: 'VEHICLE FINANCE', url: '/vehicle-finance' },
						{ id: 7, label: 'BUY A CAR', url: '/buy-a-car' },
						{ id: 8, label: 'SELL YOUR CAR', url: '/sell-your-car' },
						{ id: 9, label: 'ROADSIDE ASSIST', url: '/roadside-assist' },
					],
				},
				// {
				// 	id: 4,
				// 	label: 'CRED ED',
				// 	url: '/cred-ed',
				// 	submenuItems: [
				// 		{ id: 2, label: 'SIMULATOR', url: '/cred-ed/simulator' },
				// 		{ id: 3, label: 'RESOURCES', url: '/cred-ed/resources' },
				// 	],
				// },
				{ id: 5, label: 'SUPPORT', url: '/support' },
			],
			leftNavItems: [
				{
					id: 'shoppingCart',
					label: 'SHOPPING CART',
					url: '#',
					button: true,
					imageIcon: true,
					imagePassive: `${baseUrl}assets/img/shopping_cart_icon_disabled.svg`,
					imageActive: `${baseUrl}assets/img/shopping_cart_icon_active.svg`,
					imageRollover: `${baseUrl}assets/img/shopping_cart_icon_click.svg`,
				},
				{ id: 2, dropdown: true },
			],
			dropdownItems: [
				{ id: 1, label: 'Edit Profile', url: '/profile', externalRoute: true },
				{ id: 2, divider: true },
				{
					id: 3,
					label: 'Logout',
					url: '/sign-out',
					icon: 'sign-out',
					externalRoute: false,
				},
			],
		};

		this.toggleNav = this.toggleNav.bind(this);
	}

	toggleNav() {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	}

	render() {
		const isOffersEnabled = this.props.user && this.props.user.user.offers_enabled;

		const filteredRightNavItems = isOffersEnabled
			? this.state.rightNavItems
			: this.state.rightNavItems.filter((item) => item.label !== 'OFFERS & SERVICES');

		const filteredRightNavItemsLite = isOffersEnabled
			? this.state.rightNavItemsLite
			: this.state.rightNavItemsLite.filter((item) => item.label !== 'OFFERS & SERVICES');

		return (
			<NavbarComponent
				className="text-color-purple text-center"
				navBarBrand={this.state.navBarBrand}
				navBarImageUrl={this.state.navBarImageUrl}
				covID19ImageUrl={this.state.covID19ImageUrl}
				rightNavItems={filteredRightNavItems}
				rightNavItemsLite={filteredRightNavItemsLite}
				leftNavItems={this.state.leftNavItems}
				leftNavItemsLite={this.state.leftNavItemsLite}
				rightPublicNavItems={this.state.rightPublicNavItems}
				leftPublicNavItems={this.state.leftPublicNavItems}
				user={this.props.user}
				headerContent={{
					id: 1,
					avatar: true,
					avatarBrand: 'user',
					user: this.props.user,
				}}
				dropdownItems={this.state.dropdownItems}
				dropdownItemsLite={this.state.dropdownItemsLite}
				toggle={this.props.toggle}
				updateParentState={this.props.updateParentState}
				togglePopOver={this.props.togglePopOver}
			/>
		);
	}
}

export default Header;
