import React, { Component } from 'react';
import { Col, Container, Row } from 'mdbreact';
import BuyComponent from '../../../contents/Services/Buy/BuyComponent';
import { baseUrl } from '../../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';

class ServicePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toServices: false
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			toServices: true
		});
	};

	render() {
		console.log('Location', JSON.stringify(this.props.location));
		if (this.state.toServices === true) {
			return <Redirect to="/offers_and_services/featured" />;
		}
		return (
			<Container className="mt-2">
				<Row>
					<Col className="col-12 col-md-1 justify-content-end align-self-center">
						<Row>
							<Col className="col-12 col-md-11">
								<a
									onClick={() => {
										this.navigateToPage();
									}}
								>
									<img
										src={`${baseUrl}assets/img/previous_arrow_active.svg`}
										alt=""
										width="100%"
									/>
								</a>
							</Col>
						</Row>
					</Col>
					<Col className="col-12 col-md-11">
						{this.props.location.pathname.includes('/services/buy/') && (
							<BuyComponent
								location={this.props.location}
								network={this.props.network}
								setNetwork={this.props.setNetwork}
								airtimeVASNetworks={this.props.airtimeVASNetworks}
								airtimeVASProducts={this.props.airtimeVASProducts}
								buyVASProductResponse={this.props.buyVASProductResponse}
								fetchAirtimeVASNetworks={this.props.fetchAirtimeVASNetworks}
								fetchAirtimeVASProducts={this.props.fetchAirtimeVASProducts}
								buyVASProduct={this.props.buyVASProduct}
							/>
						)}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default ServicePage;
