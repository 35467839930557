import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText, MDBCol, MDBRow } from 'mdbreact';
import { FormWithConstraints } from 'react-form-with-constraints';
import SelectComponent from '../../../../../components/Select/SelectComponent';
import { FadeTransform } from 'react-animation-components';
import { baseUrl } from '../../../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';
import AirtimeVASPinLessFormInput from './AirtimeVASPinLessFormInput';
import AirtimeVASBundledFormInput from './AirtimeVASBundledFormInput';
import AirtimeNetworkInputField from './AirtimeNetworkInputField';

class AirtimeVASNetworksForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isResult: false,
			isFormValid: false,
			invalidFormMessage: ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getValueOfSelect = this.getValueOfSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			isResult: true
		});
	};

	getValueOfSelect = value => {
		console.log(value);
	};

	async handleChange(e) {
		e.preventDefault();
		let inputName = e.target.name;
		let inputValue = e.target.value;
		console.log('Name', inputName);

		// Validates only the given fields and returns Promise<Field[]>
		const fields = await this.form.validateFields(e.target);

		const fieldIsValid = fields.every(field => field.isValid());
		if (fieldIsValid) {
			console.log(`Field '${inputName}' is valid and value is ${inputValue}`);
		} else {
			console.log(`Field '${inputName}' is invalid`);
		}

		if (this.form.isValid()) {
			console.log('The form is valid');
		} else {
			console.log('The form is invalid');
		}
	}

	async handleSubmit(e) {
		const form = e.target;

		e.preventDefault();

		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every(field => field.isValid());
		this.setState({
			isFormValid: formIsValid
		});

		if (formIsValid) {
			console.log('The form is valid');
			const formData = new FormData(form);
			const isBundles = this.props.location.pathname.includes(
				'/services/buy/blue-label/bundles/vas-network-list-form'
			);
			const product = isBundles
				? this.props.airtimeVASProducts.airtimeVASProducts.filter(
						product =>
							product.AirtimeProductName === formData.get('AirtimeProductName')
				  )[0]
				: null;
			const url = isBundles
				? 'vas-buy-bundle-airtime'
				: 'vas-buy-pinless-airtime';
			this.props.buyVASProduct(url, {
				PhoneNumber: formData.get('PhoneNumber'),
				Network: formData.get('Networks').replace(/\s/g, ''),
				Amount: isBundles
					? Number(product.ProductPrice).toFixed(2)
					: Number(formData.get('Amount')).toFixed(2),
				ProductCode: isBundles ? product.AirtimeProductCode : ''
			});
		} else {
			this.setState({
				invalidFormMessage: 'The form is invalid'
			});
			console.log('The form is invalid');
		}
	}

	render() {
		let productType = null;
		if (
			this.props.location.pathname.includes(
				'/services/buy/blue-label/airtime/vas-network-list-form'
			)
		) {
			productType = 'Airtime';
		} else if (
			this.props.location.pathname.includes(
				'/services/buy/blue-label/bundles/vas-network-list-form'
			)
		) {
			productType = 'Bundles';
		} else if (
			this.props.location.pathname.includes(
				'/services/buy/blue-label/electricity/vas-network-list-form'
			)
		) {
			productType = 'Electricity';
		}

		if (this.state.isResult && this.state.isFormValid) {
			return <Redirect to="/services/buy/blue-label/transaction/result" />;
		} else {
			return (
				<FormWithConstraints
					className="needs-validation"
					id="airtime-network-form"
					ref={formWithConstraints => (this.form = formWithConstraints)}
					onSubmit={this.handleSubmit}
					noValidate
				>
					<MDBRow>
						<MDBCol className="col-12 col-md-11">
							<Card className="pl-3 pr-3">
								<CardBody>
									<MDBRow>
										<MDBCol className="col-12 col-md-6 align-self-end">
											<CardText>Hassle-free airtime purchases</CardText>
											<CardTitle className="sub-title text-nowrap">
												Buy {productType}
											</CardTitle>
										</MDBCol>
										<MDBCol className="col-12 col-md-3 align-self-end d-flex justify-content-center">
											<MDBRow>
												<MDBCol className="col-12">
													<p className="red-text">
														{this.state.invalidFormMessage}
													</p>
												</MDBCol>
											</MDBRow>
										</MDBCol>
										<MDBCol className="col-12 col-md-3">
											<img
												src={`${baseUrl}assets/img/blue_label_telecoms_logo.png`}
												alt=""
												width="100%"
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow className="partner-offers">
										<MDBCol className="col-12">
											<MDBRow>
												<MDBCol md="6">
													<AirtimeNetworkInputField
														location={this.props.location}
														network={this.props.network}
														setNetwork={this.props.setNetwork}
														airtimeVASNetworks={this.props.airtimeVASNetworks}
														handleChange={this.handleChange}
													/>
												</MDBCol>
											</MDBRow>
											{(this.props.location.pathname.includes(
												'/services/buy/blue-label/airtime/vas-network-list-form'
											) ||
												this.props.location.pathname.includes(
													'/services/buy/blue-label/electricity/vas-network-list-form'
												)) && (
												<AirtimeVASPinLessFormInput
													location={this.props.location}
													handleChange={this.handleChange}
												/>
											)}
											{this.props.location.pathname.includes(
												'/services/buy/blue-label/bundles/vas-network-list-form'
											) &&
												this.props.network != null && (
													<AirtimeVASBundledFormInput
														handleChange={this.handleChange}
														airtimeVASProducts={this.props.airtimeVASProducts}
													/>
												)}
										</MDBCol>
									</MDBRow>
								</CardBody>
							</Card>
						</MDBCol>
						<MDBCol className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document
										.getElementById('airtime-network-form')
										.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img
									src={`${baseUrl}assets/img/next_arrow_active.svg`}
									alt=""
									width="100%"
								/>
							</a>
						</MDBCol>
					</MDBRow>
				</FormWithConstraints>
			);
		}
	}
}

export default AirtimeVASNetworksForm;
