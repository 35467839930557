import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import './dispute.css';
class ElectronicDisputeSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toDashboard: false,
		};
	}
	handleRedirect = (event) => {
		event.preventDefault();
		this.setState({
			toDashboard: !this.state.toDashboard,
		});
	};
	render() {
		let kredGuru;
		let kredImage = 'kred_guru_+2.svg';
		let kgMessage = (
			<div>
				Thank you, your dispute has been submitted. In compliance with the National Credit Act 34 of
				2005, XDS will resolve your dispute within 20 business days. Contact 011 645 9100, 0860 937
				000 or dispute@xds.co.za / info@splendi.co.za for queries.
			</div>
		);
		let header = null;
		let signature = 'KG – Splendi’s Kred Guru';
		kredGuru = (
			<KredGuruSection
				id="registeredSuccessKG"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
			/>
		);
		if (this.state.toDashboard) {
			return <Redirect to="/dashboard" />;
		}
		return (
			<MDBContainer className="dispute-success pt-5" size="sm">
				<MDBRow>
					<MDBCol className="col-12 col-md-7">
						<MDBCard>
							<MDBCardBody>
								<MDBRow>
									<MDBCol className="col-12 col-md-11 mt-3">{kredGuru}</MDBCol>
								</MDBRow>
								<div className="text-center py-0 mb-3 mt-4">
									<MDBBtn
										color="white-text"
										size="sm"
										className="btn-custom-primary "
										type="submit"
										onClick={this.handleRedirect}
									>
										Done
									</MDBBtn>
								</div>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default ElectronicDisputeSuccess;
