import React, { Component } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

class DisputeInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subscriptionKGCount: 0,
		};
	}

	render() {
		return (
			<>
				<MDBRow>
					<MDBCol className="mt-4">
						<p className="mt-4 mb-0">
							Online dispute: Click on the button “create online dispute” to create and submit your
							dispute online.
						</p>
						<p>
							Download forms: Click on the button “download forms” to download the dispute forms.
							Complete the dispute form and email it to{' '}
							<a
								href="mailto:info@splendi.co.za"
								style={{ color: '#000', textDecoration: 'underline' }}
							>
								info@splendi.co.za
							</a>{' '}
							along with the relevant documents mentioned below.
						</p>
						<p>
							<strong>
								For paid in full / closed, account is not / was never in arrears, prescription and I
								was not notified of the adverse listing the following documentation is required:
							</strong>
						</p>
						<ul>
							<li>Dispute forms signed and completed</li>
							<li>Proof of residence</li>
							<li>ID copy (clear)</li>
							<li>Power of attorney ( If requested by an attorney )</li>
							<li>Consent letter ( if letting someone send documentation on your behalf )</li>
							<li>Paid up letters</li>
						</ul>
						<p>
							<strong>
								For Fraudulent account / Incorrect judgement the following documentation is
								required:
							</strong>
						</p>
						<ul>
							<li>XDS Dispute forms signed and completed</li>
							<li> Certified copy of your ID</li>
							<li> Proof of address (not older than 3 months)</li>
							<li>Affidavit referencing the fraudulent account with SAPS case number</li>
							<li> Ten sample signatures of yours, written on one page</li>
						</ul>
						<p>
							<strong>For a paid up judgement the following documentation is required:</strong>
						</p>
						<ul>
							<li>A paid up letter with the Case number and ID number reflecting</li>
							<li>Proof of residence</li>
							<li>ID copy (clear)</li>
							<li>Power of attorney ( If requested by an attorney )</li>
							<li>Consent letter ( if letting someone send documentation on your behalf )</li>
							<li>Paid up letters</li>
						</ul>
						<p>
							<strong>
								For Rescission of Judgement / Admin order the following documentation is required:
							</strong>
						</p>
						<ul>
							<li>Dispute forms signed and completed</li>
							<li>A paid up letter with the Case number and ID number reflecting</li>
							<li>Proof of residence</li>
							<li>ID copy (clear)</li>
							<li>Power of attorney ( If requested by an attorney )</li>
							<li>Consent letter ( if letting someone send documentation on your behalf )</li>
							<li>Court order with a clear court stamp</li>
							<li>Notice of motion</li>
						</ul>
						<p>
							<strong>For Debt review please be advised:</strong>
						</p>
						<p>
							XDS has been instructed by the NCR to refer all Debt Counsellors and/or other third
							party entities to process requests through the NCR Debt Help system.
						</p>
					</MDBCol>
				</MDBRow>
			</>
		);
	}
}

export default DisputeInfo;
