import React from 'react';
import { MDBRangeInput, MDBRow, MDBCol } from 'mdbreact';
import { FieldFeedback, FieldFeedbacks } from 'react-form-with-constraints';

class SliderComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			number: props.value
		};
		this.getValue = this.getValue.bind(this);
	}

	getValue = value => {
		this.setState({ number: value });
		this.props.handleChange(value);
		console.log(value);
	};
	render() {
		return (
			<div>
				<MDBRow>
					<MDBCol center>
						<label
							className="text-color-gray-light form-label-text"
							htmlFor={this.props.id}
						>
							{this.props.label + ' '}
						</label>
						<span className="text-color-green form-label-text">
							{this.props.prepend ? this.props.prepend + ' ' : ''}
							{' ' + this.state.number}
							{this.props.append ? ' ' + this.props.append : ''}
						</span>
					</MDBCol>
					<input
						className="form-control"
						id={this.props.id}
						name={this.props.name}
						type="text"
						value={this.state.number}
						hidden
					/>
					<FieldFeedbacks className="red-text" for={this.props.name}>
						<FieldFeedback when="*" />
					</FieldFeedbacks>
				</MDBRow>
				<MDBRow center>
					<span>
						<p className="text-color-purple mr-2 text-smaller">
							{this.props.prepend ? this.props.prepend + ' ' : ''}
							{this.props.min}
						</p>
					</span>
					<MDBRangeInput
						name={this.props.name + 'temp'}
						id={this.props.id + 'temp'}
						min={this.props.min}
						max={this.props.max}
						value={this.state.number}
						getValue={this.getValue}
						formClassName="w-75"
					/>
					<span>
						<p className="text-color-purple ml-2 text-smaller">
							{this.props.prepend ? this.props.prepend + ' ' : ''}
							{this.props.max}
						</p>
					</span>
				</MDBRow>
			</div>
		);
	}
}

export default SliderComponent;
