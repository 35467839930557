import * as ActionTypes from './ActionTypes';
import { persistExpiresAt } from './persistExpiresAt';

export const User = (
	state = {
		isLoading: true,
		errMess: null,
		isAuthenticated: false,
		user: [],
		persistExpiresAt: persistExpiresAt(),
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_USER:
			return {
				...state,
				isLoading: false,
				errMess: null,
				user: action.payload,
				isAuthenticated: true,
			};
		case ActionTypes.USER_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				user: [],
				isAuthenticated: false,
			};
		case ActionTypes.USER_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				user: [],
				isAuthenticated: false,
			};

		case ActionTypes.LOGOUT:
			return {
				...state,
				errMess: null,
				user: [],
				isAuthenticated: false,
			};
		default:
			return state;
	}
};
