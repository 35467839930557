import * as ActionTypes from './ActionTypes';
import { persistExpiresAt } from './persistExpiresAt';

export const Consumer = (
	state = {
		isLoading: true,
		errMess: null,
		consumer: [],
		persistExpiresAt: persistExpiresAt()
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ADD_CONSUMER:
			return {
				...state,
				isLoading: false,
				errMess: null,
				consumer: action.payload
			};
		case ActionTypes.CONSUMER_LOADING:
			return { ...state, isLoading: true, errMess: null, consumer: [] };
		case ActionTypes.CONSUMER_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				consumer: []
			};
		default:
			return state;
	}
};
