import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const fetchNewSubscription = (formData) => (dispatch) => {
	dispatch(newSubscriptionLoading(true));
	console.log('New Subscription form data ', formData);
	return fetch(baseUrl + requestPath('api/submit-new-subscription'), {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Subscription failed. Please contact support or try again later');
					error.response = response;
					throw error;
				}
			},
			(error) => {
				console.log('error:', error);
				throw new Error(error);
			}
		)
		.then((response) => response.json())
		.then((newSub) => dispatch(addNewSubscription(newSub)))
		.catch((error) => dispatch(newSubscriptionFailed(error.message)));
};

export const newSubscriptionLoading = () => ({
	type: ActionTypes.NEW_SUBSCRIPTION_LOADING,
});

export const newSubscriptionFailed = (errmess) => ({
	type: ActionTypes.NEW_SUBSCRIPTION_FAILED,
	payload: errmess,
});

export const addNewSubscription = (subscription) => ({
	type: ActionTypes.ADD_NEW_SUBSCRIPTION,
	payload: subscription,
});
