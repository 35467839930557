import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';
import { requestPath } from '../../util/util';

export const disputeFormPDF = (data) => (dispatch) => {
	dispatch(generatePDFLoading(true));
	return fetch(baseUrl + requestPath('api/dispute-form-pdf'), {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf',
		},
		credentials: 'same-origin',
	})
		.then(
			(response) => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error('Error ' + response.status + ': ' + response.statusText);
					error.response = response;
					throw error;
				}
			},
			(error) => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then((response) => response.json())
		.then((data) => dispatch(generatePDF(data)))
		.catch((error) => dispatch(generatePdfFailed(error.message)));
};

export const generatePDFLoading = () => ({
	type: ActionTypes.GENERATE_PDF_LOADING,
});

export const generatePdfFailed = (errmess) => ({
	type: ActionTypes.GENERATE_PDF_FAILED,
	payload: errmess,
});

export const generatePDF = (data) => ({
	type: ActionTypes.GENERATE_PDF,
	payload: data,
});
