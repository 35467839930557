import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DetailedReportPDFPaymentFailure.module.css';

const DetailedReportPDFPaymentError = () => {
	return (
		<div className={`card-big ${styles.cardSizeSmall}`}>
			<div className={styles.errorMessageContainer}>
				<div className={styles.errorGreyBox}>
					<div className={styles.errorText}>Oh no! Something went wrong, please try again.</div>
					<div className={styles.kgText}>
						<i>
							<strong>KG – Splendi’s Kred Guru</strong>
						</i>
					</div>
				</div>
				<div className={styles.kgError} />
			</div>
			<div className={styles.kgErrorButtons}>
				<div onClick={() => (window.location.href = '/dashboard')}>
					<button className={styles.kgErrorCancel}>Cancel</button>
				</div>
				<div tonClick={() => (window.location.href = '/credit-report')}>
					<div className={styles.kgErrorTryAgain}>Try again</div>
				</div>
			</div>
		</div>
	);
};

export default DetailedReportPDFPaymentError;
