export const FEATURE_TOGGLE_LIST =
	window._env_.FEATURE_TOGGLE_LIST ||
	"[SPLENDI_LITE: 'splendiLite', NAV_BAR_ITEMS_LITE: 'navBarItems', CREDIT_ED_LITE: 'credit_end_lite', SHOPPING_CARD: 'shoppingCard',CRED_ED_LITE: 'cred_ed_lite', CRED-ED-SIMULATOR: 'cred_ed_simulator',SERVICEPAGE-AND-OFFERS:'service_and_offers',PDF_DOWNLOAD: 'pdf_download']";

export const FEATURE_TOGGLE_VALUES = {
	// Try setting this to true
	[FEATURE_TOGGLE_LIST.SPLENDI_LITE]: true,
	[FEATURE_TOGGLE_LIST.NAV_BAR_ITEMS_LITE]: true,
	[FEATURE_TOGGLE_LIST.CREDIT_ED_LITE]: true,
	[FEATURE_TOGGLE_LIST.SHOPPING_CARD]: true,
	[FEATURE_TOGGLE_LIST.CRED_ED_LITE]: true,
	[FEATURE_TOGGLE_LIST.CRED_ED_SIMULATOR]: true,
	[FEATURE_TOGGLE_LIST.SERVICEPAGE_AND_OFFERS]: true,
	[FEATURE_TOGGLE_LIST.PDF_DOWNLOAD]: true,
	// ... add more here
};
