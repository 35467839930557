import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';

export const ModalComponent = (props) => {
	let isOpen = props.modal.isOpen;
	return (
		<Modal
			className="form-elegant"
			isOpen={isOpen}
			toggle={() => {
				isOpen = false;
			}}
			size={props.size}
		>
			<ModalHeader
				className={props.modal.className}
				titleClass="title-2 w-100 font-weight-bold my-3"
				toggle={() => props.toggle()}
			>
				{props.modal.header}
			</ModalHeader>
			<ModalBody className={`mx-3 ${props.modal.bodyClass}`}>{props.modal.content}</ModalBody>
			<ModalFooter className={`mx-3 ${props.modal.footerClass}`}>{props.modal.footer}</ModalFooter>
		</Modal>
	);
};
