import React, { Component } from 'react';
import { Row, Col, Animation } from 'mdbreact';
import {
	RenderDebtGuage,
	RenderDeptComponent,
} from '../../components/Charts/ProgressDebtGuage/ProgressDebtGuageComponent';
import { Loading } from '../../../components/Loading/LoadingComponent';
import KredGuruSection from '../KredGuru/KredGuruSection';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import './CreditScoreSection.css';
let quotesData = require('../../../shared/data');

class CreditScoreSection extends Component {
	render() {
		if (this.props.consumer.isLoading) {
			return <Loading />;
		} else if (this.props.consumer.errMess) {
			return <ErrorComponent />;
		} else {
			const consumer = this.props.consumer.consumer;
			const cpaConsumerAccounts =
				consumer.ConsumerAccountStatus instanceof Array ? consumer.ConsumerAccountStatus : [];
			const nlrConsumerAccounts =
				consumer.ConsumerNLRAccountStatus instanceof Array ? consumer.ConsumerNLRAccountStatus : [];
			const cpaConsumer24MonthlyPayment = consumer.Consumer24MonthlyPayment;
			const nrlConsumer24MonthlyPayment = consumer.ConsumerNLR24MonthlyPayment;
			const cpaConsumer24MonthlyPaymentHeader = consumer.Consumer24MonthlyPaymentHeader;
			const nrlConsumer24MonthlyPaymentHeader = consumer.ConsumerNLR24MonthlyPaymentHeader;
			const paymentHeaders = [];
			let count = 0;
			for (let [key, value] of Object.entries(cpaConsumer24MonthlyPaymentHeader)) {
				if (key !== 'DisplayText' && key !== 'Company') {
					count++;
					if (count > 12) {
						paymentHeaders.push(value.value);
					}
				}
			}

			let shortTermOutstandingDebt = 0;
			let shortTermCreditLimit = 0;
			cpaConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(cpaConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['Z', 'S', 'R', 'F', 'G', 'I', 'M', 'N', 'O', 'P', 'V', 'K', '1', '2', '3', '4'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					shortTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					shortTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			nlrConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(nrlConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['Z', 'S', 'R', 'F', 'G', 'I', 'M', 'N', 'O', 'P', 'V', 'K', '1', '2', '3', '4'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					shortTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					shortTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			const shortTermUsedPercentage =
				shortTermCreditLimit === 0
					? 0
					: Math.round((shortTermOutstandingDebt / shortTermCreditLimit) * 100);

			let longTermCreditLimit = 0;
			let longTermOutstandingDebt = 0;
			cpaConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(cpaConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['B', 'C', 'D', 'E', 'H', 'J', 'L', 'T', 'U', 'W', 'X', 'Y'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					longTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					longTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			nlrConsumerAccounts.forEach((account, index) => {
				const containc = Object.values(nrlConsumer24MonthlyPayment[index]).map((obj) => obj.value);
				if (
					['B', 'C', 'D', 'E', 'H', 'J', 'L', 'T', 'U', 'W', 'X', 'Y'].includes(
						account.AccountType.value
					) &&
					account.StatusCodeDesc.value !== 'Closed' &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Acc Sold To 3rd Party') &&
					!(containc.includes('C') && account.StatusCodeDesc.value === 'Account Purchased')
				) {
					longTermCreditLimit += Number(account.CreditLimitAmt.value.replace(/\s/g, ''));
					longTermOutstandingDebt += Number(account.CurrentBalanceAmt.value.replace(/\s/g, ''));
				}
			});
			const longTermUsedPercentage =
				longTermCreditLimit === 0
					? 0
					: Math.round((longTermOutstandingDebt / longTermCreditLimit) * 100);

			const shortTerm = {
				title: 'REVOLVING & SHORT TERM DEBT',
				creditData: {
					totalOutstandingDebt: shortTermOutstandingDebt,
					creditLimitLabel: 'Credit Limit',
					totalCreditLimit: shortTermCreditLimit,
					creditType: 'short',
				},
				totalDebtPercentage: shortTermUsedPercentage,
				trailColor: 'rgb(72,72,72)',
				strokeColor: 'rgb(255, 165, 0)',
				status: 'USED',
				percentageColor: 'text-color-orange',
				description: quotesData.D1,
				id: 'D1',
			};
			const longTerm = {
				title: 'MEDIUM & LONG TERM DEBT',
				creditData: {
					totalOutstandingDebt: longTermOutstandingDebt,
					creditLimitLabel: 'Credit Limit',
					totalCreditLimit: longTermCreditLimit,
					creditType: 'long',
				},
				totalDebtPercentage: longTermUsedPercentage,
				trailColor: 'rgb(72,72,72)',
				strokeColor: 'rgb(148,148,196)',
				status: 'USED',
				percentageColor: 'text-color-light-purple',
				description: quotesData.D2,
				id: 'D2',
			};
			let kredImage = null;
			let kredContent = null;
			if (consumer.ConsumerScoring.RiskCategory.value === 'Medium Risk') {
				kredImage = 'kred_guru_-1.svg';
				kredContent = `Your credit score is stable ${
					consumer.ConsumerDetail.FirstName.value
				}! Check out Splendi’s Cred Ed resources for tips to boost your credit reputation.`;
			} else if (consumer.ConsumerScoring.RiskCategory.value === 'Potential High Risk') {
				kredImage = 'kred_guru_-2.svg';
				kredContent = `Your credit reputation needs attention &lt;User First Name&gt;. Use our Cred Ed resources to improve your creditworthiness.`;
			} else if (consumer.ConsumerScoring.RiskCategory.value === 'Low Risk') {
				kredImage =
					Number(consumer.ConsumerScoring.FinalScore.value) < 840
						? 'kred_guru_+2.svg'
						: 'kred_guru_+2.svg';
				kredContent =
					Number(consumer.ConsumerScoring.FinalScore.value) < 840
						? `Nice work ${
								consumer.ConsumerDetail.FirstName.value
						  }! Your smart credit decisions are paying off.`
						: `Splendid ${
								consumer.ConsumerDetail.FirstName.value
						  }! Your credit score is looking healthy - Keep it up!`;
			}

			if (Number(consumer.ConsumerCPANLRDebtSummary.TotalArrearAmount.value) > 0) {
				kredImage = 'kred_guru_-2.svg';
				kredContent = `Your total arrears amount is R ${Number(
					consumer.ConsumerCPANLRDebtSummary.TotalArrearAmount.value
				).toFixed(
					2
				)}. Making additional payments to get all accounts up to date is recommended. Your credit score and your debtors will be grateful.`;
			} else if (Number(consumer.ConsumerCPANLRDebtSummary.NoOfAccountInBadStanding.value) > 0) {
				kredImage = 'kred_guru_-2.svg';
				kredContent =
					'Settling your accounts in bad standing - is a good idea. The earlier, the better for the sake of your\n' +
					' credit reputation. Check out Splendi’s Cred Ed resources for tips to boost your Cred Rep.';
			} else if (
				Number(consumer.ConsumerCPANLRDebtSummary.NoOfAccountsOpenedinLast45Days.value) > 0
			) {
				kredImage = 'kred_guru_-1.svg';
				kredContent =
					'Quick heads-up. A new account is open in your name. It will show as ‘New’ for 45 days after the\n' +
					'account activation. After this, it will show up in your Active Accounts.';
			}

			return (
				<Col className="col-12 col-md-6 pl-5 pr-5 pr-md-3">
					<Row>
						<Col className="col-md-2 offset-md-10 ml-auto mt-4" />
					</Row>
					<KredGuruSection id="OverviewKG" kredGuruImage={kredImage} content={kredContent} />
					<Row className="mt-4">
						<Col className="align-self-center col-md-auto col-auto">
							<Row>
								<Col className="col-12 credit-score">
									<h5 className="h5-responsive  sub-title">YOUR</h5>
								</Col>
								<Col className="col-12 credit-score">
									<h5 className="h5-responsive sub-title">CREDIT SCORE</h5>
								</Col>
							</Row>
						</Col>
						<Animation className="col-md-auto credit-score" type="bounce">
							<h1 className="display-1  text-nowrap font-weight-bolder">
								{consumer.ConsumerScoring.FinalScore.value}
							</h1>
						</Animation>
						<Col className="col-md-auto col-auto align-self-center">
							<Row>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">OUT OF</h5>
								</Col>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">1000</h5>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col className="col-12 col-md-6 align-self-center">
							<Row>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">REVOLVING &</h5>
								</Col>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">SHORT TERM DEBT</h5>
								</Col>
							</Row>
							<RenderDeptComponent data={shortTerm.creditData} />
						</Col>
						<Col className="col-12 col-md-6">
							<RenderDebtGuage data={shortTerm} />
						</Col>
					</Row>
					<Row className="mt-4">
						<Col className="col-12 col-md-6 align-self-center">
							<Row>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">MEDIUM &</h5>
								</Col>
								<Col className="col-12 text-center">
									<h5 className="h5-responsive sub-title">LONG TERM DEBT</h5>
								</Col>
							</Row>
							<RenderDeptComponent data={longTerm.creditData} />
						</Col>
						<Col className="col-12 col-md-6">
							<RenderDebtGuage data={longTerm} />
						</Col>
					</Row>
				</Col>
			);
		}
	}
}

export default CreditScoreSection;
