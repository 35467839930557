import React, { Component } from 'react';
import './about.css';
import LandingFooter from '../Footer/footer';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBContainer } from 'mdbreact';

class About extends Component {
	render() {
		return (
			<div className="about-page-main">
				<MDBContainer className="mt-2">
					<p
						className="text-center"
						style={{
							marginTop: '7rem',
							marginBottom: '1rem',
						}}
					>
						<a href="https://sacoronavirus.co.za/" target="_blank">
							<img
								src="assets/img/covid-sa-logo.png"
								alt="COVID19"
								width="auto"
								height="auto"
								className="covid-image"
							/>
						</a>
					</p>
					<MDBCard className="about-card">
						<MDBCardBody className="pb-0">
							<MDBRow className="d-md-flex justify-content-between px-3 ">
								<MDBCol className="col-12 col-md-7 p-2">
									<MDBCardTitle style={{ color: '#3f51b5' }} className="h4-responsive title">
										ABOUT SPLENDI
									</MDBCardTitle>
									<div style={{ textAlign: 'justify' }}>
										<p>
											Splendi is a cutting-edge South African innovation championing credit wellness
											by delivering your most up-to-date credit information for free, anytime you
											choose.
										</p>
										<p>
											Custom-designed with your credit health at the centre of it, Splendi
											calculates your latest credit score, on each login.
										</p>
										<p>
											By uniquely combining real-time data with handy tech tools, Splendi unlocks
											new ways for you to manage, protect and improve your credit reputation.
										</p>
										<p>
											Our Cred Ed resources offer helpful pointers and financial scenarios to
											empower you with insights to make smarter credit decisions.
										</p>
										<p>
											Splendi simplifies things by grouping all your open accounts and loans in one
											place and displays your risk profile and percentages of debt used and paid
											back.
										</p>
										<p>
											Access to your 24-month account history helps you easily keep track of your
											credit commitments. You will also know when new accounts open in your name or
											are in arrears.
										</p>
										<p>
											Knowing where you are is the first step to growing and securing a credit
											profile that supports your future goals and ambitions.
										</p>
									</div>
								</MDBCol>
								<MDBCol className="col-12 col-md-5 px-2 pt-2 pb-0 about-images">
									<MDBRow>
										<MDBCol className="col-md-3">
											<img src="assets/img/about_splendi_icon_1.svg" alt="" />
										</MDBCol>
										<MDBCol className="col-md-3">
											<img src="assets/img/about_splendi_icon_2.svg" alt="" />
										</MDBCol>
										<MDBCol className="col-md-3">
											<img src="assets/img/about_splendi_icon_3.svg" alt="" />
										</MDBCol>
										<MDBCol className="col-md-3">
											<img src="assets/img/about_splendi_icon_4.svg" alt="" />
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-md-12">
											<img
												className="piggybank"
												src="assets/img/about_splendi_piggybank.jpg"
												alt=""
											/>
										</MDBCol>
									</MDBRow>
								</MDBCol>
							</MDBRow>
						</MDBCardBody>
					</MDBCard>
				</MDBContainer>
				<LandingFooter />
			</div>
		);
	}
}

export default About;
