import { baseUrl } from '../../../shared/baseUrl';
import * as ActionTypes from '../../ActionTypes';

export const fetchEnvironmentVariables = () => dispatch => {
	dispatch(envLoading());
	return fetch(baseUrl + 'sec/env-variables')
		.then(
			response => {
				if (response.ok) {
					return response;
				} else {
					let error = new Error(
						'Error ' + response.status + ': ' + response.statusText
					);
					error.response = response;
					throw error;
				}
			},
			error => {
				let errmess = new Error(error.message);
				throw errmess;
			}
		)
		.then(response => response.json())
		.then(user => dispatch(envSuccess(user)))
		.catch(error => dispatch(envFailed(error.message)));
};

export const envLoading = () => {
	localStorage.setItem('continue', false);
	return {
		type: ActionTypes.ENV_VARIABLES_LOADING
	};
};

export const envFailed = errmess => ({
	type: ActionTypes.ENV_VARIABLES_FAILED,
	payload: errmess
});

export const envSuccess = user => {
	localStorage.setItem('continue', true);
	return {
		type: ActionTypes.ENV_VARIABLES_SUCCESS,
		payload: user
	};
};
