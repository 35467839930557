import React, { Component } from 'react';
import { MDBRow, MDBCard, MDBCardBody, MDBCardTitle, MDBCol } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../shared/baseUrl';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import CredEdResourceSummary from './components/CredEdResourceSummary';
import { Loading } from '../../../../src/components/Loading/LoadingComponent';

class CredEdResourcePage extends Component {
	render() {
		let resources = null;
		let resourcePage = null;

		if (
			this.props.credEdCmsArticles.articles &&
			this.props.credEdCmsArticlesIsLoading === false
		) {
			resources = this.props.credEdCmsArticles.articles.map(
				(article, index) => (
					<CredEdResourceSummary
						title={article.summary.title}
						slug={article.slug}
						summaryContent={article.summary.summary_content}
						thumbnail={article.summary.thumbnail.url}
						key={index}
					/>
				)
			);
		}

		if (
			this.props.credEdCmsArticles.articles &&
			this.props.credEdCmsArticlesIsLoading === true
		) {
			resourcePage = <Loading />;
		}

		if (
			this.props.credEdCmsArticles.articles &&
			this.props.credEdCmsArticlesIsLoading === false
		) {
			resourcePage = (
				<FadeTransform
					in
					transfromProps={{
						exitTransform: 'scale(0.5) translate()-50%'
					}}
				>
					<MDBCard className="h-100">
						<MDBCardBody className="h-100">
							<MDBRow className="d-md-flex justify-content-between px-3 pb-5">
								<MDBCol className="col-12 col-md-6 p-2">
									<MDBCardTitle className="h4-responsive title">
										CRED ED RESOURCES
									</MDBCardTitle>
									<p>
										Financial wisdom emerges when calculated risks deliver value
										beyond the purchase price, interest rate and inflation.
										Splendi's Cred Ed resources are crafted to enable you to
										navigate your financial future on your terms.
									</p>
									<Link style={{ fontWeight: 'bold' }} to={`/cred-ed/covid19`}>
										Find out more on how To Manage Your Accounts And Finances
										During COVID- 19
									</Link>
								</MDBCol>
								<MDBCol className="col-12 col-md-6 p-2">
									<KredGuruSection
										id="DashboardKG"
										kredGuruImage={`kred_guru_+3.svg`}
										content={
											'Our Cred Ed resources are developed to help\n' +
											'you up-your-game with How-To tips for\n' +
											'making smarter credit decisions and to boost\n' +
											'your Cred Rep to new levels!'
										}
										signature={'KG – Splendi’s Kred Guru'}
									/>
								</MDBCol>
							</MDBRow>
							{resources}
						</MDBCardBody>
					</MDBCard>
				</FadeTransform>
			);
		}

		return resourcePage;
	}
}

export default CredEdResourcePage;
