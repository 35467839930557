import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import KredGuruSection from '../../sections/KredGuru/KredGuruSection';
import './SubscriptionPaymentSuccess.css';
class SubscriptionPaymentSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toDashboard: false,
		};
	}
	handleRedirect = (event) => {
		event.preventDefault();
		this.setState({
			toDashboard: !this.state.toDashboard,
		});
	};
	render() {
		const user = this.props.user.user;
		let kredGuru;
		let kredImage = 'kred_guru_+2.svg';
		const link = (
			<a href="mailto:info@splendi.co.za" style={{ color: '#0000FF' }}>
				info@splendi.co.za
			</a>
		);
		let kgMessage = (
			<div>
				Congratulations {user.givenName}, you are now subscribed to our Launch offer! Should you
				wish to change your payment date, please email us at {link}{' '}
			</div>
		);
		let header = null;
		let signature = 'KG – Splendi’s Kred Guru';
		kredGuru = (
			<KredGuruSection
				id="registeredSuccessKG"
				kredGuruImage={kredImage}
				header={header}
				content={kgMessage}
				signature={signature}
			/>
		);
		if (this.state.toDashboard) {
			return <Redirect to="/dashboard" />;
		}
		return (
			<MDBContainer className="validation-success pt-5" size="sm">
				<MDBRow className="d-flex justify-content-center">
					<MDBCol className="col-12 col-md-7">
						<MDBCard>
							<MDBCardBody>
								<MDBRow className="d-flex justify-content-center">
									<MDBCol className="col-12 col-md-11 mt-3">
										{kredGuru}
										{/*<p className="py-0">*/}
										{/*	Your welcome pack has been emailed to you.*/}
										{/*</p>*/}
									</MDBCol>
								</MDBRow>
								<div className="text-center py-0 mb-5 mt-4">
									<MDBBtn
										color="white-text"
										size="sm"
										className="successButton"
										type="submit"
										onClick={this.handleRedirect}
									>
										Done
									</MDBBtn>
								</div>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default SubscriptionPaymentSuccess;
