import * as ActionTypes from '../../ActionTypes';
import { persistExpiresAt } from '../../persistExpiresAt';

export const GenerateDisputeId = (
	state = {
		isLoading: false,
		errMess: null,
		disputeId: [],
		persistExpiresAt: persistExpiresAt()
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.GENERATE_DISPUTE_ID:
			return {
				...state,
				isLoading: false,
				errMess: null,
				disputeId: action.payload
			};
		case ActionTypes.GENERATE_DISPUTE_ID_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				disputeId: []
			};
		case ActionTypes.GENERATE_DISPUTE_ID_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				disputeId: []
			};
		default:
			return state;
	}
};
