import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, Card, CardBody, MDBTable, MDBTableBody, Fa } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { FormWithConstraints } from 'react-form-with-constraints';
import InputComponent from '../../../../../components/Input/InputComponent';
import SelectComponent from '../../../../../components/Select/SelectComponent';
import DatePickerComponent from '../../../../../components/DatePicker/DatePickerComponent';
import './PersonalDetailsSection.css';

class RenderPersonalDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isReadOnly: true,
		};
		this.toggleEditForm = this.toggleEditForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getValueOfSelect = this.getValueOfSelect.bind(this);
	}

	toggleEditForm() {
		this.setState({
			isReadOnly: !this.state.isReadOnly,
		});
	}

	async handleChange(e) {
		let inputName = e.target.name;
		let inputValue = e.target.value;

		let data = { ...this.props.consumerDetail };

		// Validates only the given fields and returns Promise<Field[]>
		const fields = await this.form.validateFields(e.target);
		data[inputName].value = inputValue;
		this.setState(data);
		console.log(`Field '${inputName}' is valid and value is ${inputValue}`);

		const fieldIsValid = fields.every((field) => field.isValid());
		if (fieldIsValid) {
			console.log(`Field '${inputName}' is valid and value is ${inputValue}`);
		} else {
			console.log(`Field '${inputName}' is invalid`);
		}

		if (this.form.isValid()) {
			console.log('The form is valid');
		} else {
			console.log('The form is invalid');
		}
	}

	async handleSubmit(e) {
		const form = e.target;

		e.preventDefault();

		// Validates the non-dirty fields and returns Promise<Field[]>
		const fields = await this.form.validateForm();

		// or simply use this.form.isValid()
		const formIsValid = fields.every((field) => field.isValid());

		if (formIsValid) {
			console.log('The form is valid');

			const consumerDetail = this.props.consumerDetail;
			const formData = new FormData(form);
			consumerDetail.TitleDesc.value = formData.get('title');
			consumerDetail.Gender.value = formData.get('gender');
			consumerDetail.MaritalStatusDesc.value = formData.get('maritalStatus');
			consumerDetail.BirthDate.value = formData.get('dateOfBirth');
			// this.props.updatePersonDetails(consumerDetail);
			this.toggleEditForm();
		} else {
			console.log('The form is invalid');
		}
	}

	getValueOfSelect = (value) => {
		console.log(value);
	};

	render() {
		const { consumerDetail } = this.props;

		let personalDetails = <div />;
		if (!this.state.isReadOnly) {
			personalDetails = (
				<FormWithConstraints
					className="needs-validation"
					ref={(formWithConstraints) => (this.form = formWithConstraints)}
					onSubmit={this.handleSubmit}
					noValidate
				>
					<MDBRow>
						<MDBCol className="col-12">
							<InputComponent
								name="ReferenceNo"
								id="ReferenceNo"
								label={consumerDetail.ReferenceNo.label}
								disabled={true}
								type="text"
								value={consumerDetail.ReferenceNo.value}
								handleChange={this.handleChange}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="Surname"
								id="Surname"
								label={consumerDetail.Surname.label}
								hint={consumerDetail.Surname.value}
								disabled={false}
								type="text"
								value={consumerDetail.Surname.value}
								handleChange={this.handleChange}
								required={true}
								minLength={3}
								maxLength={15}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="FirstName"
								id="FirstName"
								label={consumerDetail.FirstName.label}
								hint={consumerDetail.FirstName.value}
								value={consumerDetail.FirstName.value}
								disabled={false}
								type="text"
								handleChange={this.handleChange}
								required={true}
								minLength={3}
								maxLength={15}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="SecondName"
								id="SecondName"
								label={consumerDetail.SecondName.label}
								hint={consumerDetail.SecondName.value}
								value={consumerDetail.SecondName.value}
								disabled={false}
								type="text"
								handleChange={this.handleChange}
								required={false}
								minLength={3}
								maxLength={15}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="MaidenName"
								id="MaidenName"
								label={consumerDetail.MaidenName.label}
								hint={consumerDetail.MaidenName.value}
								value={consumerDetail.MaidenName.value}
								disabled={false}
								type="text"
								handleChange={this.handleChange}
								required={false}
								minLength={3}
								maxLength={15}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="6">
							<SelectComponent
								id="TitleDesc"
								name="TitleDesc"
								label={consumerDetail.TitleDesc.label}
								getValue={this.getValueOfSelect}
								handleChange={this.handleChange}
								value={consumerDetail.TitleDesc.value}
								options={[
									{ label: 'Mister', value: 'Mister', id: 1 },
									{ label: 'Mr', value: 'Mr', id: 2 },
									{ label: 'Mrs', value: 'Mrs', id: 3 },
									{ label: 'Miss', value: 'Miss', id: 4 },
									{ label: 'Dr', value: 'Dr', id: 5 },
								]}
							/>
						</MDBCol>
						<MDBCol md="6">
							<SelectComponent
								id="Gender"
								name="Gender"
								label={consumerDetail.Gender.label}
								getValue={this.getValueOfSelect}
								handleChange={this.handleChange}
								value={consumerDetail.Gender.value}
								options={[
									{ label: 'Female', value: 'Female', id: 1 },
									{ label: 'Male', value: 'Male', id: 2 },
								]}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="IDNo"
								id="IDNo"
								label={consumerDetail.IDNo.label}
								hint={consumerDetail.IDNo.value}
								value={consumerDetail.IDNo.value}
								disabled={false}
								type="number"
								handleChange={this.handleChange}
								required={true}
								minLength={10}
								maxLength={13}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<InputComponent
								name="PassportNo"
								id="PassportNo"
								label={consumerDetail.PassportNo.label}
								hint={consumerDetail.PassportNo.value}
								value={consumerDetail.PassportNo.value}
								disabled={false}
								type="text"
								handleChange={this.handleChange}
								required={false}
								minLength={10}
								maxLength={13}
							/>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="6">
							<DatePickerComponent
								id="BirthDate"
								name="BirthDate"
								label={consumerDetail.BirthDate.label}
								value={consumerDetail.BirthDate.value}
								getValue={this.getValueOfSelect}
							/>
						</MDBCol>
						<MDBCol md="6">
							<SelectComponent
								id="MaritalStatusDesc"
								name="MaritalStatusDesc"
								label={consumerDetail.MaritalStatusDesc.label}
								getValue={this.getValueOfSelect}
								handleChange={this.handleChange}
								value={consumerDetail.MaritalStatusDesc.value}
								options={[
									{ label: 'Single', value: 'Single', id: 1 },
									{ label: 'Married', value: 'Married', id: 2 },
									{ label: 'Widow', value: 'Widow', id: 3 },
									{ label: 'Other', value: 'Other', id: 4 },
								]}
							/>
						</MDBCol>
					</MDBRow>

					<MDBRow className="d-flex justify-content-end">
						<MDBCol md="10" className="d-none d-md-block">
							<MDBBtn rounded type="submit" gradient="peach">
								SAVE
							</MDBBtn>
							<MDBBtn rounded gradient="purple" onClick={this.toggleEditForm}>
								CANCEL
							</MDBBtn>
						</MDBCol>
						<MDBCol md="10" className="d-block d-sm-none">
							<MDBBtn type="submit" tag="a" floating gradient="peach" onClick={this.toggleEditForm}>
								<Fa icon="save" />
							</MDBBtn>
							<MDBBtn tag="a" floating gradient="purple" onClick={this.toggleEditForm}>
								<Fa icon="close" />
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</FormWithConstraints>
			);
		} else {
			personalDetails = (
				<MDBTable borderless responsive className="table-sm text-white">
					<MDBTableBody>
						<tr>
							<th>{consumerDetail.Surname.label}</th>
							<td>{consumerDetail.Surname.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.Initials.label}</th>
							<td>{consumerDetail.Initials.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.FirstName.label}</th>
							<td>{consumerDetail.FirstName.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.SecondName.label}</th>
							<td>{consumerDetail.SecondName.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.MaidenName.label}</th>
							<td>{consumerDetail.MaidenName.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.TitleDesc.label}</th>
							<td>{consumerDetail.TitleDesc.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.Gender.label}</th>
							<td>{consumerDetail.Gender.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.IDNo.label}</th>
							<td>{consumerDetail.IDNo.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.PassportNo.label}</th>
							<td>{consumerDetail.PassportNo.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.BirthDate.label}</th>
							<td>{consumerDetail.BirthDate.value}</td>
						</tr>
						<tr>
							<th>{consumerDetail.MaritalStatusDesc.label}</th>
							<td>{consumerDetail.MaritalStatusDesc.value}</td>
						</tr>
					</MDBTableBody>
				</MDBTable>
			);
		}

		let updateMDBButton = <div />;
		if (this.state.isReadOnly) {
			updateMDBButton = (
				<MDBCol md="6" className="d-flex justify-content-end">
					<MDBBtn
						rounded
						className="d-none d-md-block"
						color="mdb-color"
						onClick={this.toggleEditForm}
					>
						UPDATE
					</MDBBtn>
					<MDBBtn
						className="d-block d-sm-none"
						tag="a"
						floating
						gradient="aqua"
						onClick={this.toggleEditForm}
					>
						<Fa icon="pencil" />
					</MDBBtn>
				</MDBCol>
			);
		}

		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%',
				}}
			>
				<Card className="text-white bg-color-purple-dark-2">
					<CardBody>
						<MDBRow>
							<MDBCol className="col-12">{personalDetails}</MDBCol>
						</MDBRow>
					</CardBody>
				</Card>
			</FadeTransform>
		);
	}
}

export default RenderPersonalDetails;
