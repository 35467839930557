import React, { Component } from 'react';
import { Col, Container, Row, CardBody, Card } from 'mdbreact';
import BayPortContent from '../../../contents/partner-offers/BayPort/BayPort';
import AARoadsideAssistOffer from '../../../contents/partner-offers/AARoadsideAssist/AARoadsideAssistOffer';
import LifestyleAndRewards from '../../../contents/partner-offers/LifestyleAndRewards/LifestyleAndRewards';
import QuoteForm from '../../../contents/partner-offers/BayPort/Quote/QuoteForm';
import ResultComponent from '../../../contents/partner-offers/BayPort/Result/ResultComponent';
import { baseUrl } from '../../../../shared/baseUrl';
import { Redirect } from 'react-router-dom';
import { IframeComponent } from '../../../../components/IFrame/IFrameComponent';
import CrimeAndSecurityAssist from '../../../contents/partner-offers/CrimeAndSecurityAssist/CrimeAndSecurityAssist';
import BailAssist from '../../../contents/partner-offers/BailAssist/BailAssist';
// import MainComponent from '../../components/Main/MainComponent';

class OfferPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toOffers: false,
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			toOffers: true,
		});
	};

	render() {
		console.log('Location', JSON.stringify(this.props.location));
		if (this.state.toOffers === true) {
			return <Redirect to="/offers_and_services/featured" />;
		}
		return (
			<Container className="mt-2">
				<Row>
					<Col className="col-auto col-md-1 d-none d-md-flex justify-content-center align-self-center">
						<Row>
							<Col className="col-12 col-md-11">
								<a
									onClick={() => {
										this.navigateToPage();
									}}
								>
									<img src={`${baseUrl}assets/img/previous_arrow_active.svg`} alt="" width="100%" />
									<p className="white-text">PreviousArrow</p>
								</a>
							</Col>
						</Row>
					</Col>
					<Col className="col-12 col-md-11">
						{this.props.location.pathname === '/offers/featured/offer/bayport/req-form' && (
							<BayPortContent fetchBayPortQuote={this.props.fetchBayPortQuote} />
						)}
						{this.props.location.pathname ===
							'/offers/featured/offer/bayport/accept-guote-form' && (
							<QuoteForm
								location={this.props.location}
								bayPortQuote={this.props.bayPortQuote}
								acceptBayPortQuote={this.props.acceptBayPortQuote}
							/>
						)}
						{this.props.location.pathname ===
							'/offers/featured/offer/bayport/credit-acknowledgment' && (
							<ResultComponent bayPortAcknowledgment={this.props.bayPortAcknowledgment} />
						)}
						{(this.props.location.pathname === '/offers/featured/offer/aa-roadside/req-form' ||
							this.props.location.pathname ===
								'/offers/featured/offer/aa-roadside-bundled/req-form') && (
							<AARoadsideAssistOffer
								location={this.props.location}
								shoppingCart={this.props.shoppingCart}
								addShoppingCartItem={this.props.addShoppingCartItem}
								user={this.props.user}
							/>
						)}
						{this.props.location.pathname ===
							'/offers/featured/offer/lifestyle-rewards/req-form' && (
							<LifestyleAndRewards
								location={this.props.location}
								shoppingCart={this.props.shoppingCart}
								addShoppingCartItem={this.props.addShoppingCartItem}
								user={this.props.user}
							/>
						)}
						{this.props.location.pathname ===
							'/offers/featured/offer/crime-and-security-assist/req-form' && (
							<CrimeAndSecurityAssist
								location={this.props.location}
								shoppingCart={this.props.shoppingCart}
								addShoppingCartItem={this.props.addShoppingCartItem}
								user={this.props.user}
							/>
						)}
						{this.props.location.pathname === '/offers/featured/offer/bail-assist/req-form' && (
							<BailAssist
								location={this.props.location}
								shoppingCart={this.props.shoppingCart}
								addShoppingCartItem={this.props.addShoppingCartItem}
								user={this.props.user}
							/>
						)}
						{this.props.location.pathname === '/offers/featured/offer/carzar/req-form' && (
							<Card>
								<CardBody>
									<IframeComponent url="https://staging4.carzar.co.za/widget-test" />
								</CardBody>
							</Card>
						)}
						{this.props.location.pathname === '/offers/featured/offer/dealfin/req-form' && (
							<Card>
								<CardBody>
									<IframeComponent url="https://dealfin.net/dealfin-fast-track/" />
								</CardBody>
							</Card>
						)}
						{this.props.location.pathname ===
							'/offers/featured/offer/dealfin-private-finance/req-form' && (
							<Card>
								<CardBody>
									<IframeComponent url="https://dealfin.net/cars/" />
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default OfferPage;
