import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Row } from 'mdbreact';


export default (props) => {

    let content = null
    let image = null

    if (props.content) {
        content = <Col className="col-12 col-md-8 px-2 pt-3">
            <ReactMarkdown source={props.content} className="px-5"/>
        </Col>
    }

    if (props.image) {
        image = <Col className="col-12 col-md-4">
            <div className="px-5 py-3">
                <img
                    src={props.image}
                    alt=""
                    width="100%"
                />
            </div>
        </Col>
    }

    return (
        <Row className="px-2 pt-2"> 
            {content}
            {image}       
        </Row> 
    )
};

