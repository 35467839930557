import React, { Component } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import InputComponent from '../../../../../components/Input/InputComponent';

class AirtimeVASPinLessFormInput extends Component {
	render() {
		return (
			<MDBRow>
				<MDBCol className="col-12">
					{this.props.location.pathname.includes(
						'/services/buy/blue-label/electricity/vas-network-list-form'
					) && (
						<MDBRow>
							<MDBCol md="6">
								<InputComponent
									name="MeterNumber"
									id="MeterNumber"
									label="Meter Number"
									hint=""
									disabled={false}
									type="number"
									handleChange={this.props.handleChange}
									required={true}
								/>
							</MDBCol>
						</MDBRow>
					)}
					<MDBRow>
						<MDBCol md="6">
							<InputComponent
								name="PhoneNumber"
								id="PhoneNumber"
								label="Phone Number"
								hint="+27"
								disabled={false}
								type="tel"
								handleChange={this.props.handleChange}
								outline={true}
								required={true}
								minLength={10}
							/>
						</MDBCol>
						<MDBCol md="6">
							<InputComponent
								name="Amount"
								id="Amount"
								label="Amount"
								hint="0.0"
								step="0.1"
								pattern="^\d*(\.\d{0,1})?$"
								disabled={false}
								type="price"
								required={true}
								outline={true}
								handleChange={this.props.handleChange}
							/>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		);
	}
}

export default AirtimeVASPinLessFormInput;
