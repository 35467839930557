import * as ActionTypes from '../../ActionTypes';

export const EnvironmentVariables = (
	state = {
		isLoading: true,
		errMess: null,
		environmentV: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.ENV_VARIABLES_SUCCESS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				environmentV: action.payload
			};
		case ActionTypes.ENV_VARIABLES_LOADING:
			return {
				...state,
				isLoading: true,
				errMess: null,
				environmentV: []
			};
		case ActionTypes.ENV_VARIABLES_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				environmentV: []
			};

		default:
			return state;
	}
};
