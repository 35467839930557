import React, { Component } from 'react';
import './Vehicle.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOfferSelect } from '../../../../redux/launchOffers/actionCreators/selectedOffer';
import { Loading } from '../../../../components/Loading/LoadingComponent';

const PRODUCT = {
	CAMPAIGN_ID: 3,
	// CAMPAIGN_ID: 117,
	CAMPAIGN_NAME: 'Splendi Vehicle Insure',
};

class VehicleOffer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offerCreated: false,
		};

		this.createOfferSelect = this.createOfferSelect.bind(this);
	}

	async createOfferSelect() {
		const offerData = {
			campaignReference: PRODUCT.CAMPAIGN_ID,
			sourceCampaign: PRODUCT.CAMPAIGN_NAME,
		};

		try {
			await this.props.apiOfferCreateSelect(offerData);
			this.setState({ offerCreated: true });
		} catch (err) {
			console.log('Error selecting the offer', err);
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.selectedOffer && this.state.offerCreated) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}

			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			return (
				<div>
					<div>
						<div className="card-big">
							<div className="card-big-header">
								<div className="card-big-header-title-container">
									<div className="rocket-img" />
									<div className="card-big-header-title">
										<div className="heading">VEHICLE INSURANCE</div>
										<div className="text10">
											Vehicles can be complicated, but vehicle insurance doesn't have to be. We know
											that your vehicle is important to you. Get a quote for comprehensive or Third
											Party, Fire & Theft cover options.
										</div>
									</div>
								</div>
								<div className="card-big-header-button">
									<div className="btn-green" onClick={this.createOfferSelect}>
										I'm Interested
									</div>
								</div>
							</div>

							<div className="card-small">
								<div className="car" />
								<div className="card-small-body">
									<div className="heading2">VEHICLE INSURANCE</div>
									<div className="heading3"> Get this cover:</div>
									<div className="heading4">
										{' '}
										Comprehensive | Third Party | Fire | Theft & hijacking | Accidents | Weather
										damage
									</div>
									<div className="icon-box">
										<div className="icon1" />
										<div className="icon2" />
										<div className="icon3" />
										<div className="icon4" />
										<div className="icon5" />
										<div className="icon6" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferCreateSelect: (data) => dispatch(createOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VehicleOffer);
