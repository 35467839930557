import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import KgSection1ContentBanner from './cms-cred-ed-collection/KgSection1ContentBanner';
import KgSection1ImageBanner from './cms-cred-ed-collection/KgSection1ImageBanner';
import KgSection2 from './cms-cred-ed-collection/KgSection2';
import KgSection3 from './cms-cred-ed-collection/KgSection3';
import KgImageBanner from './cms-cred-ed-collection/KgImageBanner';

export default props => {
	let selectedArticle = null;

	if (props.credEdCmsArticles.articles) {
		props.credEdCmsArticles.articles.map(article => {
			if (props.pathname === '/cred-ed/' + article.slug) {
				selectedArticle = article;
			}
		});
	}

	const title = selectedArticle.title;
	const section1Content = selectedArticle.kg_section_1.content_banner.content;
	const section1ContentBanner =
		selectedArticle.kg_section_1.content_banner.banner;
	const section1KgImage =
		selectedArticle.kg_section_1.kg_image_banner[0].image &&
		selectedArticle.kg_section_1.kg_image_banner[0].image.url;
	const section1KgBanner =
		selectedArticle.kg_section_1.kg_image_banner[0].banner;
	const section1Image =
		selectedArticle.kg_section_1.image_banner.image &&
		selectedArticle.kg_section_1.image_banner.image.url;
	const section1Banner = selectedArticle.kg_section_1.image_banner.banner;
	const section2Content1 = selectedArticle.kg_section_2.content_1;
	const section2Content2 = selectedArticle.kg_section_2.content_2;
	const section2Image1 =
		selectedArticle.kg_section_2.image_1 &&
		selectedArticle.kg_section_2.image_1.url;
	const section2Image2 =
		selectedArticle.kg_section_2.image_2 &&
		selectedArticle.kg_section_2.image_2.url;
	const section3Content = selectedArticle.kg_section_3.content;
	const section3Image =
		selectedArticle.kg_section_3.image &&
		selectedArticle.kg_section_3.image.url;
	const kgFooterImage =
		selectedArticle.kg_footer.kg_image_banner[0].image &&
		selectedArticle.kg_footer.kg_image_banner[0].image.url;
	const kgFooterBanner = selectedArticle.kg_footer.kg_image_banner[0].banner;

	let kgSection3 = null;

	if (section3Content || section3Image) {
		kgSection3 = <KgSection3 content={section3Content} image={section3Image} />;
	}

	const commonDisplay = (
		<>
			<Row className="px-2 pt-2">
				<Col className="col-12 col-md-6 px-2 pt-3">
					<CardTitle className="h4-responsive title pb-2 pl-5 pr-4">
						{title}
					</CardTitle>
					<KgSection1ContentBanner
						content={section1Content}
						banner={section1ContentBanner}
					/>
				</Col>
				<Col className="col-12 col-md-6">
					<div className="px-5">
						<KgImageBanner
							id="DashboardKG"
							kredGuruImage={section1KgImage}
							content={section1KgBanner}
						/>
					</div>
					<KgSection1ImageBanner
						image={section1Image}
						banner={section1Banner}
					/>
				</Col>
			</Row>
			<KgSection2
				content1={section2Content1}
				content2={section2Content2}
				image1={section2Image1}
				image2={section2Image2}
			/>
			{kgSection3}
			<Row>
				<Col className="col-12 col-md-6 offset-md-3">
					<KgImageBanner
						id="DashboardKG"
						kredGuruImage={kgFooterImage}
						content={kgFooterBanner}
					/>
				</Col>
			</Row>
		</>
	);

	const desktopDisplay = (
		<FadeTransform
			in
			transfromProps={{
				exitTransform: 'scale(0.5) translate()-50%'
			}}
		>
			<Card className="mt-3">
				<CardBody>{commonDisplay}</CardBody>
			</Card>
		</FadeTransform>
	);

	const mobileDisplay = (
		<FadeTransform
			in
			transfromProps={{
				exitTransform: 'scale(0.5) translate()-50%'
			}}
		>
			{commonDisplay}
		</FadeTransform>
	);

	if (props.isMobile) {
		return <>{mobileDisplay}</>;
	} else {
		return <>{desktopDisplay}</>;
	}
};
