import React from 'react';
import { MDBInput } from 'mdbreact';

class GroupRadioButtonComponent extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.state = {
			radio: 0
		};
	}

	onClick(value) {
		this.setState({ radio: value });
	}

	render() {
		const radioButtons = this.props.radioButtons.map((radioButton, index) => {
			return (
				<MDBInput
					gap
					onClick={() => this.onClick(radioButton.value)}
					checked={this.state.radio === radioButton.value ? true : false}
					getValue={this.props.getValue}
					value={this.state.radio === radioButton.value ? true : false}
					type="radio"
					label={radioButton.label}
					name={radioButton.name}
					id={radioButton.id}
					key={index}
				/>
			);
		});
		return (
			<div name={this.props.name} id={this.props.id}>
				<label className="text-muted form-label-text">{this.props.label}</label>
				{radioButtons}
			</div>
		);
	}
}

export default GroupRadioButtonComponent;
