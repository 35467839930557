import * as ActionTypes from '../../ActionTypes';

export const AvailableOffers = (
	state = {
		isLoading: true,
		errMess: null,
		available_offers: []
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.AVAILABLE_OFFERS:
			return {
				...state,
				isLoading: false,
				errMess: null,
				available_offers: action.payload
			};
		case ActionTypes.AVAILABLE_OFFERS_LOADING:
			return { ...state, isLoading: true, errMess: null, available_offers: [] };
		case ActionTypes.AVAILABLE_OFFERS_FAILED:
			return {
				...state,
				isLoading: false,
				errMess: action.payload,
				available_offers: []
			};
		default:
			return state;
	}
};
