import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import CheckBoxComponent from '../../../../components/CheckBox/CheckBoxComponent';

export const AccountDisputeNature = (props) => {
	const { handleCheckChange, natureOfDispute } = props;
	return (
		<>
			<MDBRow className="mt-4">
				<MDBCol md="4">
					<CheckBoxComponent
						id="accountPaidFull"
						name="accountPaidFull"
						label="I have paid my account in full"
						disabled={false}
						value={natureOfDispute.accountPaidFull}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="accountIsClosed"
						name="accountIsClosed"
						label="Account is closed"
						disabled={false}
						value={natureOfDispute.accountIsClosed}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="notMyAccount"
						name="notMyAccount"
						label="This is not my account"
						disabled={false}
						value={natureOfDispute.notMyAccount}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol md="4">
					<CheckBoxComponent
						id="paidMyAccount"
						name="paidMyAccount"
						label="I paid my account before it went to collections"
						disabled={false}
						value={natureOfDispute.paidMyAccount}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
				<MDBCol md="4">
					<CheckBoxComponent
						id="accountIsPrescribed"
						name="accountIsPrescribed"
						label="My account is prescribed"
						disabled={false}
						value={natureOfDispute.accountIsPrescribed}
						required={true}
						filled={true}
						handleChange={handleCheckChange}
						containerClass="mr-5"
					/>
				</MDBCol>
			</MDBRow>
		</>
	);
};
