import React from "react";
import {MDBRow, MDBCol} from "mdbreact";
import AirtimeVASNetworksForm from "../../Services/Buy/BlueLabel/AirtimeVASNetworksForm";
import BuyVASProductResult from "./BlueLabel/BuyVASProductResult";

class BuyComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toDashboard:false,
		};
		this.navigateToPage = this.navigateToPage.bind(this);
	}

	navigateToPage = () => {
		this.setState({
			toDashboard: true
		} )
	};

	render() {
		return (
			<MDBRow>
				<MDBCol>
					{(this.props.location.pathname.includes("/services/buy/blue-label/airtime/vas-network-list-form")||
						this.props.location.pathname.includes("/services/buy/blue-label/bundles/vas-network-list-form")||
						this.props.location.pathname.includes("/services/buy/blue-label/electricity/vas-network-list-form"))&&
					<AirtimeVASNetworksForm location={this.props.location}
																	network={this.props.network}
																	setNetwork={this.props.setNetwork}
																	airtimeVASNetworks={this.props.airtimeVASNetworks}
																	fetchAirtimeVASProducts={this.props.fetchAirtimeVASProducts}
																	airtimeVASProducts={this.props.airtimeVASProducts}
																	buyVASProduct={this.props.buyVASProduct}

					/>}
					{this.props.location.pathname.includes("/services/buy/blue-label/transaction/result")&&
					<BuyVASProductResult location={this.props.location}
															 buyVASProductResponse={this.props.buyVASProductResponse}/>}
				</MDBCol>
			</MDBRow>
		);
	}
}

export default BuyComponent;
