import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'mdbreact';
import './OverviewContent.css';
import { Loading } from '../../../../components/Loading/LoadingComponent';
import { FadeTransform } from 'react-animation-components';
import CreditScoreSection from '../../../sections/Overview/CreditScoreSection';
import CreditScoreSectionLite from '../../../sections/Overview/CreditScoreSectionLite';
import { ErrorComponent } from '../../../../components/Error/ErrorComponent';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_LIST } from '../../../../shared/featureToggles';

function RenderConsumerSummary({ consumer }) {
	const totalNoOFActiveAccounts = (
		<Card>
			<Row>
				<Col className="col-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive  ml-2">No. of Active Accounts</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-4 text-white text-center bg-color-purple-dark-2 credit-value">
					<CardBody>
						<CardTitle className="h4-responsive">
							{consumer.ConsumerCPANLRDebtSummary.NoOFActiveAccounts.value}
						</CardTitle>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalGoodAccount = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">No. of Accounts in Good Standing</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerCPANLRDebtSummary.NoOfAccountInGoodStanding.value}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalBadAccount = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">No. of Accounts in Bad Standing</CardTitle>
					</CardBody>
				</Col>
				<Col
					className={`col-5 col-md-4 text-white text-center bg-color-purple-dark-2  px-0 ${
						Number(consumer.ConsumerCPANLRDebtSummary.NoOfAccountInBadStanding.value) > 0
							? 'bg-color-red'
							: 'bg-color-purple'
					}  credit-value`}
				>
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerCPANLRDebtSummary.NoOfAccountInBadStanding.value}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalOutstandingDebt = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Total Outstanding Debt</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<CardTitle className="h4-responsive">
							R {Number(consumer.ConsumerCPANLRDebtSummary.TotalOutStandingDebt.value).toFixed(2)}
						</CardTitle>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalMonthlyInstalment = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Total Monthly Instalment</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<CardTitle className="h4-responsive">
							R{' '}
							{Number(consumer.ConsumerCPANLRDebtSummary.TotalMonthlyInstallment.value).toFixed(2)}
						</CardTitle>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalAreas = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Total Arrears Amount</CardTitle>
					</CardBody>
				</Col>
				<Col
					className={`col-5 col-md-4 text-white text-center px-0 ${
						Number(consumer.ConsumerCPANLRDebtSummary.TotalArrearAmount.value) > 0
							? 'bg-color-red'
							: 'bg-color-purple-dark-2'
					}  credit-value`}
				>
					<CardBody className="px-0">
						<CardTitle className="h4-responsive">
							R {Number(consumer.ConsumerCPANLRDebtSummary.TotalArrearAmount.value).toFixed(2)}
						</CardTitle>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const totalNewAccountsOpened = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">New Accounts Opened</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerCPANLRDebtSummary.NoOfAccountsOpenedinLast45Days.value}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const adverseListings = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Adverse Listings</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerAdverseInformation instanceof Array
								? consumer.ConsumerAdverseInformation.length
								: 0}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const directorshipListings = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Directorship Listings</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerDirectorSummary.NumberOfCompanyDirector.value}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	const propertyshipListings = (
		<Card>
			<Row>
				<Col className="col-7 col-md-8 align-self-center">
					<CardBody>
						<CardTitle className="h4-responsive ml-2">Property Listings</CardTitle>
					</CardBody>
				</Col>
				<Col className="col-5 col-md-4 text-white text-center bg-color-purple-dark-2 credit-value px-0">
					<CardBody className="px-0">
						<h2 className="h2-responsive">
							{consumer.ConsumerPropertyInformationSummary.TotalProperty.value}
						</h2>
					</CardBody>
				</Col>
			</Row>
		</Card>
	);

	return (
		<Row className="credit-report-overview px-3 px-md-0">
			<Col className="col-12 mt-4">{totalGoodAccount}</Col>
			<Col className="col-12 mt-4">{totalBadAccount}</Col>
			<Col className="col-12 mt-4">{totalOutstandingDebt}</Col>
			<Col className="col-12 mt-4">{totalMonthlyInstalment}</Col>
			<Col className="col-12 mt-4">{totalAreas}</Col>
			<Col className="col-12 mt-4">{totalNewAccountsOpened}</Col>
			<Col className="col-12 mt-4">{adverseListings}</Col>
			<Col className="col-12 mt-4">{directorshipListings}</Col>
			<Col className="col-12 mt-4">{propertyshipListings}</Col>
		</Row>
	);
}

const OverviewContent = (props) => {
	if (props.consumer.isLoading) {
		return <Loading />;
	} else if (props.consumer.errMess) {
		return <ErrorComponent />;
	} else {
		const consumer = props.consumer.consumer;
		return (
			<Container className="mt-3">
				<FeatureToggle featureName={FEATURE_TOGGLE_LIST.CREDIT_ED_LITE} showOnlyWhenDisabled>
					<FadeTransform
						in
						transfromProps={{
							exitTransform: 'scale(0.5) translate()-50%',
						}}
					>
						<Row>
							<Col className="col-12 col-md-6 pr-md-5">
								<Row>
									<Col className="col-12 mb-md-3">
										<h4 style={{ color: '#5151F9' }} className="h4-responsive title pl-3">
											OVERVIEW
										</h4>
									</Col>
								</Row>
								<RenderConsumerSummary consumer={consumer} />
							</Col>
							<CreditScoreSection consumer={props.consumer} />
						</Row>
					</FadeTransform>
				</FeatureToggle>

				<FeatureToggle featureName={FEATURE_TOGGLE_LIST.CREDIT_ED_LITE}>
					<FadeTransform
						in
						transfromProps={{
							exitTransform: 'scale(0.5) translate()-50%',
						}}
					>
						<Row>
							<Col className="col-12 pl-5 mb-3">
								<h4 style={{ color: '#5151f9' }} className="h4-responsive dashboardTitle">
									OVERVIEW
								</h4>
							</Col>
							<CreditScoreSectionLite consumer={props.consumer} />
							<Col className="col-12 col-md-6 px-5 pl-md-1 pr-md-5">
								<RenderConsumerSummary consumer={consumer} />
							</Col>
						</Row>
					</FadeTransform>
				</FeatureToggle>
			</Container>
		);
	}
};
export default OverviewContent;
