import React from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemTitle,
	AccordionItemBody,
} from 'react-accessible-accordion';
import { Row, Col } from 'mdbreact';
import 'react-accessible-accordion/dist/fancy-example.css';
import './AccountsComponent.css';

export const AccountCollapseComponent = (props) => {
	const accordionContent = props.accordionContent.map((key, index) => {
		return (
			<AccordionItem key={index} className="test">
				<AccordionItemTitle>
					<Row>
						<Col className="col-10">
							<h6>{key.headerContent}</h6>
						</Col>
						<Col className="col-2 ">
							<div className="accordion__arrow" role="presentation" />
						</Col>
					</Row>
				</AccordionItemTitle>
				<AccordionItemBody>{key.bodyContent}</AccordionItemBody>
			</AccordionItem>
		);
	});
	return <Accordion>{accordionContent}</Accordion>;
};
