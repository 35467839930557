import React from 'react';
import { MDBContainer, MDBIframe } from 'mdbreact';

export const IframeComponent = props => {
	return (
		<MDBContainer className="text-center">
			<MDBIframe src={props.url} />
		</MDBContainer>
	);
};
