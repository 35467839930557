import React, { Component } from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	Col,
	MDBBtn,
	MDBTooltip,
	Row
} from 'mdbreact';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../shared/baseUrl';
import './LifestyleAndRewards.css';
class LifestyleAndRewards extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offer: false
		};
	}
	navigateToPage = () => {
		this.setState({
			toQuote: true
		});
	};
	subscribeNow = () => {
		this.setState({
			offer: true
		});
	};

	render() {
		const userPermission = this.props.user.user.userPermissions;
		const filterByOfferID1 =
			userPermission !== undefined
				? userPermission.filter(value => value.OfferID === 1)
				: undefined;
		const offerID1 =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].OfferID
				: undefined;
		const isUserSubscribed =
			filterByOfferID1 !== undefined && filterByOfferID1.length > 0
				? filterByOfferID1[0].IsSubscribed
				: undefined;
		const isSubscribed =
			offerID1 === 1 && isUserSubscribed ? (
				<Col className="col-12 col-md-4 pt-4">
					<h5 className="pt-4">You have already subscribed</h5>
				</Col>
			) : (
				<>
					<Col className="col-12 col-md-2 pt-5 pr-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/1">
								<MDBBtn size="md" color="success" className="font-weight-bold">
									R24.99 per month
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
					<Col className="col-12 col-md-2 pt-5 pr-0 pl-0">
						<MDBTooltip placement="top">
							<Link to="/new-subscription/3">
								<MDBBtn color="success" size="md" className="font-weight-bold">
									R299.88 per year
								</MDBBtn>
							</Link>
							<div>Subscribe now</div>
						</MDBTooltip>
					</Col>
				</>
			);
		return (
			<FadeTransform
				in
				transfromProps={{
					exitTransform: 'scale(0.5) translate()-50%'
				}}
			>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="d-md-flex justify-content-between ">
									<div className="p-2 text-left">
										<CardTitle className="h4-responsive title text-left">
											LIFESTYLE & REWARDS
										</CardTitle>
										<p>
											Splendi’s Lifestyle & Rewards Offer includes the
											following:
										</p>
									</div>
									{isSubscribed}
									<div className="p-2 col-example text-md-right">
										<img
											src={`${baseUrl}assets/img/splendi_launch_offer_rocket_icon-small.svg`}
											alt="launch icon"
											width="50%"
										/>
										<p className="white-text">LaunchOfferRocketIcon</p>
									</div>
								</div>
								<Row>
									<Col className="col-12 col-md-6">
										<div className="d-flex flex-row">
											<div className="px-2">
												<CardTitle className="h4-responsive font-weight-bolder">
													Lifestyle Mall
												</CardTitle>
											</div>
										</div>
										<div className="px-2">
											<p>
												The Mall is a digital platform that groups a vast
												network of partners into a single rewards and benefits
												solution.
												<br />
												Members get access to deals and benefits from hundreds
												of leading brands – with over 350 partners, and over 80%
												having a national footprint.
												<br />
												The Mall offers benefits across numerous lifestyle
												categories, and you will be the first to know when
												exclusive offers launch on the platform.
											</p>
											<label className="h4-responsive font-weight-bolder">
												Benefits to members
											</label>
											<ul>
												<li>
													24-hour, unlimited access, allowing members to
													download vouchers.
												</li>
												<li>
													Multi-channel voucher and coupon redemption options to
													streamline the shopping experience.
												</li>
												<li>
													Online support providing members with prompt responses
													to queries and questions.
												</li>
												<li>
													Multiple benefit and partner categories that range
													from day-to-day items to dream purchases.
												</li>
												<li>
													New partners and deals go live on an on-going basis,
													through a dedicated partner procurement and management
													team. We ensure Lifestyle Mall members receive the
													latest, and most meaningful benefits - saving them
													time and money.
												</li>
											</ul>
										</div>
									</Col>
									<Col className="col-12 col-md-6">
										<img
											src={`${baseUrl}assets/img/lifestyle_article_image.svg`}
											alt="lifestyle article image"
											width="100%"
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					{this.props.location.pathname ===
						'/offers/featured/offer/lifestyle-rewards/req-form' && (
						<Col className="col-auto col-md-1 d-flex justify-content-center align-self-center">
							<a
								onClick={() => {
									document.dispatchEvent(new Event('submit'));
									this.navigateToPage();
								}}
							>
								<img
									src={`${baseUrl}assets/img/next_arrow_active.svg`}
									alt=""
									width="100%"
								/>
								<p className="white-text">PreviousArrow</p>
							</a>
						</Col>
					)}
				</Row>
			</FadeTransform>
		);
	}
}

export default LifestyleAndRewards;
