import React, { Component } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import CheckBoxComponent from '../../../components/CheckBox/CheckBoxComponent';
import { Loading } from '../../../components/Loading/LoadingComponent';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';

class CommunicationOption extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFormValid: false,
		};
	}

	handleSaveContactInfo = async (e) => {
		e.preventDefault();
		await this.props.handleContactSubmit(e);
	};
	saveAndContinue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};
	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { handleCommWaysCheckBox, contact, updateContact } = this.props;
		let showBtn = (
			<MDBBtn
				color="white-text"
				size="sm"
				type="submit"
				className="btn-custom-primary"
				onClick={this.handleSaveContactInfo}
			>
				save
			</MDBBtn>
		);
		if (updateContact.isLoading) {
			return <Loading />;
		} else if (updateContact.errMess) {
			return <ErrorComponent />;
		} else {
			let successMsg = '';
			if (
				typeof updateContact.updateContact.response !== 'undefined' &&
				updateContact.updateContact.response.status
			) {
				successMsg =
					updateContact.updateContact.response.message + ', click the next button to continue';
				showBtn = (
					<MDBBtn
						color=" white-text"
						size="sm"
						className="btn-custom-primary font-weight-bold "
						onClick={this.saveAndContinue}
					>
						Next
					</MDBBtn>
				);
			}
			return (
				<MDBContainer>
					<MDBRow>
						<MDBCol className="col-12">
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold font-weight-bold my-2">
										Communication - Please indicate as to how you want to be contacted on the
										outcome of this investigation
									</p>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol>
									<p className="text-center font-weight-bold my-2 text-success">{successMsg}</p>
								</MDBCol>
							</MDBRow>
							<MDBRow className="mt-5">
								<MDBCol md="6" className="text-md-center">
									<CheckBoxComponent
										id="telephone"
										name="telephone"
										label="Telephone - mobile"
										disabled={false}
										value={contact.telephone}
										required={true}
										filled={true}
										handleChange={handleCommWaysCheckBox}
										containerClass="mr-5"
									/>
								</MDBCol>
								<MDBCol md="6" className="text-md-center">
									<CheckBoxComponent
										id="email"
										name="email"
										label="Email"
										disabled={false}
										value={contact.email}
										required={true}
										filled={true}
										handleChange={handleCommWaysCheckBox}
										containerClass="mr-5"
									/>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol style={{ textAlign: 'center' }} className="col-12 col-md-12 mt-5 mb-5">
									<MDBBtn
										color="white-text"
										size="sm"
										className="btn-custom-secondary mr-md-5"
										onClick={this.back}
									>
										previous
									</MDBBtn>
									{showBtn}
								</MDBCol>
							</MDBRow>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			);
		}
	}
}

export default CommunicationOption;
