import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import KredGuruSection from '../../../sections/KredGuru/KredGuruSection';

const SendEmailSuccess = () => {
	let signature = 'KG – Splendi’s Kred Guru';
	let kredGuru;
	let kredImage = 'kred_guru_+2.svg';
	let kgMessage = (
		<div>
			Your request for a PDF copy of your credit report has been received. An agent will be in touch
			with you shortly.
		</div>
	);
	kredGuru = (
		<KredGuruSection
			id="sendEmailKG"
			kredGuruImage={kredImage}
			header={null}
			content={kgMessage}
			signature={signature}
		/>
	);
	return (
		<MDBRow>
			<MDBCol className="col-12 col-md-7">
				<MDBCard>
					<MDBCardBody>
						<MDBRow>
							<MDBCol className="col-12 col-md-11 mt-3">{kredGuru}</MDBCol>
						</MDBRow>
					</MDBCardBody>
				</MDBCard>
			</MDBCol>
		</MDBRow>
	);
};
export default SendEmailSuccess;
