import React, { Component } from 'react';
import './Vehicle.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOfferSelect } from '../../../../redux/launchOffers/actionCreators/selectedOffer';
import { Loading } from '../../../../components/Loading/LoadingComponent';

const PRODUCT = {
	// CAMPAIGN_ID: 119,
	CAMPAIGN_ID: 5,
	CAMPAIGN_NAME: 'Splendi Consolidation Loan',
};
class Consolidation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offerCreated: false,
		};

		this.createOfferSelect = this.createOfferSelect.bind(this);
	}

	async createOfferSelect() {
		const offerData = {
			campaignReference: PRODUCT.CAMPAIGN_ID,
			sourceCampaign: PRODUCT.CAMPAIGN_NAME,
		};

		try {
			await this.props.apiOfferCreateSelect(offerData);
			this.setState({ offerCreated: true });
		} catch (err) {
			console.log('Error selecting the offer', err);
		}
	}

	render() {
		if (this.props.selectedOffer.isLoading) {
			return <Loading />;
		} else {
			if (this.props.selectedOffer.selectedOffer && this.state.offerCreated) {
				return <Redirect to="/offers_and_services/acknowledge" />;
			}

			if (this.props.selectedOffer.errMess) {
				return <Redirect to="/offers_and_services/failure" />;
			}

			return (
				<div>
					<div>
						<div className="card-big">
							<div className="card-big-header">
								<div className="card-big-header-title-container">
									<div className="rocket-img" />
									<div className="card-big-header-title">
										<div className="heading">CONSOLIDATION LOANS</div>
										<div className="text10">
											Debt can adversely affect your financial health and act as a roadblock to
											growing your savings. By taking out a single loan to pay off your old debts,
											you are left with just one monthly payment that can actually save you money
											and improve your cash flow.
										</div>
									</div>
								</div>
								<div className="card-big-header-button">
									<div className="btn-green" onClick={this.createOfferSelect}>
										I'm Interested
									</div>
								</div>
							</div>

							<div className="card-small">
								<div className="consolidation" />
								<div className="card-small-body">
									<div className="heading2">CONSOLIDATION LOANS</div>
									<div className="offerTxt">
										Paying off more than one debt at a time can be challenging and complicated. By
										combining multiple debts together, you could get a lower overall interest rate
										when compared to paying them all down individually.
									</div>
									<div className="offerTxt">
										Lower rates mean that more money goes to the original debt, and less money is
										gobbled up by interest. With the extra money, you can either make bigger debt
										payments to pay it all down faster or have some breathing room in your monthly
										budget to build up some savings.
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>
				</div>
			);
		}
	}
}

const mapDispatchToProps = (dispatch) => ({
	apiOfferCreateSelect: (data) => dispatch(createOfferSelect(data)),
});
const mapStateToProps = (state) => {
	return {
		user: state.user,
		selectedOffer: state.selectedOffer,
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Consolidation);
