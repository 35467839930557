import React from 'react';
import { MDBInput } from 'mdbreact';
import { FieldFeedbacks, FieldFeedback } from 'react-form-with-constraints';

import './InputCompenent.css';

import styles from './InputComponent.module.css';

class InputComponent extends React.Component {
	render() {
		let inputField = <div />;
		const asterisk = <span style={{ color: '#5151F9', marginLeft: '5px' }}>*</span>;
		let label = this.props.label;
		if (this.props.required === true) {
			label = (
				<p className={styles.inputLabel}>
					{label}
					{asterisk}
				</p>
			);
		} else {
			label = <p className={styles.inputLabel}>{label}</p>;
		}
		if (this.props.type === 'password') {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					disabled={this.props.disabled}
					type={this.props.type}
					value={this.props.value}
					required={this.props.required}
					pattern={this.props.pattern}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="valueMissing" />
						<FieldFeedback className="invalid-feedback" when="patternMismatch">
							Should be at least {this.props.minLength} characters long
						</FieldFeedback>
						<FieldFeedback className="invalid-feedback" when={(value) => !/\d/.test(value)} warning>
							Should contain numbers
						</FieldFeedback>
						<FieldFeedback
							className="invalid-feedback"
							when={(value) => !/[a-z]/.test(value)}
							warning
						>
							Should contain small letters
						</FieldFeedback>
						<FieldFeedback
							className="invalid-feedback"
							when={(value) => !/[A-Z]/.test(value)}
							warning
						>
							Should contain capital letters
						</FieldFeedback>
					</FieldFeedbacks>
				</MDBInput>
			);
		} else if (this.props.type === 'text') {
			inputField = (
				<div>
					{label}
					<MDBInput
						className={styles.inputFields}
						name={this.props.name}
						id={this.props.id}
						hint={this.props.hint}
						readOnly={this.props.readOnly}
						disabled={this.props.disabled}
						type={this.props.type}
						value={this.props.value}
						required={this.props.required}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}
						onChange={this.props.handleChange}
						outline={this.props.outline}
					>
						<FieldFeedbacks for={this.props.name}>
							<FieldFeedback className="invalid-feedback" when="*" />
						</FieldFeedbacks>
					</MDBInput>
				</div>
			);
		} else if (this.props.type === 'textarea') {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					type="textarea"
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					rows={this.props.rows}
					required={this.props.required}
					readOnly={this.props.readOnly}
					icon="pencil"
					disabled={this.props.disabled}
					value={this.props.value}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="*" />
					</FieldFeedbacks>
				</MDBInput>
			);
		} else if (this.props.type === 'price') {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					disabled={this.props.disabled}
					type="number"
					step={this.props.step}
					readOnly={this.props.readOnly}
					value={this.props.value}
					pattern={this.props.pattern}
					required={this.props.required}
					min={this.props.min}
					minLength={this.props.minLength}
					maxLength={this.props.maxLength}
					max={this.props.max}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks className="red-text" for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="*" />
					</FieldFeedbacks>
				</MDBInput>
			);
		} else if (this.props.type === 'digit') {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					disabled={this.props.disabled}
					readOnly={this.props.readOnly}
					type="number"
					required={this.props.required}
					value={this.props.value}
					step={this.props.step}
					pattern="[0-9]"
					min={this.props.min}
					minLength={this.props.minLength}
					maxLength={this.props.maxLength}
					max={this.props.max}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="*" />
					</FieldFeedbacks>
				</MDBInput>
			);
		} else if (this.props.type === 'number') {
			inputField = (
				<div>
					{label}
					<MDBInput
						className={styles.inputFields}
						name={this.props.name}
						id={this.props.id}
						value={this.props.value}
						hint={this.props.hint}
						disabled={this.props.disabled}
						type="number"
						step="any"
						required={this.props.required}
						readOnly={this.props.readOnly}
						min={this.props.min}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}
						max={this.props.max}
						onChange={this.props.handleChange}
						outline={this.props.outline}
					>
						<FieldFeedbacks for={this.props.name}>
							<FieldFeedback className="invalid-feedback" when="*" />
						</FieldFeedbacks>
					</MDBInput>
				</div>
			);
		} else if (this.props.type === 'email') {
			inputField = (
				<div>
					{label}
					<MDBInput
						className={styles.inputFields}
						name={this.props.name}
						id={this.props.id}
						hint={this.props.hint}
						disabled={this.props.disabled}
						readOnly={this.props.readOnly}
						type={this.props.type}
						value={this.props.value}
						required={this.props.required}
						minLength={this.props.minLength}
						onChange={this.props.handleChange}
						outline={this.props.outline}
					>
						<FieldFeedbacks for={this.props.name}>
							<FieldFeedback className="invalid-feedback" when="tooShort">
								Too short
							</FieldFeedback>
							<FieldFeedback className="invalid-feedback" when="*" />
							<FieldFeedback className="invalid-feedback" when={(value) => value.length === 0}>
								Please fill out this field.
							</FieldFeedback>
							<FieldFeedback className="invalid-feedback" when={(value) => !/\S+@\S+/.test(value)}>
								Invalid email address.
							</FieldFeedback>
							<FieldFeedback className="valid-feedback" when="valid">
								Looks good!
							</FieldFeedback>
						</FieldFeedbacks>
					</MDBInput>
				</div>
			);
		} else if (this.props.type === 'tel') {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					disabled={this.props.disabled}
					type="tel"
					readOnly={this.props.readOnly}
					value={this.props.value}
					required={this.props.required}
					minLength={this.props.minLength}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="tooShort">
							Too short
						</FieldFeedback>
						<FieldFeedback className="invalid-feedback" when="*" />
						<FieldFeedback className="invalid-feedback" when={(value) => value.length === 0}>
							Please fill out this field.
						</FieldFeedback>
						<FieldFeedback
							className="invalid-feedback"
							when={(value) => !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(value)}
						>
							Invalid phone number.
						</FieldFeedback>
						<FieldFeedback className="valid-feedback" when="valid">
							Looks good!
						</FieldFeedback>
					</FieldFeedbacks>
				</MDBInput>
			);
		} else {
			inputField = (
				<MDBInput
					className={styles.inputFields}
					name={this.props.name}
					id={this.props.id}
					hint={this.props.hint}
					disabled={this.props.disabled}
					readOnly={this.props.readOnly}
					type={this.props.type}
					value={this.props.value}
					onChange={this.props.handleChange}
					outline={this.props.outline}
				>
					<FieldFeedbacks for={this.props.name}>
						<FieldFeedback className="invalid-feedback" when="*" />
					</FieldFeedbacks>
				</MDBInput>
			);
		}
		return <div>{inputField}</div>;
	}
}

export default InputComponent;
