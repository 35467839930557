import React, { Component } from 'react';

class ProfilePage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div className="container">Profile</div>;
	}
}

export default ProfilePage;
